import _ from 'lodash';
import { window } from '../../global';
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, LabeledSelect, LabeledCheckbox,
  Button, H5, Box, AddNoteIcon, AddTaskIcon, ShowPopup,
  IconButton,
  Artwork,
  TR,
  colors,
  MailIcon,
} from '@commonsku/styles';

import DateInput, { CSKUInput} from '../DateInput';
import SelectFilePopup from '../SelectFilePopup';
import MentionTextarea from '../MentionTextarea';
import TruncatedText from '../TruncatedText';

import { createAddMessage, createUpdateNote,createUpdateMessage,deleteMessage, createAddComment} from '../../actions/message';
import { createDeleteTemp } from '../../actions/temp';
import { createEditMessagePopup, createEmailMessagePopup } from '../../actions/popup';
import { getUserOptions } from '../../selectors/dropdowns';
import { PinIcon,TrashIcon,PendingApprovalIcon, CommentIcon , EditIcon} from '@commonsku/styles';
import ReactTooltip from 'react-tooltip' ;

import { getCommonskuStyleDropdownOptions, parseMysqlDate, dateStr, toTimestamp, getImageSrc, download, sortMessages, getImageSrcByImgPaths } from '../../utils';
import Img from '../Img';
import { isBrowser } from '../../global';

const TEMP_FILE_REGISTER = 'note_file_id';

const handleClickDownload = (file) => {
  const url = getImageSrc(file, 'original');
  const file_name = file.file_display_name;

  return download(url, file_name);
};

export default function Tasks({ purchase_order_id, onShowPopup, onClosePopup, job_id }) {

  const identity = useSelector(state => state.identity || {});
  const files = useSelector(state => state.temp[TEMP_FILE_REGISTER] ? [state.entities.files[state.temp[TEMP_FILE_REGISTER]]] : []);

  const [addNote, setAddNote] = useState(false);
  const [addTask, setAddTask] = useState(false);
  const [addCommentId, setAddCommentId] = useState(0);
  const [userId, setUserId] = useState(null);
  const [text, setText] = useState(null);
  const [date, setDate] = useState(null);
  const [hoverPinId,setHoverPinId]= useState(0);
  const [hoverDeleteId,setHoverDeleteId]= useState(0);
  const [hoverEditTaskId, setHoverEditTaskId] = useState(0);
  const [hoverCommentId, setHoverCommentId] = useState(0);
  const [commentText , setCommentText] = useState(null);
  const [showFilesPopup, setShowFilesPopup] = useState(false);

  const users = useSelector(state => getCommonskuStyleDropdownOptions(getUserOptions(state)) || []);
  const mention_users = useSelector(state => Object.values(state.entities.users).filter(u => u.mask && u.mask !== ''));
  const po = useSelector(state => state.entities.purchase_orders[purchase_order_id] || {});
  const reminder_list = useSelector(
    state => state.entities.reminder_lists  && state.entities.reminder_lists[purchase_order_id]
    ? Object.values(state.entities.reminder_lists[purchase_order_id].reminder_list)
    : state.entities.messages ? Object.values(state.entities.messages).filter(x=>x.details_parent_id == purchase_order_id ).map(obj=>
      ({...obj.note,actor:obj.actor,replies: obj.replies, pinned:obj.pinned, email: obj.email, text:obj.text, subject:obj.subject, ...(obj.email && {date_created : obj.email.date_created})}))
    : []
  ).filter(x=>x.parent_type != 'JOB' && x.parent_type != 'ORDER' && x.details_parent_type != 'ORDER').sort((a, b) => toTimestamp(b.date_created) - toTimestamp(a.date_created));
  const isTasksNote = ({ date_reminder }) => date_reminder && date_reminder !== '0000-00-00 00:00:00';
  const upcoming_tasks = reminder_list.filter(r => isTasksNote(r) && r.reminder_complete == 0);
  const completed_tasks = reminder_list.filter(r => r.reminder_complete == 1);
  const notes = reminder_list.filter(r => !isTasksNote(r));
  const pinHoverStyle =(id)=>{
    return {
      fontSize:'12px',
      padding : '4px',
      backgroundColor: id === hoverPinId? colors.teal[20]:'',
      border: 'none',
      borderRadius: id === hoverPinId ?'5px':'',
      verticalAlign: 'middle',
      display: 'inline-block',
    };
  };
  const editTaskHoverStyle=(id)=>{
    return {
      fontSize:'12px',
      padding : '4px',
      backgroundColor: id === hoverEditTaskId ? colors.teal[20]:'',
      border: 'none',
      borderRadius:id === hoverEditTaskId ?'5px':'',
      verticalAlign: 'middle',
      display: 'inline-block',
    };
  };

  const deleteHoverStyle =(id)=>{
    return {
      fontSize:'1px',
      padding : '4px',
      backgroundColor: id === hoverDeleteId? colors.teal[20] :'',
      border:'none',
      borderRadius:id === hoverDeleteId ?'5px':'',
      verticalAlign: 'middle',
      display: 'inline-block',
    };

  };

  const commentHoverStyle = (n) => {
    let id = n.email ? n.email.message_id : n.message_id;
    return {
    marginLeft: n.pinned == 1  ? '1.3rem' : '0.5rem',
    padding: '5px',
    backgroundColor: id === hoverCommentId ? colors.teal[20] : '',
    border: 'none',
    borderRadius : id === hoverCommentId ? '5px' : '',
    verticalAlign: 'middle',
    display: 'inline-block',
    fontSize: '0.75rem'
    };
  };

  const dispatch = useDispatch();

  useEffect(() => {
    return () => {
      resetPublisherPanel();
    };
  }, []);

  useEffect(() => {
    if(addTask) {
      setUserId(identity.user_id);
      setDate(dateStr(new Date()));
    }
  }, [addTask]);

  function resetPublisherPanel() {
    setAddNote(false);
    setAddTask(false);
    setUserId(null);
    setText(null);
    setDate(null);
    dispatch(createDeleteTemp(TEMP_FILE_REGISTER));
  }

  function createNote() {
    if(text === '' || !text.trim()) {
      return false;
    }
    const uploads = files.length ? [files[0].file_id]: [];

    dispatch(createAddMessage(
      po.job_id,
      text,
      userId,
      date,
      uploads,
      null,
      po.purchase_order_id,
      'PURCHASE ORDER',
      null
    ));
    resetPublisherPanel();
  }

  const handleTogglePin=(n)=>e=> {
    e.preventDefault();
    const newValue = n.pinned== 1 ? 0 : 1;
    dispatch(createUpdateMessage(n.message_id || n.email.message_id,'pinned',newValue, purchase_order_id));
   };

   const handlePinHover=(id)=>e=>{
     e.preventDefault();
    setHoverPinId(id);

   };
   const handlePinHoverLeave=e=>{
    e.preventDefault();
    setHoverPinId(0);
   };
   const handleDeleteHover=(id)=>e=>{
     e.preventDefault();
     setHoverDeleteId(id);
   };
   const handleDeleteHoverLeave=e=>{
     e.preventDefault();
      setHoverDeleteId(0);
   };

   const handleEditTaskHover = (id)=>e=>{
    e.preventDefault();
    setHoverEditTaskId(id);
   };

   const handleEditTaskHoverLeave = e=> {
    e.preventDefault();
    setHoverEditTaskId(0);
   };

   const handleCommentHover = (id)=>e=> {
     e.preventDefault();
     setHoverCommentId(id);
   };

   const handleCommentHoverLeave = (id)=>e=> {
     e.preventDefault();
     setHoverCommentId(0);
   };

   const handleComment = (id)=>e=> {
    e.preventDefault();
    addCommentId === id ? setAddCommentId(0) : setAddCommentId(id);
   };

   const onAddComment =(message_id, user_id , parent_id) =>e=> {
    e.preventDefault();
    if (!commentText.trim()) {
      return;
    }
    let pattern =  /(@[A-z0-9]+)/g;
    let user_ids_arr = [];
    let m;
    do {
    m = pattern.exec(commentText);
    if(m && mention_users.filter(u => u.mask == m[0].slice(1)).length != 0) {
      user_ids_arr.push(mention_users.filter(u => u.mask == m[0].slice(1))[0]['user_id']);
    }
    } while(m);
    let tagged_users = user_ids_arr.toString();
    dispatch(createAddComment(commentText, message_id, "message", user_id, tagged_users,{purchase_order_id: parent_id }));
    setCommentText(null);
   };
   const deleteNote=(n)=>e=> {
     e.preventDefault();
     if (window.confirm("Are you sure you want to delete?")) {
      dispatch(deleteMessage(n.message_id, n.parent_id));
     }
   };

   const editTask =(t)=>e=>{
    e.preventDefault();
    dispatch(createEditMessagePopup(t,'task',po.job_id));
   };

  function renderPublisher() {
    if(addTask) {
      return(
        <Row>
          <Col padded xs>
            <MentionTextarea placeholder="Task"
              value={text} mention_users={mention_users}
              onChange={setText}
              style={{ maxWidth: '100%' }}
            />
          </Col>
          <Col padded xl={6} sm={6} xs>
            <LabeledSelect label="Task For" name="task_for" noMargin
              value={_.find(users, { value: userId })} options={users} onChange={e => setUserId(e.value)}
            />
          </Col>
          <Col padded xl={6} sm={6} xs>
            <label style={styles.label}>Task Date</label>
            <DateInput
              customInput={<CSKUInput />}
              showMonthDropdown
              showYearDropdown
              placeholder="Task Date"
              value={date}
              onChange={setDate} />
          </Col>
          <Col padded xl={6} sm={6} xs>
          </Col>
          <Col padded xl={6} sm={6} xs>
            <Button onClick={() => createNote()} style={{ float: 'right' }}>Create Task</Button>
          </Col>
        </Row>
      );
    }

    return (
      <Row>
        <Col padded xs>
          <MentionTextarea placeholder="Note"
            value={text || ''} mention_users={mention_users}
            onChange={setText}
            style={{ maxWidth: '100%' }}
          />
        </Col>
        {files[0] ?
          <Col padded xs>{files[0].file_display_name} is selected.</Col>
        : null}
        <Col padded xs>
          <Button onClick={() => {
            onShowPopup && onShowPopup();
            setShowFilesPopup(true);
          }}>Select Files</Button>
          <Button onClick={() => createNote()} style={{ marginLeft: '10px' }}>Create Note</Button>
        </Col>
      </Row>
    );
  }

  function renderPublisherPanel() {
    return (
      <Col xs>
        {!addNote && !addTask ?
          <Box style={styles.boxStyle}>
            <Row style={{ justifyContent: 'space-around' }}>
              <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ textAlign: "center" }} onClick={() => setAddNote(true)}>
                  <AddNoteIcon size="huge" pointer />
                </div>
                <div style={{color: colors.primary1.main}}>Add Note</div>
              </div>
              <div style={{ flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
                <div style={{ textAlign: "center" }} onClick={() => setAddTask(true)}>
                  <AddTaskIcon size="huge" pointer />
                </div>
                <div style={{color: colors.primary1.main}}>Add Task</div>
              </div>
            </Row>
          </Box>
        :
          <Box style={styles.boxStyle}>
            {renderPublisher()}
          </Box>
        }
      </Col>
    );
  }
  function renderEmailMessage(message) {
    const html = _.filter(
      (message.text || '')
        .replace(/(href=['"])(?!https?:)/, "$1/")
        .replace(/<a /, '<a target="_blank" ')
        .split(),
      (line) => {
        return !!line;
      }
    ).join(' ');
    return(
    <Row style={pinStyle(message)}>
      <Col xs={12} style={pinPadding(message)}>
        Email to {message.email.email_to_recipients}
       <br />
        Subject: {message.subject}
        <TruncatedText html={html} limit={300}/>
        <br />
        <IconButton variant="secondary" onClick={(e)=>{  dispatch(createEmailMessagePopup(message));}}><MailIcon size="small" style={{verticalAlign: 'sub'}}></MailIcon> View Email</IconButton>
        {renderFooter(message)}
      </Col>
    </Row>

    );
  }

  function renderFooter(n, isTask = false) {
    if (isBrowser()) {
      ReactTooltip.rebuild();
    }
    let creator;
    let imgSrc;
    let id;
    let userId = n.actor ? n.actor.user_id : n.created_by;
    if (n.email) {
      creator= (n.email.email_from_actor && n.email.email_from_actor.user_first_name) ||  (n.email.email_from_actor && n.email.email_from_actor.user_last_name) ? `${n.email.email_from_actor.user_first_name} ${n.email.email_from_actor.user_last_name}` : 'Anonymous';
      imgSrc = n.email.email_from_actor && n.email.email_from_actor.user_image_paths;
      id = n.email.message_id;
    } else {
      creator = (n.creator_user_first_name || n.creator_user_last_name) ? `${n.creator_user_first_name} ${n.creator_user_last_name}` : 'Anonymous';
      imgSrc = n.user_image_paths || (n.actor && n.actor.user_image_paths);
      id = n.message_id;
    }
		let date = n.email ? n.email.date_created : n.date_created;
    return(
      <>
      <div style={{display: 'flex', alignItems : 'center', marginTop: n.date_reminder && n.date_reminder !== '0000-00-00 00:00:00' ? '' : '10px'}}>
        <span style={{color:colors.neutrals[70], display:'flex', alignItems:'center'}}>
          <Img alt="user"
          style={{borderRadius: '50%', paddingRight: 5, height: 30}}
          src={getImageSrcByImgPaths(imgSrc, '/images/user-avatar3-48x48.png')}
          />
          <small>by {creator} on {parseMysqlDate(date)}
          </small>
        </span>
        <Col>
          <div style={footerStyle(n)}>
            <a href="#" onMouseEnter={handleCommentHover(id)} onMouseLeave={handleCommentHoverLeave()}
            style= {commentHoverStyle(n)} onClick ={handleComment(id)} data-tip data-for="commentTip"
            >
              <CommentIcon altText="" size="small" number={n.replies ? n.replies.length : 0} style = {{verticalAlign: 'middle'}} filled = {id === hoverCommentId ? true : false} />
            </a>
            <a href="#" id ={n.pinned == 1 ? id + '-pinned' : id} onMouseEnter={handlePinHover(id)} onMouseLeave={handlePinHoverLeave} onClick ={handleTogglePin(n)}
            style={pinHoverStyle(id)} data-tip={n.pinned == 1 ? 'Unpin' : 'Pin'} data-for="pinTip">
             {n.pinned == 1 ?
              <PinIcon altText="" size="small" unpin filled={ id === hoverPinId ? true : false } style={{verticalAlign: 'middle'}} pinned />
              : <PinIcon altText="" size="small" filled={ id === hoverPinId ? true : false } style={{verticalAlign: 'middle'}} />  }
            </a>
            {isTask ?
            <a href="#" onClick={editTask(n)} onMouseEnter={handleEditTaskHover(id)} onMouseLeave={handleEditTaskHoverLeave}
              style={editTaskHoverStyle(id)} data-tip data-for="editTaskTip">
              <EditIcon altText="" size='small' filled={id === hoverEditTaskId ? true : false} style={{verticalAlign:"middle"}}/>
            </a>
            :
            null}
            {userId == identity.user_id && !n.email ?
            <a href="#" onClick={deleteNote(n)} onMouseEnter={handleDeleteHover(id)} onMouseLeave={handleDeleteHoverLeave} style={deleteHoverStyle(id)} data-tip data-for="deleteTip">
              <TrashIcon  altText="" pinned size="small"color="#D10411" filled={id === hoverDeleteId ? true : false } style={{verticalAlign:'middle'}} ></TrashIcon>
            </a> :
            null }
          </div>
        </Col>
      </div>

      </>
      );
  }

  function renderComments(n) {
    return (
      <Row style = {{backgroundColor: colors.neutrals[20] , padding:'10px', width:'100%', marginTop:'15px'}}>
      {(n.replies || []).map(r =>
      renderReply(r)
      )}
        <Col xs={1}>
          <Img
          style={{borderRadius: '50%', paddingRight: 5, height: 30}}
          src={getImageSrcByImgPaths(_.get(identity, ['user_image_paths']), '/images/user-avatar3-48x48.png')}
          />
        </Col>
        <Col xs={10} >
          <div className="tasks-comment">
          <MentionTextarea
          placeholder = "Your comment..."
          mention_users={mention_users}
          value={commentText || ''}
          onChange={setCommentText}
          style={{ maxWidth: '100%' }}
          />
          </div>
        </Col>
        <Col offset={1} style={{ marginTop: '-0.5rem'}}>
          <Button style= {{padding : '5px 10px'}} onClick={onAddComment(n.message_id || n.email.message_id , n.created_by || n.actor.user_id,purchase_order_id)}>Comment</Button>
        </Col>
      </Row>
    );
  }
  function renderTask(t) {

    function handleToggleReminder(reminder) {
      const new_value = reminder.reminder_complete == 1 ? 0 : 1;
      dispatch(createUpdateNote(reminder.message_id, reminder.note_id, 'reminder_complete', reminder.reminder_complete, new_value));
    }
    return (
    <>
      <Row key={t.message_id} style={pinStyle(t)}>
        <Col xs={12} style={pinPadding(t)}>
          <span style={t.reminder_complete == 1 ? { textDecoration: 'line-through' } : null} dangerouslySetInnerHTML={{ __html: t.message_text }} />
          <br />
          <div style={{display: 'flex', alignItems : 'center'}}>
          <span style = {{marginTop: '10px', marginLeft: '-2px', marginRight: '-25px'}}>
          <LabeledCheckbox checked={t.reminder_complete == 1} onChange={() => handleToggleReminder(t)} />
          </span>
          <small><b>Task for {t.reminder_user_first_name} {t.reminder_user_last_name} on {parseMysqlDate(t.date_reminder)}</b></small>
          </div>
         {renderFooter(t, true)}
        </Col>
        </Row>
        {addCommentId === t.message_id ?
         renderComments(t) :
          null}
    </>
    );
  }

  function renderNote(n) {
    let file = null;
    if(n.file) {
      file = n.file;
    }else if(n.file_id) {
      file = {
        file_display_name: n.file_display_name,
        file_id: n.file_id,
        file_path: n.file_path,
        file_type: n.file_type,
        hash: n.hash,
        file_name: n.file_name
      };
    }
    let message_text = n.message_text;
    let id = n.email ? n.email.message_id : n.message_id;
    return (
      <Row key={id} style={{ width: '100%', marginBottom: '1rem' }}>
        {n.email ? <>{renderEmailMessage(n)}</> : <>
          <Row style={pinStyle(n)}>
            <Col xs={12} style={pinPadding(n)}>
              <span dangerouslySetInnerHTML={{ __html: message_text }} />
              <br />
              {renderFooter(n)}
            </Col>
            {file ?
              <Col xs>
                <Artwork
                  name={file.file_display_name ? file.file_display_name : file.file_id ? file.file_id : ''}
                  picture={getImageSrc(file, 'medium')}
                  onDownload={e => handleClickDownload(file)}
                  edit={false}
                />
              </Col>
            : null}
          </Row>
        </>}
        {addCommentId === id ?
         renderComments(n) :
          null}
      </Row>

    );
  }

  function renderReply(r) {
    return (
      <Row key={r.message_id} style={{ width: '100%', marginBottom: '1.5rem' }}>
        <Col xs={12} style={{display:'flex'}}>
          <Img
          style={{borderRadius: '50%', paddingRight: 5, height: 30,}}
          src={getImageSrcByImgPaths(r.actor.user_image_paths,'/images/user-avatar3-48x48.png')} />
          <p style={{paddingLeft: '5px', marginBottom:'0px', marginTop:'0px'}}>
          <b>{r.actor.user_first_name}{r.actor.user_last_name ? ' '+ r.actor.user_last_name : null}:</b>&nbsp;<span dangerouslySetInnerHTML={{ __html: r.message_text }} />
          <br />
          <small style= {{color:colors.neutrals[70]}}>{parseMysqlDate(r.date_created)}</small>
          </p>
          </Col>

      </Row>
    );
  }

  function renderUpcomingTasks() {
    return (
      <Row style ={{ width: '100%' }}>
        <Col xs><H5 style={{ marginBlockStart: '1.67em', marginBlockEnd: '1.67em'}}>New Tasks</H5></Col>
        {upcoming_tasks.sort(sortMessages).map((t,i,{length}) => (
        <React.Fragment key={`${t.message_id}-${i+1}`}>
        {renderTask(t)}
        {i+1 != length ? <Col xs><hr style={{width: '100%', border: '0.5px solid #E6EFF2'}}/></Col> : ''}
        </React.Fragment>
        )
        )}
      </Row>
    );
  }

  function renderCompletedTasks() {
    return (
      <Row style={{width:'100%'}}>
        <Col xs><H5 style={{ marginBlockStart: '1.67em', marginBlockEnd: '1.67em'}}>Completed Tasks</H5></Col>
        {completed_tasks.sort(sortMessages).map((t,i,{length}) => (
          <React.Fragment key={`${t.message_id}-${i+1}`}>
          {renderTask(t)}
          {i+1 != length ? <Col xs><hr style={{width: '100%', border: '0.5px solid #E6EFF2', margin:'0px'}}/></Col> : '' }
          </React.Fragment>
        ))}
      </Row>
    );
  }

  function renderOtherNotes() {
    return (
      <Row style={{ width: '100%' }}>
        <Col xs><H5 style={{ marginBlockStart: '1.67em', marginBlockEnd: '1.67em'}}>Notes</H5></Col>
        {notes.sort(sortMessages).map((n,i,{length}) => (
          <React.Fragment key={`${n.email ? n.email.message_id : n.message_id}-${i+1}`}>
          {renderNote(n)}
          {i+1 != length ? <Col xs><hr style={{width: '100%', border: '0.5px solid #E6EFF2', margin:'0px'}}/></Col> : ''}
          </React.Fragment>
        )
        )}
      </Row>
    );
  }

  return (
    <Row>
      {showFilesPopup && <SelectFilePopup
        onClose={() => {
          setShowFilesPopup(false);
          onClosePopup && onClosePopup();
        }}
        register={TEMP_FILE_REGISTER}
        parent_id={po.job_id || job_id}
        parent_type="JOB"
        job_id={po.job_id || job_id}
        client_id={po.client_id}
        resku={false}
        closeOnEsc={true}
        closeOnClickOutside={false}
      />}
      {renderPublisherPanel()}
      {upcoming_tasks.length > 0 && renderUpcomingTasks()}
      {completed_tasks.length > 0 && renderCompletedTasks()}
      {notes.length > 0 && renderOtherNotes()}
    </Row>
  );
}

const styles = {
  label: {
    fontFamily: "'skufont-medium',sans-serif",
    color: '#123952',
    fontSize: '1rem',
    fontWeight: 400,
    width: '100%',
    boxSizing: 'border-box',
  },
  boxStyle: {
    borderBottom: '1px solid #E0E7EB',
    color: '#9A9A9A',
    padding: '10px',
    marginBottom: '16px',
    background: '#EDF2F4',
    borderRadius: '4px',
  }
};

 const pinStyle = (n) => {
  return {
  borderLeft: n.pinned == 1 ?'5px solid #02c0da':'none',
  width: '100%',
  marginLeft:n.pinned ==1 ? '-1rem': ''
  };
 };

 const footerStyle = (n) => {
  return {
    display:'flex',
    justifyContent:'flex-end',
    marginRight : n.pinned == 1 ? '-1rem' : ''
  };
 };
 const pinPadding =(n)=>{
   return {
    paddingLeft: n.pinned == 1 ? '10px' :''
   };
};

