import {
  ArrowIcon,
  Button,
  CheckmarkIcon,
  colors,
  CompanyShopIcon,
  IconButton,
  MarketingShopIcon,
  PopupShopIcon,
  ShopIcon,
  Text,
} from "@commonsku/styles";
import React from "react";
import { shopDetails } from "./constants";

interface SelectedShopPageProps {
  shopType: string;
  handleCustomRouting: (newRoute: string, selectedShopType?: string) => void;
}

const SelectedShopPage = ({
  shopType,
  handleCustomRouting,
}: SelectedShopPageProps) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        width: "85%",
        margin: "auto",
        marginTop: 64,
        marginBottom: 64,
      }}
    >
      <div style={{ marginRight: 24 }}>
        {shopType === "POPUP" ? (
          <PopupShopIcon color="#E6BA00" size="huge" width={240} height={240} />
        ) : shopType === "COMPANY" ? (
          <CompanyShopIcon
            color={colors.secondary3["60"]}
            size="huge"
            width={240}
            height={240}
          />
        ) : (
          <MarketingShopIcon
            color={colors.primary1["70"]}
            size="huge"
            width={240}
            height={240}
          />
        )}
      </div>
      <div style={{ textAlign: "left" }}>
        <Text
          as="h1"
          style={{ fontWeight: 600, fontSize: 32, marginBottom: 32 }}
        >
          Your Ideal Shop Type is {shopDetails[shopType].type} Shop
        </Text>
        <Text as="p" style={{ marginBottom: 24 }}>
          {shopDetails[shopType].desc}
        </Text>
        {Object.entries(shopDetails[shopType].details).map(
          ([key, value]: [string, string], index) => (
            <div
              key={`shopDetails-${index}`}
              style={{
                display: "flex",
                gap: 8,
                alignItems: "center",
                marginBottom: 16,
              }}
            >
              <CheckmarkIcon color={colors.secondary3["60"]} />
              <Text
                as="span"
                style={{
                  fontSize: 18,
                  fontWeight: 600,
                  display: "block",
                  whiteSpace: "nowrap",
                }}
              >
                {key}:
              </Text>
              <Text
                as="p"
                style={{
                  fontSize: 18,
                  fontWeight: 400,
                  marginBottom: 0,
                  whiteSpace: "nowrap",
                }}
              >
                {value}
              </Text>
            </div>
          )
        )}
        <div style={{ marginTop: 36 }}>
          <Button
            secondary
            size="large"
            style={{ marginRight: 24 }}
            onClick={() => handleCustomRouting("initialize")}
            id="shopCreation-selectedShopPage-exploreShops.btn"
          >
            Explore Other Shop Types
          </Button>
          <IconButton
            size="large"
            Icon={ArrowIcon}
            iconPosition="right"
            onClick={() => handleCustomRouting("createShopPage", shopType)}
            id="shopCreation-selectedShopPage-createShop.btn"
          >
            Create {shopDetails[shopType].type} Shop
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default SelectedShopPage;
