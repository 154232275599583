import { getThemeColor } from '@commonsku/styles';
import { createGlobalStyle as baseCreateGlobalStyle } from 'styled-components';

export const SKUFontDefinitionStyles = `
@font-face {
  font-family: "skufont-bold";
  src: url("/fonts/TT-Norms-Pro-Bold.eot");
  src: url("/fonts/TT-Norms-Pro-Bold.ttf") format("TrueType"),
      url("/fonts/TT-Norms-Pro-Bold.woff") format("woff"),
      url("/fonts/TT-Norms-Pro-Bold.woff2") format("woff2");
}
@font-face {
  font-family: "skufont-demibold";
  src: url("/fonts/TT-Norms-Pro-DemiBold.eot");
  src: url("/fonts/TT-Norms-Pro-DemiBold.ttf") format("TrueType"),
      url("/fonts/TT-Norms-Pro-DemiBold.woff") format("woff"),
      url("/fonts/TT-Norms-Pro-DemiBold.woff2") format("woff2");
}
@font-face {
  font-family: "skufont-medium";
  src: url("/fonts/TT-Norms-Pro-Medium.eot");
  src: url("/fonts/TT-Norms-Pro-Medium.otf") format("opentype"),
      url("/fonts/TT-Norms-Pro-Medium.ttf") format("TrueType"),
      url("/fonts/TT-Norms-Pro-Medium.woff") format("woff"),
      url("/fonts/TT-Norms-Pro-Medium.woff2") format("woff2");
}
@font-face {
  font-family: "skufont-regular";
  src: url("/fonts/TT-Norms-Pro-Regular.eot");
  src: url("/fonts/TT Norms Pro Regular.ttf") format("TrueType"),
      url("/fonts/TT-Norms-Pro-Regular.woff") format("woff"),
      url("/fonts/TT-Norms-Pro-Regular.woff2") format("woff2");
}
@font-face {
  font-family: "Bebas Neue";
  src: url("/fonts/BebasNeue-Regular.ttf") format("TrueType"); 
}
@font-face {
  font-family: "Poppins";
  src: url("/fonts/Poppins-Regular.ttf") format("TrueType");
}
`;

export const mentionTextareaStyles = p => `
.mention-textarea-container {
  position: relative;

  textarea {
    width: 100%;
    height: 150px !important;
    border-radius: 5px;
    box-sizing: border-box;
    font-family: 'skufont-regular',sans-serif;
    font-size: 1rem;
    margin-bottom: 1rem;

    background-color: ${getThemeColor(p, 'input.background')};
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    border: 1px solid ${getThemeColor(p, 'input.border')} !important;
    padding: 8px 8px 8px 8px;
    color: ${getThemeColor(p, 'input.text')};

    &::placeholder {
      color: ${getThemeColor(p, 'input.placeholder')};
    }

    &:hover {
      &:not(.disabled) {
        border-color: ${getThemeColor(p, 'input.hover.border')} !important;
        &::placeholder {
          color: ${getThemeColor(p, 'input.hover.placeholder')};
        }
      }
    }

    &:focus {
      border-color: ${getThemeColor(p, 'input.active.border')} !important;
      color: ${getThemeColor(p, 'input.active.text')};
      outline: none;
      box-shadow: 1px  1px 0px ${getThemeColor(p, 'input.active.border')},
                  -1px -1px 0px ${getThemeColor(p, 'input.active.border')},
                  1px -1px 0px ${getThemeColor(p, 'input.active.border')},
                  -1px  1px 0px ${getThemeColor(p, 'input.active.border')};
    }

    &:disabled {
      border: none;
      boxShadow: none;
      outline: none;
      color: ${getThemeColor(p, 'input.disabled.text')};
      background-color: ${getThemeColor(p, 'input.disabled.background')};
    }
  }

  ul.tag-results {
    list-style: none;
    border-top: 1px solid #ccc;
    border-left: 1px solid #ccc;
    border-right: 1px solid #ccc;
    border-bottom: 1px solid #ccc;
    width: 325px;
    padding: 0;
    margin: 0;
    background: #fff;
    position: absolute;
    z-index: 99;
    box-shadow: 1px 1px 1px #ddd;
    display: block;
  }

  .tag-user {
    position: relative;
    padding: .2rem 4rem;

    &:last-child {
      padding-bottom: .4rem;
    }

    &:hover {
      background: $light-gray;
    }

    img {
      position: absolute;
      top: .4rem;
      left: .4rem;
    }

    .username {
      font-size: .7em;
      cursor: pointer;
    }
  }
}
`;

export const additionalGlobalStyles = p => `
  ${SKUFontDefinitionStyles}

  ${mentionTextareaStyles(p)}

  .csku-styles {
    font-family: ${p?.theme?.fontFamily || "'skufont-regular', 'sans-serif', 'Roboto'"};
  }
`;

/**
 *
 * @param {undefined | null | string | (props?: object) => string} additionalStyles
 * @returns {ReturnType<baseCreateGlobalStyle>}
 */
const createGlobalStyle = (additionalStyles = null) => baseCreateGlobalStyle`
${p => additionalGlobalStyles(p)}

${p => additionalStyles ? typeof additionalStyles === 'function' ? additionalStyles(p) : additionalStyles : ''}
`;

export default createGlobalStyle;
