import _ from 'lodash';
import moment from 'moment';
import { createSelector } from 'reselect';

import { isZero, getAbsoluteUrl, formatMoney, round } from '../utils';

export const isAuthorized = state => !!state.authorized;
export const isShopOpen = state => !!state.open;
const getProjects = state => state.entities.projects;
const getShops = state => state.entities.shops;
const getAccounts = state => state.entities.accounts;
const getComments = state => state.entities.comments;
const getOrders = state => state.entities.orders;
export const getPurchaseOrders = state => state.entities.purchase_orders;
const getPurchaseOrderProofs = state => state.entities.purchase_order_proofs;
export const getItems = state => state.entities.items;
const getShipping = state => state.entities.shipping;
const getBreakdowns = state => state.entities.breakdowns;
const getCosts = state => state.entities.item_costs;
const getLocations = state => state.entities.item_locations;
const getDecorations = state => state.entities.item_decorations;
const getRetailAdjustments = state => state.entities.item_retail_adjustments;
const getArtworks = state => state.entities.artworks;
const getTaxAmounts = state => state.entities.tax_amounts;
const getItemImages = state => state.entities.item_images;
const getItemWarnings = state => state.entities.item_warnings;
const getAddresses = state => state.entities.addresses;
const getContacts = state => state.entities.contacts;
const getMessages = state => state.entities.messages;
const getTaxes = state => state.entities.taxes;
const getBills = state => state.entities.bills;
const getCommissions = state => state.entities.commissions;
const getUsers = state => state.entities.users;
const getFiles = state => state.entities.files;
const getStatuses = state => state.entities.statuses;
const getShipMethods = state => state.entities.ship_methods;
const getColors = state => state.entities.colors;
const getSizes = state => state.entities.sizes;
const getCollaborateMessages = state => state.entities.collaborate_messages;
const getFeedback = state => state.entities.feedback;
const getProductFeedback = state => state.entities.product_feedback;
const getBillPurchaseOrders = state => state.entities.bill_purchase_orders;
const getPhones = state => state.entities.phones;
const getCollectionThemes = state => state.entities.collection_themes;
const getCompaniesData = state => state.entities.company_data || {};
const getIdentity = state => state.identity || {};
const getSuppliers = state => state.entities.suppliers;
const getTenants = state => state.entities.tenants;
const getClients = state => state.entities.clients;
const getBaseCosts = state => state.entities.base_costs;
const getCart = state => state.temp.cart || {};
const getTags = state => state.entities.tags;
const getImprints = state => state.entities.imprints;
const getProductColors = state => state.dropdowns.product_colors;
const getProductSizes = state => state.dropdowns.product_sizes;
const getSkus = state => state.entities.skus;
const getInventory = state => state.entities.inventory;
const getWarehouses = state => state.entities.inventory_warehouses;
const getInventoryItems = state => state.entities.inventory_items;
export const getDivisions = state => state.entities.divisions;
export const getRoles = state => state.entities.roles;
export const getFeatures = state => state.entities.features;

const getCartItemData = (state, ownProps) => ({ buy_inventory: ownProps.buy_inventory ?? false });

const getProductId = (state, ownProps) => ownProps.product_id;
const getProducts = state => state.entities.products;
export const getProduct = createSelector(
 getProducts,
 getProductId,
 (products, product_id) => products[product_id]
);

const getCompanyId = (state, ownProps) => ownProps.company_id;
export const getCompany = createSelector(
  getSuppliers,
  getTenants,
  getCompanyId,
  (suppliers, tenants, company_id) => suppliers[company_id] || tenants[company_id]
);

const getContactId = (state, ownProps) => ownProps.contact_id;
export const getContact = createSelector(
  getContacts,
  getPhones,
  getContactId,
  (contacts, phones, contact_id) => Object.assign({}, contacts[contact_id], { contact_phones: Object.values(phones).filter(p => p.parent_id === contact_id) })
);

const getAddressId = (state, ownProps) => ownProps.address_id;
export const getAddress = createSelector(
  getAddresses,
  getAddressId,
  (addresses, address_id) => addresses[address_id]
);

const getShopId = (state, ownProps) => ownProps.shop_id;

const getOrderId = (state, ownProps) => ownProps.order_id;
const getOrderNumber = (state, ownProps) => ownProps.form_number || ownProps.shop_number;
const getOrderType = (state, ownProps) => ownProps.order_type || (!!ownProps.shop_number && 'SHOP');
const getItemId = (state, ownProps) => ownProps.item_id;
export const getCompanyData = createSelector(
  getCompaniesData,
  getIdentity,
  (companies_data, identity) => _.get(companies_data, identity.company_id) || {}
);

export const getCollectionImages = createSelector(
  getFiles,
  getItems,
  getOrders,
  getOrderId,
  (files, items, orders, order_id) => {
    const collection_file_ids = ((orders[order_id] || {}).collection_images || []).map(i => i.file_id);
    const option_items = Object.values(items).filter(i => 'OPTION' === i.parent_type && i.order_id === order_id);
    const file_ids = new Set(option_items.reduce((file_ids, item) => {
      return file_ids.concat((item.files || []).map(f => f.file_id)).concat((item.file || {}).file_id);
    }, collection_file_ids));
    return [...file_ids].map(file_id => files[file_id]).filter(file_id => !!file_id);
  }
);

const filterByItemId = (values, item_id) => Object.values(values).filter(v => v.item_id === item_id);

const getItemBreakdowns = createSelector(getBreakdowns, getItemId, filterByItemId);
const getItemCosts = createSelector(getCosts, getItemId, filterByItemId);
const getItemLocations = createSelector(getLocations, getItemId, filterByItemId);
const getItemDecorations = createSelector(getDecorations, getItemId, filterByItemId);
const getItemArtworks = createSelector(getArtworks, getFiles, getItemId, (artworks, files, item_id) =>
  filterByItemId(artworks, item_id).map(a => Object.assign({image: files[a.artwork_file_id]}, a))
);
const getItemTaxAmounts = createSelector(getTaxAmounts, getItemId, filterByItemId);
export const getItemImagesByItemId = createSelector(
  getItemImages,
  getFiles,
  getItemId,
  (item_images, files, item_id) => Object.values(item_images).filter(ii => ii.item_id === item_id).map(ii => ({ ...ii, image: files[ii.file_id] })).sort((a, b) => a.display_order - b.display_order)
);
export const getProductImagesByItemId = createSelector(
  getFiles,
  getItems,
  getItemId,
  (files, items, item_id) => Object.values(files).filter(f => f.parent_id === (items[item_id] || { parent_id: null }).parent_id).concat(Object.values(files).filter(f => f.parent_id === item_id))
);

const getGalleryCart = state => state.temp || {};

export const getGalleryOrderById = createSelector(
  getOrders,
  getItems,
  getBreakdowns,
  getLocations,
  getCosts,
  getColors,
  getSizes,
  getItemImages,
  getFiles,
  getGalleryCart,
  getOrderId,
  (orders, items, breakdowns, locations, costs, colors, sizes, item_images, files, cart, order_id) => {
    const order = orders[order_id];
    return {
      ...order,
      company: {
        ...order.company,
        company_avatar: order.company.company_avatar && order.company.company_avatar.medium ? getAbsoluteUrl(order.company.company_avatar.medium) : '/images/favicons/favicon-96x96.png',
      },
      items: Object.values(items).filter(i => i.order_id === order_id).map(i => ({
        ...i,
        images: Object.values(item_images).filter(ii => ii.item_id === i.item_id).map(ii => files[ii.file_id]),
        options: Object.values(items).filter(o => o.option_item_id === i.item_id).map(o => ({
          ...o,
          breakdowns: Object.values(breakdowns).filter(b => b.item_id === o.item_id),
          item_costs: Object.values(costs).filter(c => c.item_id === o.item_id)
        })),
        item_costs: Object.values(costs).filter(c => c.item_id === i.item_id).map(
          ic => ({
            ...ic,
            ...locations[ic.item_location_id],
            item_location_title: (locations[ic.item_location_id] || { item_location_title: ''}).item_location_title.replace(/^Location [0-9]:: /, '')
          })
        ),
        item_colors: Object.values(colors).filter(c => c.item_id === i.item_id),
        item_sizes: Object.values(sizes).filter(s => s.item_id === i.item_id),
        quantity: cart[i.item_id] ? cart[i.item_id].total_quantity : 0,
        inventory_items: []
      })).filter(i => 'OPTION' === i.parent_type || 'SEPARATOR' === i.parent_type)
    };
  }
);

const getParentData = (state, ownProps) => ({ parent_id: ownProps.parent_id, parent_type: ownProps.parent_type });
export const getGalleryOrderByParent = createSelector(
  getOrders,
  getItems,
  getBreakdowns,
  getLocations,
  getCosts,
  getColors,
  getSizes,
  getSkus,
  getRetailAdjustments,
  getItemImages,
  getFiles,
  getInventoryItems,
  getCart,
  getParentData,
  (orders, items, breakdowns, locations, costs, colors, sizes, skus, adjustments, item_images, files, inventory_items, cart, parentData) => {
    const order = _.first(_.values(orders || {}).filter(
      o => parentData.parent_type === 'SHOP'
            ? o.shop_id === parentData.parent_id
            : o.order_id === parentData.parent_id
    )) || {};
    return {
      ...order,
      company: {
        ...order.company,
        company_avatar: order.company && order.company.company_avatar && order.company.company_avatar.medium ? getAbsoluteUrl(order.company.company_avatar.medium) : '/images/favicons/favicon-96x96.png',
      },
      items: Object.values(items).filter(i => i.order_id === order.order_id).map(i => ({
        ...i,
        images: Object.values(item_images).filter(ii => ii.item_id === i.item_id).sort((a, b) => parseFloat(a.display_order) - parseFloat(b.display_order)).map(ii => ({ ...files[ii.file_id], item_image_id: ii.item_image_id })),
        options: Object.values(items).filter(o => o.option_item_id === i.item_id).map(o => ({
          ...o,
          breakdowns: Object.values(breakdowns).filter(b => b.item_id === o.item_id),
          item_costs: Object.values(costs).filter(c => c.item_id === o.item_id),
          item_retail_adjustments: Object.values(adjustments).filter(a => a.item_id === o.item_id).sort((a, b) => parseFloat(a.display_order) - parseFloat(b.display_order))
        })),
        item_costs: Object.values(costs).filter(c => c.item_id === i.item_id).map(
          ic => ({
            ...ic,
            ...locations[ic.item_location_id],
            item_location_title: (locations[ic.item_location_id] || { item_location_title: ''}).item_location_title.replace(/^Location [0-9]:: /, '')
          })
        ),
        item_colors: Object.values(colors).filter(c => c.item_id === i.item_id),
        item_sizes: Object.values(sizes).filter(s => s.item_id === i.item_id),
        item_skus: Object.values(skus).filter(s => s.item_id === i.item_id),
        item_retail_adjustments: Object.values(adjustments).filter(
          a => a.item_id === i.item_id
        ).sort(
          (a, b) => parseFloat(a.display_order) - parseFloat(b.display_order)
        ),
        inventory_items: Object.values(inventory_items || {}).filter(ii => ii.source_item_id === i.item_id),
        quantity: cart[i.item_id] ? cart[i.item_id].reduce((t, b) => t + parseFloat(b.quantity), 0) : 0
      })).filter(i => 'OPTION' === i.parent_type || 'SEPARATOR' === i.parent_type)
    };
  }
);

export const getFullItems = createSelector(
  getItems,
  getBreakdowns,
  getCosts,
  getArtworks,
  getLocations,
  getDecorations,
  getSizes,
  getColors,
  getItemImages,
  getFiles,
  getSkus,
  getTaxAmounts,
  getTaxes,
  getInventoryItems,
  (
    items, breakdowns, item_costs, artworks, item_locations, item_decorations,
    sizes, colors, item_images, files, skus, tax_amounts, taxes, inventory_items
  ) => {
    const filtered_costs = Object.values(item_costs)
      .filter(ic => !ic.from_db || ic.unit_cost != 0 || ic.item_cost_tab != 'INTERNATIONAL')
    ;
    return Object.values(items).map(i => {
      const base_options = Object.values(items).filter(o => o.option_item_id === i.item_id && o.order_id === i.order_id && o.parent_type === 'PRODUCT').sort((a, b) => a.display_order - b.display_order);
      const base_option_item_ids = base_options.map(i => i.item_id);
      const base_run_costs = Object.values(item_costs).filter(ic => ic.item_id === i.item_id && ic.quantity === null);
      const run_costs = base_run_costs.map(ic =>
        Object.values(item_costs).filter(ic2 => base_option_item_ids.includes(ic2.item_id) && ic2.option_item_cost_id === ic.item_cost_id)
      );
      const bs = Object.values(breakdowns).filter(b => b.item_id === i.item_id);
      const breakdown_sizes = bs.map(b => b.size_id);
      const breakdown_colors = bs.map(b => b.color_id);
      const breakdown_skus = bs.map(b => b.product_sku_id);
      return Object.assign({}, i, {
        item_images: Object.values(item_images).filter(ii => ii.item_id === i.item_id).map(ii => ({
          ...ii,
          image: files[ii.file_id]
        })).sort((a, b) => a.display_order - b.display_order),
        options: base_options.map(o => {
          const breakdown = Object.values(breakdowns).find(b => b.item_id === o.item_id);
          return {
            ...o,
            ..._.pick(breakdown, ['breakdown_id', 'unit_cost']),
          };
        }),
        hidden_costs: filtered_costs.filter(c => c.item_id === i.item_id && 1 == c.hidden && c.quantity === null).reduce((t, ic) => t + parseFloat(ic.unit_price), 0),
        sizes: Object.values(sizes).filter(s => !s.product_id || s.product_id === i.parent_id || breakdown_sizes.includes(s.size_id)),
        colors: Object.values(colors).filter(s => !s.product_id || s.product_id === i.parent_id || breakdown_colors.includes(s.color_id)),
        skus: Object.values(skus).filter(s => breakdown_skus.includes(s.product_sku_id)),
        item_locations: Object.values(item_locations).filter(
          il => il.item_id === i.item_id
        ).map(
          il => Object.assign({}, il, {
            artworks: Object.values(artworks).filter(
              a => a.item_location_id === il.item_location_id
            ).map(
              a => Object.assign({}, a, {
                image: files[a.artwork_file_id]
              })
            ),
            item_decorations: Object.values(item_decorations).filter(
              id => id.item_location_id === il.item_location_id
            )
          })
        ).sort((a, b) => a.display_order - b.display_order),
        item_costs: filtered_costs.filter(ic => ic.item_id === i.item_id && ('OPTION' !== i.parent_type || ic.quantity !== null)).sort((a, b) => a.display_order - b.display_order),
        item_decorations: Object.values(item_decorations).filter(id => id.item_id === i.item_id),
        run_costs: run_costs.sort((a, b) => a.display_order - b.display_order),
        artworks: Object.values(artworks).filter(a => a.item_id === i.item_id).filter(a => item_locations[a.item_location_id]).sort((a, b) => item_locations[a.item_location_id].display_order - item_locations[b.item_location_id].display_order),
        tax_amounts: Object.values(tax_amounts).filter(ta => ta.item_id === i.item_id).map(ta => Object.assign({}, ta, { label: (taxes[ta.tax_id] || {}).label})),
        breakdowns: Object.values(breakdowns).filter(b => b.item_id === i.item_id).sort((a, b) => a.display_order - b.display_order),
        hasInventory: Object.values(inventory_items || {}).filter(ii => ii.source_item_id === i.item_id).length > 0,
        hasInventoryBreakdowns: bs.filter(ib => !!ib.inventory_item_id).length > 0
      });
    }).sort((a, b) => a.display_order - b.display_order);
  }
);

export const getOrderByNumber = createSelector(
  getOrders,
  getStatuses,
  getFullItems,
  getTaxAmounts,
  getTaxes,
  getFiles,
  getClients,
  getCollectionThemes,
  getUsers,
  getOrderNumber,
  getOrderType,
  (orders, statuses, items, tax_amounts, taxes, files, clients, collection_themes, users, order_number, order_type) => {
    const order = Object.values(orders).filter(o => +o.form_number === +order_number && o.order_type === order_type)[0];
    const summary_taxes = _(tax_amounts)
      .filter(ta => !!items.filter(i => i.order_id === order.order_id && i.hidden == 0 && i.item_id === ta.item_id)[0]
      )
      .map((ta) => {
        return {label: _.get(taxes, [ta.tax_id, 'label'], ''), amount: ta.amount};
      })
      .groupBy('label')
      .map((amounts, label) => {
        return {
          label,
          amount: _.sumBy(amounts, (ta) => {
            return _.toNumber(ta.amount) || 0;
          })
        };
      })
      .value()
    ;
    const themes = Object.values(collection_themes).reduce((o, t) => {
      o[t.theme_id] = t.collection_theme_id;
      return o;
    }, {});
    const indexed_collection_images = (order.collection_images || []).reduce((o, i) => {
      o[parseFloat(i.display_order)] = i;
      return o;
    }, {});
    const collection_images = [0, 1, 2, 3].map(index => indexed_collection_images[index] ? files[indexed_collection_images[index].file_id] : null);
    const creator = users[order.created_by] || {};
    return Object.assign({}, order, {
      client_name: (clients[order.client_id] || {}).client_name,
      collection_themes: themes,
      collection_images,
      creator: `${creator.contact_first_name} ${creator.contact_last_name}`,
      status_name: statuses[order.status_id].status_name,
      items: items.filter(i => i.order_id === order.order_id),
      tax_amounts: summary_taxes.sort((a, b) => a.label < b.label ? -1 : 1)
    });
  }
);

const getProjectId = (state, ownProps) => ownProps.project_id;
const getProjectNumber = (state, ownProps) => ownProps.job_number;

const getShortOrder = (order, statuses) => {
  return Object.assign({}, order, { status_name: statuses[order.status_id] ? statuses[order.status_id].status_name : ''});
};

export const getShortOrderByNumber = createSelector(
  getOrders,
  getStatuses,
  getOrderNumber,
  getOrderType,
  (orders, statuses, form_number, order_type) => Object.values(orders).filter(o => +o.form_number === +form_number && o.order_type === order_type).map(o => getShortOrder(o, statuses))[0]
);

export const getProject = (state, ownProps) => ownProps?.project || state.entities.projects[Object.keys(state.entities.projects)[0]];

export const getPopups = state => _.get(state, 'display.popups', []);

export const getProjectMessages = createSelector(
  getMessages,
  messages => Object.values(messages).filter(m => m.parent_id === null || m.parent_id === m.message_id).sort((a, b) => b.latest_update - a.latest_update)
);

const getShopNumber = (state, ownProps) => ownProps.shop_number;
export const getShopByNumber = createSelector(
  getShops,
  getClients,
  getTags,
  getShopNumber,
  (shops, clients, tags, shop_number) => {
    const shop = Object.values(shops).filter(s => +s.shop_number === +shop_number)[0];
    return {
      ...shop,
      tags: Object.values(tags).filter(t => t.resource_id === shop.shop_id).map(t => t.label),
      client: shop.client_id ? clients[shop.client_id] : null
    };
  }
);

export const getShopById = createSelector(
  getShops,
  getShopId,
  (shops, shop_id) => shops[shop_id]
);

export const getShopByOrderId = createSelector(
  getShops,
  getOrders,
  getOrderId,
  (shops, orders, order_id) => {
    const order = Object.values(orders).find(o => o.order_id === order_id);
    if (!order) {
      return null;
    }
    return Object.values(shops).find(s => s.shop_id === order.shop_id);
  }
);

export const getProjectByNumber = createSelector(
  getProjects,
  getAccounts,
  getOrders,
  getItems,
  getPurchaseOrders,
  getBills,
  getBillPurchaseOrders,
  getMessages,
  getCollaborateMessages,
  getStatuses,
  getUsers,
  getProjectNumber,
  (projects, accounts, orders, items, purchase_orders, bills, bill_purchase_orders, messages, collaborate_messages, statuses, users, job_number) => {
    const project = Object.values(projects).filter(p => +p.job_number === +job_number)[0];

    const invoice_items = Object.values(items).filter(
      i => 'INVOICE' === _.get(orders, [i.order_id, 'order_type']) && 0 == i.hidden && 'SEPARATOR' !== i.parent_type
    );
    const margin_items = invoice_items.filter(i => 0 == i.exclude_from_margin);
    const margin_subtotal = margin_items.reduce((t, i) => t + parseFloat(i.total_subtotal), 0);
    const booked_cost = margin_items.reduce((t, i) => {
      if ('SERVICE' === i.parent_type || 'ARTWORK_PO' === i.parent_type) {
        return t + parseFloat(i.total_units * i.unit_cost);
      }
      return t + parseFloat(i.total_cost);
    }, 0);
    const bill_items = Object.values(Object.values(bills).reduce(
      (o1, b) => Object.assign(
        o1,
        (b.purchase_orders || []).filter(po => project.sales_orders.includes(po.order_id)).reduce(
          (o2, po) => Object.assign(
            o2,
            (po.items || []).reduce(
              (o3, i) => Object.assign(
                o3,
                i.breakdowns.reduce(
                  (o4, b) => Object.assign(o4, { [b.bill_item_id]: b }), {}
                ),
                i.costs.reduce(
                  (o5, c) => Object.assign(o5, { [c.bill_item_id]: c }), {}
                )
              ),
              {}
            ),
            (po.extra_items || []).reduce(
              (o6, i) => Object.assign(o6, { [i.bill_item_id]: i }), {}
            )
          ),
          {}
        ),
        (b.orders || []).filter(o => project.sales_orders.includes(o.order_id)).reduce(
          (o7, o) => Object.assign(
            o7,
            o.extra_items.reduce(
              (o8, i) => Object.assign(o8, { [i.bill_item_id]: i }), {}
            )
          ),
          {}
        ),
        {}
      ),
      {}
    ));
    const purchase_order_items = Object.values(purchase_orders).reduce(
      (o, po) => Object.assign(
        o,
        (po.items || []).reduce(
          (io, poi) => Object.assign(io, { [poi.purchase_order_item_id]: poi }),
          {}
        )
      ),
      {}
    );
    const already_added = {};
    const billed_cost = bill_items.filter(bi => 0 == bi.exclude_from_margin).reduce((t, bi) => {
      const poi = purchase_order_items[bi.purchase_order_item_id];
      if (poi && poi.item_cost_id) {
        if (_.get(already_added, `${bi.bill_id}${poi.item_cost_id}.${poi.breakdown_id}.${poi.artwork_location_description}`, false)) {
          return t;
        }
        _.set(already_added, `${bi.bill_id}${poi.item_cost_id}.${poi.breakdown_id}.${poi.artwork_location_description}`, true);
      }
      return t + parseFloat(bi.total_subtotal);
    }, 0);
    const billed_margin = isZero(margin_subtotal) ? 0 : (margin_subtotal - billed_cost) / margin_subtotal * 100;
    const booked_margin = isZero(margin_subtotal) ? 0 : (margin_subtotal - booked_cost) / margin_subtotal * 100;

    return Object.assign({}, project, {
      account: accounts[project.account_id],
      sales_rep: users[project.client_rep_id],
      client_rep: users[project.sales_rep_id],
      purchase_orders: project.purchase_orders
        .filter(o => Boolean(o) && Boolean(purchase_orders[o]))
        .map(o => getShortOrder(purchase_orders[o], statuses)),
      bills: project.bills.map(o => getShortOrder(bills[o], statuses)),
      margin_data: Object.values(orders).filter(o => 'SALES ORDER' === o.order_type).reduce((d, o) => {
        const margin_subtotal = margin_items.filter(i => orders[i.order_id].parent_order_id === o.order_id).reduce((t, i) => t + parseFloat(i.total_subtotal), 0);
        const booked_cost = margin_items.filter(i => orders[i.order_id].parent_order_id === o.order_id).reduce((t, i) => {
          if ('SERVICE' === i.parent_type || 'ARTWORK_PO' === i.parent_type) {
            return t + parseFloat(i.total_units || 0) * parseFloat(i.unit_cost || 0) * Number(i.exchange_rate);
          }
          return t + parseFloat(i.total_cost || 0) * Number(i.exchange_rate);
        }, 0);
        const order_bills = (bill_purchase_orders[o.order_id] || []).map(bpo => bpo.bill_id);
        const already_added = {};
        const billed_cost = bill_items.filter(bi => 0 == bi.exclude_from_margin).filter(bi => order_bills.includes(bi.bill_id)).reduce((t, bi) => {
          const poi = purchase_order_items[bi.purchase_order_item_id];
          if (poi && poi.item_cost_id) {
            if (_.get(already_added, `${bi.bill_id}${poi.item_cost_id}.${poi.breakdown_id}.${poi.artwork_location_description}`, false)) {
              return t;
            }
            _.set(already_added, `${bi.bill_id}${poi.item_cost_id}.${poi.breakdown_id}.${poi.artwork_location_description}`, true);
          }
          return t + parseFloat(bi.total_subtotal) * parseFloat(bi.exchange_rate);
        }, 0);
        const booked_margin = isZero(margin_subtotal) ? 0 : (margin_subtotal - booked_cost) / margin_subtotal * 100;
        const billed_margin = isZero(margin_subtotal) ? 0 : (margin_subtotal - billed_cost) / margin_subtotal * 100;
        d[o.order_id] = {
          total_subtotal: invoice_items.filter(i => orders[i.order_id].parent_order_id === o.order_id).reduce((t, i) => t + parseFloat(i.total_subtotal), 0),
          margin_subtotal,
          total_margin_amount: margin_subtotal - billed_cost,
          billed_margin,
          booked_margin
        };
        return d;
      }, {}),
      subtotal: invoice_items.reduce((t, i) => t + parseFloat(i.total_subtotal), 0),
      margin_subtotal,
      total_margin_amount: margin_subtotal - billed_cost,
      billed_margin,
      booked_margin,
      messages: Object.keys(messages)
        .filter(k =>
          messages[k].parent_id === null ||
          messages[k].parent_id === messages[k].message_id)
        .map(k => messages[k])
        .sort((a, b) => b.latest_update - a.latest_update),
      collaborate_messages: Object.keys(collaborate_messages)
        .map(k => collaborate_messages[k])
        .sort((a, b) => b.latest_update - a.latest_update),
      history: [],
    }, _.mapValues(_.pick(project, [
      'opportunities', 'presentations', 'estimates', 'sales_orders', 'invoices',
    ]), (ids) => {
      return _.map(ids, id => getShortOrder(orders[id], statuses));
    }));
  }
);

const ORDER_TYPE_SORT_ORDER = {'OPPORTUNITY': 0, 'PRESENTATION': 1, 'ESTIMATE': 2, 'SALES ORDER': 3, 'INVOICE': 4};
const compare_orders = (a, b) => {
  if (ORDER_TYPE_SORT_ORDER[a.order_type] < ORDER_TYPE_SORT_ORDER[b.order_type]) {
    return -1;
  } else if (ORDER_TYPE_SORT_ORDER[a.order_type] > ORDER_TYPE_SORT_ORDER[b.order_type]) {
    return 1;
  }
  return parseFloat(a.form_number) - parseFloat(b.form_number);
};
export const getMostRecentUnlockedOrder = createSelector(
  getOrders,
  getProjectId,
  (orders, project_id) => Object.values(orders).filter(o => 'OPPORTUNITY' !== o.order_type && 1 != o.locked && o.job_id === project_id).sort(compare_orders).pop()
);

const getPurchaseOrderId = (state, ownProps) => ownProps.purchase_order_id;
export const getPurchaseOrder = createSelector(
  getPurchaseOrders,
  getStatuses,
  getPurchaseOrderId,
  (purchase_orders, statuses, purchase_order_id) => ({
    ...purchase_orders[purchase_order_id],
    status: statuses[purchase_orders[purchase_order_id]?.status_id]
  })
);

export const getPurchaseOrderProofsList = createSelector(
  getPurchaseOrderProofs,
  getComments,
  getStatuses,
  (purchaseOrderProofs, comments, statuses) => {
    comments = _(comments)
      .filter({parent_type: 'PROOF'})
      .groupBy('parent_id')
      .value()
    ;

    return _.map(purchaseOrderProofs, (proof) => {
      return {
        ...proof,
        comments: (comments[proof.purchase_order_proof_id] || []).sort((c1, c2) => {
          return moment(c2.date_created).valueOf() - moment(c1.date_created).valueOf();
        }),
        status: statuses[proof.status_id],
      };
    });
  }
);

export const getPurchaseOrderList = createSelector(
  getPurchaseOrders,
  getPurchaseOrderProofsList,
  getOrders,
  getArtworks,
  getStatuses,
  (purchase_orders, purchaseOrderProofs, orders, artworks, statuses) => {
    const proofsByPoId = _.groupBy(purchaseOrderProofs, 'purchase_order_id');
    const artworksByItemId = _.groupBy(_.values(artworks), 'item_id');

    return _(purchase_orders)
      .map((po, purchase_order_id) => {
        const items = _.map(po.items, item => {
          return {
            ...item,
            artworks: artworksByItemId[item.item_id] || [],
          };
        });
        return {
          ...po,
          status: statuses[po.status_id],
          proofs: (proofsByPoId[po.purchase_order_id] || []).sort((a, b) => {
            if (a.file.date_created < b.file.date_created) {
              return -1;
            } else if (a.file.date_created > b.file.date_created) {
              return 1;
            }
            return 0;
          }),
          order: orders[po.order_id],
          items,
        };
      })
      .sort((a, b) => a.form_number - b.form_number)
      .value()
    ;
  }
);

export const getBaseItem = createSelector(
  getItems,
  getItemId,
  (items, item_id) => items[item_id]
);

export const constructFullItem = (items, breakdowns, item_costs, item_locations, item_decorations, artworks, tax_amounts, sizes, colors, item_images, item_warnings, files, base_costs, item_id, product_colors, product_sizes) => {
  const hidden_costs = item_costs.filter(c => 1 == c.hidden && c.quantity === null).reduce((t, ic) => t + (parseFloat(ic.unit_price) || 0), 0);
  const breakdown_quantity = breakdowns.reduce((t, b) => t + parseFloat(b.quantity), 0);
  const breakdown_cost = breakdowns.reduce((t, b) => t + parseFloat(b.quantity) * parseFloat(b.unit_cost), 0);
  //const filtered_costs = item_costs.filter(ic => !ic.from_db || ic.unit_cost != 0 || ic.item_cost_tab != 'INTERNATIONAL');
  const breakdown_sizes = breakdowns.map(b => b.size_id);
  const breakdown_colors = breakdowns.map(b => b.color_id);
  return Object.assign({}, items[item_id], {
    total_units: round(items[item_id].total_units, 0),
    unit_price: formatMoney(items[item_id].unit_price),
    total_subtotal: formatMoney(items[item_id].total_subtotal),
    total_taxes: formatMoney(items[item_id].total_taxes),
    total_total: formatMoney(items[item_id].total_total),
    base_costs: base_costs[item_id],
    item_images: Object.values(item_images).filter(ii => ii.item_id === item_id).map(ii => ({ ...ii, image: files[ii.file_id] })).sort((a, b) => a.display_order - b.display_order),
    item_warnings: Object.values(item_warnings).filter(iw => iw.item_id === item_id),
    breakdowns: breakdowns.sort((a, b) => a.display_order - b.display_order),
    item_costs: item_costs.sort((a, b) => a.display_order - b.display_order),
    item_decorations,
    item_locations: item_locations.sort((a, b) => a.display_order - b.display_order),
    artworks,
    tax_amounts: tax_amounts.sort((a, b) => a.display_order - b.display_order).map(
      ta => ({ ...ta, amount: formatMoney(ta.amount) })
    ),
    sizes: Object.values(sizes).filter(s => (product_sizes[items[item_id].parent_id] || []).indexOf(s.size_id) !== -1 || breakdown_sizes.includes(s.size_id)),
    colors: Object.values(colors).filter(c => (product_colors[items[item_id].parent_id] || []).indexOf(c.color_id) !== -1 || breakdown_colors.includes(c.color_id)).sort((a, b) => {
      if (a.color_name === 'TBD') {
        return -1;
      }
      if (a.color_name < b.color_name) {
        return -1;
      } else if (a.color_name > b.color_name) {
        return 1;
      } else {
        return 0;
      }
    }),
    hidden_costs,
    breakdown_quantity,
    breakdown_cost
  });
};

export const getFullItem = createSelector(
  getItems,
  getItemBreakdowns,
  getItemCosts,
  getItemLocations,
  getItemDecorations,
  getItemArtworks,
  getItemTaxAmounts,
  getSizes,
  getColors,
  getItemImages,
  getItemWarnings,
  getFiles,
  getBaseCosts,
  getItemId,
  getProductColors,
  getProductSizes,
  constructFullItem
);

export const constructFullOptionItem = (items, comments, breakdowns, item_costs, item_locations, item_decorations, item_retail_adjustments, artworks, item_images, item_warnings, sizes, colors, files, base_costs, item_id) => {
  const base_item = items[item_id];
  const base_options = Object.values(items).filter(i => i.option_item_id === item_id && i.order_id === base_item.order_id && i.parent_type === 'PRODUCT').sort((a, b) => a.display_order - b.display_order);
  const base_option_item_ids = base_options.map(i => i.item_id);
  const initial_option = base_options[0];
  const base_run_costs = Object.values(item_costs).filter(ic => ic.item_id === item_id && ic.quantity === null);
  const run_costs = base_run_costs.map(ic =>
    Object.values(item_costs).filter(ic2 => base_option_item_ids.includes(ic2.item_id) && ic2.option_item_cost_id === ic.item_cost_id)
  ).filter(ic => ic.length);
  const breakdown_cost = Object.values(breakdowns).reduce((t, b) => t + parseFloat(b.quantity) * parseFloat(b.unit_cost), 0);
  const breakdown_sizes = Object.values(breakdowns).filter(b => b.item_id === item_id).map(b => b.size_id);
  const breakdown_colors = Object.values(breakdowns).filter(b => b.item_id === item_id).map(b => b.color_id);
  return Object.assign({}, base_item, {
    base_costs: base_costs[item_id],
    item_locations: Object.values(item_locations).filter(il => il.item_id === item_id).sort((a, b) => a.display_order - b.display_order),
    item_decorations: Object.values(item_decorations).filter(id => id.item_id === item_id),
    item_retail_adjustments: Object.values(item_retail_adjustments).filter(
      ira => ira.item_id === item_id
    ).sort(
      (a, b) => a.display_order - b.display_order
    ),
    artworks,
    item_images: Object.values(item_images).filter(ii => ii.item_id === item_id).map(ii => ({ ...ii, image: files[ii.file_id] })).sort((a, b) => a.display_order - b.display_order),
    item_warnings: Object.values(item_warnings).filter(iw => iw.item_id === item_id),
    options: base_options.map(i => {
      const breakdown = Object.values(breakdowns).filter(b => b.item_id === i.item_id)[0];
      return Object.assign({}, i, {
        breakdown_id: breakdown.breakdown_id,
        unit_cost: breakdown.unit_cost,
        item_locations: Object.values(item_locations).filter(il => il.item_id === i.item_id).sort((a, b) => a.display_order - b.display_order),
        item_decorations: Object.values(item_decorations).filter(id => id.item_id === i.item_id)
       });
    }),
    comments: Object.values(comments || {}).filter(c => c.presentation_item_id === item_id).sort((a, b) => (new Date(b.date_created).getTime()) - (new Date(a.date_created).getTime())),
    breakdown_cost,
    base_run_costs,
    run_costs,
    fixed_costs: Object.values(item_costs).filter(ic => ic.item_id === item_id && ic.quantity !== null),
    initial_option,
    sizes: Object.values(sizes).filter(s => !s.product_id || s.product_id === items[item_id].parent_id || breakdown_sizes.includes(s.size_id)),
    colors: Object.values(colors).filter(s => !s.product_id || s.product_id === items[item_id].parent_id || breakdown_colors.includes(s.color_id)).sort((a, b) => {
      if (a.color_name === 'TBD') {
        return -1;
      }
      if (a.color_name < b.color_name) {
        return -1;
      } else if (a.color_name > b.color_name) {
        return 1;
      } else {
        return 0;
      }
    }),
  });
};

export const getFullOptionItem = createSelector(
  getItems,
  getComments,
  getBreakdowns,
  getCosts,
  getLocations,
  getDecorations,
  getRetailAdjustments,
  getItemArtworks,
  getItemImages,
  getItemWarnings,
  getSizes,
  getColors,
  getFiles,
  getBaseCosts,
  getItemId,
  constructFullOptionItem
);

export const getItemShipping = createSelector(
  getFullItems,
  getShipping,
  getShipMethods,
  getOrders,
  (items, shipping, ship_methods, orders) => {
    return Object.values(items)
      .filter(i => 'PRODUCT' === i.parent_type)
      .filter(i => 'SALES ORDER' === orders[i.order_id].order_type)
      .map(i => {
        const item = Object.assign({}, i, {
          default_shipping_address_id: orders[i.order_id].shipping_address_id
        });
        if (i.shipping && shipping[i.shipping[0]]) {
          item.vendor_details = Object.assign({}, shipping[i.shipping[0]], ship_methods[shipping[i.shipping[0]].ship_method_id]);
        } else {
          item.vendor_details = { source_parent_id: i.division_id, origin: i.origin };
        }
        if (i.shipping && shipping[i.shipping[1]]) {
          item.decorator_details = Object.assign({}, shipping[i.shipping[1]], ship_methods[shipping[i.shipping[1]].ship_method_id]);
        }
        return item;
      })
      .sort((a, b) => a.display_order - b.display_order)
    ;
  }
);

export const getBillingBySalesOrder = createSelector(
  getBillPurchaseOrders,
  getBills,
  getPurchaseOrders,
  getOrders,
  getStatuses,
  getProject,
  (bpos_by_order, bills, purchase_orders, orders, statuses, project) => {
   const result = Object.values(orders).filter(o => 'SALES ORDER' === o.order_type && o.job_id === project.job_id).reduce((t, o) => {
     t[o.order_id] = { order_id: o.order_id, form_number: o.form_number, currency_id: o.currency_id, bills: [] };
     return t;
   }, {});
   const has_pos = [];
   Object.values(bpos_by_order).reduce((result, bpos) => result.concat(bpos.map(bpo => {
     const bill = bills[bpo.bill_id];
     if (bpo.purchase_order_id) {
       const po = purchase_orders[bpo.purchase_order_id];
       if (!po) {
         return null;
       }
       has_pos.push(bpo.purchase_order_id);
       const bill_items = bill.purchase_orders.filter(
         po => po.purchase_order_id === bpo.purchase_order_id
       ).reduce(
         (bis, po) => bis.concat(
           po.items.reduce(
             (po_bis, i) => po_bis.concat(i.breakdowns).concat(i.costs),
             []
           ).concat(
             po.extra_items
	   )
         ),
         []
       );
       return {
         order_id: bpo.order_id,
         purchase_order_id: bpo.purchase_order_id,
         form_number: po.form_number,
         po_status_name: statuses[po.status_id].status_name,
         billed: po.billed,
         total: po.total,
         po_division_id: po.division_id || po.supplier_id,
         po_company_id: po.company_id,
         po_division_name: po.division_name,
         bill_id: bpo.bill_id,
         bill_status_name: statuses[bill.status_id].status_name,
         bill_reference_number: bill.bill_reference_number,
         bill_subtotal: bill.bill_subtotal,
         bill_item_subtotal: bill_items.reduce((t, bi) => t + Number(bi.total_subtotal), 0),
         bill_division_id: bill.division_id,
         bill_company_id: bill.company_id,
         bill_division_name: bill.division_name,
         bill_currency: bill.currency_id,
         bill_exchange_rate: bill.exchange_rate,
       };
     } else {
       const bill_items = bill.purchase_orders.filter(
         o => o.order_id === bpo.order_id
       ).reduce(
         (bis, o) => bis.concat(
           o.items.reduce(
             (o_bis, i) => o_bis.concat(i.breakdowns).concat(i.costs),
             []
           ).concat(
             o.extra_items
	   )
         ),
         []
       );
       return {
         order_id: bpo.order_id,
         purchase_order_id: '',
         form_number: '',
         po_status_name: '',
         billed: 1,
         total: '',
         po_division_id: null,
         po_company_id: null,
         po_division_name: null,
         bill_id: bpo.bill_id,
         bill_status_name: statuses[bill.status_id].status_name,
         bill_reference_number: bill.bill_reference_number,
         bill_subtotal: bill.bill_subtotal,
         bill_item_subtotal: bill_items.reduce((t, bi) => t + Number(bi.total_subtotal), 0),
         bill_division_id: bill.division_id,
         bill_company_id: bill.company_id,
         bill_division_name: bill.division_name,
         bill_currency: bill.currency_id,
         bill_exchange_rate: bill.exchange_rate,
       };
     }
   }).filter(bpo => bpo)), []).concat(Object.values(purchase_orders).filter(po => 0 == po.billed || !has_pos.includes(po.purchase_order_id)).map(po => ({
     order_id: po.order_id,
     purchase_order_id: po.purchase_order_id,
     form_number: po.form_number,
     po_status_name: statuses[po.status_id].status_name,
     billed: po.billed,
     total: po.total,
     po_division_id: po.division_id || po.supplier_id,
     po_company_id: po.company_id,
     po_division_name: po.division_name,
     bill_id: null,
     bill_status_name: null,
     bill_reference_number: null,
     bill_subtotal: null,
     bill_item_subtotal: null,
     bill_division_id: null,
     bill_company_id: null,
     bill_division_name: null,
     bill_currency: null,
     bill_exchange_rate: null,
   }))).forEach(b => {
    result[b.order_id] = { ...result[b.order_id], bills: result[b.order_id].bills.concat(b) };
  });
  return Object.values(result);
});

export const getCommissionsBySalesOrder = createSelector(
  getCommissions,
  getOrders,
  getUsers,
  (commissions, orders, users) => Object.values(orders).filter(o => 'SALES ORDER' === o.order_type).map(o => {
    const invoices = Object.values(orders).filter(i => 'INVOICE' === i.order_type);
    return {
      order_id: o.order_id,
      form_number: o.form_number,
      total_margin_amount: invoices.reduce((t, i) => t + parseFloat(i.total_margin_amount), 0),
      margin_subtotal: invoices.reduce((t, i) => t + parseFloat(i.margin_subtotal), 0),
      commissions: Object.values(commissions).filter(c => c.order_id === o.order_id && 0 == c.hidden).map(c => { c.user_name = `${users[c.sales_rep_id].contact_first_name} ${users[c.sales_rep_id].contact_last_name}`; return c; })
    };
  })
);

export const getProductionShipping = createSelector(
  getItems,
  getShipping,
  getOrders,
  getPurchaseOrders,
  getStatuses,
  (items, shipping, orders, purchase_orders, statuses) => {
    return _.filter(
      Object.values(items),
      i => 'PRODUCT' === i.parent_type && 'SALES ORDER' === orders[i.order_id].order_type
    ).reduce(
      (s, i) => s.concat(i.shipping.filter(s => !!shipping[s]).map(s => Object.assign({ order_id: i.order_id }, shipping[s]))),
      []
    ).map(
      s => {
        s.purchase_orders = Object.values(purchase_orders).filter(
          po => po.shipping_id === s.shipping_id
        ).map(
          po => ({ ...po, status: statuses[po.status_id] })
        );
        return s;
      }
    );
  }
);

export const getFeedbackByOrderId = createSelector(
  getFeedback,
  getProductFeedback,
  getItemImages,
  getFiles,
  getItems,
  getOrders,
  getOrderId,
  (feedback, product_feedback, item_images, files, items, orders, order_id) => {
    const product_images = Object.values(items).filter(i => orders[i.order_id].order_type === 'SALES ORDER' && i.parent_type === 'PRODUCT').reduce((p, i) => {
      const file_id = Object.values(item_images).filter(ii => ii.item_id === i.item_id).sort((a, b) => a.display_order - b.display_order)[0]?.file_id;
      if (file_id) {
        p[i.parent_id] = files[file_id];
      }
      return p;
    }, {});
    return Object.values(feedback).filter(f => f.order_id === order_id).map(f =>
      Object.assign({}, f, {
        product_feedback: Object.values(product_feedback).filter(pf => pf.feedback_id === f.feedback_id).map(pf =>
          Object.assign({}, pf, {
            product_image: product_images[pf.product_id] || files[pf.default_img_id]
          })
        ),
        files: Object.values(files).filter(fi => fi.parent_id === f.feedback_id)
      })
    );
  }
);

export const getInventoryByShopId = createSelector(
  getInventory,
  getWarehouses,
  (state, ownProps) => state.dropdowns.contacts,
  getShopId,
  (inventory, warehouses, contacts, shop_id) => Object.values(inventory).filter(
    i => i.shop_id === shop_id
  ).map(
    i => ({
      ...i,
      warehouse: warehouses[i.warehouse_id],
      contacts: contacts[i.inventory_id] || []
    })
  )[0]
);

const getInventoryId = (state, ownProps) => ownProps.inventory_id;

export const getItemsToInventoryByInventoryIdAndOrderId = createSelector(
  getInventoryItems,
  getItems,
  getItemImages,
  getFiles,
  getInventoryId,
  getOrderId,
  (inventory_items, items, item_images, files, inventory_id, order_id) => {
    const inventory_item_map = Object.values(inventory_items).filter(
      ii => ii.inventory_id === inventory_id
    ).reduce(
      (o, ii) => ({
        ...o,
        [ii.source_item_id]: (
          (o[ii.source_item_id] || []).concat([[ii.product_sku_id, ii.size_id, ii.color_id]])
        )
      }),
      {}
    );
    return Object.values(items).filter(
      i => i.order_id === order_id && i.parent_type === 'OPTION'
    ).map(
      i => i.item_skus.length > 0 ?
        ({
           item_id: i.item_id,
           item_name: i.item_name,
           item_sku: i.item_sku,
           image: Object.values(item_images).filter(ii => ii.item_id === i.item_id).map(ii => files[ii.file_id])[0],
           options: i.item_skus.filter(
             s => (inventory_item_map[i.item_id] || []).filter(ii => ii[0] === s.product_sku_id && ii[1] === null && ii[2] === null).length === 0
           ).map(
             s => ({
               key: { source_item_id: i.item_id, product_sku_id: s.product_sku_id, size_id: null, color_id: null },
               value: s.sku
	     })
	   )
        }) :
        ({
           item_id: i.item_id,
           item_name: i.item_name,
           item_sku: i.item_sku,
           image: Object.values(item_images).filter(ii => ii.item_id === i.item_id).map(ii => files[ii.file_id])[0],
           options: i.item_sizes.reduce(
             (o, s) => [...o, ...i.item_colors.filter(
               c => (inventory_item_map[i.item_id] || []).filter(ii=> ii[0] === null && ii[1] === s.size_id && ii[2] === c.color_id).length === 0
	     ).map(c => ({ key: { source_item_id: i.item_id, product_sku_id: null, size_id: s.size_id, color_id: c.color_id }, value: `${s.size_name} / ${c.color_name}` }))],
             []
           )
        })
    ).filter(
      o => o.options.length > 0
    );
  }
);

const getSkuDescription = sku_options => {
  const axes = Object.keys(sku_options).toSorted();
  return axes.map(
    axis => 'dimension' === axis && axes.length > 1 ? false : sku_options[axis]
  ).filter(Boolean).join("/");
};

export const getInventoryItemsByInventoryId = createSelector(
  getItems,
  getColors,
  getSizes,
  getSkus,
  getInventoryItems,
  getInventoryId,
  (items, colors, sizes, skus, inventory_items, inventory_id) => Object.values(inventory_items).filter(
    ii => ii.inventory_id === inventory_id
  ).map(
    ii => {
      const sku_details = skus[ii.product_sku_id] ?? {};
      const sku_options = JSON.parse(sku_details.sku_options ?? '{}');
      const sku = sku_details.sku ?? "";
      const sku_description = sku_details.sku_description || getSkuDescription(sku_options);
      return {
        ...ii,
        item_name: items[ii.source_item_id].item_name,
        item_sku: items[ii.source_item_id].item_sku,
        color_name: (colors[ii.color_id] || {}).color_name,
        size_name: (sizes[ii.size_id] || {}).size_name,
        sku,
        sku_description,
      };
    }
  )
);

const getClientId = (state, ownProps) => ownProps.client_id;
export const getClientById = createSelector(
  getClients,
  getClientId,
  (clients, client_id) => clients[client_id]
);

export const getOrderById = createSelector(
  getOrders,
  getItems,
  getOrderId,
  (orders, items, order_id) => orders[order_id]
);

const getInventoryItemId = (state, ownProps) => ownProps.inventory_item_id;

export const getInventoryItemById = createSelector(
  getInventoryItems,
  getInventoryItemId,
  (inventory_items, inventory_item_id) => inventory_items[inventory_item_id]
);

export const getRoleById = createSelector(
  getRoles,
  (state, id) => id,
  (roles, id) => roles[id]
);

export const getRoleOptions = createSelector(
  getRoles,
  r => Object.values(r).map(v => ({ value: v.role_id, label: v.role_name }))
);

export const getFeaturesList = createSelector(
  getFeatures,
  s => Object.values(s),
);

export const getFullFeatureId = createSelector(
  getFeatures,
  s => _.get(
    _.find(Object.values(s), v => v.feature_name === 'FULL'),
    ['feature_id']
  ),
);

export const getSocialFeatureId = createSelector(
  getFeatures,
  s => _.get(
    _.find(Object.values(s), v => v.feature_name === 'SOCIAL'),
    ['feature_id']
  ),
);

export const getFeatureIdByName = createSelector(
  [
    getFeatures,
    (s, featureName) => featureName,
  ],
  (s, featureName) => _.get(
    _.find(Object.values(s), v => v.feature_name === featureName),
    ['feature_id']
  ),
);

function selectCartItem(
  item_id,
  items,
  locations,
  costs,
  item_images,
  artworks,
  imprints,
  files,
  colors,
  sizes,
  skus,
  inventory_items
) {
  const item = items[item_id];
  const options = Object.values(items).filter(
    i => i.option_item_id === item_id && i.parent_type === 'PRODUCT' && 0 === +i.hidden
  ).sort(
    (a, b) => parseFloat(a.total_units) - parseFloat(b.total_units)
  );
  const minimum_quantity = parseFloat(options[0]?.total_units ?? 0);

  const fixed_costs = 1 === +item.share_fixed_charges ?
    Object.values(costs).filter(
      ic => ic.item_id === item_id && ic.quantity !== null && 0 === +ic.hidden
    ).map(
      ic => {
        const title = (locations[ic.item_location_id] || { item_location_title: ''}).item_location_title.replace(/^Location [0-9]+:: /, '');
        return {
          item_cost_id: ic.item_cost_id,
          item_cost_name: ic.item_cost_name,
          item_cost_title: ic.item_cost_title + (title ? ` - ${title}` : ''),
          quantity: ic.quantity,
          unit_price: ic.unit_price,
          subtotal: parseFloat(ic.total_price)
        };
      }
    ) :
    [];
  return {
    item_id,
    order_id: item.order_id,
    options,
    fixed_costs,
    retail_price: parseFloat(item.retail_price),
    minimum_quantity,
    item_name: item.item_name,
    copied_from: item.copied_from,
    image: Object.values(item_images).filter(ii => ii.item_id === item_id).map(ii => files[ii.file_id])[0],
    artworks: Object.values(artworks).filter(a => a.item_id === item_id).map(a => ({
      ...a,
      item_location_title: locations?.[a.item_location_id]?.item_location_title?.replace(/^Location [0-9]:: /, ''),
      imprint_name: (imprints[a.imprint_id] || { imprint_name: 'None' }).imprint_name,
      image: '00000000-0000-0000-0000-000000000000' !== a.artwork_file_id ? files[a.artwork_file_id] : null
    })),
    sizes: Object.values(sizes).filter(s => s.item_id === item_id),
    colors: Object.values(colors).filter(c => c.item_id === item_id).sort((a, b) => {
      if (a.color_name === 'TBD') {
        return -1;
      }
      if (a.color_name < b.color_name) {
        return -1;
      } else if (a.color_name > b.color_name) {
        return 1;
      } else {
        return 0;
      }
    }),
    skus: Object.values(skus).filter(s => s.item_id === item_id),
    inventory_items: Object.values(inventory_items ?? {}).filter(ii => ii.source_item_id === item_id)
  };
}

const getBaseSelectCartItem = createSelector(
  getItemId,
  getItems,
  getLocations,
  getCosts,
  getItemImages,
  getArtworks,
  getImprints,
  getFiles,
  getColors,
  getSizes,
  getSkus,
  getInventoryItems,
  selectCartItem
);

const getSkuMap = createSelector(
  getSkus,
  skus => Object.values(skus).reduce(
    (o, s) => ({
      ...o,
      [s.sku]: s.product_sku_id,
    }),
    {}
  )
);

const getSkuOptionMap = createSelector(
  getSkus,
  skus => Object.values(skus).reduce(
    (ooo, s) => ({
      ...ooo,
      ...s.options.reduce(
        (oo, o) => ({
          ...oo,
          [o.option_axis + ':' + o.option_name]: (oo[o.option_axis + ':' + o.option_name] || []).concat(s.product_sku_id)
        }),
        ooo
      )
    }),
    {}
  )
);

// Sometimes state.entities.colors uses item_color_id as the key so that if there are multiple item_colors with the same color_id they can all exist in the map
// This selector indexes the colors by color_id
const getColorsByColorId = createSelector(
  getColors,
  colors => Object.values(colors).reduce(
    (o, c) => ({
      ...o,
      [c.color_id]: c
    }),
    {}
  )
);

// Sometimes state.entities.sizes uses item_size_id as the key so that if there are multiple item_sizes with the same size_id they can all exist in the map
// This selector indexes the sizes by size_id
const getSizesBySizeId = createSelector(
  getSizes,
  sizes => Object.values(sizes).reduce(
    (o, s) => ({
      ...o,
      [s.size_id]: s
    }),
    {}
  )
);

// Sometimes state.entities.skus uses item_sku_id as the key so that if there are multiple item_skus with the same product_sku_id they can all exist in the map
// This selector indexes the skus by sku_id
const getSkusBySkuId = createSelector(
  getSkus,
  skus => Object.values(skus).reduce(
    (o, s) => ({
      ...o,
      [s.product_sku_id]: s
    }),
    {}
  )
);

const getFullCartItemData = (
  item,
  orders,
  colors_by_color_id,
  sizes_by_size_id,
  skus_by_sku_id,
  adjustments,
  sku_map,
  sku_option_map,
  cart,
  cartItemData
) => {
  const isAggregate = +(orders[item.order_id]?.aggregate ?? 0) === 1;
  const buyInventory = !!cartItemData?.buy_inventory;
  const baseAdjustments = Object.values(adjustments ?? {}).filter(
    a => a.item_id === item.item_id
  );
  const itemBreakdowns = cart[item.item_id] ?? [];
  const quantity = itemBreakdowns.reduce((t, b) => t + parseFloat(b.quantity), 0);
  const baseUnitPrice = isAggregate ?
    item.retail_price :
    parseFloat((
      item.options.findLast(
        o => parseFloat(o.total_units) <= parseFloat(quantity)
      ) ?? item.options[0] ?? { unit_price: 0 }
    ).unit_price);

  const getUnitPrice = (product_sku_id = null, color_id = null, size_id = null) => {
    if (!!product_sku_id) {
      const inventory_item = item.inventory_items.find(ii => ii.product_sku_id === product_sku_id);
      if (inventory_item && !buyInventory) {
        return inventory_item.price || 0;
      }
      return baseAdjustments.filter(
        a => (sku_map[a.sku] === product_sku_id || (sku_option_map[a.axis + ':' + a.option] || []).includes(product_sku_id))
      ).reduce(
        (t, a) => t + parseFloat(a.adjustment),
        baseUnitPrice
      );
    } else if (!!color_id || !!size_id) {
      const inventory_item = item.inventory_items.find(ii => ii.size_id === size_id && ii.color_id === color_id);
      if (inventory_item && !buyInventory) {
        return inventory_item.price || 0;
      }
      return baseAdjustments.filter(
        a => (
          a.axis === 'color' && !!color_id && (colors_by_color_id[color_id] ?? { color_name: '' }).color_name.toLowerCase() === a.option.toLowerCase()
        ) || (
          a.axis === 'size' && !!size_id && (sizes_by_size_id[size_id] ?? { size_name: '' }).size_name.toLowerCase() === a.option.toLowerCase()
        )
      ).reduce(
        (t, a) => t + parseFloat(a.adjustment),
        baseUnitPrice
      );
    }
    return baseUnitPrice;
  };

  const breakdowns = itemBreakdowns.map(b => ({
    ...b,
    sku_description: skus_by_sku_id[b.product_sku_id]?.sku_description,
    color_name: colors_by_color_id[b.color_id]?.color_name,
    size_name: sizes_by_size_id[b.size_id]?.size_name,
    unit_price: getUnitPrice(b.product_sku_id, b.color_id, b.size_id),
    inventory_item: item.inventory_items.find(
      ii => (
        ii.source_item_id === item.item_id &&
        ii.product_sku_id === b.product_sku_id &&
        ii.size_id === b.size_id &&
        ii.color_id === b.color_id
      )
    ),
    subtotal: b.quantity * getUnitPrice(b.product_sku_id, b.color_id, b.size_id)
  }));

  const hasNonInventoryItems = breakdowns.filter(b => !b.inventory_item).length > 0;
  const fixed_costs = quantity > 0 && hasNonInventoryItems ? item.fixed_costs : [];
  const subtotal = breakdowns.reduce(
    (t, b) => t + parseFloat(b.subtotal),
    0
  ) + fixed_costs.reduce(
    (t, c) => t + parseFloat(c.subtotal),
    0
  );
  return {
    ...item,
    breakdowns,
    fixed_costs,
    quantity,
    subtotal
  };
}

const getCustomCart = (state, ownProps) => ownProps.cart;
export const getCustomCartItem = createSelector(
  getBaseSelectCartItem,
  getOrders,
  getColorsByColorId,
  getSizesBySizeId,
  getSkusBySkuId,
  getRetailAdjustments,
  getSkuMap,
  getSkuOptionMap,
  getCustomCart,
  getCartItemData,
  getFullCartItemData
);

export const getCartItem = createSelector(
  getBaseSelectCartItem,
  getOrders,
  getColorsByColorId,
  getSizesBySizeId,
  getSkusBySkuId,
  getRetailAdjustments,
  getSkuMap,
  getSkuOptionMap,
  getCart,
  getCartItemData,
  getFullCartItemData
);

export const getFullCart = createSelector(
  getCart,
  getOrders,
  getItems,
  getLocations,
  getItemImages,
  getRetailAdjustments,
  getArtworks,
  getImprints,
  getFiles,
  getCosts,
  getColors,
  getSizes,
  getSkus,
  getColorsByColorId,
  getSizesBySizeId,
  getSkusBySkuId,
  getSkuMap,
  getSkuOptionMap,
  getInventoryItems,
  getCartItemData,
  (cart, orders, items, locations, item_images, adjustments, artworks, imprints, files, costs, colors, sizes, skus, colors_by_color_id, sizes_by_size_id, skus_by_sku_id, sku_map, sku_option_map, inventory_items, cartItemData) => ({
    ...cart,
    items: Object.keys(cart).map(
      item_id => {
        const item = selectCartItem(
          item_id,
          items,
          locations,
          costs,
          item_images,
          artworks,
          imprints,
          files,
          colors,
          sizes,
          skus,
          inventory_items
        );
        return getFullCartItemData(
          item,
          orders,
          colors_by_color_id,
          sizes_by_size_id,
          skus_by_sku_id,
          adjustments,
          sku_map,
          sku_option_map,
          cart,
          cartItemData
        );
      }
    )
  })
);

export const selectCartItemQuantity = createSelector(
  getCart,
  getItemId,
  (cart, item_id) => cart?.[item_id]?.reduce((t, b) => t + parseFloat(b.quantity), 0) ?? 0
);

export const selectCartQuantity = createSelector(
  getCart,
  (cart) => Object.values(cart || {}).reduce(
    (qty, v) => (qty + v.reduce((acc, q) => acc+q.quantity, 0)),
    0
  ) ?? 0
);

export const getUserImageByFileId = createSelector(
  getFiles,
  (state, fileId) => fileId,
  (files, fileId) => files[fileId]
);
