import update from 'immutability-helper';
import { get, filter, drop, map, take, isEmpty, findIndex } from 'lodash';
import React, { useState, useEffect, useMemo, useCallback, useRef } from 'react';
import styled from 'styled-components';
import { useDrag, useDrop } from 'react-dnd';
import { DragIcon, Button, Row, Col, Popup, TabBar, Tab, Dropdown, colors, ConnectedPlusIcon } from '@commonsku/styles';

import DragItemTypes from '../DragItemTypes';
import { getIdentityUtils } from '../utils';
import { useIdentity, useProductSearchTabOrder } from '../hooks';
import ErrorBoundary from './ErrorBoundary';

const useGetTabProps = () => {
  const identity = useIdentity();
  const { hasUserFlags } = getIdentityUtils(identity);
  const { company_name } = identity;
  const tabProps = {
    'personal-products': {
      label: company_name, newProduct: true,
    },
    'commonsku-products': {
      label: hasUserFlags('SEARCH-SUPPLIER-DIRECT') ? 'Supplier Direct' : 'Manually Added', newProduct: true,
    },
    'dc-products': {
      label: 'Distributor Central',
    },
    'esp-products': {
      label: 'ESP',
    },
    'sage-products': {
      label: 'SAGE',
    },
    'sage-connect-products': {
      label: 'SAGE Connect',
    },
    'ps-products': {
      label: 'Promostandards', tab: 'promostandards',
    },
    'bookmark-products': {
      label: 'Bookmarks',
    },
    'tagged-products': {
      label: 'Tagged',
    },
    'promodata-products': {
      label: 'Promodata',
    },
    'order-products': {
      label: 'Previous Orders',
    },
    'collection-products': {
      label: 'From Collections',
    },
    'connected-plus-products': {
      label: 'Connected +',
      tab: 'connected-plus',
    },
    'product-suggestion-products': {
      label: <div style={{ display: 'inline-flex', alignItems: 'center', gap: 8 }}><ConnectedPlusIcon /> <div>Connected+ Search</div></div>,
      tab: 'product-suggestion',
    }
  };

  return (key) => {
    const props = get(tabProps, key);
    return !props ? null : {
      key,
      tab: key.replace(/-products/, ''),
      ...props,
    };
  };
};

const DraggableLabel = ({
  id,
  label,
  index,
  onMove,
  onDrop,
}) => {
  const ref = useRef(null);
  const [{ handlerId }, drop] = useDrop({
    accept: DragItemTypes.SEARCHTAB,
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }

      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset).y - hoverBoundingRect.top;

      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      onMove(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
    drop(item, monitor) {
      onDrop(item, monitor);
    },
  });

  const [{ isDragging }, drag] = useDrag({
    type: DragItemTypes.SEARCHTAB,
    canDrag: !!label,
    item: () => {
      return { id, label, index };
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const opacity = isDragging ? 0 : 1;
  drag(drop(ref));

  return (
    <div ref={ref} style={{ opacity }} data-handler-id={handlerId}>
      {!label
        ? <div style={{ display: 'flex', borderTop: `2px solid ${colors.neutrals['60']}`, paddingBottom: 10 }}/>
        : <div
          style={{
            display: 'flex',
            alignItems: 'center',
            padding: '0 12px 10px 9px',
            minHeight: 30,
            color: colors.primary1['main']
          }}
        ><DragIcon style={{ marginRight: '1rem' }}/> {label}</div>}
    </div>
  );
};

const DragContainer = ({ productSearchTabOrder, setProductSearchTabOrder }) => {
  const getTabProps = useGetTabProps();
  const [tabOrder, setTabOrder] = useState(productSearchTabOrder);

  useEffect(() => {
    setTabOrder(productSearchTabOrder);
  }, [productSearchTabOrder]);

  const onMove = useCallback((dragIndex, hoverIndex) => {
    setTabOrder(prevState =>
      update(prevState, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevState[dragIndex]],
        ],
      })
    );
  }, []);

  const onDrop = useCallback((item, monitor) => {
    setProductSearchTabOrder(tabOrder);
  }, [setProductSearchTabOrder, tabOrder]);

  const renderLabel = useCallback(
    (label, index, key) => {
      return (
        <DraggableLabel
          key={key}
          id={key}
          index={index}
          label={label}
          onMove={onMove}
          onDrop={onDrop}
        />
      );
    },
    [onMove, onDrop],
  );

  return (
    <>
      {map(tabOrder, (key, i) => {
        let label = '';
        if (!isEmpty(key)) {
          const tabProps = getTabProps(key);
          if (tabProps === null) {
            return null;
          } else {
            label = tabProps.label;
          }
        }
        return renderLabel(label, i, key);
      })}
    </>
  );
};

const ReOrderPopup = ({ onClose, productSearchTabOrder, setProductSearchTabOrder }) => {

  return <Popup
    style={{ width: 'auto', height: 'auto', padding: '1rem 0' }}
    header={<Row></Row>}
    closeOnClickOutside={true}
    onClose={() => {
      onClose();
    }}
  >
    <ErrorBoundary>
      <Col style={{ flexDirection: 'column' }}>
        <DragContainer
          productSearchTabOrder={productSearchTabOrder}
          setProductSearchTabOrder={setProductSearchTabOrder}
        />
      </Col>
      <Row style={{ justifyContent: 'center', padding: '0 12px' }}>
        <Button style={{ width: '100%', fontWeight: 600 }} size="medium" onClick={onClose}>Done</Button>
      </Row>
    </ErrorBoundary>
  </Popup>;
};

const Customize = () => {
  return <div style={{ fontWeight: 700 }}>Customize</div>;
};

const ProductSearchTabContainer = ({
  order_type, isProductsPage,
  order_id, total_products,
  handleClickTab, handleClickAddNewProduct,
  isCollectionPage,
  isSuggestSearch,
  ...props
}) => {
  const [productSearchTabOrder, setProductSearchTabOrder] = useProductSearchTabOrder({ order_type, isProductsPage, isCollectionPage });
  const getTabProps = useGetTabProps();
  const tabs = filter(map(productSearchTabOrder, getTabProps));
  const visibleSize = useMemo(
    () => (productSearchTabOrder || []).indexOf(''),
    [productSearchTabOrder]
  );
  const visibleTabs = take(tabs, visibleSize);
  const collapsedTabs = drop(tabs, visibleSize);
  const [showPopup, setShowPopup] = useState(false);
  const [selectedTab, setSelectedTab] = useState(get(tabs, '0.tab'));
  const index = findIndex(tabs, {tab: selectedTab});
  const handleChangeTab = (tab) => (e) => {
    setSelectedTab(tab);
    handleClickTab(e, tab);
  };
  const selectedCollapsedTab = get(collapsedTabs, index - visibleSize);

  useEffect(() => {
    if (!isEmpty(tabs)) {
      handleChangeTab(selectedTab)(null);
    }
  }, []);

  useEffect(() => {
    if (isSuggestSearch) {
      handleChangeTab('product-suggestion')(null);
    }
  }, [isSuggestSearch]);

  return <TabBar {...props}>
    {map(
      [
        ...visibleTabs,
        collapsedTabs.length === 0
          ? {
            label: <Customize/>,
            onClick: () => {
              setShowPopup(true);
            }
          }
          : {
            label: <Dropdown
              className={`collapsible-tabs-dropdown ${selectedCollapsedTab ? 'selected' : ''}`}
              primary={true}
              width={200}
              size="small" text={selectedCollapsedTab ? selectedCollapsedTab.label : 'More'} buttonVariant="text"
              items={[
                ...map(collapsedTabs, (tab, j) => {
                  const i = j + visibleSize;
                  return {
                    content: tab.label,
                    onClick: handleChangeTab(tab.tab),
                    className: `dropdown-item ${i === index ? 'selected' : ''}`,
                  };
                }),
                {
                  content: <Customize/>,
                  onClick: () => {
                    setShowPopup(true);
                  }
                }
              ]}
            />,
            onClick: (e) => {
              e.stopPropagation();
            },
          }
      ],
      ({ label, tab, ...tabProps }, i) => {
        const selected = i === index || (i === visibleSize && index > visibleSize);
        return <Tab
          key={i} className={selected ? 'selected' : ''} selected={selected} onClick={handleChangeTab(tab)}
          {...tabProps}
        >{label} {selected && total_products != -1 && `(${total_products})`}</Tab>;
      }
    )}
    {get(tabs, [index, 'newProduct']) ? <button
      className="button alt" onClick={e => {e.preventDefault(); handleClickAddNewProduct(order_id); }}
    >+ New Product</button> : null}
    {showPopup && <ReOrderPopup
      visibleSize={visibleSize}
      productSearchTabOrder={productSearchTabOrder}
      setProductSearchTabOrder={setProductSearchTabOrder}
      onClose={() => {
        setShowPopup(false);
      }}
    />}
  </TabBar>;
};

export default styled(ProductSearchTabContainer)`
  &&& {
    li {
      color: ${colors.teal.main};
      opacity: 1;
      padding-bottom: 0.4rem;
    }
    
    li.selected, .dropdown-item.selected {
      color: ${colors.primary1.darkest};
      font-weight: 600;
      border-bottom-color: ${colors.primary1.main};
    }
    
    .collapsible-tabs-dropdown {
      font-weight: normal;
      min-width: 400px;
    
      &.selected button {
        color: ${colors.primary1.darkest};
        font-weight: 600;       
      }
    
      button {
        padding: 0;
        font-size: 16px;
        border: none;
        background-color: inherit;
        font-family: 'skufont-demibold', sans-serif;
      }
    }
  }
`;