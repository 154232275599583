import React, { useMemo } from "react";
import {
  ArrowIcon,
  Button,
  Col,
  colors,
  IconButton,
  Row,
  Text,
} from "@commonsku/styles";
import Img from "../../../components/Img";
import { IPublicViewTemplateData } from "../../../interfaces/PublicViewTemplateData";
import ThreeDotsMenu from "../ThreeDotsMenu/ThreeDotsMenu";
import _ from "lodash";
import { useIdentity } from "../../../hooks";
import { getIdentityUtils } from "../../../utils";
import { useSelector } from "react-redux";
import { selectIsLoading } from "../../../redux/isLoading";

type TemplateTileProps = {
  templates: (IPublicViewTemplateData & {
    company_id: string;
    date_created: string;
  })[];
  onRemoveDefaultTemplate?: (
    public_view_template_id: string,
    order_types: string[]
  ) => void;
  onAddDefaultTemplate?: (
    public_view_template_id: string,
    order_types: string[]
  ) => void;
  shopDefaultTemplateId?: string;
  templateNames?: string[];
  isTeam?: boolean;
  isPopup?: boolean;
  tab?: string;
  onCreateShop?: (
    public_view_template_id: string,
    isCustomize?: boolean
  ) => void;
};

const TemplateTile = ({
  templates,
  shopDefaultTemplateId,
  onRemoveDefaultTemplate,
  onAddDefaultTemplate,
  templateNames,
  isTeam,
  isPopup,
  tab,
  onCreateShop,
}: TemplateTileProps) => {
  const identity = useIdentity();
  const { hasCapabilities } = getIdentityUtils(identity);
  const canManageTemplates = hasCapabilities(["MANAGE-PUBLIC-VIEW-TEMPLATE"]);
  const isLoading = useSelector(selectIsLoading);

  const desc = useMemo(() => {
    if (!tab) return;
    if (tab === "company") {
      const companyTemplates = templates.filter(
        (template) =>
          template.template_type === "TEMPLATE" && template.company_id
      );
      return companyTemplates.length > 0
        ? "Pick a template to set the look and feel of your  presentation."
        : null;
    } else if (tab === "commonsku") {
      return "Get started with a commonsku template! ";
    } else {
      return "Start fresh with a layout!";
    }
  }, [tab, templates]);

  const sortedTemplates = useMemo(() => {
    if (!templates || tab === "layouts") {
      return templates || [];
    }

    const defaultTemplate = templates.find(
      (t) => t?.public_view_template_id === shopDefaultTemplateId
    );

    if (!isTeam) {
      return [
        defaultTemplate,
        ...templates
          .filter((t) => t.public_view_template_id !== shopDefaultTemplateId)
          .sort((a, b) => b.date_created.localeCompare(a.date_created)),
      ];
    }

    return templates.sort((a, b) =>
      b.date_created.localeCompare(a.date_created)
    );
  }, [templates, shopDefaultTemplateId, isTeam, tab]);

  return (
    <Col xs>
      {isPopup && (
        <Row>
          <Col xs style={{ paddingLeft: 8, marginTop: 16 }}>
            <Text
              as="p"
              style={{
                fontFamily: "var(--font-family-regular)",
                fontSize: 18,
              }}
            >
              {desc}
            </Text>
          </Col>
        </Row>
      )}
      <Row>
        {sortedTemplates.map((template, i) => {
          if (!template) return null;

          const {
            public_view_template_id,
            public_view_template_title,
            public_view_template_background,
            template_type,
            status,
          } = template;
          const isDefaultTemplate =
            shopDefaultTemplateId &&
            shopDefaultTemplateId === public_view_template_id;
          return (
            <Col
              padded
              xs
              sm={6}
              md={4}
              key={public_view_template_title + "-" + i}
              style={{ overflow: "hidden", minWidth: 392, minHeight: 414 }}
            >
              <Row
                style={{
                  background:
                    isDefaultTemplate && !isPopup ? "#E1F7FA" : "white",
                  padding: 16,
                  border: isDefaultTemplate && "3px solid #00A0B6",
                  borderRadius: 5,
                  justifyContent: "right",
                }}
              >
                <Row
                  style={{
                    alignItems: "center",
                    width: "100%",
                    justifyContent: "space-between",
                  }}
                >
                  {isDefaultTemplate && !isPopup && (
                    <Col totalCols={4} xs={3}>
                      <Text
                        as="h3"
                        style={{
                          color: colors.neutrals["90"],
                          fontWeight: 600,
                          fontSize: 20,
                          marginBottom: 0,
                        }}
                      >
                        Default Template
                      </Text>
                    </Col>
                  )}
                  <Col totalCols={4} xs={isDefaultTemplate ? 1 : 4}>
                    {!isPopup
                      ? template_type !== "LAYOUT" &&
                        canManageTemplates && (
                          <ThreeDotsMenu
                            draft={status}
                            public_view_template_id={public_view_template_id}
                            public_view_template_title={
                              public_view_template_title
                            }
                            isDefaultTemplate={isDefaultTemplate}
                            onRemoveDefaultTemplate={onRemoveDefaultTemplate}
                            onAddDefaultTemplate={onAddDefaultTemplate}
                            templateNames={templateNames}
                          />
                        )
                      : null}
                  </Col>
                </Row>
                <Col
                  xs
                  style={{ height: 282, textAlign: "center", display: "flex" }}
                >
                  <Img
                    style={{
                      maxHeight: "100%",
                      width: "100%",
                      alignSelf: "center",
                    }}
                    alt={public_view_template_title}
                    src={public_view_template_background}
                  />
                </Col>
                <Col xs style={{ paddingTop: 24 }}>
                  <Text
                    style={{
                      color: "var(--color-neutrals-90)",
                      fontFamily: "var(--font-family-regular)",
                      fontSize: 18,
                    }}
                  >
                    {public_view_template_title}{" "}
                    {isPopup && _.startCase(template_type.toLowerCase())}
                  </Text>
                </Col>
                {isPopup && (
                  <Col xs style={{ paddingTop: 24 }}>
                    <Row>
                      <Col xs={6} style={{ paddingRight: 8, paddingTop: 8 }}>
                        <Button
                          variant="secondary"
                          size="medium"
                          style={{ width: "100%" }}
                          onClick={() =>
                            window.open(public_view_template_background)
                          }
                          disabled={isLoading}
                        >
                          Preview
                        </Button>
                      </Col>
                      <Col
                        xs={6}
                        style={{
                          paddingLeft: 8,
                          textAlign: "right",
                          paddingTop: 8,
                        }}
                      >
                        <IconButton
                          variant="primary"
                          size="medium"
                          style={{
                            width: "100%",
                            paddingLeft: "8px",
                            paddingRight: "8px",
                          }}
                          onClick={() =>
                            onCreateShop(
                              public_view_template_id,
                              isDefaultTemplate ? true : false
                            )
                          }
                          Icon={isDefaultTemplate ? ArrowIcon : null}
                          iconPosition="right"
                          iconProps={{ direction: "right" }}
                          disabled={isLoading}
                        >
                          {tab === "layouts"
                            ? isDefaultTemplate
                              ? "Customize"
                              : "Select"
                            : isDefaultTemplate
                            ? "Customize"
                            : "Select Template"}
                        </IconButton>
                      </Col>
                    </Row>
                  </Col>
                )}
              </Row>
            </Col>
          );
        })}
      </Row>
    </Col>
  );
};

export default TemplateTile;
