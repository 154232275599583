import styled from 'styled-components'

export const ItemListTableHeader = styled.thead`
  height: 60px;
  ${props => props.hasItem && `
    @media screen and (max-width: ${props.isArtwork ? '1940px' : '1650px'}) {
      height: 100px;
      vertical-align: bottom;
    }
  `}
`;

export const ItemListTHDescription = styled.th`
${props => props.isArtwork && 'min-width: 580px;'}
  ${props => props.hasItem && `
    @media screen and (max-width: ${props.isArtwork ? '1940px' : '1650px'}) {
      &&&&& {
        ${props.isArtwork ? 'position: absolute;' : ''}
        width: auto;
        ${props.isArtwork ? 'min-width: auto;' : ''}
      }
    }
  `}
`;