import React, { useEffect, useMemo } from 'react';
import { connect, useSelector } from 'react-redux';
import { BrowserRouter, Route, Routes, useLocation, useParams, } from "react-router-dom";

import App from '../containers/App';
import NoMatch from './NoMatch';
import ErrorBoundary from './ErrorBoundary';
import { useHasCapabilities } from '../hooks';

const AppRoute = React.memo(({
  panel,
  modal,
  isProject,
  isAddProduct,
  isPresentationItem,
  isItem,
  isOrder,
  isBill,
}) => {
  const allParams = useParams();
  const location = useLocation();

  const job = useSelector(state => state.entities.projects[Object.keys(state.entities.projects)[0]]);
  const orders = useSelector(state => state.entities.orders);

  const params = useMemo(
    () => {
      const result = {
        ...allParams,
        order_type: (allParams.order_type || '').toUpperCase().replace('-', ' ') || undefined,
        job_number: job.job_number,
      };
      if (isPresentationItem) {
        return {...result, order_type: 'PRESENTATION'};
      }
      return result;
    },
    [allParams, job, isPresentationItem]
  );
  const opportunity = useMemo(
    () => (
      Object.values(orders).filter(o => o.order_type === 'OPPORTUNITY')[0]
    ),
    [orders]
  );
  const order = useMemo(
    () => (
      (isOrder || isItem || isPresentationItem) ?
        Object.values(orders).filter(function(o) {
          let order_type = params.order_type;
          let order_number = params.order_number;

          return o.order_type.toUpperCase() === order_type && o.form_number === order_number;
        })[0]
      : null
    ),
    [orders, params, isOrder, isItem, isPresentationItem]
  );

  if (isAddProduct && [
    'PRESENTATION', 'ESTIMATE', 'SALES ORDER', 'INVOICE'
  ].includes(params.order_type)) {
    return (
      <App
        modal="product-search"
        project={job}
        order={order}
        params={params}
        location={location}
      />
    );
  } else if (panel === 'presentation-design') {
    return (
      <App
        panel={panel}
        project={job}
        order={order}
        params={params}
        location={location}
      />
    );
  } else if (isPresentationItem) {
    return (
      <App
        modal="presentation-item"
        project={job}
        order={order}
        params={params}
        location={location}
      />
    );
  } else if (isItem && [
    'ESTIMATE', 'SALES ORDER', 'INVOICE'
  ].includes(params.order_type)) {
    return (
      <App
        modal="order-item"
        project={job}
        order={order}
        params={params}
        location={location}
      />
    );
  } else if (isBill) {
    return (
      <App
        modal="bill"
        project={job}
        params={{
          ...params,
          bill_id: params.bill_id !== 'add-bill' ? params.bill_id : null,
        }}
        location={location}
      />
    );
  } else if (isOrder && [
    'PRESENTATION', 'ESTIMATE', 'SALES ORDER', 'INVOICE'
  ].includes(params.order_type)) {
    return (
      <App
        panel="order"
        project={job}
        order={order}
        params={params}
        location={location}
      />
    );
  } else if (isProject) {
    return (
      <App
        panel="notes"
        project={job}
        params={params}
        location={location}
      />
    );
  }

  if (panel) {
    return <App panel={panel} params={params} location={location} project={job} order={order} />;
  } else if (modal) {
    if (["product-search", "bill"].includes(modal)) {
      return <App
        modal={modal}
        location={location}
        project={job}
        params={params}
        {...(modal === "product-search" ? { order: opportunity } : {})}
      />;
    }
    return <App modal={modal} params={params} location={location} project={job} order={order} />;
  } else if (isAddProduct || isPresentationItem || isItem || isOrder || isBill) {
    return <App params={params} location={location} project={job} order={order} />;
  }

  return <NoMatch location={location} />;
});

const ProjectRouter = ({ job, orders, account_name, location }) => {
  useEffect(() => {
    document.title = `${account_name}${!!account_name ? ' - ' : ''}Project #${job.job_number} - ${job.job_name}`;
  }, [job, account_name]);

  return (
    <BrowserRouter basename={location}>
      <Routes>
        <Route
          path="/project/:job_number"
          element={<AppRoute panel="notes" isProject={true} />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/project/:job_number/files"
          element={<AppRoute panel="files" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/project/:job_number/collaborate"
          element={<AppRoute panel="collaborate" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/project/:job_number/add-product"
          element={<AppRoute modal="product-search" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/project/:job_number/:order_type/:order_number/add-product"
          element={<AppRoute panel="product-search" isAddProduct={true} isOrder={true} />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/project/:job_number/:order_type/:order_number"
          element={<AppRoute panel="order" isOrder={true} />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/project/:job_number/presentation/:order_number/design"
          element={<AppRoute panel="presentation-design" isPresentationItem={true} isItem={false} isOrder={true} />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/project/:job_number/presentation/:order_number/:item_id"
          element={<AppRoute modal="presentation-item" isPresentationItem={true} isItem={true} isOrder={true} />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/project/:job_number/:order_type/:order_number/:item_id"
          element={<AppRoute modal="order-item" isItem={true} isOrder={true} />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/project/:job_number/shipping"
          element={<AppRoute panel="shipping" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/project/:job_number/production"
          element={<AppRoute panel="production" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/project/:job_number/bills"
          element={<AppRoute panel="bills" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/project/:job_number/bills/add-bill"
          element={<AppRoute modal="bill" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path="/project/:job_number/bills/:bill_id"
          element={<AppRoute modal="bill" isBill={true} />}
          ErrorBoundary={ErrorBoundary}
        />
        {useHasCapabilities('HAS-COMMISSIONS') &&
          <Route
            path="/project/:job_number/commissions"
            element={<AppRoute panel="commissions" />}
            ErrorBoundary={ErrorBoundary}
          />
        }
        <Route
          path="/project/:job_number/feedback"
          element={<AppRoute panel="feedback" />}
          ErrorBoundary={ErrorBoundary}
        />
        <Route
          path={"*"}
          element={<AppRoute noMatch />}
          ErrorBoundary={ErrorBoundary}
        />
      </Routes>
    </BrowserRouter>
  );
};

const mapStateToProps = (state) => {
  const account = state.entities.accounts[Object.keys(state.entities.accounts)[0]] || { company_name: ''};
  return {
    account_name: account.company_name,
    job: state.entities.projects[Object.keys(state.entities.projects)[0]],
    orders: state.entities.orders
  };
};

export default connect(mapStateToProps)(ProjectRouter);
