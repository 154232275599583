import {
  ADD_TEMP,
  DELETE_TEMP,
  CLEAR_TEMP,
  ADD_MULTI_TEMP,
  DELETE_MULTI_TEMP,
} from "../actions/temp";
import { ADD_PRODUCT_SUCCESS } from "../actions/project";
import {
  CLEAN_CART,
  EMPTY_CART,
  EDIT_CART_ITEM,
  DELETE_CART_ITEM,
  UPDATE_CART_BREAKDOWN,
  EDIT_CHECKOUT,
  SET_CART,
} from "../actions/shop";
import {
  cleanCartFromLocalStorage,
  saveCartStateToLocalStorage,
} from "../utils/cartLocalStorage";
import productQuestionsReducer from "../redux/productQuestions";

export const UPDATE_MARKETING_SOFTWARE_DATA = "UPDATE_MARKETING_SOFTWARE_DATA";
export const UPDATE_MARKETING_SOFTWARE_KEY_VALUE =
  "UPDATE_MARKETING_SOFTWARE_KEY_VALUE";
export const updateMarketingSoftwareKeyValue = (key, value) => ({
  type: UPDATE_MARKETING_SOFTWARE_KEY_VALUE,
  payload: {
    key,
    value,
  },
});
export const updateMarketingSoftwareData = (data) => ({
  type: UPDATE_MARKETING_SOFTWARE_DATA,
  payload: {
    data,
  },
});
const marketingSoftwareReducer = (state = {}, action) => {
  switch (action.type) {
    case UPDATE_MARKETING_SOFTWARE_KEY_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value,
      };
    case UPDATE_MARKETING_SOFTWARE_DATA:
      return {
        ...state,
        ...action.payload.data,
      };
  }
  return state;
};

const updateCartItem = (item, breakdown, is_shop_free, allow_multiple_free_item = false, cartFreeItemEdited = false) => {
  if (!item) {
    return [breakdown];
  }
  const has_breakdown = !!item.filter(
    (b) =>
      b.item_id === breakdown.item_id &&
      b.size_id === breakdown.size_id &&
      b.color_id === breakdown.color_id &&
      b.product_sku_id == breakdown.product_sku_id
  ).length;
  if (has_breakdown) {
    if (!breakdown.quantity) {
      return item.filter(
        (b) =>
          !(
            b.item_id == breakdown.item_id &&
            b.size_id === breakdown.size_id &&
            b.color_id === breakdown.color_id &&
            b.product_sku_id === breakdown.product_sku_id
          )
      );
    } else {
      if (is_shop_free == 1 && !allow_multiple_free_item) {
        item[0] = breakdown;
        return item;
      }
      if (is_shop_free == 1 && allow_multiple_free_item) {
        return item.map((b) =>
          b.item_id === breakdown.item_id &&
          b.size_id === breakdown.size_id &&
          b.color_id === breakdown.color_id &&
          b.product_sku_id === breakdown.product_sku_id
            ? { ...b, quantity: cartFreeItemEdited ? breakdown.quantity : b.quantity + 1 }
            : b
        );
      }
      return item.map((b) =>
        b.item_id === breakdown.item_id &&
        b.size_id === breakdown.size_id &&
        b.color_id === breakdown.color_id &&
        b.product_sku_id === breakdown.product_sku_id
          ? breakdown
          : b
      );
    }
  } else {
    if (!breakdown.quantity) {
      return item;
    } else {
      if (is_shop_free == 1 && !allow_multiple_free_item) {
        item[0] = breakdown;
        return item;
      }
      return item.concat(breakdown);
    }
  }
};

const cartReducer = (state = {}, action, saveToLocalStorage = true) => {
  let updatedCartState;

  switch (action.type) {
    case EDIT_CART_ITEM: {
      const { item_id, shop_id } = action.payload;
        updatedCartState = {
          ...state,
          [item_id]: state[item_id] || [],
        };
      saveToLocalStorage && saveCartStateToLocalStorage(shop_id, updatedCartState);
      return updatedCartState;
    }
    case DELETE_CART_ITEM: {
      const { item_id, shop_id } = action.payload;
      updatedCartState = Object.keys(state)
        .filter((itemId) => itemId !== item_id)
        .reduce((o, itemId) => {
          o[itemId] = state[itemId];
          return o;
        }, {});

      if (Object.keys(updatedCartState).length === 0) {
        saveToLocalStorage && cleanCartFromLocalStorage(shop_id);
      } else {
        saveToLocalStorage && saveCartStateToLocalStorage(shop_id, updatedCartState);
      }
      return updatedCartState;
    }
    case UPDATE_CART_BREAKDOWN: {
      const { breakdown, is_shop_free, shop_id, allow_multiple_free_item, cartFreeItemEdited } = action.payload;
      const { item_id } = action.payload.breakdown;
      updatedCartState = {
        ...state,
        [item_id]: updateCartItem(state[item_id], breakdown, is_shop_free, allow_multiple_free_item, cartFreeItemEdited),
      };
      saveToLocalStorage && saveCartStateToLocalStorage(shop_id, updatedCartState);
      return updatedCartState;
    }
    case CLEAN_CART: {
      const { shop_id } = action.payload;
      updatedCartState = Object.keys(state)
        .filter((item_id) => !!state[item_id].length)
        .reduce((o, item_id) => {
          o[item_id] = state[item_id];
          return o;
        }, {});
      if (Object.keys(updatedCartState).length === 0) {
        saveToLocalStorage && cleanCartFromLocalStorage(shop_id);
      } else {
        saveToLocalStorage && saveCartStateToLocalStorage(shop_id, updatedCartState);
      }
      return updatedCartState;
    }
    case EMPTY_CART: {
      const { shop_id } = action.payload;
      updatedCartState = {};
      saveToLocalStorage && cleanCartFromLocalStorage(shop_id);
      return updatedCartState;
    }
    case SET_CART:
      updatedCartState = action.payload;
      return updatedCartState;
  }
  return state;
};

const checkoutReducer = (state = {}, action) => {
  switch (action.type) {
    case EDIT_CHECKOUT:
      return {
        ...state,
        ...(action.payload.data || {}),
      };
  }
  return state;
};

const reducer = (state = {}, action) => {
  const cartState = {
    ...state,
    cart: cartReducer(state.cart, action, state.saveToLocalStorage ?? true),
    checkout: checkoutReducer(state.checkout || {}, action),
    productQuestions: productQuestionsReducer(state.productQuestions || {}, action),
  };
  const marketingSoftDataState = {
    ...state,
    marketing_software_data: marketingSoftwareReducer(
      state.marketing_software_data,
      action
    ),
  };
  switch (action.type) {
    case EDIT_CART_ITEM:
      const result = { ...cartState };
      if (action.payload.updateEditingCartItemId) {
        result.editingCartItemId = action.payload.item_id;
      }
      return result;
    case ADD_TEMP:
      return Object.assign({}, cartState, {
        [action.payload.key]: action.payload.value,
      });
    case DELETE_TEMP:
      const test = action.payload.key.test
        ? (k) => !action.payload.key.test(k)
        : (k) => k !== action.payload.key;
      return Object.keys(cartState)
        .filter((k) => test(k))
        .reduce((o, k) => {
          o[k] = cartState[k];
          return o;
        }, {});
    case ADD_PRODUCT_SUCCESS:
      return Object.assign({}, cartState, {
        new_product: action.payload.product,
      });
    case CLEAR_TEMP:
      return {};
    case ADD_MULTI_TEMP:
      return { ...cartState, ...action.payload.keyvalues };
    case DELETE_MULTI_TEMP:
      return Object.keys(cartState)
        .filter((k) => !action.payload.keys.includes(k))
        .reduce((o, k) => {
          o[k] = cartState[k];
          return o;
        }, {});
    case UPDATE_MARKETING_SOFTWARE_KEY_VALUE:
      return {
        ...marketingSoftDataState,
        [action.payload.key]: action.payload.value,
      };
    case UPDATE_MARKETING_SOFTWARE_DATA:
      return { ...marketingSoftDataState, ...action.payload.data };
  }
  return cartState;
};

export default reducer;
