import React, { useState } from "react";
import { colors, DebouncedInput, IconButton, Input, Typography } from "@commonsku/styles";
import { useDispatch, useSelector } from "react-redux";
import { createOrUpdateItemProperty, selectItemSizeGuideUrlProperty } from "../../redux/itemProperties";
import { StoreState } from "../../reducers/core";
import { isValidHttpUrl } from "../../utils";

type SizeGuideUrlProps = {
  item_id: string;
};
const SizeGuideUrl = ({ item_id }: SizeGuideUrlProps) => {
  const dispatch = useDispatch();
  const itemSizeGuideUrlProperty = useSelector(
    (state: StoreState) => selectItemSizeGuideUrlProperty(state, item_id)
  );

  const sizeGuideUrl = itemSizeGuideUrlProperty?.property_value?.value || '';
  const [isEditing, setIsEditing] = useState<boolean>(Boolean(itemSizeGuideUrlProperty?.property_value?.value));
  const [error, setError] = useState('');

  const handleSaveUrl = (value: string) => {
    if (value && !isValidHttpUrl(value)) {
      setError('Invalid url');
      return;
    }
    setError('');
    dispatch(createOrUpdateItemProperty({
      item_property_id: itemSizeGuideUrlProperty?.item_property_id,
      item_id: item_id,
      property_name: 'size_guide_url',
      property_value: { value },
    }));
    setIsEditing(false);
  };

  return !isEditing && !sizeGuideUrl ? (
    <IconButton onClick={() => setIsEditing(true)} variant="text">
      Add a Size Guide URL
    </IconButton>
  ) : (
    <div className="small-12 columns">
      {error ? <Typography color={colors.error}>{error}</Typography> : null}
      <DebouncedInput
        error={Boolean(error)}
        labeled
        label="Size Guide URL"
        className="field1"
        onChange={handleSaveUrl}
        onFocus={() => {
          setIsEditing(true);
        }}
        value={sizeGuideUrl}
        timeout={800}
      />
    </div>
  );
};

export default SizeGuideUrl;
