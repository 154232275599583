import React from "react";
import { Csku, SwitchStyled, Text } from "@commonsku/styles";

export default function HelpScreen({ value, onChange, isConfigurationPage }) {
  return (
    <Csku
      as="div"
      forceStyles
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: isConfigurationPage ? "flex-start" : "space-between",
        marginBottom: isConfigurationPage && 8,
      }}
    >
      <Text
        as="p"
        style={{
          color: "var(--color-neutrals-90)",
          fontFamily: "var(--font-family-regular)",
          fontSize: 16,
          marginBottom: 0,
          width: isConfigurationPage ? 232 : 144,
          fontWeight: isConfigurationPage ? 700 : 400,
        }}
      >
        Help screen popup on first load
      </Text>
      <SwitchStyled
        selected={+value === 1}
        onClick={() => onChange(+value !== 1 ? 1 : 0)}
        data-testid="show_help_screen_toggle"
      />
    </Csku>
  );
}
