import {
  colors,
  EmptyStateArrow,
  SkubotSpinner,
  Tabs,
  Text,
} from "@commonsku/styles";
import React, { useEffect, useMemo, useState } from "react";
import TemplateTile from "../TemplateTile/TemplateTile";
import { TemplatesData } from "../../../containers/ShopTemplatesApp";
import styled from "styled-components";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { useIdentity } from "../../../hooks";
import {
  createFetchPublicViewTemplates,
  createShop,
} from "../../../actions/shop";
import { oauth } from "../../../utils";
import {
  selectIsLoading,
  setIsLoadingFalse,
  setIsLoadingTrue,
} from "../../../redux/isLoading";

const StyledTemplateDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
`;

const StyledTemplateText = styled.p`
  color: ${colors.neutrals.bodyText};
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
`;

const StyledSpinnerDiv = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  pointer-events: auto;
`;

interface ShopInitializationTemplatePopupProps {
  shop_name: string;
  client_id: string;
  redirect: boolean;
  shopType: string;
}

const TemplateSelection = ({
  shop_name,
  client_id,
  redirect,
  shopType,
}: ShopInitializationTemplatePopupProps) => {
  const dispatch: ThunkDispatch<any, any, AnyAction> = useDispatch();
  const isLoading = useSelector(selectIsLoading);
  const aggregate = shopType === "POPUP" ? 1 : 0;
  const { company_name } = useIdentity();
  const [tabPosition, setTabPosition] = useState<number>();
  const [templateOrderTypes, setTemplateOrderTypes] = useState([]);

  useEffect(() => {
    oauth("GET", "public-view-template", {
      include_layouts: true,
      include_commonsku_templates: true,
      status: "ALL",
    }).then(({ json }) => {
      const layouts = json.layouts ?? [];
      const templates = json.templates ?? [];
      const commonsku_templates = json.commonsku_templates ?? [];
      const publicViewTemplates = [
        ...layouts,
        ...templates,
        ...commonsku_templates,
      ];
      dispatch(createFetchPublicViewTemplates(publicViewTemplates));
      setTemplateOrderTypes(json?.order_types ?? []);
    });
  }, []);
  const publicViewTemplates = useSelector(
    (state: any) => state.entities.public_view_templates
  );
  const templates = useMemo(
    () =>
      Array.isArray(publicViewTemplates)
        ? publicViewTemplates
        : Object.values(publicViewTemplates),
    [publicViewTemplates]
  );

  const companyTemplates = templates.filter(
    (template) => template.template_type === "TEMPLATE" && template.company_id
  );

  const shopDefaultTemplate = useMemo(() => {
    const shopDefaultTemplateIds = templateOrderTypes
      .filter((v) => v.order_type === "SHOP" && +v.is_default === 1)
      .map((v) => v.public_view_template_id);
    return templates.find(
      (t) =>
        t.template_type !== "LAYOUT" &&
        shopDefaultTemplateIds.includes(t.public_view_template_id)
    );
  }, [templates, templateOrderTypes]);
  const shopDefaultTemplateId = shopDefaultTemplate?.public_view_template_id;

  const onCreateShop = async (
    public_view_template_id: string,
    isCustomize = false
  ): Promise<void> => {
    dispatch(setIsLoadingTrue());
    try {
      await dispatch(
        createShop(
          shop_name,
          client_id,
          aggregate,
          redirect,
          public_view_template_id,
          isCustomize
        )
      );
    } catch (error) {
      dispatch(setIsLoadingFalse());
      throw error;
    }
  };

  useEffect(() => {
    const tabElems = document
      .querySelector(".shops-popup .template-tabs-wrapper > div > ul")
      .querySelectorAll("li");

    if ((tabElems?.length || 0) > 0) {
      const companyTabPos = tabElems[0].getBoundingClientRect();
      const commonskuTabPos = tabElems[1].getBoundingClientRect();

      const tabCenter =
        companyTabPos.width + commonskuTabPos.width / 2 + 24 + 15;
      setTabPosition(tabCenter - 95);
    }
  }, []);

  const templateTabs = (
    <Tabs
      padded={false}
      variant={"secondary"}
      tabs={[
        {
          label: `${company_name} Templates`,
          content:
            companyTemplates.length > 0 ? (
              <TemplateTile
                templates={templates.filter(
                  (template: TemplatesData) =>
                    template.template_type !== "LAYOUT" && template.company_id
                )}
                shopDefaultTemplateId={shopDefaultTemplateId}
                isPopup
                tab="company"
                onCreateShop={onCreateShop}
              />
            ) : (
              <StyledTemplateDiv>
                <EmptyStateArrow
                  direction="up"
                  size="default"
                  style={{
                    height: "300px",
                    position: "absolute",
                    width: "190px",
                    display: "block",
                    left: tabPosition,
                    top: 110,
                  }}
                />
                <div style={{ marginTop: 250, textAlign: "center" }}>
                  <StyledTemplateText>
                    Use one a commonsku template to get started!
                  </StyledTemplateText>
                  <StyledTemplateText>
                    Your saved templates will show here once you build a Shop in
                    the Design Studio and ‘save as a new template’.
                  </StyledTemplateText>
                  {/* <a>Learn More About Templating</a> */}
                </div>
              </StyledTemplateDiv>
            ),
        },
        {
          label: "commonsku Templates",
          content: (
            <TemplateTile
              templates={templates.filter(
                (template: TemplatesData) =>
                  template.status === "PUBLISHED" &&
                  template.template_type !== "LAYOUT" &&
                  !template.company_id
              )}
              shopDefaultTemplateId={shopDefaultTemplateId}
              isPopup
              tab="commonsku"
              onCreateShop={onCreateShop}
            />
          ),
        },
        {
          label: "Layouts",
          content: (
            <TemplateTile
              templates={templates.filter(
                (template: TemplatesData) => template.template_type === "LAYOUT"
              )}
              isPopup
              tab="layouts"
              onCreateShop={onCreateShop}
            />
          ),
        },
      ]}
    />
  );
  return (
    <div style={{ height: "100vh", overflow: "auto" }}>
      <Text
        as="p"
        style={{
          color: "var(--color-neutrals-90)",
          fontFamily: "var(--font-family-bold)",
          fontSize: 24,
          paddingLeft: 8,
        }}
      >
        Select a template or start fresh with a layout
      </Text>
      <div>
        <div
          className="template-tabs-wrapper"
          style={{
            overflow: "auto",
            height: "100%",
            width: "100%",
            textAlign: "left",
          }}
        >
          {templateTabs}
          {isLoading && (
            <StyledSpinnerDiv>
              <SkubotSpinner skubot style={{ margin: "auto" }} size="huge" />
            </StyledSpinnerDiv>
          )}
        </div>
      </div>
    </div>
  );
};

export default TemplateSelection;
