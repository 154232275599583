import { get } from "lodash";
import * as htmlToImage from 'html-to-image';
import { TEMPLATE_OPTIONS } from "../helpers";

export const POPUP_TYPES = {
  SELECT_THEME: 'SELECT_THEME',
  SELECT_CAROUSEL_IMAGE: 'SELECT_CAROUSEL_IMAGE',
  SELECT_BACKGROUND_IMAGE: 'SELECT_BACKGROUND_IMAGE',
  CATEGORY_BANNER: 'CATEGORY_BANNER',
  SELECT_LOGO_IMAGE: 'SELECT_LOGO_IMAGE',
  SELECT_CATEGORIES_IMAGE: 'SELECT_CATEGORIES_IMAGE',
  SELECT_HELP_SCREEN_IMAGE: 'SELECT_HELP_SCREEN_IMAGE',
  SELECT_FEATURED_IMAGE: 'SELECT_FEATURED_IMAGE',
  SAVE_TEMPLATE: 'SAVE_TEMPLATE',
  TEMPLATE_WARN: 'TEMPLATE_WARN',
};

export const CUSTOMIZATION_PANELS = {
  GLOBAL: 'GLOBAL',
  PAGE: 'PAGE',
  HELP_SCREEN: 'HELP_SCREEN',
};

export const CUSTOMIZATION_SIDEBAR_WIDTH = 264;

export function parseThemesByPublicViewTemplate(entity, template) {
  const template_data = get(entity, ['template_data', template.public_view_template_id]) || {};
  const templateOptions = TEMPLATE_OPTIONS[template.public_view_template_name] || {};
  return {
    template_data: template_data,
    templateData: {
      ...templateOptions,
      ...(Object.values(template_data).reduce(
        (acc, v) => ({ ...acc, [v.name]: v.value }),
        {}
      )),
    },
    title: template.public_view_template_name || '',
    image: template.public_view_template_background || '',
    properties: Object.values(template.public_view_template_properties || {})
      .reduce((acc, v) => ({
        ...acc,
        [v.public_view_template_property_name]: {
          ...v,
          value: v.public_view_template_property_value,
          type: v.public_view_template_property_type,
          context: v.public_view_template_property_context,
        }
      }), {}) || {},
    description: template.public_view_template_description || '',
    public_view_template_id: template.public_view_template_id || '',
    public_view_template_name: template.public_view_template_name || '',
    public_view_template_properties: template.public_view_template_properties || {},
    public_view_template_description: template.public_view_template_description || '',
    public_view_template_order_types: template.public_view_template_order_types || [],
    status: template.status,
    template_type: template.template_type,
    parent_id: template.parent_id,
    company_id: template.company_id,
    date_created: template.date_created
  };
}

export function getDefaultPublicViewTemplateTheme(entity, publicViewTemplates) {
  const result = parseThemesByPublicViewTemplate(
    entity,
    publicViewTemplates.find(v => v.public_view_template_id === entity.public_view_layout_id) || {}
  );
  if (!result.templateData.template_color) {
    result.templateData.template_color = entity.template_color;
  }
  if (!result.templateData.product_list_style) {
    result.templateData.product_list_style = entity.product_list_style;
  }
  result.template_color = result.templateData.template_color;
  result.product_list_style = result.templateData.product_list_style;
  return result;
}

export function getThemesByPublicViewTemplates(entity, publicViewTemplates) {
  return publicViewTemplates.map(v => {
    const result = parseThemesByPublicViewTemplate(entity, v);
    if (!result.templateData.template_color) {
      result.templateData.template_color = entity.template_color;
    }
    if (!result.templateData.product_list_style) {
      result.templateData.product_list_style = entity.product_list_style;
    }
    result.template_color = entity.template_color;
    result.product_list_style = entity.product_list_style;
    return result;
  });
}

/**
 * Get Template Screenshot
 *
 * Usage:
 * ```
 *  const screenshotDataUrl = await getTemplateScreenshot('storefront-template-iframe');
 *  await fetch(screenshotDataUrl)
 *    .then(res => res.blob())
 *    .then(blob => {
 *      dispatch(createUploadFile(entityId, 'SHOP_TEMPLATE', blob));
 *    });
 * ```
 * @param {?string} nodeId
 *
 * @returns {Promise<string>}
 */
export async function getTemplateScreenshot(nodeId = 'storefront-template-iframe') {
  const node = document.getElementById(nodeId);
  try {
    const dataUrl = await htmlToImage
      .toJpeg(node, { cacheBust: true, });
    return dataUrl;
  } catch (error) {
    console.error('oops, something went wrong!', error);
  }
}
