import React, { useCallback, useReducer } from "react";

import tempReducer from "../reducers/temp";
import {
  createCancelEditCartItem,
  createDeleteCartItem,
  createEditCartItem,
  createEditItem,
  createSaveCartItem,
  createUpdateBreakdown,
  setCart,
} from "../actions/shop";
import { Item } from "../interfaces/Item";

type CustomCartItem = {
  item_id: string;
  color_id: string;
  size_id: string;
  product_sku_id: string | null;
  quantity: number;
};
type CartItem = Item & { quantity: number };
type GalleryCartItem = CustomCartItem & { to_be_added: boolean };
export type BaseCart = {
  items: CartItem[];
} & { [K: string]: CustomCartItem | CustomCartItem[] };
type GalleryCart = {
  items: CartItem[];
} & { [K: string]: GalleryCartItem | GalleryCartItem[] };

export const parseCart = (cart: BaseCart): GalleryCart => {
  const newCart = Object.keys(cart ?? {})
    .filter((k) => k !== "items")
    .map((k) => {
      const val = cart[k];
      if (Array.isArray(val)) {
        return val.map((v) => ({
          ...v,
          to_be_added: false,
          quantity: 0,
        }));
      }
      return { ...val, to_be_added: false, quantity: 0 };
    })
    .filter((v) => (Array.isArray(v) ? v.length > 0 : Boolean(v)))
    .reduce((acc, v) => {
      let item_id: string;
      if (Array.isArray(v)) {
        item_id = v[0].item_id;
      } else {
        item_id = v.item_id;
      }
      return { ...acc, [item_id]: v };
    }, {}) as GalleryCart;
  newCart.items = [];
  return newCart;
};

type TReducer = React.Reducer<
  { cart: GalleryCart; checkout: object; saveToLocalStorage?: boolean; },
  { type: string; payload: any }
>;

type useCartProps = { entityId: string; isEntityFree: string | number, allowMultipleFreeItem: boolean };
const useCart = ({ entityId, isEntityFree, allowMultipleFreeItem=false }: useCartProps) => {
  const [state, dispatch] = useReducer<TReducer>(tempReducer, {
    saveToLocalStorage: false,
    cart: { items: [] },
    checkout: {},
  });

  const onAddCartItem = useCallback(
    (item_id: string) => {
      return createEditCartItem(entityId, item_id, isEntityFree)(dispatch);
    },
    [entityId, isEntityFree]
  );

  const onEditCartItem = useCallback(
    (item_id: string) => {
      return dispatch(createEditItem(entityId, item_id, isEntityFree, false));
    },
    [isEntityFree, entityId]
  );

  const onSaveCartItem = useCallback(
    (item_id: string, updates: GalleryCartItem[]) => {
      return createSaveCartItem(item_id, updates)(dispatch, () => ({
        temp: state,
      }));
    },
    [state]
  );

  const onUpdateBreakdown = useCallback(
    (
      item_id: string,
      color_id: string | null,
      size_id: string | null,
      sku_id: string | null,
      quantity: string | number,
      allowMultipleFreeItemBreakdowns: boolean | null = null
    ) => {
      return dispatch(
        createUpdateBreakdown(
          item_id,
          color_id,
          size_id,
          sku_id,
          quantity,
          isEntityFree,
          entityId,
          allowMultipleFreeItemBreakdowns !== null ? allowMultipleFreeItemBreakdowns : allowMultipleFreeItem
        )
      );
    },
    [isEntityFree, entityId]
  );

  const onCancelEditCartItem = useCallback(() => {
    return createCancelEditCartItem()(dispatch);
  }, []);

  const onDeleteCartItem = useCallback(
    (item_id: string) => {
      return createDeleteCartItem(item_id, entityId)(dispatch);
    },
    [entityId]
  );

  const onSetCart = useCallback((cart: BaseCart) => {
    return dispatch(setCart(parseCart(cart)));
  }, []);

  return {
    state,
    dispatch,
    onSetCart,
    onAddCartItem,
    onEditCartItem,
    onSaveCartItem,
    onUpdateBreakdown,
    onCancelEditCartItem,
    onDeleteCartItem,
  };
};

export default useCart;
