import { colors, EmptyStateArrow, Tabs } from "@commonsku/styles";
import React, { useEffect, useMemo, useState } from "react";
import ThemeListView from "./ThemeListView";
import styled from "styled-components";
import {
  IPublicViewTemplateData,
  ITemplateData,
} from "../../../../interfaces/PublicViewTemplateData";

interface SelectedThemeAndTemplateData extends IPublicViewTemplateData {
  product_page_style?: string;
  product_list_style?: string;
  template_color: string;
  company_id: string | null;
  description: string;
}
interface TemplateTabsProps {
  company_name: string;
  templateData: ITemplateData;
  selectedTheme: SelectedThemeAndTemplateData;
  selectedTemplate: SelectedThemeAndTemplateData;
  themesFiltered: SelectedThemeAndTemplateData[];
  onClose: () => void;
  onClickSelect: () => void;
  onClickFavourite: () => void;
  onClickPreview: () => void;
  isShop: boolean;
  tabPosition: { center: number };
}

const StyledTemplateDiv = styled.div`
  display: flex;
  flex-direction: column;
  width: auto;
  align-items: center;
`;

const StyledTemplateText = styled.p`
  color: ${colors.neutrals.bodyText};
  font-size: 24px;
  line-height: 28px;
  font-weight: 400;
`;

const TemplateTabs = ({
  company_name,
  templateData,
  selectedTheme,
  selectedTemplate,
  themesFiltered,
  onClose,
  onClickSelect,
  onClickFavourite,
  onClickPreview,
  isShop,
  tabPosition,
}: TemplateTabsProps) => {
  const [position, setPosition] = useState({ left: 0 });

  const divCenter = tabPosition?.center - 95;

  useEffect(() => {
    setPosition({ left: divCenter });
  }, [tabPosition]);

  const { layouts, companyTemplates, defaultTemplates } = useMemo(() => {
    const layouts = [];
    const companyTemplates = [];
    const defaultTemplates = [];

    themesFiltered.forEach((theme) => {
      if (theme.template_type === "LAYOUT") {
        layouts.push(theme);
      } else if (theme.template_type === "TEMPLATE") {
        if (theme.company_id) {
          companyTemplates.push(theme);
        } else {
          defaultTemplates.push(theme);
        }
      }
    });

    return { layouts, companyTemplates, defaultTemplates };
  }, [themesFiltered]);

  return (
    <Tabs
      padded={true}
      variant={"secondary"}
      tabs={[
        {
          label: `${company_name} Templates`,
          content:
            companyTemplates.length > 0 ? (
              <ThemeListView
                templateData={templateData}
                selectedTheme={selectedTemplate}
                themes={companyTemplates}
                onClose={onClose}
                onClickSelect={onClickSelect}
                onClickFavourite={onClickFavourite}
                onClickPreview={onClickPreview}
                tab="company"
                isShop={isShop}
              />
            ) : (
              <StyledTemplateDiv>
                <EmptyStateArrow
                  direction="up"
                  size="default"
                  style={{
                    height: "300px",
                    position: "absolute",
                    width: "190px",
                    display: "block",
                    left: position.left,
                    top: 110,
                  }}
                />
                <div style={{ marginTop: 250, textAlign: "center" }}>
                  <StyledTemplateText>
                    Use one a commonsku template to get started!
                  </StyledTemplateText>
                  <StyledTemplateText>
                    Your saved templates will show here once you build a Shop in
                    the Design Studio and ‘save as a new template’.
                  </StyledTemplateText>
                  {/* <a>Learn More About Templating</a> */}
                </div>
              </StyledTemplateDiv>
            ),
        },
        {
          label: "commonsku Templates",
          content: (
            <ThemeListView
              templateData={templateData}
              selectedTheme={selectedTemplate}
              themes={defaultTemplates}
              onClose={onClose}
              onClickSelect={onClickSelect}
              onClickFavourite={onClickFavourite}
              onClickPreview={onClickPreview}
              tab="commonsku"
              isShop={isShop}
            />
          ),
        },
        {
          label: "Layouts",
          content: (
            <ThemeListView
              templateData={templateData}
              selectedTheme={selectedTemplate ? null : selectedTheme}
              themes={layouts}
              onClose={onClose}
              onClickSelect={onClickSelect}
              onClickFavourite={onClickFavourite}
              onClickPreview={onClickPreview}
              tab="layouts"
              isShop={isShop}
            />
          ),
        },
      ]}
    />
  );
};

export default TemplateTabs;
