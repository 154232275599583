import React from 'react';
import { Select, SharedStyleTypes, ToggleSwitchStyled } from '@commonsku/styles';
import { useArtworkLibraryContext } from './context';

export interface CopyToClientProps {
}

export const CopyToClient = ({ ...props }: CopyToClientProps & SharedStyleTypes) => {
  const { copyToClient, setCopyToClient, activeCopyFolder, setActiveCopyFolder, parents, folders } = useArtworkLibraryContext();
  if (parents.filter((p) => 'CLIENT' === p.parent_type).length === 0) {
    return null;
  }

  const folderOptions = folders.filter(f => f.parent_type === 'CLIENT').map(f => ({ value: f.folder_id, label: f.folder_name }));
  folderOptions.unshift({ value: null, label: 'All Files' });
  const folder = copyToClient ?
    <Select options={folderOptions} value={activeCopyFolder || { value: null, label: 'All Files' }} onChange={(f) => {
      setActiveCopyFolder(f);
    }} />
    : null;

  return <div className='dropzone-container' style={{ marginTop: '1em' }} onClick={(e) => { e.stopPropagation(); e.preventDefault(); }}>
    <div className='dropzone-place-holder-text'>Also save uploads to client folder:</div>
    <ToggleSwitchStyled
      size="small"
      selected={+copyToClient === 1}
      onClick={() => setCopyToClient(+copyToClient !== 1 ? 1 : 0)}
      style={{ maxHeight: '25px', width: '50px', alignSelf: 'center' }}
    />
    {folder}
  </div>;
};
