import React, { Component } from 'react';
import { useSelector } from 'react-redux';

import DropdownMenu, { MenuTrigger } from './DropdownMenu';

import { toTitleCase } from '../utils';

import { get, isEmpty } from 'lodash';

export const ItemSummaryTaxTd = ({ item, renderTaxAmounts }) => {
  const avalara_categories = useSelector((state) => {
    return get(state, 'entities.avalara_categories');
  });
  const avalara_category = get(avalara_categories, item.avalara_category_id);

  return <td className="tax">
    {!isEmpty(avalara_category)
      ? <div>{avalara_category.category}</div>
      : (
        !renderTaxAmounts
          ? item.tax_amounts.map((t, i) => {
            return <div key={i}>{t.label}</div>;
          })
          : renderTaxAmounts(item.tax_amounts)
      )
    }
  </td>;
};

class BaseItemSummary extends Component {

  constructor(props) {
    super(props);

    this.state = {};

    this.handleClickAdd = this.handleClickAdd.bind(this);
    this.handleClickDelete = this.handleClickDelete.bind(this);
    this.handleClickCopy = this.handleClickCopy.bind(this);
    this.handleClickCopyToOrder = this.handleClickCopyToOrder.bind(this);
    this.handleClickToggle = this.handleClickToggle.bind(this);
    this.handleMouseOver = this.handleMouseOver.bind(this);
    this.handleMouseOut = this.handleMouseOut.bind(this);
    this.getTooltip = this.getTooltip.bind(this);
  }

  handleMouseOver(tooltip) {
    return e => {
      this.setState({ [tooltip]: !this.props.isDragging, tooltip_position: { top: e.clientY, left: e.clientX } });
    };
  }

  handleMouseOut(tooltip) {
    return e => {
      this.setState({ [tooltip]: false });
    };
  }

  getTooltip(tooltip) {
    return null;
  }

  handleClickAdd(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.locked) {
      return;
    }
    this.props.onAddItem(this.props.index);
  }

  handleClickDelete(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.locked) {
      return;
    }
    this.props.onDeleteItem(this.props.item.item_id);
  }

  handleClickCopy(e) {
    e.preventDefault();
    e.stopPropagation();
    if (this.props.locked) {
      return;
    }
    this.props.onCopyItem(this.props.item.item_id, this.props.index + 1);
  }

  handleClickCopyToOrder = e => {
    e.preventDefault();
    e.stopPropagation();
    const { item, add_to_order, job_number } = this.props;
    if(item.parent_type === 'OPTION') {
      this.props.onCopyOptionItem(item, add_to_order);
    }else{
      this.props.onCopyItem(item.item_id, null, add_to_order, null, job_number);
    }
  };

  handleClickToggle(field) {
    return e => {
      e.preventDefault();
      e.stopPropagation();
      if (this.props.locked) {
        return;
      }
      this.props.onUpdateItem(this.props.item.item_id, field, this.props.item[field])(1 == this.props.item[field] ? 0 : 1);
    };
  }

  renderToggleAction(field, on_label, off_label) {
    return !this.props.locked ? <a className="tiny button" onClick={this.handleClickToggle(field)}>{1 == this.props.item[field] ? off_label : on_label}</a> : null;
  }

  renderAddAction() {
    const {
      locked,
      dropdownOptions,
    } = this.props;
    return <DropdownMenu options={dropdownOptions}>
      {!locked ?
        <MenuTrigger>
          <a className="button alt tiny">
            <i className="fi-plus" />&nbsp;&nbsp;Add
          </a>
        </MenuTrigger> : null}
    </DropdownMenu>;
  }

  renderEditAction() {
    const { locked, item: { hasInventoryBreakdowns = false, hasPurchaseOrder = false} } = this.props;
    return !(hasInventoryBreakdowns && hasPurchaseOrder) && <a className="tiny button">{locked ? 'view' : 'edit'}</a>;
  }

  renderCopyAction() {
    const { locked, item: { hasInventoryBreakdowns = false} } = this.props;
    return !locked && !hasInventoryBreakdowns ? <a className="tiny button" onClick={this.handleClickCopy}>copy</a> : null;
  }

  renderCopyToOrderAction() {
    const { item, add_to_order } = this.props;
    if (!item.hidden && add_to_order && !item.hasInventoryBreakdowns) {
      return item.order_id !== add_to_order.order_id ? <a className="tiny button" onClick={this.handleClickCopyToOrder}>copy to {toTitleCase(add_to_order.order_type)}</a> : null;
    } else {
      return null;
    }
  }

  renderDeleteAction() {
    const { locked, item: { hasInventoryBreakdowns = false, hasPurchaseOrder = false} } = this.props;
    return !this.props.locked && !(hasInventoryBreakdowns && hasPurchaseOrder) ? <a className="tiny button alert" onClick={this.handleClickDelete}>delete</a> : null;
  }

  renderActions() {
    return (
      <span className="actions">
      </span>
    );
  }

  renderItem() {
    return null;
  }

  render() {
    const {
      className = '',
      item, detailed, display, locked,
      onEditItem, handleContextMenu,
      isDragging, isTitle, itemSelected
    } = this.props;

    const handleClick = onEditItem ? (e => {
      e.preventDefault();
      e.stopPropagation();
      if (!(item.hasInventoryBreakdowns && item.hasPurchaseOrder)) {
        onEditItem(item.item_id, item.parent_id);
      }
    }) : null;
    let style = 1 == item.hidden ? { opacity : 0.5, borderTop : '1px solid #ddd' } : { borderTop : '1px solid #ddd' };

    if (itemSelected) {
      style.backgroundColor = '#edf2f5';
    }

    let result = <tbody ref={this.props.rootRef} data-handler-id={this.props.handlerId} style={style}
      className={className + ' item-summary' + (detailed ? ' detailed' : '')}
      onClick={handleClick} onContextMenu={!locked ? handleContextMenu : null}>
        {this.renderItem()}
    </tbody>;
    if (display === 'grid') {
      style = 1 == item.hidden ? { opacity : 0.5 } : { };
      result = <div ref={this.props.rootRef} data-handler-id={this.props.handlerId} className={`${isDragging ? 'dragging' : ''} draggable-item-container ${isTitle ? 'title-item-container' : ''}`}>
        <table><tbody style={style}
        className={className + ' item-summary' + (detailed ? ' detailed' : '')}
        onClick={handleClick} onContextMenu={!locked ? handleContextMenu : null}>
          {this.renderItem()}
      </tbody></table></div>;
    }

    if (locked || display === 'artwork' || display === 'comment') {
      return result;
    } else {
      return result;
    }
  }
}

export default BaseItemSummary;
