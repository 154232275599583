import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Popup, Text, XIcon, } from "@commonsku/styles";
import { CATEGORIES, POPUP_MAX_HEIGHT, POPUP_MAX_WIDTH, isShopThemeFavourite, isThemeFeatured } from './helpers';
import ThemeListView from "./ThemeListView";
import ThemePreview from "./ThemePreview";
import { usePublicViewEntityContext } from "../../../../context/PublicViewEntityProvider";
import TemplateTabs from "./TemplateTabs";
import { useDispatch } from "react-redux";
import { createLoadOrder } from "../../../../actions/order";
import { PublicViewTemplate } from "../../../../models/PublicViewTemplate";

export default function SelectThemePopup({
  onClose,
  layouts,
  templateData,
  selectedTheme=null,
  selectedTemplate=null,
  initialFilters = [],
  initialCategory = CATEGORIES.all.key,
  onClickFavouriteTheme,
}) {
  const { entityLayoutId, entityTemplateId, onUpdateEntity, isShop, entity, entityOrderId } = usePublicViewEntityContext();
  const companyName = entity.company_data?.tenant_name || '';

  const ref = useRef(null);
  const [popupHeight, setPopupHeight] = useState(300);
  const [selectedPreviewThemeIdx, setPreviewThemeIdx] = useState(-1);
  const [category, setCategory] = useState(initialCategory);
  const [selectedFilters, setFilters] = useState(initialFilters);
  const [tabPosition, setTabPosition] = useState();

  const dispatch = useDispatch();

  const themesFiltered = useMemo(() => {
    if (category === 'favourites') {
      return layouts.filter(t => isShopThemeFavourite(templateData, t));
    } else if (category === 'featured') {
      return layouts.filter(t => isThemeFeatured(t));
    } else if (category === 'all') {
      return layouts;
    }
    return [];
  }, [templateData, layouts, category]);

  const presentationThemes = themesFiltered.filter(template => template.template_type === "LAYOUT");

  const onToggleFilters = useCallback((value) => {
    setFilters(s => {
      let result = s;
      const idx = s.findIndex(v => v === value);
      if (idx > -1) {
        result = [
          ...s.slice(0, idx),
          ...s.slice(idx + 1),
        ];
      } else {
        result = Array.from(new Set(s));
      }
      return result;
    });
  }, []);

  function onClickFavourite(theme) {
    onClickFavouriteTheme && onClickFavouriteTheme(
      theme,
      templateData.favourite && templateData.favourite.value === theme.public_view_template_id ? 0 : 1
    );
  }

  function onClickPreview(theme) {
    const selectedLayout = layouts.find(t => t.public_view_template_id === theme.public_view_template_id);
    window.open(selectedLayout.image);
  }

  function onClickSelect(theme) {
    if (theme.template_type === 'TEMPLATE') {
      onUpdateEntity('public_view_layout_id', entityLayoutId)(theme.parent_id);
      onUpdateEntity('public_view_template_id', entityTemplateId)(theme.public_view_template_id);
      if ([
        PublicViewTemplate.CATEGORIES_CAROUSEL,
        PublicViewTemplate.LEFT_NAV_HERO_IMAGE,
      ].includes(theme.public_view_template_name)) {
        // reload order info with categories
        dispatch(createLoadOrder(entityOrderId, true));
      }
    } else {
      onUpdateEntity('public_view_layout_id', entityLayoutId)(theme.public_view_template_id);
      onUpdateEntity('public_view_template_id', entityTemplateId)('');
    }
    onClose && onClose();
  }

  useEffect(() => {
    const elem = ref.current;
    function updateHeight() {
      setPopupHeight(elem?.clientHeight || 300);
    }
    const mutationObserver = new MutationObserver(updateHeight);
    const resizeObserver = new ResizeObserver(updateHeight);
    if (elem) {
      mutationObserver.observe(elem, { childList: true, subtree: true });
      resizeObserver.observe(elem);
    }
    updateHeight();
    return () => {
      mutationObserver.disconnect();
      if (elem) {
        resizeObserver.unobserve(elem);
      } else {
        resizeObserver.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (!isShop) return;
    const tabElems = document.querySelector(
      '.popup.select-theme-popup .popup-content.select-theme-popup-content .template-tabs-wrapper > div > ul'
    ).querySelectorAll('li');
      if ((tabElems?.length || 0) > 0) {
        const companyTabPos = tabElems[0].getBoundingClientRect();
        const commonskuTabPos = tabElems[1].getBoundingClientRect();

        const tabCenter = companyTabPos.width + (commonskuTabPos.width / 2) + 24 + 15;
        setTabPosition({ center: tabCenter });
      }
  }, [isShop]);

  return (
    <Popup
      ref={ref}
      closeOnEsc
      noHeader
      popupClassName="select-theme-popup"
      contentClassName="select-theme-popup-content"
      style={{
        maxHeight: POPUP_MAX_HEIGHT,
        maxWidth: 1700,
        overflow: 'hidden',
        padding: isShop ? 24 : 0,
        background: isShop ? 'var(--color-neutrals-20)' : ''
      }}
    >
      {isShop && <Text as="p" style={{ color: 'var(--color-neutrals-90)', fontFamily: 'var(--font-family-bold)', fontSize: 24, paddingLeft: '1rem' }}>
        Currently Selected: {selectedTemplate?.public_view_template_name || selectedTheme?.public_view_template_name} {selectedTemplate ? 'Template' : 'Layout'}
      </Text>}
      <div>
        <XIcon
          style={{ position: 'absolute', right: isShop ? 5 : 25, cursor: 'pointer', top: isShop ? 5 : 25, zIndex: 9, }}
          onClick={onClose}
        />
        <div className="template-tabs-wrapper" style={{
          overflow: 'auto',
          height: '100%',
          width: '100%',
        }}>
          {selectedPreviewThemeIdx < 0 ? isShop ?
            <TemplateTabs
              company_name={companyName}
              templateData={templateData}
              selectedTheme={selectedTheme}
              selectedTemplate={selectedTemplate}
              themesFiltered={themesFiltered}
              filters={selectedFilters}
              category={category}
              onClose={onClose}
              setCategory={setCategory}
              onToggleFilters={onToggleFilters}
              onClickSelect={onClickSelect}
              onClickFavourite={onClickFavourite}
              onClickPreview={onClickPreview}
              isShop = {isShop}
              tabPosition={tabPosition}
            /> :
            <ThemeListView
              templateData={templateData}
              selectedTheme={selectedTemplate || selectedTheme}
              themes={presentationThemes}
              filters={selectedFilters}
              category={category}
              onClose={onClose}
              setCategory={setCategory}
              onToggleFilters={onToggleFilters}
              onClickSelect={onClickSelect}
              onClickFavourite={onClickFavourite}
              onClickPreview={onClickPreview}
              isShop = {isShop}
          /> : <ThemePreview
            theme={layouts[selectedPreviewThemeIdx]}
            maxHeight={popupHeight}
            isEditable={false}
            hasNext={selectedPreviewThemeIdx+1 <= layouts.length-1}
            hasPrev={selectedPreviewThemeIdx-1 >= 0}
            onGoBack={() => setPreviewThemeIdx(-1)}
            onSelectTheme={() => {
              onClickSelect(layouts[selectedPreviewThemeIdx]);
            }}
            onClickNextTheme={() => {
              if (selectedPreviewThemeIdx+1 > layouts.length-1) {
                setPreviewThemeIdx(0);
                return;
              }
              setPreviewThemeIdx(selectedPreviewThemeIdx+1);
            }}
            onClickPrevTheme={() => {
              if (selectedPreviewThemeIdx-1 < 0) {
                setPreviewThemeIdx(layouts.length-1);
                return;
              }
              setPreviewThemeIdx(selectedPreviewThemeIdx-1);
            }}
          />}
        </div>
      </div>
    </Popup>
  );
}
