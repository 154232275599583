import { get } from "lodash";
import React, {
  forwardRef,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import { Col, Csku, Row, Text } from "@commonsku/styles";
import { useContainerScroll } from "../../../hooks";
import GlobalStylesPanel from "./GlobalStylesPanel";
import { CustomizationPanel } from "../helpers";
import PageStylesPanelContent from "./PageStylesPanelContent";
import { ScrollContainer } from "../../../hooks/useContainerScroll";
import {
  CUSTOMIZATION_PANELS,
  CUSTOMIZATION_SIDEBAR_WIDTH,
  POPUP_TYPES,
} from "./helpers";
import { PublicViewTemplate } from "../../../models/PublicViewTemplate";
import HelpScreenStylesPanelContent from "./HelpScreenStylesPanelContent";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";
import { useDispatch } from "react-redux";
import { createSelectStorefrontImage } from "../../../actions/popup";
import { usePublicViewEntityContext } from "../../../context/PublicViewEntityProvider";

const CustomizationsSidebar = (props, ref) => {
  const {
    themeName,
    customizationPanels,
    toggleCustomizationPanel,
    handleShowPopup,
    onCategoryPageChange,

    isMobileView = false,
  } = props;

  const { entityId, entityType, onUpdateTemplateField, onDeleteTemplateField } =
    usePublicViewEntityContext();
  const { getCarouselImagesLastPos, getCarouselImages } =
    usePublicViewTemplateDataContext();

  useEffect(() => {
    toggleCustomizationPanel(CUSTOMIZATION_PANELS.PAGE);
  }, []);

  const carouselImages = getCarouselImages();

  const dispatch = useDispatch();
  const panelRef = useRef(null);
  const { elemRect, canScrollDown, canScrollUp, scrollDown, scrollUp } =
    useContainerScroll(panelRef, [themeName]);
  const isPosterTheme = themeName === PublicViewTemplate.LEFT_NAV_HERO_IMAGE;
  const pageStylesPanelRef = useRef(null);

  useImperativeHandle(ref, () => ({
    getCurrentPage: () => pageStylesPanelRef.current?.getPage(),
  }));

  const getTemplateDataKey = (popupType) => {
    let templateDataKey = "";
    if (popupType === POPUP_TYPES.SELECT_LOGO_IMAGE) {
      templateDataKey = "header-title";
    } else if (popupType === POPUP_TYPES.SELECT_BACKGROUND_IMAGE) {
      templateDataKey = "background";
    } else if (popupType === POPUP_TYPES.SELECT_HELP_SCREEN_IMAGE) {
      templateDataKey = "help_screen_image";
    } else if (popupType === POPUP_TYPES.SELECT_CAROUSEL_IMAGE) {
      const heroImagesLen = getCarouselImagesLastPos();
      templateDataKey = `hero${heroImagesLen + 1}-image`;
    }

    return templateDataKey;
  };

  const handleChangeImagePopup = (
    popupType,
    templateDataKey = "",
    data = {}
  ) => {
    return dispatch(
      createSelectStorefrontImage(
        entityId,
        entityType,
        templateDataKey || getTemplateDataKey(popupType),
        popupType !== POPUP_TYPES.SELECT_LOGO_IMAGE,
        popupType === POPUP_TYPES.SELECT_CAROUSEL_IMAGE,
        { ...data, popupType },
        false
      )
    );
  };

  return (
    <Csku
      as={Row}
      ref={panelRef}
      className="customizations-sidebar"
      forceStyles
      style={{
        background: "var(--color-neutrals-20)",
        padding: 14,
        overflowY: "auto",
        alignContent: "flex-start",
      }}
      sx={{
        xs: {
          maxWidth: "100%",
          marginLeft: 14,
        },
        sm: {
          position: "fixed",
          height: "calc(100vh - 122px)",
        },
        md: {
          maxWidth: CUSTOMIZATION_SIDEBAR_WIDTH,
          marginLeft: 0,
        },
      }}
    >
      <Col xs style={{ paddingBottom: 16 }}>
        <GlobalStylesPanel
          onUpdateField={onUpdateTemplateField}
          isOpen={customizationPanels.includes(CUSTOMIZATION_PANELS.GLOBAL)}
          togglePanel={() =>
            toggleCustomizationPanel(CUSTOMIZATION_PANELS.GLOBAL)
          }
          onOpenImagesPopup={() =>
            handleChangeImagePopup(POPUP_TYPES.SELECT_LOGO_IMAGE)
          }
          isProductListStyleEditable={!isPosterTheme}
          isMobileView={isMobileView}
        />
      </Col>
      <Col xs style={{ paddingBottom: 16 }}>
        <CustomizationPanel
          title={
            <Text
              as="p"
              style={{
                color: "var(--color-neutrals-90)",
                fontFamily: "var(--font-family-bold)",
                fontSize: 20,
                marginBottom: 0,
              }}
            >
              Page Styles
            </Text>
          }
          isOpen={customizationPanels.includes(CUSTOMIZATION_PANELS.PAGE)}
          togglePanel={() =>
            toggleCustomizationPanel(CUSTOMIZATION_PANELS.PAGE)
          }
          data-testid="page_styles_panel_dropdown"
        >
          <PageStylesPanelContent
            ref={pageStylesPanelRef}
            themeName={themeName}
            carouselImages={carouselImages}
            onUpdateField={onUpdateTemplateField}
            onDeleteField={onDeleteTemplateField}
            onOpenImagesPopup={handleChangeImagePopup}
            onOpenCategoriesPopup={(popupConfig) =>
              handleShowPopup(POPUP_TYPES.SELECT_CATEGORIES_IMAGE, popupConfig)
            }
            onOpenFeatureProductsPopup={() =>
              handleShowPopup(POPUP_TYPES.SELECT_FEATURED_IMAGE)
            }
            onCategoryPageChange={onCategoryPageChange}
          />
        </CustomizationPanel>
      </Col>

      <Col xs style={{ paddingBottom: 16 }}>
        <CustomizationPanel
          title={
            <Text
              as="p"
              style={{
                color: "var(--color-neutrals-90)",
                fontFamily: "var(--font-family-bold)",
                fontSize: 20,
                marginBottom: 0,
              }}
            >
              Help Screen Styles
            </Text>
          }
          isOpen={customizationPanels.includes(
            CUSTOMIZATION_PANELS.HELP_SCREEN
          )}
          togglePanel={() =>
            toggleCustomizationPanel(CUSTOMIZATION_PANELS.HELP_SCREEN)
          }
          data-testid="help_screen_styles_panel_dropdown"
        >
          <HelpScreenStylesPanelContent
            themeName={themeName}
            onUpdateField={onUpdateTemplateField}
            onDeleteField={onDeleteTemplateField}
            onOpenImagesPopup={() =>
              handleChangeImagePopup(POPUP_TYPES.SELECT_HELP_SCREEN_IMAGE)
            }
            shopId={entityId}
          />
        </CustomizationPanel>
      </Col>

      {panelRef.current ? (
        <>
          <ScrollContainer
            isUp
            onClick={scrollUp}
            canScroll={canScrollUp()}
            color={"var(--color-primary1-main)"}
            width={panelRef.current.clientWidth - 8}
            zIndex={4}
            top={elemRect.top - 15}
            bottom={"auto"}
            left={"auto"}
            right={8}
          />

          <ScrollContainer
            isUp={false}
            onClick={scrollDown}
            canScroll={canScrollDown()}
            color={"var(--color-primary1-main)"}
            width={panelRef.current.clientWidth - 8}
            zIndex={4}
            top={"auto"}
            bottom={-24}
            left={"auto"}
            right={8}
          />
        </>
      ) : null}
    </Csku>
  );
};

export default forwardRef(CustomizationsSidebar);
