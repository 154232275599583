import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import api from '../middleware/api';
import thunk from 'redux-thunk';

import { ADD_TEMP, DELETE_TEMP } from '../actions/temp';
import {
  COPY_ITEM_FROM_COLLECTION_REQUEST,
  COPY_ITEM_FROM_COLLECTION_SUCCESS,
  COPY_ITEM_FROM_COLLECTION_FAILURE,
} from '../actions/item';
import { UPDATE_PRODUCT_ITEMS_SUCCESS } from '../actions/shop';

import { LOAD_SHOP_SUCCESS } from '../actions/shop';

import tempReducer from '../reducers/temp';
import displayReducer from '../reducers/display';
import artworkReducer from '../reducers/artwork';
import fileReducer from '../reducers/file';
import breakdownReducer from '../reducers/breakdown';
import colorReducer from '../reducers/color';
import sizeReducer from '../reducers/size';
import itemCostReducer from '../reducers/item_cost';
import itemImageReducer from '../reducers/item_image';
import itemLocationReducer from '../reducers/item_location';
import itemRetailAdjustmentReducer from '../reducers/item_retail_adjustment';
import skuReducer from '../reducers/sku';
import galleryReducer from '../redux/gallery';
import heroImageReducer from '../redux/heroImage';

import { window } from '../global';
import { commentReducer } from '../reducers/item';
import itemPropertiesReducer from '../redux/itemProperties';

var initialState = typeof(initialState) === 'undefined' ? window.initialState : initialState;

initialState.added_to_orders = {};
initialState.display = {};
initialState.display.popups = [];
if (!initialState.temp) {
  initialState.temp = {};
}
initialState.temp.first_view = true;
if (!initialState.dropdowns) {
  initialState.dropdowns = {};
}
if (!initialState.identity) {
  initialState.identity = {};
}
if(!initialState.authorized) {
  initialState.authorized = false;
}
if (!initialState.open) {
  initialState.open = false;
}

const itemReducer = (state = initialState && initialState.items ? initialState.items : {}, action) => {
  switch (action.type) {
    case UPDATE_PRODUCT_ITEMS_SUCCESS:
      return { ...state, ...action.payload.items };
  }
  return state;
};

const entityReducer = (state = initialState && initialState.entities ? initialState.entities : {}, action) => {
  switch (action.type) {
    case LOAD_SHOP_SUCCESS:
      return {
        ...state,
        ...action.payload.entities
      };
  }
  return {
    ...state,
    comments: commentReducer(state.comments || {}, action),
    artworks: artworkReducer(state.artworks, action),
    breakdowns: breakdownReducer(state.breakdowns, action),
    colors: colorReducer(state.colors, action),
    sizes: sizeReducer(state.sizes, action),
    files: fileReducer(state.files, action),
    items: itemReducer(state.items, action),
    item_costs: itemCostReducer(state.item_costs, action),
    item_images: itemImageReducer(state.item_images, action),
    item_locations: itemLocationReducer(state.item_locations, action),
    item_retail_adjustments: itemRetailAdjustmentReducer(state.item_retail_adjustments, action),
		skus: skuReducer(state.skus, action)
  };
};

export const added_to_orders = (state = initialState.added_to_orders, action) => {
  switch (action.type) {
    case ADD_TEMP:
    // fall through
    case DELETE_TEMP:
      return Object.assign({}, state, [] );
    case COPY_ITEM_FROM_COLLECTION_REQUEST:
      return Object.assign({}, state, { [action.payload.order_id]: 'loading' });
    case COPY_ITEM_FROM_COLLECTION_FAILURE:
      return Object.assign({}, state, Object.keys(state.added_to_orders).filter(k => k !== action.payload.order_id).reduce((o, k) => {
        o[k] = state.added_to_orders[k];
        return o;
      }, {}));
    case COPY_ITEM_FROM_COLLECTION_SUCCESS:
      return Object.assign({}, state, { [action.payload.order.order_id]: 'added' });
  }
  return state;
};

const identity = (state = initialState.identity, action) => {
  switch (action.type) {
    case LOAD_SHOP_SUCCESS:
      return {
        ...state,
        ...action.payload.identity
      };
  }
  return state;
};

const dropdowns = (state = initialState.dropdowns, action) => {
  switch (action.type) {
    case LOAD_SHOP_SUCCESS:
      return {
        ...state,
        ...action.payload.dropdowns
      };
  }
  return state;
};

const authorized = (state = initialState.authorized, action) => {
  switch (action.type) {
    case LOAD_SHOP_SUCCESS:
      return {
        ...state,
        ...action.payload.authorized
      };
  }
  return state;
};

const open = (state = initialState.open, action) => {
  switch (action.type) {
    case LOAD_SHOP_SUCCESS:
      return {
        ...state,
        ...action.payload.open
      };
  }
  return state;
};

export const reducers = {
  added_to_orders,
  identity,
  dropdowns,
  authorized,
  open,
  temp: tempReducer,
  display: displayReducer,
  entities: entityReducer,
  gallery: galleryReducer,
  heroImage: heroImageReducer,
  itemProperties: itemPropertiesReducer,
};

export default function configureStore(initialState) {
  const store = createStore(
    combineReducers(reducers),
    initialState,
    compose(
      applyMiddleware(thunk, api),
      window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        ? window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
        : f => f
    )
  );

  return store;
};
