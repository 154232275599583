import _, { omitBy, pickBy } from 'lodash';
import * as actions from '../actions/item';
import { CALCULATE_AVALARA_TAX } from '../actions/tax';
import { ADD_ARTWORK_SUCCESS, UPDATE_ARTWORK_SUCCESS } from '../actions/artwork';
import {
  UPDATE_ORDER_TAX_ID_SUCESS, LOAD_ORDER_SUCCESS, DELETE_ORDER_SUCCESS, UPDATE_ORDER_SUCCESS
} from '../actions/order';
import { ADD_ORDER_SUCCESS } from '../actions/project';
import { UPDATE_SHIPPING_SUCCESS } from '../actions/shipping';
import { FETCH_PRODUCT_DETAIL_SUCCESS } from '../actions/product';
import { REGENERATE_PURCHASE_ORDER_SUCCESS, DELETE_PURCHASE_ORDER_SUCCESS } from '../actions/purchase_order';
import { applyMargin, determineCost, recalculateMargin } from '../utils';
import { ADD_METADATA_SUCCESS, DELETE_METADATA_SUCCESS, UPDATE_METADATA_SUCCESS } from '../actions';

const updateOrderItems = (state, payload) => {
  const items = _.keyBy(_.get(payload, 'order.items'), 'item_id');

  return _.mapValues(state, (item, item_id) => {
    const new_item = _.get(items, item_id);
    return !new_item ? item : {
      ...item,
      ...items[item_id],
    };
  });
};

export const itemReducer = (state = {}, action) => {
  let item_id, updated_item, items, metadata, metadata_id, new_metadata_list, parent_type;
  const {
    payload = {},
    meta = {},
  } = action;
  const uuid = {
    updateUUID: meta.updateUUID || null,
  };
  switch (action.type) {
    case actions.SIMPLE_UPDATE_ITEM_REQUEST:
      return {
        ...state,
        [payload.id]: { ...state[payload.id], ...payload.data }
      };
    case actions.SIMPLE_UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        [payload.id]: { ...state[payload.id], ...payload.data }
      };
    case actions.SIMPLE_UPDATE_ITEM_FAILURE:
      return {
        ...state,
        [payload.id]: { ...state[payload.id], ...payload.data }
      };
    case actions.COMPLEX_UPDATE_ITEM_REQUEST:
      switch (action.payload.field) {
        case 'total_margin':
          return {
            ...state,
            [payload.id]: {
              ...state[payload.id],
              ...payload.data,
              unit_price: applyMargin(state[payload.id].unit_cost, payload.data.total_margin, Number(state[payload.id].exchange_rate))
            }
          };
        case 'unit_price':
          if (!payload.response) {
            return state;
          }
          return {
            ...state,
            [payload.id]: {
              ...state[payload.id],
              ...payload.data,
              total_margin: recalculateMargin(payload.response.item.unit_cost, payload.data.unit_price, Number(payload.response.item.exchange_rate))
            }
          };
        case 'unit_cost':
          const unit_price = applyMargin(payload.data.unit_cost, state[payload.id].total_margin, Number(state[payload.id].exchange_rate));
          return {
            ...state,
            [payload.id]: {
              ...state[payload.id],
              ...payload.data,
              unit_price,
              total_margin: recalculateMargin(payload.data.unit_cost, unit_price, Number(state[payload.id].exchange_rate))
            }
          };
        default:
          if (state[payload.id][payload.field] === payload.data[payload.field]) {
            return state;
          }
          return {
            ...state,
            [payload.id]: { ...state[payload.id], ...payload.data }
          };
      }
    case actions.COMPLEX_UPDATE_ITEM_SUCCESS:
      switch (action.payload.field) {
        case 'total_margin':
          return {
            ...state,
            [payload.id]: { ...state[payload.id], ...action.payload.response.item }
          };
        case 'unit_price':
          return {
            ...state,
            [payload.id]: { ...state[payload.id], ...action.payload.response.item }
	        };
        default:
          return {
            ...state,
            [payload.id]: { ...state[payload.id], ...action.payload.response.item }
          };
      }
    case actions.COMPLEX_UPDATE_ITEM_FAILURE:
      return {
        ...state,
        [payload.id]: { ...state[payload.id], ...payload.data }
      };
    case UPDATE_SHIPPING_SUCCESS:
      return Object.assign({}, state, payload.item_ids.map(i => Object.assign({}, state[i], { shipping: Object.keys(payload.shipping) })).reduce((o, item) => { o[item.item_id] = item; return o; }, {}));
    case actions.UPDATE_ITEM_REQUEST:
      if (payload.data.hidden != null) {
        return Object.assign({}, state, { [payload.id]: Object.assign({}, state[payload.id], payload.data) });
      }
      // fall through
    case actions.UPDATE_ITEM_SUCCESS:
      // fall through
    case actions.UPDATE_ITEM_FAILURE:
      // temp hack to prevent client state rollback after server update
      // use updateUUID indicate this update already been done in client side,
      // so no need to update component.state from server response props
      let data = payload.data;
      if (meta.alsoUpdate && action.type === actions.UPDATE_ITEM_REQUEST) {
        data = Object.assign({}, data, meta.alsoUpdate);
      }
      item_id = payload.id;
      if (payload.response) {
        updated_item = Object.assign({}, state[item_id], payload.response.item);
        if (payload.data.hidden == null) {
          items = payload.response.items;
        }
        if (!items && (payload.response.item || {}).options) {
          items = payload.response.item.options;
        }
      } else{
        updated_item = Object.assign({}, state[item_id], data);
      }
      return Object.assign({}, state,
        (items || []).reduce((o, i) => {
          if (!_.every(i, (v, k) => {
            return _.isEqual(v, state[i.item_id][k]);
          })) {
            o[i.item_id] = Object.assign({}, state[i.item_id], i, uuid);
          };
          return o;
        }, {}),
        {[item_id]: Object.assign({}, updated_item, uuid)});
    case actions.ADD_ITEM_SUCCESS:
      return Object.assign(
        {},
        state,
        (payload.items || []).reduce((o, i) => {
          o[i.item_id] = Object.assign(
            {},
            state[i.item_id],
            Object.keys(i).filter(k => i[k] !== null).reduce((x, k) => {
              x[k] = i[k];
              return x;
            }, {})
          );
          return o;
        }, {}),
        {
          [payload.item.item_id]: payload.item
        },
        (payload.item.options || []).reduce((o, i) => {
          o[i.item_id] = i;
          return o;
        }, {})
      );
    case actions.DELETE_ITEM_REQUEST:
      // fall through
    case actions.DELETE_ITEM_SUCCESS:
      return Object.keys(state).filter(k => k !== payload.item_id).reduce((o, k) => { o[k] = state[k]; return o; }, {});
    case actions.DELETE_ITEM_FAILURE:
      return state;
    case actions.COMPLEX_UPDATE_BREAKDOWN_SUCCESS:
      return {
        ...state,
        [action.payload.response.item.item_id]: {
          ...state[action.payload.response.item.item_id],
          ...action.payload.response.item
	      }
      };
    case actions.UPDATE_ITEM_BREAKDOWN_SUCCESS:
      item_id = payload.response.item.item_id;
      updated_item = Object.assign({}, state[item_id], payload.response.item, uuid);
      return Object.assign({}, state, {[item_id]: updated_item});
    case actions.UPDATE_ITEM_COST_SUCCESS:
      if (!action.payload.response) {
        return state;
      }
      item_id = payload.response.item.item_id;
      updated_item = Object.assign({}, state[item_id], payload.response.item);
      return Object.assign({}, state, {[item_id]: updated_item});
    case actions.UPDATE_ITEM_LOCATION_SUCCESS:
    case actions.UPDATE_ITEM_TAX_AMOUNT_SUCCESS:
      if (payload.item === undefined) {
        return state;
      }
      item_id = payload.item.item_id;
      updated_item = Object.assign({}, state[item_id], payload.item);
      return Object.assign({}, state, {[item_id]: updated_item});
    case actions.UPDATE_ITEM_DECORATION_SUCCESS:
      return {
        ...state,
        [action.payload.item.item_id]: {
          ...state[action.payload.item.item_id],
          ...action.payload.item
        },
        ...(action.payload.item.options || []).reduce(
          (o, i) => ({ ...o, [i.item_id]: { ...state[i.item_id], ...i } }),
          {}
        )
      };
    case actions.ADD_ITEM_BREAKDOWN_SUCCESS:
    case actions.DELETE_ITEM_BREAKDOWN_SUCCESS:
    case actions.ADD_ITEM_COST_SUCCESS:
    case actions.DELETE_ITEM_COST_SUCCESS:
    case actions.ADD_ITEM_LOCATION_SUCCESS:
    case actions.DELETE_ITEM_LOCATION_SUCCESS:
    case actions.ADD_ITEM_TAX_AMOUNT_SUCCESS:
    case actions.DELETE_ITEM_TAX_AMOUNT_SUCCESS:
      const options = _.map(_.get(payload, 'item.options'), (option) => {
        return {
          ...state[option.item_id],
          unit_price: option.unit_price,
        };
      });
      if (!_.isEmpty(options)) {
        state = Object.assign({}, state, _.keyBy(options, 'item_id'));
      }
      item_id = payload.item.item_id;
      updated_item = Object.assign({}, state[item_id], payload.item);
      return Object.assign({}, state, {[item_id]: updated_item});
    case DELETE_ORDER_SUCCESS:
      return Object.values(state).filter(i => i.order_id !== payload.data.order_id).reduce((o, i) => { o[i.item_id] = i; return o; }, {});
    case ADD_ORDER_SUCCESS:
      if(payload.data.order.items){
        return Object.assign({}, state, payload.data.order.items.reduce((items, item) => { items[item.item_id] = item; return items; }, {}));
      }
      return state;
    case actions.REORDER_ITEM_SUCCESS:
      const old_item = state[payload.id];
      const old_order = parseInt(old_item.display_order, 10);
      const new_order = parseInt(payload.data.display_order, 10);
      const newState = Object.assign({}, state, {
        [payload.id] : {
          ...old_item,
          display_order: new_order.toString(),
        }
      });
      const itemsOnSameOrder = _.pickBy(state, (item) => {
        return item.order_id === payload.response.order.order_id &&
          item.item_id !== old_item.item_id;
      });
      if (payload.response.items) {
        return Object.assign(newState, _.mapValues(itemsOnSameOrder, (item) => {
          return {
            ...item,
            display_order: payload.response.items.find(i => i.item_id == item.item_id)?.display_order.toString(),
          };
        }));
      }

      // update my display_order
      if (new_order > old_order) {
        // if increase display order, same order items with higher than original display_order but <= new display order decrease by 1
        return Object.assign(newState, _.mapValues(itemsOnSameOrder, (item) => {
          const displayOrder = parseInt(item.display_order, 10);
          if (displayOrder > old_order && displayOrder <= new_order) {
            return {
              ...item,
              display_order: (displayOrder - 1).toString(),
            };
          } else {
            return item;
          }
        }));
      } else if(new_order < old_order) {
        // if decreased display order, same order items lower than original display_order but >= new display order increase by 1
        return Object.assign(newState, _.mapValues(itemsOnSameOrder, (item) => {
          const displayOrder = parseInt(item.display_order, 10);
          if (displayOrder < old_order && displayOrder >= new_order) {
            return { ...item,
              display_order: (displayOrder + 1).toString(),
            };
          } else {
            return item;
          }
        }));
      }
      return state;

    case actions.ADD_ITEM_SIZE_SUCCESS:
      return Object.assign({}, state, {[action.payload.item_id]: Object.assign({}, state[action.payload.item_id], {
        item_sizes: (state[action.payload.item_id].item_sizes || []).concat(action.payload.item_size),
        product_sizes: (state[action.payload.item_id].item_sizes || []).concat(action.payload.item_size).map(c => c.size_name).join(', ')
        })
      });
    case actions.ADD_ITEM_COLOR_SUCCESS:
      return Object.assign({}, state, {[action.payload.item_id]: Object.assign({}, state[action.payload.item_id], {
        item_colors: (state[action.payload.item_id].item_colors || []).concat(action.payload.item_color),
        product_colors: (state[action.payload.item_id].item_colors || []).concat(action.payload.item_color).map(c => c.color_name).join(', ')
        })
      });
    case actions.DELETE_ITEM_SIZE_SUCCESS:
      return Object.values(state).reduce((o, i) => {
        o[i.item_id] = Object.assign({}, i, {
          item_sizes: action.payload.item_id === i.item_id ? [] : (i.item_sizes || []).filter(iss => iss.item_size_id !== action.payload.item_size_id),
          product_sizes: action.payload.item_id === i.item_id ? '' : (i.item_sizes || []).filter(iss => iss.item_size_id !== action.payload.item_size_id).map(iss => iss.size_name).join(', ')
        });
        return o;
      }, {});
    case actions.DELETE_ITEM_COLOR_SUCCESS:
      return Object.values(state).reduce((o, i) => {
        o[i.item_id] = Object.assign({}, i, {
          item_colors: action.payload.item_id === i.item_id ? [] : (i.item_colors || []).filter(iss => iss.item_color_id !== action.payload.item_color_id),
          product_colors: action.payload.item_id === i.item_id ? '' : (i.item_colors || []).filter(iss => iss.item_color_id !== action.payload.item_color_id).map(iss => iss.color_name).sort().join(', ')
        });
        return o;
      }, {});
    case actions.ADD_ITEM_SKU_SUCCESS:
      return {
        ...state,
       [action.payload.item_id]: {
          ...state[action.payload.item_id],
          item_skus: (state[action.payload.item_id].item_skus || []).concat(action.payload.item_sku),
          product_skus: (state[action.payload.item_id].item_skus || []).concat(action.payload.item_sku).map(s => !!s.description ? (s.description + (!s.sku_description ? '' : ' (' + s.sku_description + ')')) : s.sku_description).sort().join(', ')
        }
      };
    case actions.DELETE_ITEM_SKU_SUCCESS:
      return Object.values(state).reduce((o, i) => ({
        ...o,
        [i.item_id]: {
          ...i,
          item_skus: action.payload.item_id === i.item_id ? [] : (i.item_skus || []).filter(isku => isku.item_sku_id !== action.payload.item_sku_id),
          product_skus: action.payload.item_id === i.item_id ? [] : (i.item_skus || []).filter(isku => isku.item_sku_id !== action.payload.item_sku_id).map(s => !!s.description ? (s.description + (!s.sku_description ? '' : ' (' + s.sku_description + ')')) : s.sku_description).sort().join(', '),
        }
      }), {});
    case LOAD_ORDER_SUCCESS:
      return Object.assign({}, state, action.payload.order.items.reduce((o, i) => {
        o[i.item_id] = Object.keys(i).filter(k => k !== 'tax_amounts' && k !== 'breakdowns' && k !== 'item_costs' && k !== 'item_breakdowns' && k !== 'item_locations' && k !== 'artworks' && k !== 'item_images' && k !== 'item_warnings').reduce((i2, k) => {
          i2[k] = i[k];
          return i2;
        }, {});
        return o;
      }, {}));
    case actions.UPDATE_PRODUCT_WARNINGS_SUCCESS:
      return Object.assign({}, state, {
        [action.payload.item_id]: Object.assign({}, state[action.payload.item_id], {
          date_warnings_updated: action.payload.date_warnings_updated,
          product_warnings: action.payload.product_warnings
        })
      });
    case REGENERATE_PURCHASE_ORDER_SUCCESS:
      return {
        ...state,
	      ...action.payload.deleted_for_items.reduce(
          (o, i) => ({ ...o, [i]: { ...state[i], hasPurchaseOrder: false }}),
          {}
        ),
        ...action.payload.purchase_orders.reduce(
          (item_ids, po) => item_ids.concat(
            po.items.map(
              poi => poi.item_id
            )
          ),
          []
	      ).reduce(
          (o, i) => ({ ...o, [i]: { ...state[i], hasPurchaseOrder: true }}),
          {}
        )
      };
    case DELETE_PURCHASE_ORDER_SUCCESS:
      return Object.values(state).filter(
        i => !(action.payload.deleted_for_items.includes(i.item_id) && i.parent_type === 'ARTWORK_PO')
      ).reduce(
        (o, i) => ({
          ...o,
          [i.item_id]: {
            ...i,
             hasPurchaseOrder: i.hasPurchaseOrder &&
               !action.payload.deleted_for_items.includes(i.item_id)
          }
        }),
        {}
      );
    case UPDATE_ORDER_SUCCESS:
      return updateOrderItems(state, payload.response);
    case ADD_METADATA_SUCCESS:
      parent_type = action.payload.parent_type;
      if(parent_type === 'ITEM'){
        item_id = action.payload.parent_id;
        metadata = action.payload.metadata;

        return Object.assign({}, state, {
          [item_id]: Object.assign({}, state[item_id], {
            metadata: Object.assign({}, state[item_id].metadata, {
              [metadata.metadata_id]: metadata
            })
          })
        });
      }
      return state;
    case UPDATE_METADATA_SUCCESS:
      parent_type = action.payload.parent_type;
      if (parent_type === 'ITEM'){
        item_id = action.payload.parent_id;
        metadata_id = action.payload.metadata_id;

        return Object.assign({}, state, {
          [item_id]: Object.assign({}, state[item_id], {
            metadata: Object.assign({}, state[item_id].metadata, {
              [metadata_id]: Object.assign({}, state[item_id].metadata[metadata_id],
                action.payload.data
              )
            })
          })
        });
      }
      return state;
    case DELETE_METADATA_SUCCESS:
      parent_type = action.payload.parent_type;
      if(parent_type === 'ITEM'){
        item_id = action.payload.parent_id;
        metadata_id = action.payload.metadata_id;

        new_metadata_list = Object.assign({}, state[item_id].metadata);
        delete new_metadata_list[metadata_id];

        return Object.assign({}, state, {
          [item_id]: Object.assign({}, state[item_id], {
            metadata: new_metadata_list
          })
        });
      }
      return state;
  }

  return state;
};

export const itemCostReducer = (state = {}, action) => {
  const finalize = actions.UPDATE_ITEM_COST_REQUEST !== action.type;
  let state_copy;
  const uuid = {
    updateUUID: _.get(action, 'meta.updateUUID', null),
  };
  const field_lock = _.get(action, 'payload.extra_data.field_lock', 'unit_cost');
	const exchange_rate = _.get(action, 'payload.extra_data.exchange_rate', 1.0);
  switch (action.type) {
    case actions.COMPLEX_UPDATE_ITEM_COST_REQUEST:
      switch (action.payload.field) {
        case 'unit_cost':
          return {
            ...state,
            [action.payload.id]: {
              ...state[action.payload.id],
              ...action.payload.data,
              unit_price: field_lock === 'unit_cost' ? applyMargin(action.payload.data.unit_cost, state[action.payload.id].total_margin, exchange_rate) : state[action.payload.id].unit_price,
              total_margin: field_lock === 'unit_price' ? recalculateMargin(action.payload.data.unit_cost, state[action.payload.id].unit_price, exchange_rate) : state[action.payload.id].total_margin
            }
          };
        case 'total_margin':
          return {
            ...state,
            [action.payload.id]: {
              ...state[action.payload.id],
              ...action.payload.data,
              unit_price: field_lock === 'unit_cost' ? applyMargin(state[action.payload.id].unit_cost, action.payload.data.total_margin, exchange_rate) : state[action.payload.id].unit_price,
              unit_cost: field_lock === 'unit_price' ? determineCost(state[action.payload.id].unit_price, action.payload.data.total_margin, exchange_rate) : state[action.payload.id].unit_cost
            }
          };
        case 'unit_price':
          return {
            ...state,
            [action.payload.id]: {
              ...state[action.payload.id],
              ...action.payload.data,
              total_margin: recalculateMargin(state[action.payload.id].unit_cost, action.payload.data.unit_price, exchange_rate)
            }
          };
        default:
          return {
            ...state,
            [action.payload.id]: { ...state[action.payload.id], ...action.payload.data }
          };
      }
    case actions.COMPLEX_UPDATE_ITEM_COST_SUCCESS:
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], ...action.payload.data }
      };
    case actions.COMPLEX_UPDATE_ITEM_COST_FAILURE:
      return {
        ...state,
        [action.paylod.id]: { ...state[action.payload.id], ...action.payload.data }
      };
    case actions.COMPLEX_UPDATE_ITEM_SUCCESS:
     if ('share_fixed_charges' === action.payload.field) {
       return {
         ...state,
         ...action.payload.response.item.item_costs.reduce(
           (o, ic) => ({ ...o, [ic.item_cost_id]: ic }),
           {}
         )
       };
     }
     return state;
    case actions.ADD_ITEM_SUCCESS:
      const option_costs = Object.assign.apply(null, [{}].concat((action.payload.item.options || []).map(i => i.item_costs.reduce((o, c) => {o[c.item_cost_id] = Object.assign({}, c, { from_db: true }); return o;}, {}))));
      return Object.assign({}, state, (action.payload.item.item_costs || []).reduce((o, c) => {o[c.item_cost_id] = Object.assign({}, c, { from_db: true }); return o;}, {}), option_costs);
    case actions.UPDATE_ITEM_SUCCESS:
      if (action.payload.field === 'is_3rd_party_decorator') {
        const { id, response: { item: { item_costs } } } = action.payload;
        if (item_costs.length === 0) {
          return _.omitBy(state, (item_cost) => item_cost.item_id === id);
        }
      }
      return {
        ...state,
        ...(((action.payload.response || {}).item || {}).item_costs || []).reduce((o, ic) => ({
          ...o,
          [ic.item_cost_id]: {
            ...state[ic.item_cost_id],
            ...ic
          }
        }),
        {})
      };
    case actions.UPDATE_ITEM_COST_REQUEST:
      // fall through
    case actions.UPDATE_ITEM_COST_SUCCESS:
      // fall through
    case actions.UPDATE_ITEM_COST_FAILURE:
      const item_cost_id = action.payload.id;
      let updated_item_cost;
      if (action.payload.response) {
        updated_item_cost = action.payload.response.item_cost;
      } else if (action.payload.breakdown_cost === undefined) {
        updated_item_cost = Object.assign({}, state[item_cost_id], action.payload.data);
      } else {
        updated_item_cost = updateItemCost(state[item_cost_id], action.payload.data, action.payload.breakdown_cost, finalize, field_lock);
      }
      return Object.assign({}, state, {[item_cost_id]: {
        ...updated_item_cost,
        ...uuid,
      }});
    case actions.ADD_ITEM_COST_SUCCESS:
      return Object.assign({}, state, {[action.payload.item_cost.item_cost_id]: Object.assign({}, action.payload.item_cost, { from_db: false})}, (action.payload.item_cost.options || []).reduce((o, c) => { o[c.item_cost_id] = Object.assign({}, c, {from_db: false}); return o;}, {}));
    case actions.DELETE_ITEM_COST_REQUEST:
      // fall through
    case actions.DELETE_ITEM_COST_SUCCESS:
      return Object.keys(state).filter(k => k !== action.payload.item_cost_id).reduce((o, k) => { o[k] = state[k]; return o; }, {});
    case actions.ADD_ITEM_LOCATION_SUCCESS:
      state_copy = omitBy(state, (ic) => {
        return isDeletedLocation(ic, action.payload.item);
      });
      if (action.payload.item_location) {
        action.payload.item_location.item_costs.forEach(ic => {
          state_copy[ic.item_cost_id] = ic;
        });
      }
      if (action.payload.item_locations) {
        action.payload.item_locations.forEach(il => {
          il.item_costs.forEach(ic => {
            state_copy[ic.item_cost_id] = ic;
          });
        });
      }
      return state_copy;
    case actions.DELETE_ITEM_LOCATION_REQUEST:
      return Object.keys(state).filter(k => state[k].item_location_id !== action.payload.item_location_id).reduce((o, k) => { o[k] = state[k]; return o; }, {});
    case actions.DELETE_ITEM_LOCATION_SUCCESS:
      return Object.keys(state).filter(k => !action.payload.deleted.item_costs.includes(k)).reduce((o, k) => ({ ...o, [k]: state[k] }), {});
    case actions.UPDATE_ITEM_BREAKDOWN_SUCCESS:
      return {
        ...state,
        ...action.payload.response.item.item_costs.reduce((o, ic) => ({
          ...o,
          [ic.item_cost_id]: {
            ...state[ic.item_cost_id],
            ...ic
          }
        }),
        {})
      };
    case actions.ADD_ITEM_BREAKDOWN_SUCCESS:
    case actions.DELETE_ITEM_BREAKDOWN_SUCCESS:
      return Object.assign({}, state, action.payload.item.item_costs.filter(k => k.percent !== null).reduce((o, k) => { o[k.item_cost_id] = Object.assign({}, state[k.item_cost_id], k); return o; }, {}));
    case ADD_ORDER_SUCCESS:
      if(action.payload.data.order.items){
        let item_costs = {};
        action.payload.data.order.items.forEach(i => i.item_costs.forEach(ic => { item_costs[ic.item_cost_id] = ic; }));
        return Object.assign({}, state, item_costs);
      }
      return state;
    case actions.REORDER_ITEM_COST_SUCCESS:
      // update my display_order
      const old_order = state[action.payload.id].display_order;
      const new_order = action.payload.data.display_order;
      if (new_order > old_order) {
        // if increase display order, same order items with higher than original display_order but <= new display order decrease by 1
        return Object.assign({}, state,
          { [action.payload.id] : Object.assign({}, state[action.payload.id], {display_order: new_order}) },
          Object.values(state).filter(i => i.item_id === action.payload.response.item.item_id && i.display_order > old_order && i.display_order <= new_order).map(i =>
            Object.assign({}, i, { display_order: parseInt(i.display_order, 10) - 1 })
          ).reduce((o, i) => { o[i.item_cost_id] = i; return o; }, {})
        );
      } else {
        // if decreased display order, same order items lower than original display_order but >= new display order increase by 1
        return Object.assign({}, state,
          { [action.payload.id] : Object.assign({}, state[action.payload.id], {display_order: new_order}) },
          Object.values(state).filter(i => i.item_id === action.payload.response.item.item_id && i.display_order < old_order && i.display_order >= new_order).map(i =>
            Object.assign({}, i, { display_order: parseInt(i.display_order, 10) + 1 })
          ).reduce((o, i) => { o[i.item_cost_id] = i; return o; }, {})
        );
      }
    case LOAD_ORDER_SUCCESS:
      return Object.assign({}, state, ...(action.payload.order.items.map(i => i.item_costs.reduce((o, ic) => {
          o[ic.item_cost_id] = ic;
          return o;
        }, {})
      )));
    case actions.UPDATE_ITEM_DECORATION_SUCCESS:
      let item_decoration_ids = [action.payload.item_decoration.item_decoration_id].concat(
        (action.payload.item.options || []).reduce(
          (o, i) => o.concat(
            i.item_decorations.filter(
              id => id.item_location_id === action.payload.item_decoration.item_location_id
            ).map(
              id => id.item_decoration_id
            )
          ),
          []
        )
      );
      return Object.values(state).filter(
        ic => !item_decoration_ids.includes(ic.item_decoration_id)
      ).concat(
        action.payload.item_costs
      ).reduce(
        (o, ic) => ({
          ...o,
          [ic.item_cost_id]: ic
        }),
        {}
      );
    case ADD_ARTWORK_SUCCESS: // fall through
    case UPDATE_ARTWORK_SUCCESS: {
      return {
        ...Object.values(state).filter(ic => ic.item_location_id !== action.payload.data.artwork.item_location_id).reduce(
	  (o, ic) => ({ ...o, [ic.item_cost_id]: ic }),
          {}
        ),
        ...(action.payload.data.item_costs || []).reduce(
          (o, ic) => ({ ...o, [ic.item_cost_id]: ic }),
          {}
        )
      };
    }
  }
  return state;
};

const updateItemCost = (item_cost, data, breakdown_cost = 0, finalize = false, field_lock = 'unit_cost', exchange_rate = 1.0) => {
  let result = Object.assign({}, item_cost);
  const fields = Object.keys(data);
  fields.forEach(field => {
    switch (field) {
      case 'unit_cost':
        result.unit_cost = data[field];
        if ('unit_cost' === field_lock) {
          result.unit_price = applyMargin(result.unit_cost, result.total_margin, exchange_rate);
        }
        result.total_margin = recalculateMargin(result.unit_cost, result.unit_price, exchange_rate);
        if (result.percent !== null) {
          if (0 == breakdown_cost) {
            result.percent = 0;
          } else {
            result.percent = result.unit_cost / breakdown_cost * 100;
          }
        }
        break;
      case 'total_margin':
        if ('unit_price' === field_lock) {
          result.unit_cost = determineCost(result.unit_price, data[field], exchange_rate);
        } else {
          result.unit_price = applyMargin(result.unit_cost, data[field], exchange_rate);
        }
        if (finalize) {
          result.total_margin = recalculateMargin(result.unit_cost, result.unit_price, exchange_rate);
        } else {
          result.total_margin = data[field];
        }
        break;
      case 'unit_price':
        result.unit_price = data[field];
        result.total_margin = recalculateMargin(result.unit_cost, result.unit_price, exchange_rate);
        break;
      case 'percent':
        result.percent = data[field];
        result.unit_cost = breakdown_cost * result.percent / 100;
        result.unit_price = applyMargin(result.unit_cost, result.total_margin, exchange_rate);
        result.total_margin = recalculateMargin(result.unit_cost, result.unit_price, exchange_rate);
        break;
      default:
        result[field] = data[field];
        break;
    }
  });
  return result;
};

export const itemDecorationReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.ADD_ITEM_SUCCESS:
      return {
        ...state,
        ...(action.payload.item.item_decorations || []).reduce(
          (o, id) => ({
            ...o,
            [id.item_decoration_id]: id
          }),
          {}
        ),
        ...(action.payload.item.options || []).reduce(
          (o, i) => ({
            ...o,
            ...(i.item_decorations || []).reduce(
              (o2, id) => ({
                ...o2,
                [id.item_decoration_id]: id
              }),
              {}
            )
          }),
          {}
        )
      };
    case actions.UPDATE_ITEM_DECORATION_REQUEST:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload.data
        }
      };
    case actions.UPDATE_ITEM_DECORATION_SUCCESS:
      return {
        ...state,
        [action.payload.item_decoration.item_decoration_id]: {
          ...state[action.payload.item_decoration.item_decoration_id],
          ...action.payload.item_decoration
        }
      };
    case actions.ADD_ITEM_LOCATION_SUCCESS:
      return {
        ...state,
        ...action.payload.item_locations.reduce(
          (o, il) => ({
            ...o,
            ...il.item_decorations.reduce((o2, id) => ({
              ...o2,
              [id.item_decoration_id]: id
            }), {})
          }), {}),
        ...(action.payload.item_location ?
          action.payload.item_location.item_decorations.reduce(
            (o, id) => ({
              ...o,
              [id.item_decoration_id]: id
            }), {}) : {})
      };
    case actions.DELETE_ITEM_LOCATION_SUCCESS:
      return Object.keys(state).filter(k => !action.payload.deleted.item_decorations.includes(k)).reduce((o, k) => ({ ...o, [k]: state[k] }), {});
    case LOAD_ORDER_SUCCESS:
      return {
        ...state,
        ...(action.payload.order.items.reduce((o, i) => ({
          ...o,
          ...i.item_decorations.reduce((o2, id) => ({
            ...o2,
            [id.item_decoration_id]: id
          }), {})
        }), {}))
      };
    case ADD_ORDER_SUCCESS:
      return {
        ...state,
        ...(action.payload.data.order.items || []).reduce(
          (o, i) => ({
            ...o,
            ...(i.item_decorations || []).reduce(
              (o2, id) => ({
                ...o2,
                [id.item_decoration_id]: id
              }),
              {}
            )
          }),
          {}
        )
      };
    case ADD_ARTWORK_SUCCESS:
      return {
        ...state,
        ...(action.payload.data.item_decorations || []).reduce(
          (o, id) => ({ ...o, [id.item_decoration_id]: id }),
          {}
        )
      };
    case actions.UPDATE_ITEM_SUCCESS:
      if (action.payload.field === 'is_3rd_party_decorator') {
        const { id, response: { item: { item_decorations } } } = action.payload;
        if (item_decorations.length === 0) {
          return omitBy(state, (v, k) => v.item_id === id);
        }
        return {
          ...state,
          ...item_decorations.map((id) => ({[id.item_location_id]: id}))
        };
      }
      return state;
  }
  return state;
};

export const itemRetailAdjustmentReducer = (state = {}, action) => {
  const uuid = {
    updateUUID: _.get(action, 'meta.updateUUID', null),
  };
  switch (action.type) {
    case LOAD_ORDER_SUCCESS:
      return {
        ...state,
        ...(action.payload.order.items.reduce((o, i) => ({
          ...o,
          ...i.item_retail_adjustments.reduce((o2, ira) => ({
            ...o2,
            [ira.item_retail_adjustment_id]: ira
          }), {})
        }), {}))
      };
    case actions.ADD_ITEM_RETAIL_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        [action.payload.item_retail_adjustment.item_retail_adjustment_id]: action.payload.item_retail_adjustment
      };
    case actions.UPDATE_ITEM_RETAIL_ADJUSTMENT_REQUEST:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...uuid
        }
      };
    case actions.UPDATE_ITEM_RETAIL_ADJUSTMENT_SUCCESS:
      return {
        ...state,
        [action.payload.response.item_retail_adjustment.item_retail_adjustment_id]: action.payload.response.item_retail_adjustment
      };
    case actions.DELETE_ITEM_RETAIL_ADJUSTMENT_SUCCESS:
      return Object.keys(state).filter(
        k => !action.payload.deleted.item_retail_adjustments.includes(k)
      ).reduce(
        (o, k) => ({
          ...o,
          [k]: state[k]
        }),
        {}
      );
  }
  return state;
};

const isDeletedLocation = ({ item_id, item_location_id }, item) => {
  return item && item.item_locations && item_location_id && (
    item_id === item.item_id && !_.find(item.item_locations, { item_location_id })
  );
};

export const itemLocationReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.ADD_ITEM_SUCCESS:
      const option_locations = Object.assign.apply(null, [{}].concat((action.payload.item.options || []).map(i => i.item_locations.reduce((o, l) => {o[l.item_location_id] = l; return o;}, {}))));
      return Object.assign({}, state, (action.payload.item.item_locations || []).reduce((o, l) => {o[l.item_location_id] = l; return o;}, {}), option_locations);
    case actions.UPDATE_ITEM_LOCATION_REQUEST:
      // fall through
    case actions.UPDATE_ITEM_LOCATION_SUCCESS:
      // fall through
    case actions.UPDATE_ITEM_LOCATION_FAILURE:
      const item_location_id = action.payload.id;
      const updated_item_location = Object.assign({}, state[item_location_id], action.payload.response && action.payload.response.item_location ? action.payload.response.item_location : action.payload.data);
      return Object.assign({}, state, {[item_location_id]: updated_item_location});
    case actions.ADD_ITEM_LOCATION_SUCCESS:
      return {
        ...omitBy(state, (il) => {
          return isDeletedLocation(il, action.payload.item);
        }),
        ...(action.payload.item_locations.reduce((o, il) => ({ ...o, [il.item_location_id]: { ...state[il.item_location_id], ...il } }), {})),
        ...(action.payload.item_location ? { [action.payload.item_location.item_location_id]: action.payload.item_location } : {})
      };
    case actions.DELETE_ITEM_LOCATION_SUCCESS:
      return Object.keys(state).filter(k => !action.payload.deleted.item_locations.includes(k)).reduce((o, k) => ({ ...o, [k]: state[k] }), {});
    case ADD_ORDER_SUCCESS:
      if(action.payload.data.order.items){
        let item_locations = {};
        action.payload.data.order.items.forEach(i => i.item_locations.forEach(il => { item_locations[il.item_location_id] = il; }));
        return Object.assign({}, state, item_locations);
      }
      return state;
    case actions.REORDER_ITEM_LOCATION_SUCCESS:
      // update my display_order
      const old_order = state[action.payload.id].display_order;
      const new_order = action.payload.data.display_order;
      if (new_order > old_order) {
        // if increase display order, same order items with higher than original display_order but <= new display order decrease by 1
        return Object.assign({}, state,
          { [action.payload.id] : Object.assign({}, state[action.payload.id], {display_order: new_order}) },
          Object.values(state).filter(i => i.item_id === action.payload.response.item.item_id && i.display_order > old_order && i.display_order <= new_order).map(i =>
            Object.assign({}, i, { display_order: parseInt(i.display_order, 10) - 1 })
          ).reduce((o, i) => { o[i.item_location_id] = i; return o; }, {})
        );
      } else {
        // if decreased display order, same order items lower than original display_order but >= new display order increase by 1
        return Object.assign({}, state,
          { [action.payload.id] : Object.assign({}, state[action.payload.id], {display_order: new_order}) },
          Object.values(state).filter(i => i.item_id === action.payload.response.item.item_id && i.display_order < old_order && i.display_order >= new_order).map(i =>
            Object.assign({}, i, { display_order: parseInt(i.display_order, 10) + 1 })
          ).reduce((o, i) => { o[i.item_location_id] = i; return o; }, {})
        );
      }
    case LOAD_ORDER_SUCCESS:
      return Object.assign({}, state, ...(action.payload.order.items.map(i => i.item_locations.reduce((o, il) => {
          o[il.item_location_id] = il;
          return o;
        }, {})
      )));
    case actions.UPDATE_ITEM_SUCCESS:
      if (action.payload.field === 'is_3rd_party_decorator') {
        const { id, response: { item: { item_locations } } } = action.payload;
        if (item_locations.length === 0) {
          return omitBy(state, (v, k) => v.item_id === id);
        }
        return {
          ...state,
          ...item_locations.map((il) => ({[il.item_location_id]: il}))
        };
      }

      return state;
  }
  return state;
};

export const itemBreakdownReducer = (state = {}, action) => {
  const finalize = actions.UPDATE_ITEM_BREAKDOWN_REQUEST !== action.type;
  switch (action.type) {
    case actions.COMPLEX_UPDATE_BREAKDOWN_REQUEST:
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], ...action.payload.data }
      };
    case actions.COMPLEX_UPDATE_BREAKDOWN_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
           ...state[action.payload.id],
           ...(action.payload.response.breakdown ?? action.payload.data)
        }
      };
    case actions.COMPLEX_UPDATE_BREAKDOWN_FAILURE:
      return {
        ...state,
        [action.payload.id]: { ...state[action.payload.id], ...action.payload.data }
      };
    case actions.ADD_ITEM_SUCCESS:
      const option_breakdowns = Object.assign.apply(null, [{}].concat((action.payload.item.options || []).map(i => i.breakdowns.reduce((o, b) => {o[b.breakdown_id] = b; return o;}, {}))));
      return Object.assign({}, state, (action.payload.item.breakdowns || []).reduce((o, b) => {o[b.breakdown_id] = b; return o;}, {}), option_breakdowns);
    case actions.UPDATE_ITEM_SUCCESS:
      return {
        ...state,
        ...(((action.payload.response || {}).item || {}).breakdowns || []).reduce(
          (o, b) => ({
            ...o,
            [b.breakdown_id]: { ...state[b.breakdown_id], ...b }
          }),
          {}
        ),
        ...(((action.payload.response || {}).item || {}).options || []).reduce(
          (o, i) => ({
            ...o,
            ...(i.breakdowns || []).reduce(
              (o2, b) => ({
                ...o2,
                [b.breakdown_id]: { ...state[b.breakdown_id], ...b }
              }),
              {}
            )
          }),
          {}
        )
      };
    case actions.UPDATE_ITEM_BREAKDOWN_REQUEST:
      // fall through
    case actions.UPDATE_ITEM_BREAKDOWN_FAILURE:
      return {
        ...state,
        [action.payload.id]: updateBreakdown(state[action.payload.id], action.payload.data, finalize, action.payload.response, action.payload.extra_data.field_lock || 'unit_cost', action.payload.extra_data.exchange_rate || 1.0)
      };
    case actions.UPDATE_ITEM_BREAKDOWN_SUCCESS:
      return {
        ...state,
        ...(action.payload.response.item.breakdowns || []).filter(
          b => !!state[b.breakdown_id] && b.breakdown_id !== action.payload.id
        ).reduce(
          (o, b) => ({
            ...o,
            // This is because changing the quantity on a breakdown can affect the unit cost of other breakdowns on the same item
            [b.breakdown_id]: 'quantity' === action.payload.field ? updateBreakdown(state[b.breakdown_id], { only_unit_cost: b.unit_cost }, finalize, null, action.payload.extra_data.field_lock || 'unit_cost', action.payload.extra_data.exchange_rate || 1.0) : state[b.breakdown_id]
          }),
          {}),
        [action.payload.id]: updateBreakdown(state[action.payload.id], action.payload.data, finalize, action.payload.response, action.payload.extra_data.field_lock || 'unit_cost', action.payload.extra_data.exchange_rate || 1.0)
      };
    case actions.ADD_ITEM_BREAKDOWN_SUCCESS:
      return {
        ...state,
        ...action.payload.item.breakdowns.reduce(
          (o, b) => ({
            ...o,
            [b.breakdown_id]: { ...state[b.breakdown_id], ...b }
          }),
          {}),
        [action.payload.breakdown.breakdown_id]: action.payload.breakdown
      };
    case actions.DELETE_ITEM_BREAKDOWN_REQUEST:
      // fall through
    case actions.DELETE_ITEM_BREAKDOWN_SUCCESS:
      return {
        ...(
          Object.keys(state).filter(
            k => k !== action.payload.breakdown_id
          ).reduce(
            (o, k) => ({
              ...o,
              [k]: state[k]
            }),
            {}
          )
        ),
        ...((action.payload.item || {}).breakdowns || []).filter(b => !!state[b.breakdown_id]).reduce(
          (o, b) => ({
            ...o,
            [b.breakdown_id]: { ...state[b.breakdown_id], ...b }
          }),
          {}
        )
      };
    case ADD_ORDER_SUCCESS:
      if(action.payload.data.order.items){
        let breakdowns = {};
        action.payload.data.order.items.forEach(i => i.breakdowns.forEach(b => { breakdowns[b.breakdown_id] = b; }));
        return Object.assign({}, state, breakdowns);
      }
      return state;
    case actions.REORDER_ITEM_BREAKDOWN_SUCCESS:
      // update my display_order
      const old_order = _.get(state, [action.payload.id, 'display_order'], 0);
      const new_order = action.payload.data.display_order;
      if (new_order > old_order) {
        // if increase display order, same order items with higher than original display_order but <= new display order decrease by 1
        return Object.assign({}, state,
          { [action.payload.id] : Object.assign({}, state[action.payload.id], {display_order: new_order}) },
          Object.values(state).filter(i => i.item_id === action.payload.response.item.item_id && i.display_order > old_order && i.display_order <= new_order).map(i =>
            Object.assign({}, i, { display_order: parseInt(i.display_order, 10) - 1 })
          ).reduce((o, i) => { o[i.breakdown_id] = i; return o; }, {})
        );
      } else {
        // if decreased display order, same order items lower than original display_order but >= new display order increase by 1
        return Object.assign({}, state,
          { [action.payload.id] : Object.assign({}, state[action.payload.id], {display_order: new_order}) },
          Object.values(state).filter(i => i.item_id === action.payload.response.item.item_id && i.display_order < old_order && i.display_order >= new_order).map(i =>
            Object.assign({}, i, { display_order: parseInt(i.display_order, 10) + 1 })
          ).reduce((o, i) => { o[i.breakdown_id] = i; return o; }, {})
        );
      }
    case LOAD_ORDER_SUCCESS:
      return Object.assign({}, state, ...(action.payload.order.items.map(i => i.breakdowns.reduce((o, ib) => {
          o[ib.breakdown_id] = ib;
          return o;
        }, {})
      )));
  }
  return state;
};

const updateBreakdown = (breakdown, data, finalize = false, response = null, field_lock = 'unit_cost', exchange_rate = 1.0) => {
  let result = Object.assign({}, breakdown);
  const fields = Object.keys(data);
  fields.forEach(field => {
    switch (field) {
      case 'only_unit_cost':
        result.unit_cost = data[field];
        result.unit_price = parseFloat(breakdown.unit_price);
        result.breakdown_margin = recalculateMargin(result.unit_cost, result.unit_price, exchange_rate);
        break;
      case 'unit_cost':
        result.unit_cost = data[field];
        if ('unit_cost' === field_lock) {
          result.unit_price = applyMargin(result.unit_cost, breakdown.breakdown_margin, exchange_rate);
          result.unit_price = parseFloat(result.unit_price).toFixed(2);
        }
        result.breakdown_margin = recalculateMargin(result.unit_cost, result.unit_price, exchange_rate);
        result.breakdown_margin = parseFloat(result.breakdown_margin).toFixed(2);
        break;
      case 'breakdown_margin':
        if ('unit_price' === field_lock) {
          result.unit_cost = parseFloat(determineCost(result.unit_price, data[field], exchange_rate)).toFixed(4);
        } else {
          result.unit_price = parseFloat(applyMargin(result.unit_cost, data[field], exchange_rate)).toFixed(2);
        }
        if (finalize) {
          result.breakdown_margin = recalculateMargin(result.unit_cost, result.unit_price, exchange_rate);
        } else {
          result.breakdown_margin = data[field];
        }
        break;
      case 'unit_price':
        result.unit_price = data[field];
        result.breakdown_margin = recalculateMargin(result.unit_cost, result.unit_price, exchange_rate);
        result.breakdown_margin = parseFloat(result.breakdown_margin).toFixed(2);
        break;
      case 'product_sku_id':
        result[field] = data[field];
        if (response) {
          result.sku = response.breakdown.sku;
          result.quantity = response.breakdown.quantity;
          result.unit_cost = response.breakdown.unit_cost;
          result.unit_price = response.breakdown.unit_price;
          result.breakdown_margin = response.breakdown.breakdown_margin;
        }
        break;
      case 'quantity':
        result[field] = data[field];
        if (response) {
          result.unit_cost = response.breakdown.unit_cost;
          result.unit_price = response.breakdown.unit_price;
          result.breakdown_margin = recalculateMargin(result.unit_cost, result.unit_price, exchange_rate);
          result.unit_price = parseFloat(result.unit_price).toFixed(2);
          result.breakdown_margin = parseFloat(result.breakdown_margin).toFixed(2);
        }
        break;
      default:
        result[field] = data[field];
        break;
    }
  });
  if (finalize) {
    result.quantity = parseFloat(result.quantity).toFixed(0);
    result.unit_cost = parseFloat(result.unit_cost).toFixed(4);
    result.unit_price = parseFloat(result.unit_price).toFixed(2);
    result.breakdown_margin = parseFloat(result.breakdown_margin).toFixed(2);
  }
  return result;
};

const calculateTaxAmounts = (state, payload, items) => {
  if (!payload) {
    return state;
  }
  const { order, item } = payload;
  const item_tax_amounts = _.get(item, 'tax_amounts');
  const order_items = _.get(order, 'items');
  const override_item = !_.isUndefined(item_tax_amounts);
  const override_order = !_.isUndefined(order_items);
  // only rewrite tax_amounts when got response from order.renderFull()
  const tax_amounts = pickBy(state, (ta) => {
    const { item_id, order_id } = _.get(items, [ta.item_id || ta.parent_id]) || {};
    return !(
      // exclude tax_amounts on same item
      (override_item && item_id === item.item_id) ||
      // exclude tax_amounts on same order
      (override_order && order_id === order.order_id)
    );
  });

  // update existing tax_amounts with item.tax_amounts and order.items.tax_amounts
  _.map(
    _.concat(
      _.values(item_tax_amounts),
      ..._.filter(_.map(order_items, 'tax_amounts')),
    ),
    ({ tax_amount_id, tax_id, amount, parent_id, item_id }) => {
      tax_amounts[tax_amount_id] = {
        tax_amount_id, tax_id, amount, item_id: parent_id || item_id, parent_id: parent_id || item_id
      };
    }
  );
  return tax_amounts;
};

export const itemTaxAmountReducer = (state = {}, action, extra_data = {}) => {
  const { items } = extra_data;
  const payload = action.payload;
  let tax_amounts = {};
  let tax_amount;
  switch (action.type) {
    case actions.ADD_ITEM_SUCCESS:
      const option_tax_amounts = _.assign.apply(null, _.map(
        _.get(payload, 'item.options'), i => {
          return _.keyBy(i.tax_amounts, 'tax_amount_id');
        }
      ));
      tax_amounts = _.chain(payload).get('item.tax_amounts')
        .map((t) => ({
          ...t,
          item_id: t.parent_id,
        }))
        .keyBy('tax_amount_id').value();

      return {
        ...state,
        ...tax_amounts,
        ...option_tax_amounts,
      };
    case actions.UPDATE_ITEM_COST_SUCCESS:
      // fall through
    case actions.UPDATE_ITEM_LOCATION_SUCCESS:
      // This is the case for local changes
      if (!payload.response) {
        return state;
      }
      // fall through
    case actions.UPDATE_ITEM_BREAKDOWN_SUCCESS:
      return calculateTaxAmounts(state, payload.response, items);
    case actions.ADD_ITEM_LOCATION_SUCCESS:
      // fall through
    case actions.DELETE_ITEM_LOCATION_SUCCESS:
      // fall through
    case actions.ADD_ITEM_COST_SUCCESS:
      // fall through
    case actions.DELETE_ITEM_COST_SUCCESS:
      // fall through
    case actions.ADD_ITEM_BREAKDOWN_SUCCESS:
      // fall through
    case actions.DELETE_ITEM_BREAKDOWN_SUCCESS:
      return Object.assign({}, state, (payload.item.tax_amounts || []).reduce((o, t) => {
        o[t.tax_amount_id] = Object.assign({}, state[t.tax_amount_id], {
          tax_amount_id: t.tax_amount_id,
          tax_id: t.tax_id,
          amount: t.amount,
          item_id: t.parent_id
        });
        return o;
      }, {}));
    case actions.UPDATE_ITEM_SUCCESS:
      return payload.response ? {
        ...Object.values(state).filter(
          ta => ta.parent_id !== action.payload.id
        ).reduce(
          (o, ta) => ({ ...o, [ta.tax_amount_id]: ta }),
          {}
        ),
        ..._.keyBy(
          _.map(_.get(payload, 'response.item.tax_amounts'), (ta) => {
            if (!ta.item_id && ta.parent_type === 'ITEM') {
              return {
                ...ta,
                item_id: ta.parent_id,
              };
            }
            return ta;
          }),
          'tax_amount_id'
        )
      } : state;
    case actions.DELETE_ITEM_REQUEST:
      // fall through
    case actions.DELETE_ITEM_SUCCESS:
      return _.omitBy(state, (taxAmount) => taxAmount.item_id === payload.item_id);
    case actions.UPDATE_ITEM_TAX_AMOUNT_SUCCESS:
      tax_amount = payload.tax_amount || payload.response.tax_amount;
      return _.merge({}, state, {[tax_amount.tax_amount_id]: tax_amount});
    case actions.ADD_ITEM_TAX_AMOUNT_SUCCESS:
      tax_amount = payload.tax_amount;
      return _.merge({}, state, {[tax_amount.tax_amount_id]: {
        ...tax_amount,
        item_id: tax_amount.parent_id
      }});
    case actions.DELETE_ITEM_TAX_AMOUNT_REQUEST:
      // fall through
    case actions.DELETE_ITEM_TAX_AMOUNT_SUCCESS:
      return _.omitBy(state, (taxAmount) => {
        return taxAmount.tax_amount_id === payload.tax_amount_id;
      });
    case ADD_ORDER_SUCCESS:
      if(payload.data.order.items){
        tax_amounts = {};
        payload.data.order.items.forEach(i => {
          i.tax_amounts.forEach(t => { tax_amounts[t.tax_amount_id] = t; });
        });
        return Object.assign({}, state, tax_amounts);
      }
      return state;
    case UPDATE_ORDER_TAX_ID_SUCESS:
      tax_amounts = {};
      const itemsIds = _.map(_.get(payload, 'data.order.items'), (item) => {
        _.each(item.tax_amounts, ({
          tax_amount_id, tax_id, amount
        }) => {
          tax_amounts[tax_amount_id] = {
            tax_amount_id, tax_id, amount,
            item_id: item.item_id,
          };
        });
        return item.item_id;
      });
      return Object.assign({}, _.omitBy(state, (taxAmount) => {
        return itemsIds.indexOf(taxAmount.item_id) > -1;
      }), tax_amounts);
    case LOAD_ORDER_SUCCESS:
      return Object.assign({}, state, ...(action.payload.order.items.map(i => i.tax_amounts.reduce((o, ta) => {
          o[ta.tax_amount_id] = ta;
          return o;
        }, {})
      )));
    case UPDATE_ORDER_SUCCESS:
      return calculateTaxAmounts(state, payload.response, items);
    case CALCULATE_AVALARA_TAX:
      return calculateTaxAmounts(state, payload, items);
  }
  return state;
};

export const commentReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.ADD_ITEM_COMMENT_SUCCESS:
      return Object.assign({}, state, action.payload.comment, {[action.payload.comment.presentation_comment_id]: action.payload.comment});
    case actions.DELETE_ITEM_COMMENT_SUCCESS:
      return Object.keys(state).filter(k => k !== action.payload.presentation_comment_id).reduce((o, k) => { o[k] = state[k]; return o; }, {});
    case actions.DELETE_ITEM_SUCCESS:
      return Object.keys(state).filter(k => state[k].presentation_item_id !== action.payload.item_id).reduce((o, k) => { o[k] = state[k]; return o; }, {});
    case LOAD_ORDER_SUCCESS:
      return Object.assign({}, state, action.payload.order.comments.reduce((o, c) => {
        o[c.presentation_comment_id] = c;
        return o;
      }, {}));
  }
  return state;
};

export const sizeReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.ADD_ITEM_SUCCESS:
      const item = action.payload.item;
      const sizes = (item.sizes || []).concat(
        item.options ? item.options[0].sizes : []
      ).concat(
        (item.item_sizes || []).map(c => ({ size_id: c.size_id, size_name: c.size_name }))
      ).concat(
        (item.breakdowns || []).map(b => ({ size_id: b.size_id, size_name: b.size }))
      ).map(
        c => Object.assign({}, c, {product_id: item.parent_id})
      );
      return Object.assign({}, state, sizes.reduce((o, c) => { o[c.size_id] = c; return o; }, {}));
    case actions.ADD_BREAKDOWN_SIZE_SUCCESS:
      return Object.assign({}, state, {[action.payload.productsize.size_id]: action.payload.productsize});
    case actions.ADD_ITEM_SIZE_SUCCESS:
      return Object.assign({}, state, action.payload.item_size.reduce((o, s) => { o[s.size_id] = { size_id: s.size_id, size_name: s.size_name, product_id: 'NONE' }; return o; }, {}));
  }
  return state;
};

export const skuReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.ADD_ITEM_SUCCESS:
      return { ...state, ...(action.payload.item.skus || []).reduce((o, s) => ({ ...o, [s.product_sku_id]: s }), {}) };
    case FETCH_PRODUCT_DETAIL_SUCCESS:
      return { ...state, ...(action.payload.product.skus || []).reduce((o, s) => ({ ...o, [s.product_sku_id]: s }), {}) };
  }
  return state;
};

export const colorReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.ADD_ITEM_SUCCESS:
      const item = action.payload.item;
      const colors = (item.colors || []).concat(
        item.options ? item.options[0].colors : []
      ).concat(
        (item.item_colors || []).map(c => ({ color_id: c.color_id, color_name: c.color_name }))
      ).concat(
        (item.breakdowns || []).map(b => ({ color_id: b.color_id, color_name: b.color }))
      ).map(
        c => Object.assign({}, c, {product_id: item.parent_id})
      );
      return Object.assign({}, state, colors.reduce((o, c) => { o[c.color_id] = c; return o; }, {}));
    case actions.ADD_BREAKDOWN_COLOR_SUCCESS:
      return Object.assign({}, state, {[action.payload.productcolor.color_id]: action.payload.productcolor});
    case actions.ADD_ITEM_COLOR_SUCCESS:
      return Object.assign({}, state, action.payload.item_color.reduce((o, s) => { o[s.color_id] = { color_id: s.color_id, color_name: s.color_name, product_id: 'NONE' }; return o; }, {}));
  }
  return state;
};

export const itemImageReducer = (state = {}, action) => {
  switch (action.type) {
    case ADD_ORDER_SUCCESS:
      return { ...state, ...((action.payload.data.order.items || []).reduce((o, i) => ({ ...o, ...(i.item_images.reduce((o2, ii) => ({ [ii.item_image_id]: ii }), {})) }), {})) };
    case actions.ADD_ITEM_IMAGE_SUCCESS:
      return {
        ...state,
        ...(action.payload?.item_images ?? []).reduce(
          (o, ii) => ({ ...o, [ii.item_image_id]: ii }),
          {}
        )
      };
    case actions.DELETE_ITEM_IMAGE_SUCCESS:
      return Object.keys(state).filter(k => k !== action.payload.item_image_id).reduce((o, k) => { o[k] = state[k]; return o; }, {});
    case actions.UPDATE_ITEM_IMAGE_SUCCESS:
      return { ...state, [action.payload.item_image.item_image_id]: { ...state[action.payload.item_image.item_image_id], ...action.payload.item_image }, ...action.payload.item_images.reduce((o, ii) => { o[ii.item_image_id] = ii; return o; }, {}) };
    case actions.ADD_ITEM_SUCCESS:
      return { ...state, ...((action.payload.item.item_images || []).reduce((o, ii) => ({ ...o, [ii.item_image_id]: ii }), {})) };
    case actions.UPDATE_POPUP_IMAGE_REQUEST:
      return {
        ...state,
        [action.payload.file_id]: {
          item_image_id: action.payload.file_id,
          ...action.payload,
          display_order: -1
	}
      };
    case actions.UPDATE_POPUP_IMAGE_FAILURE:
      return Object.values(state).filter(ii => ii.item_image_id !== action.payload.file_id).reduce(
        (o, ii) => ({ ...o, [ii.item_image_id]: ii }),
         {}
      );
    case actions.UPDATE_POPUP_IMAGE_SUCCESS:
      return Object.values(state).filter(ii => ii.item_id !== action.payload.item_id).reduce(
        (o, ii) => ({ ...o, [ii.item_image_id]: ii }),
        action.payload.item_images.reduce(
          (o, ii) => ({ ...o, [ii.item_image_id]: ii }),
          {}
	)
      );
    case LOAD_ORDER_SUCCESS:
      return Object.assign({}, state, ...(action.payload.order.items.map(i => i.item_images.reduce((o, ii) => {
          o[ii.item_image_id] = ii;
          return o;
        }, {})
      )));
  }
  return state;
};

export const itemWarningReducer = (state = {}, action) => {
  switch (action.type) {
    case actions.ADD_ITEM_WARNING_SUCCESS:
      return { ...state, [action.payload.item_warning.item_warning_id]: action.payload.item_warning };
    case actions.UPDATE_ITEM_WARNING_SUCCESS:
      return { ...state, [action.payload.id]: { ...state[action.payload.id], ...action.payload.data } };
    case actions.DELETE_ITEM_WARNING_SUCCESS:
      return Object.keys(state).filter(k => k !== action.payload.item_warning_id).reduce((o, k) => { o[k] = state[k]; return o; }, {});
    case actions.ADD_ITEM_SUCCESS:
      return { ...state, ...((action.payload.item.item_warnings || []).reduce((o, iw) => ({ ...o, [iw.item_warning_id]: iw }), {})) };
    case actions.UPDATE_ITEM_SUCCESS:
      const warnings = _.get(action.payload, 'response.item.item_warnings') || [];
      return {
        ...state,
        ...(warnings.reduce((o, iw) => ({ ...o, [iw.item_warning_id]: iw }), {}))
      };
    case ADD_ORDER_SUCCESS:
      return { ...state, ...((action.payload.data.order.items || []).reduce((o, i) => ({ ...o, ...(i.item_warnings.reduce((o2, iw) => ({ [iw.item_warning_id]: iw }), {})) }), {})) };
    case LOAD_ORDER_SUCCESS:
      return Object.assign({}, state, ...(action.payload.order.items.map(i => i.item_warnings.reduce((o, iw) => {
          o[iw.item_warning_id] = iw;
          return o;
        }, {})
      )));
  }
  return state;
};

export default itemReducer;
