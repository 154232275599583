import { LOAD_FOLDERS_LIST_SUCCESS } from '../actions/folders';

const reducer = (state = {}, action) => {
    switch (action.type) {
        case LOAD_FOLDERS_LIST_SUCCESS:
            return (action.payload.folders || []).reduce((o, f) => {o[f.folder_id] = f; return o;}, {});
    }
    return state;
};

export default reducer;
