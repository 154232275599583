import {
  each,
  parseInt,
  get,
  map,
  find,
  uniq,
  filter,
  flatten,
  uniqBy,
  keyBy,
  mapValues,
  size,
  orderBy,
  isEqual,
  isEmpty,
  groupBy,
  first,
  keys, max, findIndex, some
} from 'lodash';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Col, ShowPopup, Popup, Table, TBody, TR, TD as BaseTD, TH as BaseTH, Row, H4, Button, colors, fonts, Theme, Select
} from '@commonsku/styles';

import { createUpdateBreakdown } from '../actions';
import { getInventoryLevels, getProducts } from '../promostandards';
import { getImageSrc } from '../utils';
import { getBreakdownOptionDescription } from '../utils/order';
import Img from './Img';
import Loading from './Loading';
import { useSize } from '../hooks/useSize';

const styles = {
  selected: {
    color: get(colors, 'primary1.main'),
    backgroundColor:  'transparent',
  },
  clickable: {
    color: get(colors, 'primary1.main'),
    backgroundColor:  get(colors, 'primary1.20'),
    borderColor: get(colors, 'primary1.20'),
  },
  disabled: {
    color: get(colors, 'neutrals.70'),
    backgroundColor: get(colors, 'neutrals.50'),
    borderColor: get(colors, 'neutrals.50'),
  },
  error: {
    color: get(colors, 'errors.main'),
  },
  warehouseTd: {
    padding: '0.5625rem',
    textAlign: 'center',
    verticalAlign: 'top',
    fontSize: '0.7rem',
  }
};

const TD = React.forwardRef((props, ref) => {
  return <BaseTD ref={ref} bg="white" {...props} />;
});

const TH = (props) => {
  return <BaseTH bg="white" {...props} />;
};

const matchFobWarehouse = (fob, warehouse) => {
  return fob && warehouse && (
    (fob.ps_fob_id && fob.ps_fob_id === warehouse.inventory_location_id) ||
    (fob.postal_code && fob.postal_code === warehouse.postal_code) ||
    (
      warehouse.inventory_location_name &&
      ([fob.postal_code, fob.city, fob.state].indexOf(warehouse.inventory_location_name) > -1)
    )
  );
};

const hasConfiguredItems = (items) => {
  return some(items ?? [], (item) => {
    return !isEmpty(item?.item_locations) || !isEmpty(item?.item_costs);
  });
};

const ItemWarehouses = ({ item, fobs, readonly=false, first=false, onChange }) => {
  const dispatch = useDispatch();
  const [inventory, setInventory] = useState(null);
  const [firstColumnRef, { firstColumnWidth }] = useSize();
  const { item_name, item_images, breakdowns } = item;
  const [itemWarehouse, setItemWarehouse] = useState({});
  const quirk = useShouldShowWarehouse([item]);
  const { check_dropship, blank_only_warehouses } = quirk || {};
  const stickyStyle = { position: 'sticky', left: 0, zIndex: 10, backgroundColor: '#FFFFFF' };
  const hasConfigured = hasConfiguredItems([item]);

  const updateInventoryLocation = (breakdown_id, { ext_inventory_location_id, inventory_location_name, ext_fob_id }) => {
    setItemWarehouse({
      ...itemWarehouse,
      [breakdown_id]: ext_inventory_location_id,
    });
    onChange(breakdown_id, { ext_fob_id, ext_inventory_location_id, inventory_location_name, });
  };

  useEffect(() => {
    setItemWarehouse(mapValues(keyBy(breakdowns, 'breakdown_id'), 'ext_inventory_location_id'));
  }, [breakdowns]);

  useEffect(() => {
    if (fobs.length > 0) {
      getInventoryLevels({ productId: item.ext_product_id }).then((results) => {
        const inv = keyBy(results, 'ps_part_id');
        map(breakdowns, ({ breakdown_id, sku, ext_inventory_location_id }) => {
          if (ext_inventory_location_id && get(
            find(
              get(inv, [sku, 'inventory_locations'], []),
              { inventory_location_id: ext_inventory_location_id }
            ),
            'inventory_location_quantity.value'
          ) === 0) {
            const data = {ext_fob_id: '', ext_inventory_location_id: '', inventory_location_name: ''};
            updateInventoryLocation(breakdown_id, data);
            dispatch(createUpdateBreakdown(breakdown_id, data));
          }
        });
        setInventory(inv);
      });
    }
  }, [fobs]);

  const warehouses = filter(
    uniqBy(
      flatten(map(inventory, ({ inventory_locations }) => {
        return inventory_locations;
      })),
      'inventory_location_id'
    ),
    (warehouse) => {
      return findIndex(blank_only_warehouses, (fobId) => {
        return `${fobId}` === `${warehouse.inventory_location_id}`;
      }) < 0 || !hasConfigured;
    }
  );
  const warehousesFobs = {};
  map(warehouses, (warehouse) => {
    const fob = find(fobs, (fob) => {
      return matchFobWarehouse(fob, warehouse);
    });
    if (fob) {
      warehousesFobs[warehouse.inventory_location_id] = fob;
    }
  });

  const imageWidth = 200;
  const fontSize = '0.7rem';
  const orderedWarehouses = orderBy(
    map(warehouses, ({ inventory_location_name, inventory_location_id }, key) => {
      const fob = get(warehousesFobs, inventory_location_id);
      return {
        key, inventory_location_id, inventory_location_name,
        state: get(fob, 'state') || get(fob, 'ps_fob_id'), dropship: check_dropship && !fob
      };
    }),
    ['dropship', 'inventory_location_name']
  );
  return <TBody style={{
    border: 'none',
    borderTop: first ? 'none' : `1px solid ${get(colors, 'neutrals.50')}`,
  }}>
    <TR>
      <td ref={firstColumnRef} rowSpan={2} style={{ ...stickyStyle, textAlign: 'center' }}>
        <Img style={{ maxWidth: imageWidth, maxHeight: 130 }} src={getImageSrc(find(item_images))} />
      </td>
      <TH colSpan={8} style={{
        ...stickyStyle, left: firstColumnWidth, paddingTop: 20, textAlign: 'left', verticalAlign: 'top'
      }}>{item_name}</TH>
    </TR>
    <TR>
      {map(
        orderedWarehouses,
        ({ key, inventory_location_name, state, dropship }) => {
          return <TD key={key} style={styles.warehouseTd}>
            <div style={{ width: 70, margin: '0 auto' }}>
              {inventory_location_name} ({ dropship ? 'Non-warehouse' : state })
            </div>
          </TD>;
        }
      )}
    </TR>
    {map(breakdowns, (breakdown, i) => {
      const { sku, breakdown_id } = breakdown;
      return <TR key={i}>
        <TD style={{ textAlign: 'center', ...stickyStyle }}>
          <div style={{
            maxWidth: imageWidth, paddingLeft: '1rem', fontSize, margin: '0 auto',
            ...get(itemWarehouse, breakdown_id) ? {} : styles.error
          }}>
            <div>{getBreakdownOptionDescription(breakdown, item)} {breakdown.sku}</div>
            <div style={{ fontWeight: 'bold' }}>({parseInt(breakdown.quantity)} required)</div>
          </div>
        </TD>
        {!inventory ? <TD><Loading/></TD> : map(
          orderedWarehouses,
          ({ inventory_location_id, inventory_location_name }) => {
            const ilq = get(
              find(get(inventory, [sku, 'inventory_locations'], []), { inventory_location_id }),
              'inventory_location_quantity'
            );
            const fob = get(warehousesFobs, inventory_location_id);
            const selected = get(itemWarehouse, breakdown_id) === inventory_location_id;
            const disabled = !(get(ilq, 'value') > 0) || readonly;
            const radio = <Button
              style={{
                minWidth: 55, minHeight: 55, padding: '12px 0', fontWeight: 'bold', borderRadius: 10,
                ...(disabled ? styles.disabled : (selected ? styles.selected : styles.clickable))
              }}
              disabled={disabled}
              onClick={() => {
                updateInventoryLocation(breakdown_id, {
                  ext_fob_id: selected ? '' : (get(fob, 'ps_fob_id') || inventory_location_id),
                  ext_inventory_location_id: selected ? '' : inventory_location_id,
                  inventory_location_name: selected ? '' : inventory_location_name,
                });
              }}
            >{ilq ? `${ilq.value } ` + (ilq.uom === 'EA' ? '' : `(${ilq.uom})`) : '0'}</Button>;
            return <TD key={inventory_location_id} style={styles.warehouseTd}>{radio}</TD>;
        })}
      </TR>;
    })}
  </TBody>;
};

export const SelectWarehousePopup = ({
  title = 'Select Warehouse', items, readonly = false, onClose, onSave, groupByShipping = false
}) => {
  const dispatch = useDispatch();
  const [updated, setUpdated] = useState({});
  const [fobs, setFobs] = useState([]);
  items = filter(items, { copied_from: 'ps-products' });
  const itemsByShippingId = !groupByShipping ? {} : groupBy(items, (i) => {
    return get(i, 'vendor_details.shipping_id', '');
  });
  const [shippingId, setShippingId] = useState(first(keys(itemsByShippingId)) || '');

  useEffect(() => {
    const ids = uniq(filter(map(items, 'ext_product_id')));
    if (ids.length > 0) {
      getProducts({ id__in: ids.join(',') })
      .then(({ results }) => {
        const newFobs = uniqBy(flatten(map(results, 'fobs')), 'ps_fob_id');
        if (!isEqual(newFobs, fobs)) {
          setFobs(newFobs);
        }
      });
    }
  }, [items]);

  const canSave = canGeneratePo(items, updated);
  const shippingOptions = map(itemsByShippingId, (group, shipping_id) => {
    return {
      label: map(group, 'item_sku').join(', '),
      value: shipping_id,
    };
  });
  const optionWidth = max(map(shippingOptions, (option) => {
    return size(option.label);
  }));

  return <Theme theme={{
    colors: {
      primary: get(colors, 'primary1.main'),
    },
  }}>
    <Popup
      style={{ fontFamily: fonts.join(',') }} height={900}
      header={
        <Col>
          <Row justify="space-between" wrap="nowrap" style={{
            alignItems: 'flex-start', marginBottom: 20, zIndex: 20, position: 'relative'
          }}>
            <H4>{title}</H4>
            {size(itemsByShippingId) > 1 && <div style={{ width: 300 }}>
              <Select
                style={{ minWidth: optionWidth }}
                value={find(shippingOptions, { value: shippingId })}
                options={shippingOptions}
                onChange={({ value }) => {
                  setShippingId(value);
                }}
                withMarginBottom
              />
            </div>}
            <div>
              <Button mr={5} onClick={onClose}>Close</Button>
              {!readonly && <Button
                style={canSave ? {} : styles.disabled} ml={5} disabled={!canSave}
                onClick={() => {
                  Promise
                    .all(map(updated, (data, breakdown_id) => {
                      return dispatch(createUpdateBreakdown(breakdown_id, data));
                    }))
                    .then(() => {
                      setUpdated({});
                      onSave && onSave();
                      onClose();
                    })
                  ;
                }}
              >Save</Button>}
            </div>
          </Row>
          {!canSave && <Row style={{
            ...styles.error,
            backgroundColor: get(colors, 'errors.lightest'),
            padding: 15,
            borderRadius: 10,
            marginBottom: 20,
          }}>
            To select by warehouse you must select for all products.
          </Row>}
        </Col>
      }
    >
      <div style={{ maxHeight: 590, overflowY: 'auto' }}>
        <Table>
          {map(items, (item, i) => {
            const selectedShipping = size(shippingOptions) <= 1 || get(item, 'vendor_details.shipping_id', '') === shippingId;
            return item.ext_product_id && selectedShipping && <ItemWarehouses
              key={i} item={item} fobs={fobs} readonly={readonly} first={i===0}
              onChange={(breakdown_id, data) => {
                setUpdated({ ...updated, [breakdown_id]: data });
              }}
            />;
          })}
        </Table>
      </div>
    </Popup>
  </Theme>;
};

export const useShouldShowWarehouse = (items) => {
  const quirks = useSelector((state) => get(state, 'entities.promostandards_quirks'));
  const division_id = get(items, '0.vendor_details.source_parent_id') || get(items, '0.division_id');
  let quirk = find(items, { copied_from: 'ps-products' }) && get(quirks, [division_id, 'USE-WAREHOUSE']);

  if (!isEmpty(quirk)) {
    try {
      quirk = JSON.parse(quirk);
    } catch (e) {
      quirk = {required: false};
    }
  }
  return quirk;
};

export const canGeneratePo = (items, updated = {}) => {
  const itemsByShippingId = groupBy(
    filter(items, (i) => {
      return i.copied_from === 'ps-products' && get(i, 'vendor_details.po_submit_method') !== 'email';
    }),
    (i) => {
      return get(i, 'vendor_details.shipping_id', '');
    }
  );
  return !find(itemsByShippingId, (items) => {
    const ext_inventory_location_ids = [];
    each(items, (item) => {
      each(item.breakdowns, (breakdown) => {
        const { ext_inventory_location_id } = {
          ...breakdown,
          ...get(updated, breakdown.breakdown_id) || {}
        };
        ext_inventory_location_ids.push(isEmpty(ext_inventory_location_id));
      });
    });
    return size(uniq(ext_inventory_location_ids)) > 1;
  });
};

const SelectWarehouse = ({ items, readonly, render, title, onSave }) => {
  const shouldShowWarehouse = useShouldShowWarehouse(items);

  return shouldShowWarehouse
    ? <ShowPopup
      popup={SelectWarehousePopup} items={items} readonly={readonly} title={title} onSave={onSave}
      render={render || (({ onClick }) => {
        return <a className="button small" onClick={onClick}>Select Warehouse (Optional)</a>;
      })}
    />
    : null
  ;
};

export const AutoSelectWarehousePopup = ({ items }) => {
  items = filter(items, (item) => {
    const vendor_details = get(item, 'vendor_details') || {};
    return item.copied_from === 'ps-products' && vendor_details.shipping_id && vendor_details.po_submit_method !== 'email';
  });
  const [showPopup, setShowPopup] = useState(false);
  const hasError = !canGeneratePo(items);
  const snapshot = JSON.stringify(mapValues(
    groupBy(items, (i) => {
      return get(i, 'vendor_details.shipping_id', '');
    }),
    (group) => {
      return map(group, 'item_id');
    }
  ));

  useEffect(() => {
    setShowPopup(hasError);
  }, [hasError, snapshot]);

  return showPopup ? <SelectWarehousePopup items={items} groupByShipping={true} onClose={() => {
    setShowPopup(false);
  }}/> : null;
};

export default React.memo(SelectWarehouse);
