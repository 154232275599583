import { debounce } from 'lodash';
import { useRef, useEffect, useMemo } from 'react';

const useDebounce = (callback: (...args: any[]) => any, wait=500) => {
  const ref = useRef(callback);

  useEffect(() => {
    ref.current = callback;
  }, [callback]);

  return useMemo(() => {
    return debounce(
      (...args: any[]) => {
        ref.current?.(...args);
      },
      wait
    );
  }, [wait]);
};

export default useDebounce;
