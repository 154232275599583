import _ from 'lodash';
import React, { Component, useState } from 'react';
import { connect } from 'react-redux';

import ProfileDropdownMenu from '../containers/ProfileDropdownMenu';
import RecentDropdownMenu from '../containers/RecentDropdownMenu';
import ReminderDropdownMenu from '../containers/ReminderDropdownMenu';
import NotificationDropdownMenu from '../containers/NotificationDropdownMenu';
import MessageCallout from '../containers/MessageCallout';
import GlobalSearch from '../containers/GlobalSearch';
import CskuMenu from '../containers/CskuMenu';
import EventsReminder from './community-events/EventsReminder';

import { createFetchBanner } from '../actions/display';
import { createEditCompanyShippingAndBillingPopup, } from '../actions/popup';
import NewProjectPopup from './project/NewProjectPopup';
import { getIdentityUtils, oauth } from '../utils';
import { window } from '../global';

import { WebsocketContextProvider } from '../context/websocket-context';
import ChatAgentPortal from '../components/chat/ChatAgentPortal';
import VerifyEmailPopup from './email-verification';
import AssumeIdentity from './AssumeIdentity';
import SanmarModal from './SanmarModal';
import config from '../config';

const REACT_VERSION = React.version;

const StripeBanner = () => {
  const [show, setShow] = useState(true);
  const size = 26;
  return show && <div className="banner-message" style={{
    backgroundColor: '#fcdbd7', position: 'fixed', top: 0, left: 0, paddingLeft: '12rem', zIndex: 1000
  }}>
    <a href="/admin_settings_new.php?tab=billing" target="_blank" style={{
      color: '#eb5846', display: 'inline-block', marginRight: 48, textAlign: 'left', border: 'none',
    }}>
      Oops! There’s a problem with your company credit card - you might have hit the maximum limit, or your card has been replaced and needs to be updated.
      If payment hasn't gone through over the month, access will be temporarily turned off (and we don’t want to do that!).
      Please notify your company administrator, or if that's you, click here to update your credit card details.
      Thanks!
    </a>
    <span
      style={{
        display: 'inline-block', width: size, height: size,
        position: 'absolute', top: 0, right: size,
        fontSize: size, cursor: 'pointer'
      }}
      onClick={() => {
        setShow(false);
      }}
    >&times;</span>
  </div>;
};

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      show_menu: false,
      show_profile_options: false,
      show_new_project_popup: false,
      new_project_client_id: null,
    };

    _.each(['handleClickMenu', 'resizeSpaceHolder', 'handleNewProjectEvent'], (func) => {
      this[func] = this[func].bind(this);
    });
  }

  resizeSpaceHolder() {
    if (this.header && this.spaceHolder) {
      this.spaceHolder.style.height = this.header.clientHeight + 'px';
    }
  }

  componentDidMount() {
    window.addEventListener('resize', this.resizeSpaceHolder);
    window.addEventListener('startNewProject', this.handleNewProjectEvent);
    this.resizeSpaceHolder();

    this.props.onCreateFetchBanner();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeSpaceHolder);
    window.removeEventListener('startNewProject', this.handleNewProjectEvent);
  }

  componentDidUpdate() {
    this.resizeSpaceHolder();
  }

  handleClickMenu(e) {
    e.stopPropagation();
    this.setState({ show_menu: !this.state.show_menu });
  }

  handleNewProjectEvent(e) {
    const client_id = _.get(e, ['detail', 'client_id']);
    this.setState({ show_new_project_popup: true, new_project_client_id: client_id });
  }

  renderMiniChatBot() {
    const { identity } = this.props;

    if (!window || window.location.pathname.substr(1) === 'chat.php') return null;

    return (
      <WebsocketContextProvider
          identity={identity}
          channel={identity.user_id}
          tenantOptions={{ chat_welcome_message: 'default' }}
          useChatSession={false}
      >
          <ChatAgentPortal identity={identity} minimal={true} />
      </WebsocketContextProvider>
    );
  }

  render() {
    const {
      children,
      messages,
      identity,
      menu,
      trial_end_date,
      application_setting,
      stripe_unpaid,
      set_up,
      headerContainerRef,
    } = this.props;
    const {
      hasCapabilities,
      isSocial,
      isAdmin,
    } = getIdentityUtils(identity);

    const dropdown_style = {
      'position' : 'absolute',
      'top': '50px',
      'right' : 0
    };

    const notSCI = hasCapabilities([
      'FEATURE-ADMIN', 'FEATURE-LIMITED', 'FEATURE-FULL',
      'FEATURE-ESSENTIAL', 'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'
    ], true);

    const showChatBot = hasCapabilities([
      'FEATURE-ADMIN', 'FEATURE-FULL',
      'FEATURE-ESSENTIAL', 'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'
    ], true);

    const v2 = hasCapabilities('COMPANY-TYPE-DISTRIBUTOR');
    return (
      <div ref={headerContainerRef} className="header-container" reactversion={REACT_VERSION}>
        <div ref={(header) => {this.header = header;}}
          className="row full-width header" onDragOver={() => window.scrollBy(0, -20)}>
          <div className="side-column show-for-medium"></div>
          <div className={`main-content ${v2 ? 'v2-main-content' : ''}`}>
            {stripe_unpaid && stripe_unpaid != 0 && hasCapabilities([
              'FEATURE-ADMIN', 'FEATURE-FULL', 'FEATURE-COLLABORATE', 'FEATURE-INSIGHTS',
              'FEATURE-CONNECTED', 'FEATURE-COMMUNITYBASIC', 'FEATURE-COMMUNITYPLUS',
              'FEATURE-CONNECTEDBASIC', 'FEATURE-CONNECTEDPLUS',
              'FEATURE-ESSENTIAL', 'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'
            ], true) && hasCapabilities([
              'CREATE-USER', 'MODIFY-USER', 'DELETE-USER', 'MODIFY-SETTINGS', 'MODIFY-PERMISSIONS'
            ], true) ? <StripeBanner/> : null}
            {application_setting && application_setting.banner_features_visibility === 'ALL' || application_setting && application_setting.banner_features_visibility === 'CRM-FULL' ?
              <a href={application_setting.banner_link}>
                <div className="banner-message">{application_setting.banner_message}</div>
              </a>
            : null}
            {messages.map((message, index) => <MessageCallout key={index} message={message}/>)}
            <div className="global-search-container small-12 medium-7 large-7 columns"
              style={{minHeight: 1, padding: 0}}>
            {(
              hasCapabilities([
                'FEATURE-ADMIN', 'FEATURE-FULL', 'FEATURE-COLLABORATE', 'FEATURE-INSIGHTS',
                'FEATURE-CONNECTED', 'FEATURE-COMMUNITYBASIC', 'FEATURE-COMMUNITYPLUS',
                'FEATURE-CONNECTEDBASIC', 'FEATURE-CONNECTEDPLUS',
                'FEATURE-ESSENTIAL', 'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'
              ], true) && !hasCapabilities(['RESTRICTED-ACCESS'])
            ) ? <GlobalSearch/> : null}
            </div>
            <div className="small-12 medium-5 large-5 columns end"
              style={{ maxWidth: 'initial', margin: 0, padding: 0 }}>
              <div className="small-2 columns show-for-small-only">
                <a className="button menu-button"
                  onClick={this.handleClickMenu}>Menu</a>
                {this.state.show_menu ?
                  <div className={v2 ? 'sidebar-v2 mobile-sidebar' : ''} style={{
                    position: 'absolute', background: '#3C505A',
                    opacity: 1, zIndex: 1000
                  }}>
                    {menu ? menu : <CskuMenu isMobile />}
                  </div> :
                  null}
              </div>
              <div className="small-10 medium-12 columns dropdown-menus">
                <ProfileDropdownMenu />
                {hasCapabilities([
                  'FEATURE-ADMIN', 'FEATURE-LIMITED', 'FEATURE-FULL', 'FEATURE-SOCIAL',
                  'FEATURE-COLLABORATE', 'FEATURE-INSIGHTS', 'FEATURE-CONNECTED',
                  'FEATURE-COMMUNITYBASIC', 'FEATURE-COMMUNITYPLUS',
                  'FEATURE-CONNECTEDBASIC', 'FEATURE-CONNECTEDPLUS', 'FEATURE-ESSENTIAL',
                  'FEATURE-ADVANCED', 'FEATURE-ENTERPRISE'
                ], true) ? <NotificationDropdownMenu /> : null}
                {notSCI ? <ReminderDropdownMenu /> : null}
                {trial_end_date ? <a className="button tour">Tour</a> : null}
                {set_up ? <a href="/set_up.php" className="button tour">Activate</a> : null}
                {notSCI && !identity.association ? <RecentDropdownMenu /> : null}
                {showChatBot && this.renderMiniChatBot()}
                <AssumeIdentity/>
                {/* comment out for now to test users react
                {notSCI ? <BookmarkDropdownMenu/> : null} */}
                <EventsReminder />
                <VerifyEmailPopup />
              </div>
            </div>
            {React.Children.map(children, child => {
              if (React.isValidElement(child)) {
                return React.cloneElement(child, { onResize: () => this.resizeSpaceHolder() });
	      }
              return child;
	    })}
          </div>
        </div>
        <div ref={(spaceHolder) => {this.spaceHolder = spaceHolder;}}
          className="header-space-holder"></div>
        { config.sanmar.show_modal && <SanmarModal/>}
        { this.state.show_new_project_popup &&
          <NewProjectPopup
            client_id={this.state.new_project_client_id}
            onClose={() => {
              this.setState({ show_new_project_popup: false });
            }}/>}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const identity = _.get(state, 'identity') || {};
  return {
    trial_end_date: identity.trial_end_date,
    application_setting: state.display.application_setting,
    stripe_unpaid: identity.stripe_unpaid,
    set_up: identity.set_up,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCreateFetchBanner: () => {
      dispatch(createFetchBanner());
    },
    onCreateEditCompanyShippingAndBillingPopup() {
      return dispatch(createEditCompanyShippingAndBillingPopup());
    }
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);

