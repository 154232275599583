import {
  ArrowIcon,
  colors,
  CompanyShopIcon,
  IconButton,
  MarketingShopIcon,
  PopupShopIcon,
  Text,
} from "@commonsku/styles";
import React from "react";
import { useDispatch } from "react-redux";
import { createCopyOrderPopup } from "../../../actions/popup";

interface InitializationProps {
  handleCustomRouting: (newRoute: string, selectedShopType?: string) => void;
  create_shop: any;
  setShopPopup: (value: boolean) => void;
}

const Initialization = ({
  handleCustomRouting,
  create_shop,
  setShopPopup,
}: InitializationProps) => {
  const dispatch = useDispatch();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        textAlign: "left",
        height: "100%",
      }}
    >
      <div>
        <Text
          as="h1"
          style={{
            fontSize: 56,
            fontWeight: 400,
            color: colors.neutrals["90"],
            width: "70%",
            marginBottom: 64,
          }}
        >
          Answer a few questions to find your ideal Shop type
        </Text>
        <IconButton
          variant="primary"
          size="huge"
          Icon={ArrowIcon}
          iconPosition="right"
          onClick={() => handleCustomRouting("questionnaire")}
          id="shopCreation-initialPage-getStarted.btn"
        >
          Get Started
        </IconButton>
      </div>
      <div id="shopCreation-initialPage-getStarted.shopIconsWrapperDiv">
        <Text
          as="h2"
          style={{
            fontSize: 24,
            fontWeight: 600,
            marginBottom: 48,
            color: colors.neutrals["90"],
          }}
        >
          Or select your Shop type below
        </Text>
        <div style={{ display: "flex" }}>
          <IconButton
            variant="text"
            size="huge"
            Icon={PopupShopIcon({ color: "#E6BA00", width: 80, height: 80 })}
            iconPosition="top"
            style={{
              marginRight: 48,
              borderRadius: 16,
              color: colors.neutrals["90"],
              width: 208,
              height: 160,
            }}
            onClick={() => {
              if (create_shop === true || create_shop === "no_redirect") {
                handleCustomRouting("selectedShopPage", "POPUP");
              } else {
                setShopPopup(false);
                dispatch(createCopyOrderPopup(create_shop, "SHOP", "popup"));
              }
            }}
            id="shopCreation-initialPage-getStarted-popupShop.btn"
          >
            Pop-up Shop
          </IconButton>
          <IconButton
            variant="text"
            size="huge"
            Icon={CompanyShopIcon({
              color: colors.secondary3["60"],
              width: 80,
              height: 80,
            })}
            iconPosition="top"
            style={{
              marginRight: 48,
              borderRadius: 16,
              color: colors.neutrals["90"],
              width: 208,
              height: 160,
            }}
            onClick={() => {
              if (create_shop === true || create_shop === "no_redirect") {
                handleCustomRouting("selectedShopPage", "COMPANY");
              } else {
                setShopPopup(false);
                dispatch(createCopyOrderPopup(create_shop, "SHOP", "company"));
              }
            }}
            id="shopCreation-initialPage-getStarted-companyShop.btn"
          >
            Company Shop
          </IconButton>
          <IconButton
            variant="text"
            size="huge"
            Icon={MarketingShopIcon({
              color: colors.primary1["70"],
              width: 80,
              height: 80,
            })}
            iconPosition="top"
            style={{
              marginRight: 48,
              borderRadius: 16,
              color: colors.neutrals["90"],
              width: 208,
              height: 160,
            }}
            onClick={() => {
              if (create_shop === true || create_shop === "no_redirect") {
                handleCustomRouting("selectedShopPage", "MARKETING");
              } else {
                setShopPopup(false);
                dispatch(
                  createCopyOrderPopup(create_shop, "SHOP", "marketing")
                );
              }
            }}
            id="shopCreation-initialPage-getStarted-marketingShop.btn"
          >
            Marketing Shop
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default Initialization;
