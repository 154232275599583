import { get, keys, each, find, map, bindAll, pullAll } from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { getPurchaseOrderList } from '../selectors';
import { getStatusOptions, getUserOptions } from '../selectors/dropdowns';
import { updatePurchaseOrder, deletePurchaseOrder, submitPurchaseOrder, sendPurchaseOrder, fetchPurchaseOrder } from '../actions/purchase_order';
import { uploadProof } from '../actions/purchase_order_proof';
import { createSpinnerPopup, createSubmitPOPopup, closePopup, createSelectFilePopup } from '../actions/popup';
import { createValidateConfirmationPopup, createNotePopup, createCheckOrderStatusPopup } from '../actions/popup';
import { createLoadOrder } from '../actions/order';

import { createAddTemp } from '../actions/temp';
import PurchaseOrder from './PurchaseOrder';
import LabelledCheckbox from './LabelledCheckbox';
import PurchaseOrderProof from './PurchaseOrderProof';
import POSidePanel from './production-report/POSidePanel';
import { updateNotesFeed } from '../actions/message';
import ReactTooltip from 'react-tooltip';

export const SELECTED_PURCHASE_ORDERS = 'SELECTED_PURCHASE_ORDERS';

class ProjectProduction extends Component {

  constructor(props) {
    super(props);

    this.state = {
      sidePanelRow: false,
      selectedSidePanelTab: 'details'
    };
    bindAll(this, ['onPurchaseOrderSelected']);
  }

  UNSAFE_componentWillMount() {
    this.loadOrders(this.props);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const orders = this.getOrders(this.props.purchase_orders);
    const nextOrders = this.getOrders(nextProps.purchase_orders);
    if (pullAll(keys(nextOrders), keys(orders)).length > 0) {
      this.loadOrders(nextProps);
    }
  }

  loadOrders(props) {
    const { loadOrder, purchase_orders } = props;
    const orders = this.getOrders(purchase_orders);

    each(orders, (order) => {
      if (order && !order.loaded) {
        loadOrder(order.order_id);
      }
    });
  }

  getOrders(purchase_orders) {
    const orders = {};
    each(purchase_orders, (po) => {
      orders[po.order_id] = po.order;
    });
    return orders;
  }

  onPurchaseOrderSelected(purchase_order_id, selected) {
    const { selected_purchase_orders, onUpdateSelection } = this.props;
    onUpdateSelection(Object.assign({}, selected_purchase_orders, {[purchase_order_id]: selected}));
  }

  renderPurchaseOrders() {
    const {
      project,
      purchase_orders,
      regenerate_po_queue,
      send_po,
      statuses,
      proof_statuses,
      users,
      onUpdatePurchaseOrder,
      onDeletePurchaseOrder,
      onSubmitPurchaseOrder,
      onSendPurchaseOrder,
      onSendElectronicPurchaseOrder,
      hasCapability,
      onCreateValidateConfirmationPopup,
      onCreateNotePopup,
      onCreateCheckOrderStatusPopup,
    } = this.props;

    const setSidePanelRow = (sidePanelRow) => this.setState({ sidePanelRow });

    return <table className="production-table">
      <thead>
        <tr>
          <th style={{ width: '1%' }}></th>
          <th style={{ width: '8%', minWidth: 64 }}></th>
          <th style={{ width: '8%' }}>SKUs</th>
          <th style={{ width: '8%' }}>PO #</th>
          <th style={{ width: '25%', maxWidth: 600 }}>PO Details</th>
          <th style={{ width: '16%' }}>Status</th>
          <th style={{ width: '16%' }}>Production Rep</th>
          <th style={{ width: '16%' }}></th>
        </tr>
      </thead>
      <tbody>
        {purchase_orders.map(po => <PurchaseOrder
          key={po.purchase_order_id}
          purchase_order={po}
          statuses={statuses}
          proof_statuses={proof_statuses}
          users={users}
          loading={!!find(regenerate_po_queue[po.order_id], (shipping_id) => {
            return shipping_id === po.shipping_id;
          })}
          onUpdate={onUpdatePurchaseOrder}
          onDelete={onDeletePurchaseOrder}
          onSubmit={onSubmitPurchaseOrder}
          onSelected={this.onPurchaseOrderSelected}
          hasCapability={hasCapability}
          onCreateValidateConfirmationPopup={onCreateValidateConfirmationPopup}
          onCreateNotePopup={onCreateNotePopup}
          onCreateCheckOrderStatusPopup={onCreateCheckOrderStatusPopup}
          onSendPurchaseOrder={onSendPurchaseOrder}
          onSendElectronicPurchaseOrder={onSendElectronicPurchaseOrder}
          sending={send_po.includes(po.purchase_order_id)}
          project={project}
          sidePanelRow={this.state.sidePanelRow}
          setSidePanelRow={setSidePanelRow}
        />)}
      </tbody>
    </table>;
  }

  renderProofs() {
    const {
      purchase_orders,
      onUpdatePurchaseOrder,
      onCreateSelectFilePopup
    } = this.props;

    return <div style={{ paddingBottom: 15 }}>
      {purchase_orders.map(po => {
        const destination = po.origin && po.destination
            ? `${po.origin.division_name} > ${po.destination.destination_name}`
            : `${po.division_name} > Null`
        ;

        return ((po.po_type === 'ARTWORK' || po.po_type === 'SERVICE') || po.proof_required == "0") ? null :
          <div key={po.purchase_order_id} style={{ padding: '15px 0', borderBottom: '1px solid #E1E1E1' }}>
            <div className="row full-width" style={{ margin: 0, }}>
              <div className="small-6 columns" style={{ fontWeight: 'bold' }}>
                PO #{po.form_number} {destination}
              </div>
              <div className="small-6 columns">
                {po.all_proof_uploaded === "1" ? null :
                  <button className="button small float-right" type="button" onClick={e => onCreateSelectFilePopup(po.purchase_order_id)}>Upload Proof</button>
                }
                <LabelledCheckbox style={{ display: 'inline-block', float: 'right' }}
                  label="All Proofs Uploaded"
                  value={parseInt(po.all_proof_uploaded) || 0}
                  onChange={((checked) => {
                    onUpdatePurchaseOrder(
                      po.purchase_order_id, 'all_proof_uploaded', po.all_proof_uploaded
                    )((checked).toString());
                  })}
                />
              </div>
            </div>
            <div>
              {map(po.proofs, proof => <PurchaseOrderProof
                key={proof.purchase_order_proof_id} proof={proof}
              />)}
            </div>
          </div>;
      })}
    </div>;
  }

  render() {
    const { purchase_orders } = this.props;
    const { tab = 'purchase_orders' } = this.state;
    const proofNums = purchase_orders.reduce((sum, po) => {
      return sum + (po.proofs || []).length;
    }, 0);
    const tabs = {
      purchase_orders: 'Purchase Orders',
    };
    tabs.proofs = `Proofs (${proofNums})`;

    const setSidePanelRow = (sidePanelRow) => this.setState({ sidePanelRow });
    const setSelectedSidePanelTab = (selectedSidePanelTab) => this.setState({ selectedSidePanelTab });

    return <div className="main-content">
      <div>
        <ReactTooltip id="commentTip" place="top" className="customTip">View Comments</ReactTooltip>
        <ReactTooltip id ="pinTip" place="top" className="customTip" />
        <ReactTooltip id="deleteTip" place="top" className="customTip">Delete</ReactTooltip>
        <ReactTooltip id="editTaskTip" place="top" className="customTip">Edit</ReactTooltip>
        <POSidePanel
          sidePanelRow={this.state.sidePanelRow}
          setSidePanelRow={setSidePanelRow}
          selectedTab={this.state.selectedSidePanelTab}
          setSelectedTab={setSelectedSidePanelTab}
          actionMenu= {false}
        />
      </div>
      <div className="small-12 columns">
        <ul className="tabs">
          {map(tabs, (label, tabKey) => {
            const selected = tabKey === tab;
            return <li key={tabKey}
              className={'tabs-title ' + (selected ? 'is-active' : '')}
            >
              <a aria-selected={selected} onClick={e => {
                e.preventDefault();
                this.setState({ tab: tabKey });
              }} style={{ fontSize: 17 }}>{label}</a>
          </li>;
          })}
        </ul>
        <div className="tabs-content">
          <div className="tabs-panel is-active" style={{ padding: 0 }}>
            {tab === 'purchase_orders'
              ? this.renderPurchaseOrders()
              : this.renderProofs()
            }
          </div>
        </div>
      </div>
    </div>;
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    loadOrder: order_id => dispatch(createLoadOrder(order_id)),
    onUpdatePurchaseOrder: (purchase_order_id, field, previous_value) => value => {
      dispatch(updatePurchaseOrder(purchase_order_id, field, previous_value, value));
    },
    onDeletePurchaseOrder: purchase_order_id => {
      dispatch(deletePurchaseOrder(purchase_order_id));
    },
    onSubmitPurchaseOrder: (purchase_order_id, submit_method) => {
      dispatch(submitPurchaseOrder(purchase_order_id, submit_method));
    },
    onCreateValidateConfirmationPopup: (method, params_arr) => {
      dispatch(createValidateConfirmationPopup(method, params_arr));
    },
    onCreateNotePopup: (purchase_order_id, job_id) => {
      dispatch(updateNotesFeed(job_id));
     // dispatch(createNotePopup(purchase_order_id, job_id));
    },
    onCreateCheckOrderStatusPopup: (purchase_order_id, division_id, form_number, date_shipped, onUpdate) => {
      dispatch(createCheckOrderStatusPopup(purchase_order_id, division_id, form_number, date_shipped, onUpdate));
    },
    onUpdateSelection: selection => dispatch(createAddTemp(SELECTED_PURCHASE_ORDERS, selection)),
    onUploadProof: (purchase_order_id, files) => {
      dispatch(createSpinnerPopup('Uploading Proof'));
      dispatch(uploadProof(purchase_order_id, files)).then(() => {
        dispatch(closePopup());
      });
    },
    onSendPurchaseOrder: (purchase_order_id, form_number, showEPOPopup) => dispatch(createSubmitPOPopup(purchase_order_id, showEPOPopup)),
    onSendElectronicPurchaseOrder: (purchase_order_id, resend) => dispatch(sendPurchaseOrder(purchase_order_id, null, null, null, resend)),
    onCreateSelectFilePopup: purchase_order_id => dispatch(createSelectFilePopup(null, purchase_order_id, 'PURCHASE-ORDER'))
  };
};

const mapStateToProps = (state, ownProps) => {
  return {
    company_id: state.identity.company_id,
    regenerate_po_queue: state.display.regenerate_po_queue || [],
    send_po: state.display.loading.send_po,
    purchase_orders: getPurchaseOrderList(state),
    statuses: getStatusOptions(state, { order_type: 'PURCHASE ORDER' }),
    proof_statuses: getStatusOptions(state, { order_type: 'PROOF' }),
    proof_types: get(state, 'entities.proofs'),
    users: getUserOptions(state),
    hasCapability: capability => state.identity.capabilities.includes(capability),
    selected_purchase_orders: state.temp[SELECTED_PURCHASE_ORDERS] || {}
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProjectProduction);
