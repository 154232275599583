import { createStateMachine } from "../helpers/useStateMachine";

export const questions = createStateMachine({
  start: "PURPOSE",
  states: {
    PURPOSE: {
      id: "PURPOSE",
      value: "What sentence best describes your reason for creating a Shop?",
      transitions: [
        {
          value:
            "I'm creating a Shop for a specific client.  I want all the orders to be tied to their Client Page in commonsku.",
          nextState: "CHECKOUTS",
          bidirectional: true,
        },
        {
          value:
            "I'm creating a Shop for marketing purposes. I want to send it to different clients and prospects so they can place an order.",
          nextState: "MARKETING",
        },
      ],
    },
    CHECKOUTS: {
      id: "CHECKOUTS",
      value: "How would you like to process your Shop's orders?",
      transitions: [
        {
          value: "I'd like to move each order into production right away.",
          nextState: "PAYMENT",
          bidirectional: true,
        },
        {
          value: "I'd like to combine the checkouts into one sales order.",
          nextState: "POPUP",
        },
      ],
    },
    PAYMENT: {
      id: "PAYMENT",
      value: "Will your Shop offer credit card payment?",
      transitions: [
        {
          value: "Yes",
          nextState: "SHIPPING",
          bidirectional: true,
        },
        {
          value: "No",
          nextState: "COMPANY",
        },
      ],
    },
    SHIPPING: {
      id: "SHIPPING",
      value: "Do you know your shoppers' ship-to locations?",
      transitions: [
        {
          value: "Yes",
          nextState: "COMPANY",
        },
        {
          value: "No",
          nextState: "POPUP",
        },
      ],
    },
    MARKETING: {
      id: "MARKETING",
      value: null,
      transitions: [],
    },
    COMPANY: {
      id: "COMPANY",
      value: null,
      transitions: [],
    },
    POPUP: {
      id: "POPUP",
      value: null,
      transitions: [],
    },
  },
});

export const shopDetails = {
  POPUP: {
    type: "Pop-Up",
    desc: "Easily collect and combine orders from multiple shoppers while keeping products on-brand and pre-approved. Perfect for short-term events, fundraising, and employee appreciation programs.",
    details: {
      "Great for": "Fundraisers, team events, and seasonal campaigns.",
      "Combine small orders":
        "Merge multiple checkouts to meet product minimums.",
      "On-brand experience": "Pre-approved artwork and pricing.",
      "Flexible pricing":
        "Set retail prices to include additional margin for profit.",
      "Free item programs": "Reward employees or offer zero-dollar items.",
      "Seamless shopping":
        "Supports password protection, payments, coupons & tax calculation.",
      "Custom checkout questions": "Gather extra details from shoppers.",
    },
  },
  COMPANY: {
    type: "Company",
    desc: "Make branded products easily available for employees, franchisees, and partners with pre-approved designs and pricing. Enforces minimum quantities, ensuring every order is production-ready.",
    details: {
      "Great for": "Company uniforms, employee merch, and internal ordering.",
      "Exclusive access": "Designed for employees, franchisees, or partners.",
      "Consistent branding": "Pre-approved artwork and pricing.",
      "Supports inventory & minimums": "Every order generates a sales order.",
      "Secure shopping": "Password-protected with credit card payment options.",
      "Custom checkout questions": "Collect additional order details.",
    },
  },
  MARKETING: {
    type: "Marketing",
    desc: "Showcase products in an e-commerce-style presentation for a broad audience. Shoppers can upload artwork, and minimum quantities ensure clear cost visibility.",
    details: {
      "Great for": "Large-scale marketing campaigns and product promotions.",
      "Broad distribution":
        "Share with your entire client base, not tied to one client.",
      "Full customization": "Shoppers can upload their own artwork.",
      "Transparent pricing":
        "Minimum quantities ensure clear cost expectations.",
      "Seamless production": "Sales orders include full decoration details.",
      "Custom checkout questions": "Gather extra details from shoppers.",
    },
  },
};

export const shopComparison = {
  features: [
    "Inventory",
    "Minimum quantity enforced",
    "Option to show quantity price breaks",
    "Coupons",
    "Upcharges to any product’s size / color variants",
    "Limited to one client",
    "Option to add additional retail mark up",
    "Users can upload artwork",
    "Sales order created upon checkout",
    "Aggregation of orders into a single sales order",
    "User may enter shipping address",
    "Option to add tax at checkout",
    "Custom checkout questions",
    "Product personalization questions",
    "Option to enable credit card checkout",
    "Option to create invoice at checkout",
    "Option to create receipt at checkout",
    "Option to set checkout limit",
  ],
  POPUP: {
    title: "Pop-up Shop",
    features: [
      "Minimum quantity enforced",
      "Coupons",
      "Upcharges to any product’s size / color variants",
      "Limited to one client",
      "Option to add additional retail mark up",
      "Option to add additional retail mark up",
      "Aggregation of orders into a single sales order",
      "Option to add tax at checkout",
      "Custom checkout questions",
      "Product personalization questions",
      "Option to enable credit card checkout",
      "Option to create receipt at checkout",
      "Option to set checkout limit",
    ],
  },
  COMPANY: {
    title: "Company Shop",
    features: [
      "Inventory",
      "Minimum quantity enforced",
      "Option to show quantity price breaks",
      "Upcharges to any product’s size / color variants",
      "Limited to one client",
      "Sales order created upon checkout",
      "User may enter shipping address",
      "Option to add tax at checkout",
      "Custom checkout questions",
      "Option to enable credit card checkout",
      "Option to create invoice at checkout",
    ],
  },
  MARKETING: {
    title: "Marketing Shop",
    features: [
      "Minimum quantity enforced",
      "Option to show quantity price breaks",
      "Upcharges to any product’s size / color variants",
      "Users can upload artwork",
      "Sales order created upon checkout",
      "User may enter shipping address",
      "Custom checkout questions",
    ],
  },
};
