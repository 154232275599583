import { useMemo, useState } from 'react';
import { IShop } from '../interfaces/Shop';
import { IOrder } from '../interfaces/Order';
import { IPublicViewProperties } from '../interfaces/PublicViewProperty';

type TPublicViewEntityInitialValue =
  ReturnType<typeof parseShopToPublicViewEntity>
  | ReturnType<typeof parseOrderToPublicViewEntity>

const orderTypeTitle = (order_type: string) => {
  const lower = order_type.toLowerCase().split(' ')
    .map(v => `${v.charAt(0).toUpperCase()}${v.slice(1)}`);
  return lower.join(' ');
};
function getBooleanValue(value: boolean | string | number) {
  return typeof value === 'boolean'
    ? value
    : typeof value === 'string'
    ? (value === 'true' || (+value === 1))
    : +value === 1;
}

const usePublicViewEntity = (initialValue: TPublicViewEntityInitialValue) => {
  const [viewEntity, setViewEntity] = useState(initialValue);

  const isShop = viewEntity.isShop ?? Boolean(viewEntity.entity.shop_id);
  const entityId = viewEntity.entityId ?? viewEntity.entity.shop_id ?? viewEntity.entity.order_id;
  const entityNumber = viewEntity.entityNumber;
  const entityIsFree = viewEntity.entityIsFree;
  const entityBuyInventory = viewEntity.entityBuyInventory;
  const entityShowInventory = viewEntity.entityShowInventory;
  const entityAggregate = viewEntity.entityAggregate;

  const isFree = getBooleanValue(entityIsFree);
  const buyInventory = getBooleanValue(entityBuyInventory);
  const showInventory = getBooleanValue(entityShowInventory);
  const isAggregate = getBooleanValue(entityAggregate);

  const entityShowProductWarnings = viewEntity.entityShowProductWarnings;
  const entityForceMinimumQty = viewEntity.entityForceMinimumQty;
  const entityTenantId = viewEntity.entityTenantId ?? viewEntity.entity.tenant_id;
  const entityClientId = viewEntity.entityClientId ?? viewEntity.entity.client_id;

  const perCheckoutLimit = viewEntity.perCheckoutLimit;
  const entityTemplateId = viewEntity.entityTemplateId;
  const entityLayoutId = viewEntity.entityLayoutId;

  const contactName = viewEntity.contactName;
  const contactEmail = viewEntity.contactEmail;
  const introduction = viewEntity.entity.introduction ?? '';
  const title = viewEntity.title;

  const basePathname = isShop ? '/shop' : '/order';
  const baseUrl = `${basePathname}/${entityId}`;
  const baseEntityUrl = `${baseUrl}/shop`;

  return useMemo(() => ({
    viewEntity,
    setViewEntity,
    isShop,
    entityId,
    entityType: viewEntity.entityType,
    entity: viewEntity.entity,
    entityNumber,
    isFree,
    entityIsFree,
    buyInventory,
    showInventory,
    entityBuyInventory,
    entityShowInventory,
    entityShowProductWarnings,
    entityAggregate,
    isAggregate,
    entityForceMinimumQty,
    entityTenantId,
    entityClientId,
    perCheckoutLimit,
    entityTemplateId,
    entityLayoutId,
    contactName,
    contactEmail,
    introduction,
    title,
    entityOrder: viewEntity.order,
    entityOrderId: viewEntity.order?.order_id,
    entityOrderItems: viewEntity.order?.items,
    allowCouponCheckout: viewEntity.allowCouponCheckout,
    baseUrl,
    baseEntityUrl,
    basePathname,
    currencySymbol: viewEntity.currencySymbol,
    checkoutMessage: viewEntity.checkoutMessage,
    labelTitle: viewEntity.entityType === 'ORDER' ? orderTypeTitle(viewEntity.order.order_type) : 'Shop',
    entityLocation: `${baseEntityUrl}${entityBuyInventory ? '?buy_inventory=true' : ''}`,
    freeItemsCount: viewEntity.freeItemsCount,
    allowMultipleFreeItem: viewEntity.allowMultipleFreeItem,
  }), [
    viewEntity,
    isShop,
    entityId,
    entityNumber,
    entityIsFree,
    entityBuyInventory,
    entityShowInventory,
    entityShowProductWarnings,
    entityAggregate,
    entityForceMinimumQty,
    entityTenantId,
    entityClientId,
    perCheckoutLimit,
    entityTemplateId,
    entityLayoutId,
    contactName,
    contactEmail,
    introduction,
    title,
    baseUrl,
    baseEntityUrl,
    basePathname,
    buyInventory,
    isAggregate,
    isFree,
    showInventory,
  ]);
};

export const parseShopToPublicViewEntity = (
  shop: IShop,
  order: IOrder,
  publicViewProperties: IPublicViewProperties = {} as IPublicViewProperties
) => ({
  isShop: true,
  entity: shop,
  order,
  entityId: shop.shop_id,
  entityNumber: shop.shop_number,
  entityIsFree: publicViewProperties?.is_free ?? shop.is_shop_free ?? '0',
  entityBuyInventory: shop.buy_inventory ?? false,
  entityShowInventory: shop.show_inventory ?? false,
  entityShowProductWarnings: shop.show_product_warnings ?? '0',
  entityAggregate: shop.aggregate ?? '0',
  entityClientId: shop.client_id,
  entityTenantId: shop.tenant_id,
  entityForceMinimumQty: publicViewProperties.force_minimum_qty ?? shop.force_minimum_qty ?? '0',
  entityTemplateId: shop.public_view_template_id,
  entityLayoutId: shop.public_view_layout_id,
  perCheckoutLimit: shop.per_checkout_limit ?? null,
  currencySymbol: shop.currency.currency_symbol,
  contactName: shop.contact_name,
  contactEmail: shop.contact_email,
  title: shop.shop_name,
  entityType: 'SHOP',
  allowCouponCheckout: shop.allow_coupon_checkout,
  checkoutMessage: shop.checkout_message,
  freeItemsCount: +shop.free_items_count,
  allowMultipleFreeItem: +shop.allow_multiple_free_item === 1,
});

export const parseOrderToPublicViewEntity = (
  order: IOrder,
  publicViewProperties: IPublicViewProperties = {} as IPublicViewProperties
) => {
  const rep = order.resku_form && order.order_template?.footer_user_type === 'CLIENT-REP'
            ? order.client_rep : order.sales_rep;

  return {
    isShop: false,
    isOrder: true,
    entity: order,
    order: order,
    entityId: order.order_id,
    entityNumber: order.form_number,
    entityIsFree: publicViewProperties?.is_free ?? '0',
    entityBuyInventory: order.buy_inventory ?? false,
    entityShowInventory: order.show_inventory ?? false,
    entityShowProductWarnings: publicViewProperties.show_product_warnings ?? '0',
    entityAggregate: order.is_aggregate ?? '0',
    entityClientId: order.client_id,
    entityTenantId: order.tenant_id,
    entityForceMinimumQty: publicViewProperties.force_minimum_qty ?? '0',
    entityTemplateId: order.public_view_template_id,
    entityLayoutId: order.public_view_layout_id,
    perCheckoutLimit: publicViewProperties.per_checkout_limit ?? null,
    currencySymbol: order.currency_symbol,
    contactName: `${rep?.contact_first_name || ''} ${rep?.contact_last_name || ''}`.trim(),
    contactEmail: rep?.contact_email || '',
    title: order.job_name || `${order.order_type} #${order.form_number}`,
    entityType: 'ORDER',
    allowCouponCheckout: false,
    checkoutMessage: '',
    freeItemsCount: 1,
    allowMultipleFreeItem: false,
  };
};

export default usePublicViewEntity;
