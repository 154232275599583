import {
  createEntityAdapter,
  createSelector,
  createSlice,
  Dispatch,
  PayloadAction,
} from "@reduxjs/toolkit";
import { oauth } from "../utils";
import { ItemPropertiesGet, ItemProperty, ItemPropertyInitializer, ItemPropertyModifier } from "../interfaces/ItemProperty";
import { LOAD_ORDER_SUCCESS } from "../actions/order";
import { LOAD_SHOP_SUCCESS } from "../actions/shop";
import { IItem } from "../interfaces/Item";
import { StoreState } from "../reducers/core";

const API_BASE_ROUTE = "item-property";
const adapter = createEntityAdapter({ selectId: (row: ItemProperty) => row.item_property_id });
const initialState = {
  ...adapter.getInitialState(),
  loading: "idle",
};

export type ItemPropertiesState =  typeof initialState;

const slice = createSlice({
  name: "itemProperties",
  initialState: initialState,
  reducers: {
    loading(state) {
      state.loading = "pending";
    },
    loaded(state) {
      state.loading = "idle";
    },
    created(state, action: PayloadAction<ItemProperty>) {
      return adapter.upsertOne(state, action.payload);
    },
    retrieved(state, action: PayloadAction<ItemProperty>) {
      return adapter.upsertOne(state, action.payload);
    },
    retrievedMany(state, action: PayloadAction<ItemProperty[]>) {
      return adapter.upsertMany(state, action.payload);
    },
    updated(state, action: PayloadAction<ItemProperty>) {
      return adapter.upsertOne(state, action.payload);
    },
    createdOrUpdated(state, action: PayloadAction<ItemProperty>) {
      return adapter.upsertOne(state, action.payload);
    },
    deleted(state, action: PayloadAction<string>) {
      return adapter.removeOne(state, action.payload);
    },
  },
  extraReducers(builder) {
    builder.addCase<
      typeof LOAD_ORDER_SUCCESS,
      PayloadAction<{order: {[key: string]: any, items: IItem[]}}, typeof LOAD_ORDER_SUCCESS>
    >(LOAD_ORDER_SUCCESS, (state, action) => {
      const items = action.payload?.order?.items || [];
      const itemProperties = items.flatMap(ii => ii.item_properties || []);

      return adapter.upsertMany(state, itemProperties);
    });

    builder.addCase<
      typeof LOAD_SHOP_SUCCESS,
      PayloadAction<{ itemProperties: ItemPropertiesState }, typeof LOAD_SHOP_SUCCESS>
    >(LOAD_SHOP_SUCCESS, (state, action) => {
      return adapter.upsertMany(state, Object.values(action.payload?.itemProperties?.entities || {}));
    });
  },
});

function parseProperty(v: ItemProperty) {
  let property_value = v.property_value;
  if (typeof property_value === 'string') {
    try {
      property_value = JSON.parse(property_value);
    } catch (error) {
      property_value = {};
    }
  }
  if (!property_value) {
    property_value = {};
  }
  return { ...v, property_value } as ItemProperty;
};
const parseProperties = (properties: ItemProperty[]) => properties.map(v => parseProperty(v));

// ------------------------------
// Exports
// ------------------------------

export const actions = slice.actions;

export const retrieveItemProperties = (params: ItemPropertiesGet = {}) => async (dispatch: Dispatch) => {
  dispatch(actions.loading());
  try {
    const response = await oauth("GET", API_BASE_ROUTE, params);
    const records: ItemProperty[] = parseProperties(response?.json?.item_properties ?? []);
    dispatch(actions.retrievedMany(records));
    return records;
  } catch (error) {
    dispatch(actions.loaded());
    return [];
  }
};

export const createItemProperty = (data: ItemPropertyInitializer) => async (dispatch: Dispatch) => {
  dispatch(actions.loading());
  try {
    const response = await oauth("POST", API_BASE_ROUTE, data);
    const record: ItemProperty = parseProperty(response.json.item_property);
    dispatch(actions.created(record));
    return record;
  } catch (error) {
    dispatch(actions.loaded());
    return null;
  }
}

export const updateItemProperty = (id: string, data: ItemPropertyModifier) => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.loading());
    const response = await oauth("PUT", `${API_BASE_ROUTE}/${id}`, data);
    const record: ItemProperty = parseProperty(response.json.item_property);
    dispatch(actions.updated(record));
    return record;
  } catch (error) {
    dispatch(actions.loaded());
    return null;
  }
};

export const createOrUpdateItemProperty = (data: ItemPropertyModifier) => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.loading());
    const response = await oauth("POST", API_BASE_ROUTE, data);
    const record: ItemProperty = parseProperty(response.json.item_property);
    dispatch(actions.createdOrUpdated(record));
    return record;
  } catch (error) {
    dispatch(actions.loaded());
    return null;
  }
};

export const retrieveItemProperty = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.loading());
    const response = await oauth("GET", `${API_BASE_ROUTE}/${id}`);
    const record: ItemProperty = parseProperty(response.json.item_property);
    dispatch(actions.retrieved(record));
    return record;
  } catch (error) {
    dispatch(actions.loaded());
    return null;
  }
};

export const deleteItemProperty = (id: string) => async (dispatch: Dispatch) => {
  try {
    dispatch(actions.loading());
    const response = await oauth("DELETE", `${API_BASE_ROUTE}/${id}`);
    dispatch(actions.deleted(id));
    return true;
  } catch (error) {
    dispatch(actions.loaded());
    return false;
  }
};

const selectors = adapter.getSelectors();
const selectItemIdParam = (state: StoreState, itemId: string) => itemId;
const selectAll = (state: StoreState) => selectors.selectAll(state?.itemProperties || initialState);
export const selectItemPropertiesByItemId = createSelector(
  selectAll,
  selectItemIdParam,
  (state, itemId) => state.filter(v => v.item_id === itemId),
);
export const selectItemPropertyByItemIdAndName = createSelector(
  selectAll,
  selectItemIdParam,
  (state: StoreState, itemId: string, propertyName: string) => propertyName,
  (state, itemId, propertyName) => state.find(v => v.item_id === itemId && v.property_name === propertyName),
);
export const selectItemSizeGuideUrlProperty = createSelector(
  selectAll,
  selectItemIdParam,
  (state, itemId) => state.find(v => v.item_id === itemId && v.property_name === 'size_guide_url') as (
    undefined | Omit<ItemProperty, 'property_value'> & { property_value: { value: string } }
  ),
);

const itemPropertiesReducer = slice.reducer;
export default itemPropertiesReducer;
