import { map, filter, pick, isEmpty, get, some } from 'lodash';
import React, { Component } from 'react';

import config from '../config';
import { formatTimestampWithTimezone, getAbsoluteUrl, oauth } from '../utils';
import Select from './Select';
import DropdownMenu, { MenuTrigger } from './DropdownMenu';
import Img from './Img';
import PS from './PromostandardsLabel';
import { createGlobalStyle } from 'styled-components';
import { Button, colors, SkubotSpinner } from '@commonsku/styles';
import EPOPopup from './EPOPopup';
import InventoryWarningPopup from './InventoryWarningPopup';
const isArtworkOrService = (po_type) => po_type === 'ARTWORK' || po_type === 'SERVICE';

const GlobalStyle = createGlobalStyle `
  .mention-textarea-container {
    position: relative;

    textarea {
        padding: .5rem;
        color: #123952;
        width: 100%;
        height: 150px !important;    
        border: 1px solid #ABC7D1;
        border-radius: 5px;
        box-sizing: border-box;
        font-family: 'skufont-regular',sans-serif;
        font-size: 1rem;
        background-color: white;
        box-shadow: inset 0 1px 2px rgba(0,0,0,0.1);
        margin-bottom: 1rem;
    }

    ul.tag-results {
      list-style: none;
      border-top: 1px solid #ccc;
      border-left: 1px solid #ccc;
      border-right: 1px solid #ccc;
      border-bottom: 1px solid #ccc;
      width: 325px;
      padding: 0;
      margin: 0;
      background: #fff;
      position: absolute;
      z-index: 99;
      box-shadow: 1px 1px 1px #ddd;
      display: block;
    }

    .tag-user {
      position: relative;
      padding: .2rem 4rem;

      &:last-child {
        padding-bottom: .4rem;
      }

      &:hover {
        background: $light-gray;
      }

      img {
        position: absolute;
        top: .4rem;
        left: .4rem;
      }

      .username {
        font-size: .7em;
        cursor: pointer;
      }
    }
  }
  .tasks-comment {
    .mention-textarea-container {
      textarea {
        height :50px !important 
      }
    }
  } 
`;

class PurchaseOrder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      layout: {},
      showPopup: false,
      showInventoryWarningPopup: false,
      inventoryCheckError: false,
      insufficientItems: [],
    };

    this.handleClickDelete = this.handleClickDelete.bind(this);
    this.handleClickNote = this.handleClickNote.bind(this);
    this.handleCheckOrderStatus = this.handleCheckOrderStatus.bind(this);
    this.handlePopup = this.handlePopup.bind(this);
    this.handleShowInventoryWarningPopup = this.handleShowInventoryWarningPopup.bind(this);
  }

  isSelected() {
    return !isArtworkOrService(this.props.purchase_order.po_type) && this.refs.checked.checked;
  }

  handleClickDelete(e) {
    e.preventDefault();
    e.stopPropagation();
    let params_arr = [this.props.purchase_order.purchase_order_id];
    this.props.onCreateValidateConfirmationPopup(this.props.onDelete, params_arr);
  }

  handleClickNote(e, purchase_order_id, project,purchase_order) {
    e.preventDefault();
    e.stopPropagation();
    this.props.setSidePanelRow({...purchase_order, job_id: project.job_id});
    this.props.onCreateNotePopup(purchase_order_id, project.job_id, project.messages);
  }

  handleCheckOrderStatus(e) {
    const { purchase_order_id, supplier_id, form_number, date_shipped } = this.props.purchase_order;

    e.preventDefault();
    e.stopPropagation();
    this.props.onCreateCheckOrderStatusPopup(purchase_order_id, supplier_id, form_number, date_shipped, this.props.onUpdate);
  }

  renderTrackingIds() {
    const { purchase_order } = this.props;
    const { shipping_tracking_id } = purchase_order;

    if (!shipping_tracking_id) return null;

    return shipping_tracking_id
      .split(",")
      .map((id) => id.trim())
      .filter((id) => !!id)
      .map((id, i) => (
        <>
          {i > 0 ? `, ` : null}
          <a key={id} target="_blank" href={`http://google.com/search?q=${id}`}>
            {id}
          </a>
        </>
      ));
  }

  handlePopup(showPopup) {
    this.setState({
      showPopup: showPopup,
    });
  }

  async handleShowInventoryWarningPopup(showInventoryWarningPopup) {
    if (!showInventoryWarningPopup) {
      this.setState({
        showInventoryWarningPopup: false,
        insufficientItems: [],
        inventoryCheckError: false,
      });
      return;
    }

    this.setState({
      showPopup: true,
    });

    try {
      const controller = new AbortController();
      const timeoutId = setTimeout(() => controller.abort(), 20000);
      const { json } = await oauth(
        'GET',
        'purchaseorder/check-inventory',
        { purchase_order_id: this.props.purchase_order.purchase_order_id },
        null,
        { signal: controller.signal }
      );

      clearTimeout(timeoutId);

      if (get(json, 'error') || !get(json, 'insufficient_items')) {
        this.setState({
          showInventoryWarningPopup: true,
          inventoryCheckError: true,
        });
      } else if (!isEmpty(json.insufficient_items)) {
        this.setState({
          showInventoryWarningPopup: true,
          insufficientItems: json.insufficient_items,
        });
      } else {
        this.props.onSendPurchaseOrder(
          this.props.purchase_order.purchase_order_id,
          this.props.purchase_order.form_number,
          () => this.handlePopup(true)
        );
      }
    } catch (e) {
      this.setState({
        showInventoryWarningPopup: true,
        inventoryCheckError: true,
      });
    }
    this.setState({
      showPopup: false,
    });
  }

  render() {
    const {
      purchase_order, statuses, users, proof_statuses,
      onUpdate, onSubmit, onSelected,
      hasCapability, onSendPurchaseOrder, onSendElectronicPurchaseOrder,
      project, loading, sending
    } = this.props;
    if (loading) {
      return <tr><td colSpan="8" style={{
        background: 'url(../../images/gears.gif) no-repeat center',
        height: 70,
      }}/></tr>;
    }
    const mark_as_options = map([
      { key: 'FAX', value: 'Submitted by Fax', onClick: e =>
        onSubmit(purchase_order.purchase_order_id, 'FAX') },
      { key: 'FTP', value: 'Submitted by FTP', onClick: e => onSubmit(purchase_order.purchase_order_id, 'FTP') },
      { key: 'WEB', value: 'Submitted on Supplier Site', onClick: e => onSubmit(purchase_order.purchase_order_id, 'WEB') },
      { key: 'OTHER', value: 'Otherwise Submitted', onClick: e => onSubmit(purchase_order.purchase_order_id, 'OTHER') }
    ], (option) => {
      return {
        ...option,
        onClick: (e) => {
          this.dropdown.showDropdown(false);
          option.onClick(e);
        },
      };
    });
    const showOrderStatusButton = (purchase_order.order_status_check_enabled || purchase_order.shipment_check_enabled) && purchase_order.has_credentials;
    const canModifyBill = hasCapability('MODIFY-BILL');
    const statusesOptions = !canModifyBill ?
      statuses.filter(status => status.value !== 'Billed' || purchase_order.status_id === status.key) :
      statuses;

    return (
      <tr>
         <GlobalStyle theme={{ fontFamily: "'skufont-regular', 'sans-serif', 'Roboto'" }} />
        <td>
          {!isArtworkOrService(purchase_order.po_type) ? <input ref="checked" type="checkbox" onChange={e => onSelected(purchase_order.purchase_order_id, this.isSelected())} /> : null}
        </td>
        {!isArtworkOrService(purchase_order.po_type) ?
          (purchase_order.items.length ?
            <>
              <td>
                <a target="_blank" href={`/product.php?id=${purchase_order.items[0].product_id}`}>
                  {!purchase_order.items[0].product_img ? null :
                    <Img src={getAbsoluteUrl(purchase_order.items[0].product_img)} />}
                </a>
              </td>
              <td>
                {purchase_order.items.map((i, k) =>
                  [<a key={k} href={`/product.php?id=${i.product_id}`} target="_blank">{i.product_code}</a>, <br key={k + purchase_order.items.length} />]
                )}
              </td>
            </> :
            <td colSpan={2}>Not associated with any items</td>) :
            <>
	      <td>&nbsp;</td>
	      <td>
                <a target="_blank" href={`/purchase_order.php?id=${purchase_order.purchase_order_id}`}>SERVICE</a>
              </td>
	    </>}
        <td>
          <a target="_blank" href={`/purchase_order.php?id=${purchase_order.purchase_order_id}`}>{purchase_order.form_number}</a>
        </td>
        <td>
	  <div style={{ maxWidth: 640, overflowWrap: 'anywhere' }}>
          {purchase_order.origin && purchase_order.destination ?
            <a target="_blank" href={`/purchase_order.php?id=${purchase_order.purchase_order_id}`}>{`${purchase_order.origin.division_name} > ${purchase_order.destination.destination_name}`}</a>
          :
            <a target="_blank" href={`/purchase_order.php?id=${purchase_order.purchase_order_id}`}>{`${purchase_order.division_name} > Null`}</a>
          }
          <br />
          {!isArtworkOrService(purchase_order.po_type) &&
            <small>
              <a target="_blank" href={`/po_shipping_label_html.php?id=${purchase_order.purchase_order_id}`}>Shipping Label</a>
              |
              <a target="_blank" href={`/po_packing_slip_html.php?id=${purchase_order.purchase_order_id}`}>Packing Slip</a>
	      {!!purchase_order.buy_inventory && <>
                |
                <a target="_blank" href={`/po_warehouse_receivables_html.php?id=${purchase_order.purchase_order_id}`}>Warehouse Receivables</a>
	      </>}
            </small>}
          {purchase_order.date_shipped > 0 ? <span><br /><small>Date Shipped: {formatTimestampWithTimezone(purchase_order.date_shipped)}</small></span> : null}
          {purchase_order.shipping_cost ?  <span><br /><small>Shipping: {purchase_order.shipping_cost}</small></span> : null}
          {purchase_order.shipping_overage ? <span><br /><small>Overrun/Underrun: {purchase_order.shipping_overage}</small></span> : null}
          {purchase_order.shipping_tracking_id ? <span><br /><small>Tracking ID: {this.renderTrackingIds()} </small></span> : null}
          {purchase_order.tracking_numbers && purchase_order.tracking_numbers.length > 0 ?
            <span><br /><small>From Promostandards:
              {purchase_order.tracking_numbers.map((t, idx) =>
                <a key={idx} target="_blank" href={`http://google.com/search?q=${t}`} style={{'color': 'red'}}>{idx > 0 ? `, ` : null}{t}</a>
              )}
            </small></span>
          : null}
	  </div>
        </td>
        <td>
          <Select disabled={!hasCapability('MODIFY-PURCHASE-ORDER')}
            options={filter(statusesOptions, ({ key, value }) => {
              return key === purchase_order.status_id || ['Problem', 'Follow Up'].indexOf(value) === -1;
            })}
            value={purchase_order.status_id}
            change={onUpdate(purchase_order.purchase_order_id, 'status_id', purchase_order.status_id)}
            isDisabled={!canModifyBill && some(statuses, { value: 'Billed', key: purchase_order.status_id })}
            withMarginBottom
          />
          <Select disabled={!hasCapability('MODIFY-PURCHASE-ORDER')}
            options={[
              {key: 'OK', value: 'OK'},
              {key: 'PROBLEM', value: 'Problem'},
              {key: 'FOLLOWUP', value: 'Follow Up'},
            ]}
            value={purchase_order.standing}
            change={onUpdate(purchase_order.purchase_order_id, 'standing', purchase_order.standing)}
            withMarginBottom
          />

          {isArtworkOrService(purchase_order.po_type) ? null :
            <Select className="select-without-arrow" disabled={true} options={proof_statuses}
              value={purchase_order.proof_status_id}
              withMarginBottom
            />}
        </td>
        <td>
          <Select disabled={!hasCapability('MODIFY-PURCHASE-ORDER')} options={users} value={purchase_order.sales_rep_id} change={onUpdate(purchase_order.purchase_order_id, 'sales_rep_id', purchase_order.sales_rep_id)} />
        </td>
        <td>
          <div className="button-group stacked tiny">
            <div onClick={() => {
              if (window.heap) {
                console.log(pick(purchase_order, [
                  'can_send_electronically', 'status_id', 'purchase_order_id', 'po_type', 'division_name',
                  'form_number',
                ]));
                window.heap.track('Click - Email/Update/Send PO', pick(purchase_order, [
                  'can_send_electronically', 'status_id', 'purchase_order_id', 'po_type', 'division_name',
                  'form_number', 'is_regenerated_after_submission', 'ext_po_id'
                ]));
              }
            }}>
              {
                purchase_order.can_send_electronically ? (
                  <SendEPO
                      onClick={() => {
                        if (purchase_order.allow_resubmit || isEmpty(purchase_order.epo_submission_status)) {
                          this.handleShowInventoryWarningPopup(true);
                        } else {
                          this.handlePopup(true);
                        }
                      }}
                      status={purchase_order.epo_submission_status}
                      allowResubmit={purchase_order.allow_resubmit}
                    />
                ) : (purchase_order.ext_po_id ? (
                  purchase_order.is_regenerated_after_submission ? (
                    <a
                      className="button"
                      target="_blank"
                      rel="opener"
                      href={`/send_email.php?parent_type=purchase_order&parent_id=${purchase_order.purchase_order_id}`}
                    >
                      Email Update to Supplier
                    </a>
                  ) : (
                    <button className="button" disabled={true}><PS />Sent to Supplier</button>
                  )
                ) : (
                  <a
                    className="button"
                    target="_blank"
                    rel="opener"
                    href={`/send_email.php?parent_type=purchase_order&parent_id=${purchase_order.purchase_order_id}`}
                  >
                    Email to Supplier
                  </a>
                ))
              }
            </div>
            {(!purchase_order.ext_po_id && !purchase_order.can_send_electronically) && <DropdownMenu
              ref={(dropdown) => { this.dropdown = dropdown; }}
              options={mark_as_options} align="right"
              style={{width: '100%', height: 'auto'}}
              optionsProps={{style: {minWidth: '150px'}}}
            >
              <MenuTrigger>
                <a disabled={!hasCapability('MODIFY-PURCHASE-ORDER')}
                  ref="mark_as_button" className="button"
                  onClick={(e) => {e.preventDefault();}}>Mark as...</a>
              </MenuTrigger>
            </DropdownMenu>}
            <a className="button" onClick={e => { this.handleClickNote(e, purchase_order.purchase_order_id, project,purchase_order);}}>Notes</a>
            {showOrderStatusButton ? <a className="button" onClick={e => { this.handleCheckOrderStatus(e); }}>Check Order Status</a> : null}
            <a className="button alert" onClick={this.handleClickDelete} disabled={!hasCapability('DELETE-PURCHASE-ORDER')}>Delete</a>
          </div>
        </td>
        {
          this.state.showInventoryWarningPopup && <InventoryWarningPopup
            purchaseOrder={purchase_order}
            insufficientItems={this.state.insufficientItems}
            onClose={() => this.handleShowInventoryWarningPopup(false)}
            hasError={this.state.inventoryCheckError}
            checkInventory={() => this.handleShowInventoryWarningPopup(true)}
            handleResendEPO={() => this.props.onSendPurchaseOrder(
              this.props.purchase_order.purchase_order_id,
              this.props.purchase_order.form_number,
              () => {
                this.handlePopup(true);
                this.setState({
                  insufficientItems: [],
                  inventoryCheckError: false,
                });
              }
            )}
          />
        }
        {this.state.showPopup &&
          <EPOPopup
            status={purchase_order.epo_submission_status}
            purchaseOrder={purchase_order}
            onClose={() => this.handlePopup(false)}
            handleResendEPO={() => onSendElectronicPurchaseOrder(purchase_order.purchase_order_id, true)}
          />}
      </tr>
    );
  }
}

const SendPO = ({ onClick, sending }) => {
  if (sending) {
    return <a className="button"><Img src="/images/gears.gif" style={{ marginRight: '0.5rem', maxHeight: '1rem' }} />Sending to Supplier</a>;
  }
  return <a className="button" onClick={onClick}><PS iconColor='white' />Send to Supplier</a>;
};

const SendEPO = ({ onClick, status, allowResubmit }) => (
  <StatusWrapper status={status}>
    <EPOButton onClick={onClick} status={status} allowResubmit={allowResubmit} />
  </StatusWrapper>
);

const StatusWrapper = ({ children, status }) => {
  const renderFailedButton = () => (
    <div style={{ backgroundColor: colors.errors.lightest, padding: 8, paddingTop: 16, margin: -8, marginBottom: '0.3rem', marginTop: 0, display: 'flex', flexDirection: 'column', gap: 8, borderRadius: '5px', alignItems: 'center' }}>
      <div style={{ fontSize: '12px', lineHeight: '15px', color: colors.errors.dark }}>There was an error sending the PO.</div>
      {children}
    </div>
  )

  const renderPendingButton = () => (
    <>
      {children}
      <div style={{ backgroundColor: colors.secondary4['10'], padding: 16, borderRadius: '5px', fontSize: '12px', lineHeight: '15px', color: colors.neutrals.darkest }}>Sending takes a few minutes, but you don’t have to stay on this page.</div>
    </>
  )

  if (status === 'FAILED') {
    return renderFailedButton();
  }
  if (status === 'PENDING') {
    return renderPendingButton();
  }
  return children;
};

const getButtonConfig = (status, allowResubmit) => {
  const baseStyles = {
    width: '100%',
    fontSize: 10,
    fontFamily: "'skufont-regular', 'sans-serif', 'Roboto'",
    marginBottom: '0.2rem',
    gap: 4,
    alignItems: 'center'
  };

  switch (status) {
    case 'FAILED':
      return {
        text: 'Click to Fix',
        styles: { ...baseStyles, backgroundColor: '#5CA3B6', borderColor: '#5CA3B6' },
        icon: <PS iconColor='white' style={{ height: 24 }} />,
      };
    case 'PENDING':
      return {
        text: 'Sending...',
        styles: { ...baseStyles, backgroundColor: colors.primary1['85'], borderColor: colors.primary1['85'] },
        icon: <SkubotSpinner size='button' color='white' />,
      };
    case 'SUCCESS':
      return {
        text: allowResubmit ? 'Re-send to Supplier' : 'Sent to Supplier',
        styles: baseStyles,
        variant: !allowResubmit ? 'disabled' : '',
        icon: <PS iconColor={colors.neutrals[70]} style={{ height: 24 }} />,
      };
    default:
      return {
        text: 'Send to Supplier',
        styles: { ...baseStyles, backgroundColor: '#5CA3B6', borderColor: '#5CA3B6' },
        icon: <PS iconColor='white' style={{ height: 24 }} />,
      };
  }
};

const EPOButton = ({ onClick, status, allowResubmit }) => {
  const { text, styles, icon, variant } = getButtonConfig(status, allowResubmit);
  return (
    <Button
      onClick={onClick}
      size='tiny'
      inline_flex
      center
      style={{ ...styles.buttonBase, ...styles }}
      disabled={variant === 'disabled'}
      variant={variant}
    >
      {icon}
      {text}
    </Button>
  );
}

export default PurchaseOrder;
