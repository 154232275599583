import React, { useEffect, useRef, useState } from 'react';
import { InstantSearch, Configure } from 'react-instantsearch';
import aa from 'search-insights';

import CustomSearchBox from './CustomSearchBox';
import CustomMenuSelect from './CustomMenuSelect';
import CustomInfiniteHits from './CustomInfiniteHits';
import CustomPriceRange from './CustomPriceRange';
import CustomHierarchicalMenu from './CustomHierarchicalMenu';
import CustomToggle from './CustomToggle';
import CustomClearRefinements from './CustomClearRefinements';
import CustomBreadcrumb from './CustomBreadcrumb';
import { useSize } from '../../hooks/useSize';
import { min } from 'lodash';
import { searchClient, indexName } from './Algoliasearch';

const itemsPerPage = 40;
const SIDEBAR_MIN_WIDTH = 174;

const ProductSuggestionSearch = ({ order, loading, onAddItem, onDeleteItem, divisions, suggestion, setSuggestion }) => {
  const userToken = order.tenant_id;
  const [range, setRange] = useState({ min: '', max: '' });
  const menuRef = useRef(null);
  const [isShowMore, setIsShowMore] = useState(false) //force re-render when show more is clicked
  useEffect(() => {
    aa('setUserToken', userToken);
  }, [])
  const [ref, size] = useSize();

  const sidebarTop = min([0, ref.current?.clientHeight - menuRef.current?.clientHeight - 20]);

  return (
    <div style={{ display: 'flex', height: '100%', paddingTop: '20px', gap: 16, overflowY: 'auto', alignItems: 'flex-start' }} ref={ref}>
      <InstantSearch
        searchClient={searchClient} indexName={indexName} future={{ preserveSharedStateOnUnmount: true }} insights={true} >
        <div ref={menuRef} style={{ minWidth: SIDEBAR_MIN_WIDTH, display: 'flex', flex: 1, flexDirection: 'column', gap: 24, position: 'sticky', top: `${sidebarTop}px`, zIndex: 1 }} >
          <CustomMenuSelect attribute='division_id' label="Supplier" divisions={divisions} />
          <CustomToggle attribute='currency' defaultLabel="USD" />
          <CustomPriceRange range={range} setRange={setRange} />
          <CustomHierarchicalMenu
            attributes={[
              'categories.lvl0',
              'categories.lvl1',
              'categories.lvl2'
            ]}
            limit={7}
            label="Category"
            showMore={true}
            onShowMore={setIsShowMore}
          />
          <CustomClearRefinements setRange={setRange} />
        </div>
        <div style={{ flex: 3 }}>
          <CustomSearchBox keyword={suggestion} setSuggestion={setSuggestion} />
          {/* <CustomBreadcrumb
            attributes={[
              'categories.lvl0',
              'categories.lvl1',
              'categories.lvl2'
            ]}
          /> */}
          <CustomInfiniteHits loading={loading} order={order} onAddItem={onAddItem} onDeleteItem={onDeleteItem} divisions={divisions} size={size} sidebarMinWidth={SIDEBAR_MIN_WIDTH} range={range} />
        </div>
        <Configure userToken={userToken} hitsPerPage={itemsPerPage} clickAnalytics={true} />
      </InstantSearch >
    </div>
  );
};

export default ProductSuggestionSearch;
