import { colors, Label, ArrowIcon, ChevronIcon, EllipsisIcon } from '@commonsku/styles';
import { useHierarchicalMenu } from 'react-instantsearch';
import { skuFont } from '../helpers/styles';
import { useEffect } from 'react';

function CustomHierarchicalMenu({ label, onShowMore, ...props }) {
  const {
    items,
    isShowingMore,
    canToggleShowMore,
    refine,
    toggleShowMore,
  } = useHierarchicalMenu(props);
  let parentItem = null;
  let parentItems = [];

  useEffect(() => {
    if (isShowingMore) {
      onShowMore(true);
    }
  }, [isShowingMore, onShowMore]);

  const findSelectedItem = (items) => {
    parentItems = items;
    for (const item of items) {
      if (item.isRefined) {
        if (item.data && item.data.length > 0) {
          parentItem = item;
          const refinedChild = findSelectedItem(item.data);
          if (refinedChild) {
            return refinedChild;
          }
        }
        return item;
      }
    }
    return null;
  };
  const selectedItem = findSelectedItem(items);

  const getItems = () => {
    if (selectedItem && selectedItem.data && selectedItem.data.length > 0) {
      return selectedItem.data;
    }
    return parentItems;
  };

  const itemData = getItems();
  const getLabel = () => {
    if (selectedItem && selectedItem.data && selectedItem.data.length > 0) {
      return selectedItem.label;
    }
    if (parentItem && parentItem.label) {
      return parentItem.label;
    }
    return label;
  };
  return (
    <div>
      <div style={{ display: 'flex', alignItems: 'center', gap: '4px' }}>
        {parentItem && <ArrowIcon direction="left" onClick={() => refine(parentItem.value)} />} <Label style={{ textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap' }}> {getLabel()}</Label>
      </div>
      <div>
        {itemData.map((option) => (
          <div style={{ backgroundColor: option.isRefined ? 'white' : 'transparent', display: 'flex', justifyContent: 'space-between', gap: '8px', paddingLeft: '4px', paddingRight: '4px', height: '32px', alignItems: 'center' }} key={option.value} onClick={() => refine(option.value)}>
            <Label style={{ textOverflow: 'ellipsis', fontFamily: skuFont, overflow: 'hidden', whiteSpace: 'nowrap', color: option.isRefined ? colors.neutrals[100] : colors.primary1[65], fontWeight: option.isRefined ? '600' : '400' }} >{option.label}</Label>
            {!option.isRefined && <ChevronIcon />}
          </div>
        ))}
        {canToggleShowMore && !isShowingMore && itemData.length >= props.limit &&
          <div style={{ display: 'flex', justifyContent: 'space-between', gap: '8px', paddingLeft: '4px', paddingRight: '4px', height: '32px', alignItems: 'center' }} onClick={() => toggleShowMore()}>
            <Label style={{ textOverflow: 'ellipsis', fontFamily: skuFont, overflow: 'hidden', whiteSpace: 'nowrap', color: colors.primary1[65], fontWeight: '400' }} >More Categories</Label>
            <EllipsisIcon />
          </div>
        }
      </div>
    </div>
  );
}

export default CustomHierarchicalMenu;