import React, {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { createDeleteTemp } from "../../../actions/temp";
import { BASE_ZINDEX } from "../../../popup-factory";
import { XIcon } from "@commonsku/styles";
import Initialization from "./Initialization";
import Questionnaire from "./Questionnaire";
import SelectedShopPage from "./SelectedShopPage";
import CreateShopPage from "./CreateShopPage";
import TemplateSelection from "./TemplateSelection";
import PopUpInfoMenu from "../popups/PopUpInfoMenu";
import ShopFeaturesDesc from "./ShopFeaturesDesc";

const style = (showPopup: boolean): CSSProperties => ({
  display: "block",
  position: "fixed",
  bottom: 0,
  top: 0,
  left: 0,
  right: 0,
  zIndex: BASE_ZINDEX,
  textAlign: "center",
  backgroundColor: "#E6EFF2",
  overflow: "auto",
  transform: showPopup ? "translateY(0)" : "translateY(-100%)",
  transition: "transform 0.5s ease-in-out",
});

const CreateShop = () => {
  const ref = useRef(null);
  const dispatch = useDispatch();
  const [showPopup, setShopPopup] = useState<boolean>();
  const [popupHeight, setPopupHeight] = useState<number>(0);
  const create_shop = useSelector((state: any) => state.temp?.create_shop);
  const [inPopupCustomRouting, setInPopupCustomRouting] = useState({
    route: "initialize",
    selectedShopType: null,
    shopName: null,
    clientId: null,
  });

  useEffect(() => {
    if (create_shop) {
      setShopPopup(true);
    }
  }, []);

  useLayoutEffect(() => {
    const element = ref.current;
    if (!element) return;

    const resizeObserver = new ResizeObserver(() => {
      setPopupHeight(element.getBoundingClientRect().height);
    });

    resizeObserver.observe(element);
    document.body.style.overflow = "hidden";

    return () => {
      resizeObserver.unobserve(element);
      resizeObserver.disconnect();
      document.body.style.overflow = "auto";
    };
  }, []);

  const handleCustomRouting = (
    newRoute: string,
    selectedShopType?: string,
    shopName?: string,
    clientId?: string
  ) => {
    setInPopupCustomRouting((prevState) => ({
      route: newRoute,
      selectedShopType:
        newRoute === "selectedShopPage" || "createShopPage"
          ? selectedShopType ?? prevState.selectedShopType
          : null,
      shopName:
        newRoute === "templateSelection"
          ? shopName ?? prevState.shopName
          : null,
      clientId:
        newRoute === "templateSelection"
          ? clientId ?? prevState.clientId
          : null,
    }));
  };

  const handleClosePopup = () => {
    setShopPopup(false);
    setTimeout(() => {
      dispatch(createDeleteTemp("create_shop"));
    }, 500);
  };
  const redirect = create_shop === "no_redirect" ? false : true;
  return (
    <div tabIndex={-1} aria-hidden="true" style={style(showPopup)} ref={ref}>
      <div className="create-shop">
        <div className="shops-popup">
          <XIcon
            id="fixedbutton"
            style={{ cursor: "pointer" }}
            size="large"
            onClick={(e) => {
              e.preventDefault();
              handleClosePopup();
            }}
          />
          {inPopupCustomRouting.route === "initialize" && (
            <Initialization
              handleCustomRouting={handleCustomRouting}
              create_shop={create_shop}
              setShopPopup={setShopPopup}
            />
          )}
          {inPopupCustomRouting.route === "questionnaire" && (
            <Questionnaire handleCustomRouting={handleCustomRouting} />
          )}
          {inPopupCustomRouting.route === "selectedShopPage" && (
            <SelectedShopPage
              handleCustomRouting={handleCustomRouting}
              shopType={inPopupCustomRouting.selectedShopType}
            />
          )}
          {inPopupCustomRouting.route === "createShopPage" && (
            <CreateShopPage
              handleCustomRouting={handleCustomRouting}
              shopType={inPopupCustomRouting.selectedShopType}
            />
          )}
          {inPopupCustomRouting.route === "templateSelection" && (
            <TemplateSelection
              shopType={inPopupCustomRouting.selectedShopType}
              client_id={inPopupCustomRouting.clientId}
              redirect={redirect}
              shop_name={inPopupCustomRouting.shopName}
            />
          )}
        </div>
        {inPopupCustomRouting.route === "initialize" && (
          <PopUpInfoMenu
            infoPanelHeight={popupHeight * 0.73}
            menuButtonText="Compare Shop types and learn more"
            innerContainerWidth={70}
            infoMenuSize="FULL"
            showDoneButton={false}
          >
            <ShopFeaturesDesc />
          </PopUpInfoMenu>
        )}
      </div>
    </div>
  );
};

export default CreateShop;
