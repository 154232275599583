import { Button, colors } from "@commonsku/styles";
import React, { ReactNode, useState } from "react";
import styled from "styled-components";

interface PopUpInfoMenuProps {
  infoMenuSize: "FULL" | "DEFAULT" | "LARGE";
  infoPanelHeight: number;
  children: ReactNode;
  menuButtonText: string;
  innerContainerWidth?: number;
  showDoneButton?: boolean;
}

const Backdrop = styled.div<{ isOpen: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? "100%" : "0")};
  background: rgba(0, 0, 0, 0.3);
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  overflow: hidden;
  z-index: 9999;
`;

const MenuButton = styled.button<{
  isOpen: boolean;
  popupHeight: number;
  infoMenuSize: "FULL" | "DEFAULT" | "LARGE";
}>`
  background-color: ${colors.white} !important;
  border-radius: 15px 15px 0 0 !important;
  z-index: 9999;
  color: ${colors.primary1["70"]} !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  position: absolute;
  bottom: ${({ isOpen, popupHeight, infoMenuSize }) =>
    isOpen
      ? `${
          popupHeight +
          (infoMenuSize === "LARGE" ? -1 : infoMenuSize === "FULL" ? 119 : 69)
        }px`
      : "0px"};
  padding: 8px !important;
  min-width: 159px;
  transition: bottom 0.3s ease-in-out;
`;

const GuidelineContainer = styled.div<{
  isOpen: boolean;
  popupHeight: number;
  infoMenuSize: "FULL" | "DEFAULT" | "LARGE";
}>`
  position: absolute;
  bottom: ${({ infoMenuSize }) => (infoMenuSize === "FULL" ? 0 : "-50px")};
  width: ${({ infoMenuSize }) => (infoMenuSize === "FULL" ? "100%" : "980px")};
  height: ${({ isOpen, popupHeight, infoMenuSize }) =>
    isOpen
      ? `${popupHeight + (infoMenuSize === "LARGE" ? 50 : 120)}px`
      : "0px"};
  background: ${colors.white};
  overflow: hidden;
  z-index: 9999;
  transition: height 0.3s ease-in-out;
`;

const InnerContainer = styled.div<{ innerContainerWidth?: number }>`
  max-width: ${({ innerContainerWidth }) =>
    innerContainerWidth ? `${innerContainerWidth}%` : "65%"};
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin: auto !important;
  overflow: hidden;
`;

/**
 *
 * This component is designed to display an additional info menu within popups. Can be rendered both full screen or a regular popups.
 * @param infoMenuSize Rendered size of the info menu, depnding if you are rendering this component inside the full screen popup or regular popup.
 * @param infoPanelHeight The height of the area that is rendered when the menu is open.
 * @param children A React node or any content to be rendered within the panel body.
 * @param menuButtonText The text displayed on the button used to open the information panel.
 * @param innerContainerWidth The width of the rendered body, including children and the information area.
 * @param showDoneButton A flag to control the visibility of the "Done" button within the info area. It is true by default but can be hidden. The backdrop and menu button also include a close handler.
 */

const PopUpInfoMenu = ({
  infoMenuSize,
  infoPanelHeight,
  children,
  menuButtonText,
  innerContainerWidth,
  showDoneButton = true,
}: PopUpInfoMenuProps) => {
  const [guidelineIsOpen, setGuidelineIsOpen] = useState<boolean>(false);

  return (
    <>
      <Backdrop
        isOpen={guidelineIsOpen}
        onClick={() => setGuidelineIsOpen(false)}
      />
      <div
        style={{
          position: infoMenuSize !== "FULL" ? "relative" : "unset",
          display: "flex",
          justifyContent: "center",
          width: "100%",
        }}
      >
        <GuidelineContainer
          isOpen={guidelineIsOpen}
          popupHeight={infoPanelHeight}
          infoMenuSize={infoMenuSize}
        >
          <InnerContainer innerContainerWidth={innerContainerWidth}>
            <div
              style={{
                flexGrow: 1,
                minHeight: 0,
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              {children}
            </div>
            {showDoneButton && (
              <Button
                onClick={() => setGuidelineIsOpen(false)}
                style={{ marginBottom: 48 }}
                size="medium"
                id="shop-popUpInfoMenu-done.btn"
              >
                DONE
              </Button>
            )}
          </InnerContainer>
        </GuidelineContainer>

        <MenuButton
          isOpen={guidelineIsOpen}
          popupHeight={infoPanelHeight}
          infoMenuSize={infoMenuSize}
          onClick={() => setGuidelineIsOpen(!guidelineIsOpen)}
        >
          {menuButtonText}
        </MenuButton>
      </div>
    </>
  );
};

export default PopUpInfoMenu;
