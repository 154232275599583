import _ from 'lodash';
import React, { Component } from 'react';
import { connect } from 'react-redux';

import { TabBar, Tab, Button, Theme, themeOptions, Popup, Select, Row, Col, ShowPopup, Artwork } from '@commonsku/styles';
import Files from './Files';
import ReskuSelect from './Select';
import UploadFilesPopup from './UploadFilesPopup';

import { closePopup, createUploadFilesPopup } from '../actions/popup';
import { createAddTemp } from '../actions/temp';
import { createFinalizeAddProductImage } from '../actions/index';
import { addProof } from '../actions/purchase_order_proof';

import { getFolderOptions } from '../selectors/dropdowns';
import { getItemImagesByItemId, getProductImagesByItemId } from '../selectors';
import { getImageSrc } from '../utils';
import { getIdentityUtils } from '../utils';

import { BASE_ZINDEX } from '../popup-factory';
import { ImageEditorPopup } from './image-editor/ImageEditorPopup';
import { createLoadFoldersList } from '../actions/folders';
import { folderSorter } from '../redux/folders';

const DEFAULT_SELECTED_TAB_BY_PARENT_TYPE = {
  'JOB': 'project',
  'PURCHASE-ORDER': 'project',
  'SHOP': 'shop',
};

class SelectFilePopup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedTab: DEFAULT_SELECTED_TAB_BY_PARENT_TYPE[props.parent_type] ?? 'item',
      folder_id: 'all',
      item_images: props.item_images,
      showEditorPopup: false,
      showUploadPopup: false,
    };
    _.bindAll(this, ['handleChangeFolder']);
  }

  handleChangeFolder(folder_id) {
    this.setState({ folder_id });
  }

  componentDidMount() {
    this.props.onLoadFoldersList(this.props.job_id, this.props.client_id);
  }

  componentDidUpdate() {
    const element = document.getElementById('scrollableDiv');
    if (!!element) {
      element.scrollTo(0, 0);
    }
  }

  static getDerivedStateFromProps(
    nextProps,
    prevState,
  ) {
    if (nextProps.length > 0) {
      if (0 === prevState.item_images.length || nextProps.item_images[0].item_image_id !== prevState.item_images[0].item_image_id) {
        return { item_images: nextProps.item_images };
      }
    }
    return null;
  }

  canEditImage = () => {
    return true;
  };

  renderImageEditor = () => {
    if (this.canEditImage()) {
      this.setState({ showEditorPopup: true });
    }
  };

  getTabs = (parent_type, client_id) => {
    const tabs = client_id ? [
      {
        key: 'client',
        label: 'Client Files'
      },
    ] : [];

    switch (parent_type) {
      case 'JOB':
      case 'PURCHASE-ORDER':
        return ([
          {
            key: 'project',
            label: 'Project Files'
          },
          ...tabs,
        ]);
      case 'SHOP':
        return ([
          {
            key: 'shop',
            label: 'Shop Files'
          },
          ...tabs,
        ]);
      default:
        return ([
          {
            key: 'item',
            label: 'Item Files'
          },
          {
            key: 'project',
            label: 'Project Files'
          },
          ...tabs,
        ]);
    }
  };

  renderTabs() {
    const { clientFolders, jobFolders, parent_id, parent_type, client_id, resku, onCreateUploadFilesPopup, register } = this.props;
    const { selectedTab, folder_id } = this.state;
    const no_upload = register === 'message_file_id' || register === 'collaborate_file_id';

    let optionFolders = clientFolders;
    if (selectedTab === 'project') {
      optionFolders = jobFolders;
    }

    optionFolders.sort(folderSorter);

    return (
      <Row>
        <Col xs={6}>
          <TabBar>
            {this.getTabs(parent_type, client_id).map((tab, index) => <Tab key={index}
              selected={tab.key == selectedTab}
              onClick={() => { this.setState({ selectedTab: tab.key, folder_id: 'all' }); }}>
              {tab.label}
            </Tab>)}
          </TabBar>
        </Col>
        <Col xs={4}>
          {selectedTab === 'client' || selectedTab === 'project' && resku ?
            <ReskuSelect
              options={optionFolders}
              value={folder_id}
              change={this.handleChangeFolder}
              style={{ width: '150px', float: 'right', 'zIndex': 1008, marginTop: '10px' }}
            />
            : selectedTab === 'client' || selectedTab === 'project' ?
              <div style={{ marginTop: '10px' }}>
                <Select
                  options={optionFolders}
                  value={optionFolders.filter(f => f.value === folder_id)}
                  onChange={option => this.handleChangeFolder(option.value)}
                />
              </div>
              : null}
        </Col>
        <Col xs={2}>
          {!no_upload && resku ? <Button
            style={{ float: 'right' }}
            secondary
            onClick={() => this.setState({ showUploadPopup: true })}>
            Upload
          </Button>
            : (!no_upload ?
              <ShowPopup popup={UploadFilesPopup} parent_id={parent_id} parent_type={parent_type} client_id={client_id} resku={false} render={({ onClick }) => {
                return <Button onClick={onClick} style={{ float: 'right' }}>Upload</Button>;
              }} />
              : null)
          }
        </Col>
      </Row>
    );
  }

  renderTabContent() {
    const { job_id, shop_id, client_id, onSelectFile, register, product_images, item_images, autoHideOnError } = this.props;
    const { selectedTab, folder_id } = this.state;
    const exclude_file_id = item_images.length > 0 ? item_images[0].file_id : null;
    const available_product_images = product_images.filter(i => i.file_id !== exclude_file_id);

    let tbd = false;
    if (register !== 'message_file_id' && register !== 'collaborate_file_id' && register !== 'note_file_id') {
      if (selectedTab === 'project' || selectedTab === 'shop' || (selectedTab === 'client' && folder_id === 'all')) {
        tbd = true;
      }
    }

    switch (selectedTab) {
      case 'project':
        return <Files type="project" job_id={job_id} folder_id={folder_id != 'all' ? folder_id : null} onSelectFile={onSelectFile} exclude_file_ids={exclude_file_id} tbd={tbd} />;
      case 'shop':
        return <Files type="shop" shop_id={shop_id} onSelectFile={onSelectFile} exclude_file_ids={exclude_file_id} tbd={tbd} />;
      case 'client':
        return <Files type="client" client_id={client_id} folder_id={folder_id != 'all' ? folder_id : null} exclude_file_ids={exclude_file_id} onSelectFile={onSelectFile} tbd={tbd} />;
      default:
        return <Files
          type="item" onSelectFile={onSelectFile} files={available_product_images} tbd={tbd}
          autoHideOnError={(autoHideOnError || []).indexOf('item') > -1}
        />;
    }
  }

  render() {
    const { job_id, shop_id, client_id, onClose, onClosePopup, onSelectFile, resku, index, item_images, parent_id, parent_type, popupClassName = 'reveal large' } = this.props;
    const { showEditorPopup, showUploadPopup } = this.state;
    const { popupStyle = {
      display: 'block',
      zIndex: BASE_ZINDEX + index,
      height: 'initial',
      transition: 'width 0.1s, height 0.1s'
    } } = this.props;

    if (resku) {
      return (<>
        {showEditorPopup && <ImageEditorPopup
          imageSource={getImageSrc(item_images[0].image, 'large')}
          onClose={() => this.setState({ showEditorPopup: false })}
          onSave={onSelectFile}
          parent_id={parent_id}
          parent_type={parent_type}
          logo_parent_id={!!job_id ? job_id : (!!shop_id ? shop_id : parent_id)}
          logo_parent_type={!!job_id ? 'JOB' : (!!shop_id ? 'SHOP' : parent_type)}
          account_id={client_id}
          account_type="CLIENT"
          overlayZIndex={BASE_ZINDEX + index + 1}
          zIndex={BASE_ZINDEX + index + 2}
        />}
        {showUploadPopup && <UploadFilesPopup
          onClosePopup={() => this.setState({ showUploadPopup: false })}
          parent_id={parent_id}
          parent_type={parent_type}
          client_id={client_id}
          resku={resku}
          web_upload={true}
          index={BASE_ZINDEX + index + 1}
        />}
        <div className={popupClassName} style={popupStyle} data-reveal aria-labelledby="modalTitle" aria-hidden="true" role="dialog">
          <div className="row" id="SelectFileTitle">
            <h3>Select {['ITEM'].includes(parent_type) ? 'Image' : 'File'}</h3>
            <div className="small-12">
              <a className="button" style={{ position: 'fixed', right: '1rem', top: '1rem' }} onClick={e => onClosePopup()}>Close</a>
            </div>
          </div>
          <Theme theme={themeOptions}>
            <div id='scrollableDiv' style={{ overflow: 'auto', height: '100%' }}>
              <div className="row">
                {item_images.length > 0 && <div key={item_images[0].item_image_id} className="small-6 medium-4 large-2 columns end">
                  <Artwork
                    picture={getImageSrc(item_images[0].image, 'medium')}
                    onEdit={this.canEditImage() ? this.renderImageEditor : undefined}
                  />
                </div>}
              </div>
              <div className="row" style={{ height: ['JOB', 'PURCHASE-ORDER'].includes(parent_type) ? '500px' : '300px' }}>
                {!(parent_type == 'JOB' || parent_type == 'PURCHASE-ORDER')
                  && <p >Click images below to replace</p>}
                {this.renderTabs()}
                {this.renderTabContent()}
              </div>
            </div>
          </Theme>
        </div>
      </>);
    }

    return (
      <Theme>
        <Popup
          style={{ padding: '5px 25px' }}
          onClose={onClose}
          title="Select File"
          controls={<Button onClick={onClose}>Close</Button>}>
          <div className="row" style={{ overflow: 'auto' }}>
            {this.renderTabs()}
            {this.renderTabContent()}
          </div>
        </Popup>
      </Theme>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  let clientFolders = [{ key: 'all', value: 'All Files' }].concat(getFolderOptions(state).filter(f => f.type === 'CLIENT'));
  let jobFolders = [{ key: 'all', value: 'All Files' }].concat(getFolderOptions(state).filter(f => f.type === 'JOB'));

  return {
    identity: state.identity,
    job_id: ownProps.job_id ? ownProps.job_id : Object.keys(state.entities.projects || {})[0],
    shop_id: ownProps.shop_id ? ownProps.shop_id : Object.keys(state.entities.shops || {})[0],
    client_id: ownProps.client_id ? ownProps.client_id : Object.values(state.entities.projects || {})[0]?.account_id,
    clientFolders: ownProps.resku ? clientFolders : clientFolders.map(f => ({ value: f.key, label: f.value, type: 'CLIENT' })),
    jobFolders: ownProps.resku ? jobFolders : jobFolders.map(f => ({ value: f.key, label: f.value, type: 'JOB' })),
    item_images: getItemImagesByItemId(state, ownProps),
    product_images: getProductImagesByItemId(state, ownProps)
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const onClosePopup = ownProps.onClosePopup ?? (() => {
    dispatch(closePopup());
  });

  return {
    onClosePopup: onClosePopup,
    onSelectFile: (file) => {
      if (ownProps.onSelectFile != null) {
        ownProps.onSelectFile(file);
        onClosePopup();
        return;
      }

      let { closeOnSelect, resku, parent_type, onClose } = ownProps;
      if (parent_type === 'ITEM') {
        dispatch(createFinalizeAddProductImage(ownProps.parent_id, file.file_id));
      } else if (parent_type === 'PURCHASE-ORDER') {
        dispatch(addProof(ownProps.parent_id, file));
      } else {
        // used in send_email.tpl
        if (ownProps.register === 'user_file_id') {
          var attachments = document.querySelector('input[name=attachment_file_ids]');
          var attachmentList = document.querySelector('div.attachment-list');
          attachments.value = (attachments.value + "," + file.file_id);
          var html = "<p data-file-id='" + file.file_id + "'>" + file.file_display_name + " <a href='#' class='remove-file'>Remove</a></p>";
          attachmentList.insertAdjacentHTML('beforeend', html);
          attachmentList.style.display = 'block';
        } else {
          dispatch(createAddTemp(ownProps.register, file.file_id));
        }
      }

      // temp hack to fix S40-827. Should refactor these logic out of SelectFilePopup
      if (_.isUndefined(closeOnSelect)) {
        if (!resku) {
          closeOnSelect = true;
        } else if (parent_type === 'JOB') {
          onClosePopup();
        }
      }
      if (closeOnSelect) {
        if (onClose) {
          onClose();
        } else {
          onClosePopup();
        }
      }
    },
    onCreateUploadFilesPopup: () => {
      if (ownProps.parent_type === 'ITEM') {
        dispatch(createUploadFilesPopup(ownProps.parent_id, ownProps.parent_type, ownProps.client_id, true, true));
      } else {
        dispatch(createUploadFilesPopup(ownProps.parent_id, ownProps.parent_type, ownProps.client_id));
      }
    },
    onLoadFoldersList: (job_id, client_id) => {
      dispatch(createLoadFoldersList(job_id, 'JOB', client_id));
    },
  };
};

const ConnectedSelectFilePopup = connect(mapStateToProps, mapDispatchToProps)(SelectFilePopup);
export default ConnectedSelectFilePopup;
