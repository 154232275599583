let localConfig;
try {
  localConfig = require('./config.local').default;
} catch (e) {
  localConfig = {};
}

const config = {
  // also used in web/js/distributor_promostandards.js
  websocketServer: 'wss://wss.commonskudev.com/websocket/',
  peerjsServer: 'wss.commonskudev.com',
  promostandards_api_url: 'https://promo.commonsku.com/api',
  inboundEmail: {
    integrationDomain: 'commonskudev.com',
    whitelist: [
      //'vandelay', 'rightsleeve', 'metronbranding',
    ],
  },
  promodata_url: 'https://proxy.commonskudemo.com/promodata/v1',
  unsplashAppName: 'commonsku-dev',
  canAssumeAnyUser: (assume_by) => {
    return config.environment !== 'production';
  },
  googleMapsApiKey: '',
  addressAutocompleteCountries: ["us", "ca", "au", "uk", "nz"],
  ps_sort_options: [
    { key: 'min_net_price', value: 'Cost: Low to High' },
    { key: '-max_net_price', value: 'Cost: High to Low' },
    { key: '-popularity', value: 'Popularity' },
  ],
  po_financials_test_prefix: '***test***',
  skummunity_base_url: 'https://skummunity.commonsku.com',
  skummunity_return_path: '/categories',
  algolia: {
    applicationID: '',
    searchKey: '',
    index: '',
    cadDivisions: [],
  },
  payment_cc_fee_percentage: 3.0,
  sanmar: {
    show_modal: true,
    beta_exclude: [],
  },
  ...localConfig,
};

export default config;
