import { ShoppingCartIcon, themeOptions } from "@commonsku/styles";
import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import { usePublicView } from "../../../context/PublicViewProvider";
import { usePublicViewTemplateDataContext } from "../../../context/PublicViewTemplateDataProvider";

export default function CartIconLink({ totalQuantity, overrideStyles = false, style={} }) {
  const { templateColor: template_color } = usePublicViewTemplateDataContext();
  const {
    onClickCart,
    showCart,
  } = usePublicView();

  const [innerWidth] = useWindowSize();
  const defaultStyles = {
    background: !showCart ? 'transparent' : 'var(--color-neutrals-20)',
    float: 'right',
    height: '100%',
    padding: '14px 14px 14px 5px',
    marginRight: 2,
    marginLeft: 3,
    ...(innerWidth >= parseFloat(themeOptions.mediaQueries.sizeMediaWidth.md.replace('px', '')) ? {
      zIndex: 9999,
      position: 'relative',
    } : {}),
  };

  return (
    <a onClick={() => onClickCart()}
      style={{
        ...(overrideStyles ? overrideStyles: defaultStyles),
      }}
    >
      { totalQuantity > 0 && <div style={{
        display: 'inline-block',
        position: 'absolute',
        height: 28,
        width: 30,
        padding: 5,
        right: 5,
        top: 5,
        borderRadius: '50%',
        fontSize: 12,
        color: '#fff',
        fontWeight: 'bold',
        background: 'var(--color-secondary1-60)',
        ...style
      }}>{totalQuantity > 99 ? '99+' : totalQuantity}</div>}
      <ShoppingCartIcon size='large' color={template_color} height={'100%'} width={'32px'} />
    </a>
  );
}
