import { Label } from '@commonsku/styles';
import { useEffect } from 'react';
import { useRefinementList } from 'react-instantsearch';
import styled from 'styled-components';

const CurrencyIconButton = styled.button`
  && {
  width: 34px;
  height: 34px;
  border-radius: 20px;
  padding: 3px;
  line-height: 1.5rem;
  cursor: pointer;
  background: #E1F7FA;
  mix-blend-mode: normal;

  &.selected {
    border: 3px solid #00A0B6;
    background: #FFFFFF;
    padding: 0px;
  }
}`;

function CustomToggle({ defaultLabel, ...props }) {
  const { items, refine } = useRefinementList(props);

  useEffect(() => {
    refine(defaultLabel);
  }, [defaultLabel, refine]);

  const toggleCurrency = (value) => {
    refine(value);
  }
  const usdCurrency = items.find(item => item.value == 'USD');
  const cadCurrency = items.find(item => item.value == 'CAD');
  return (
    <div style={{ display: 'flex', flexDirection: 'column', minWidth: 100 }}>
      <Label>Currency</Label>
      <div style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
        <CurrencyIconButton className={usdCurrency?.isRefined && 'selected'} style={{ marginRight: 5 }} onClick={() => toggleCurrency('USD')}>
          <div style={{ opacity: usdCurrency?.isRefined ? 1 : 0.4 }} ><img src="/images/icons/us1.svg" /></div>
        </CurrencyIconButton>
        <CurrencyIconButton className={cadCurrency?.isRefined && 'selected'} onClick={() => toggleCurrency('CAD')}>
          <div style={{ opacity: cadCurrency?.isRefined ? 1 : 0.4 }}><img src="/images/icons/ca1.svg" /></div>
        </CurrencyIconButton>
      </div>
    </div>
  );
}

export default CustomToggle;