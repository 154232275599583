import { get, intersection, uniq, isEmpty } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import { useIdentity } from './index';
import { createUpdateTabOrder } from '../actions';
import { usePromodataBetaUser, usePromodataStatus } from '../components/promo-data/hooks';
import { getIdentityUtils } from '../utils';

const getProductSearchTabOrder = (identity, order_type, isProductsPage, hasPromodata, isCollectionPage) => {
  const { hasUserFlags } = getIdentityUtils(identity);
  const default_product_tab_order = 'SUPPLIER' === identity.company_type
    ? [...((hasUserFlags('NEW-PRODUCT-SEARCH-TAB') || (identity.features === 'CONNECTEDPLUS')) && (isCollectionPage || isProductsPage) ? ['connected-plus-products'] : []), 'commonsku-products', 'dc-products']
    : [
      'personal-products', 'dc-products', 'esp-products', 'sage-products', 'sage-connect-products',
      'bookmark-products', 'tagged-products',
      ...(hasUserFlags('PRODUCT-SMART-ENABLED') && !isProductsPage ? ['product-suggestion-products'] : []),
      ...(hasPromodata ? ['promodata-products'] : [])
    ]
  ;
  let product_search_tab_order = get(identity, 'preferences.product_search_tab_order');
  product_search_tab_order = intersection(
    uniq([
      ...product_search_tab_order,
      ...default_product_tab_order, // make sure all tabs are included
      'order-products', 'collection-products',
    ]),
    [
      '', 'ps-products', // ps-products only available if manually added in db
      ...default_product_tab_order,
      ...(!(['COLLECTION', 'SHOP'].includes(order_type) || isProductsPage) ? ['order-products'] : []),
      ...('OPPORTUNITY' !== order_type && !isProductsPage ? ['collection-products'] : []),
    ]
  );
  let visibleSize = product_search_tab_order.indexOf('');
  if (product_search_tab_order[visibleSize] !== '') {
    const default_visible_size = 3;
    product_search_tab_order.splice(visibleSize === -1 ? default_visible_size : visibleSize, 0, '');
  }
  return product_search_tab_order;
};

const useProductSearchTabOrder = ({ order_type, isProductsPage, isCollectionPage }) => {
  const identity = useIdentity();
  const dispatch = useDispatch();
  const promodataStatus = usePromodataStatus();
  const isBetaUser = usePromodataBetaUser();
  const hasPromodata = promodataStatus === 'ACTIVE' || isBetaUser;
  const [productSearchTabOrder, setProductSearchTabOrder] = useState(getProductSearchTabOrder(identity, order_type, isProductsPage, true, isCollectionPage));

  useEffect(
    () => {
      setProductSearchTabOrder(getProductSearchTabOrder(identity, order_type, isProductsPage, true, isCollectionPage));
    },
    [identity, order_type, isProductsPage, hasPromodata, isCollectionPage]
  );

  const onUpdate = React.useCallback(
    (newProductSearchTabOrder) => {
      setProductSearchTabOrder(newProductSearchTabOrder);
      dispatch(createUpdateTabOrder(identity.user_id, JSON.stringify(newProductSearchTabOrder)));
    },
    [identity.user_id, dispatch]
  );

  return [
    productSearchTabOrder,
    onUpdate,
  ];
};

export default useProductSearchTabOrder;
