import { Button, ClearIcon } from '@commonsku/styles';
import React from 'react';
import { useClearRefinements } from 'react-instantsearch';

function CustomClearRefinements({ setRange, ...props }) {
  const { canRefine, refine } = useClearRefinements(props);

  return (
    canRefine &&
    <Button onClick={() => {
      setRange({ min: '', max: '' });
      refine();
    }} variant='secondary' size='medium' inline_flex style={{ alignItems: 'center', justifyContent: 'center' }}>
      <ClearIcon /> Clear All
    </Button>
  );
}

export default CustomClearRefinements;