import React, { FC, useState } from "react";
import {
  AlertNotification,
  Button,
  H2,
  Input,
  Popup,
  PopupHeader,
  SkubotSpinner,
  Text,
  XIcon,
} from "@commonsku/styles";
import { BASE_ZINDEX } from "../../../popup-factory";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getTemplateScreenshot } from "../Storefront/helpers";
import { createUploadFile } from "../../../actions/file";
import { oauth } from "../../../utils";
import { AnyAction, ThunkDispatch } from "@reduxjs/toolkit";
import {
  finalizeCreatePublicViewTemplate,
  updateShop,
} from "../../../actions/shop";
import {
  selectIsLoading,
  setIsLoadingFalse,
  setIsLoadingTrue,
} from "../../../redux/isLoading";

interface SaveTemplatePopupProps {
  entityId: string;
  entityLayoutId: string;
  entityType: string;
  onClose: () => void;
}

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
`;

const SaveTemplatePopup: FC<SaveTemplatePopupProps> = ({
  onClose,
  entityId,
  entityLayoutId,
  entityType,
}) => {
  const isLoading = useSelector(selectIsLoading);
  const dispatch = useDispatch<ThunkDispatch<any, any, AnyAction>>();

  const [inputTemplateName, setInputTemplateName] = useState<string>("");
  const [error, setError] = useState<string>("");

  const handleTemplateNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setInputTemplateName(e.target.value);
    if (error) {
      setError("");
    }
  };

  const handleSaveTemplate = async () => {
    dispatch(setIsLoadingTrue());
    try {
      const screenshotDataUrl = await getTemplateScreenshot(
        "storefront-template-iframe"
      );
      const screenshotResponse = await fetch(screenshotDataUrl);
      const blob = await screenshotResponse.blob();
      const action = await dispatch(
        createUploadFile(entityId, "SHOP_TEMPLATE", blob)
      );
      let template;
      try {
        const { json } = await oauth("POST", "public-view-template", {
          public_view_template_title: inputTemplateName.trim(),
          status: "PUBLISHED",
          template_type: "TEMPLATE",
          parent_id: entityLayoutId,
          public_view_id: entityId,
          public_view_type: entityType,
          public_view_template_background:
            action?.payload?.data?.file_name_large ??
            action?.payload?.data?.file_name_medium ??
            action?.payload?.data?.file_name_original ??
            "",
        });
        template = json.template;
      } catch (err) {
        dispatch(setIsLoadingFalse());
        if (err.json.errors[0].includes("already exists")) {
          setError(
            "Template with the same name already exists. Please choose a different name."
          );
        } else {
          setError("Something went wrong.");
          console.error("Error: ", err);
        }
      }

      dispatch(finalizeCreatePublicViewTemplate(template));
      dispatch(
        updateShop(
          entityId,
          "public_view_template_id",
          null,
          template.public_view_template_id
        )
      );
      setError("");
      dispatch(setIsLoadingFalse());
      onClose();
    } catch (err) {
      dispatch(setIsLoadingFalse());
      console.error("Error: ", err);
      return;
    }
  };

  return (
    <Popup
      overlayZIndex={BASE_ZINDEX + 100}
      zIndex={BASE_ZINDEX + 200}
      style={{ width: 560, height: error ? 270 : 189, borderRadius: 10 }}
      header={
        <PopupHeader
          style={{ padding: 0, alignItems: "center", marginBottom: 16 }}
        >
          <H2 style={{ margin: "0px" }} className="title">
            Name your template
          </H2>
          <XIcon
            style={{ cursor: "pointer" }}
            size="large"
            onClick={() => onClose()}
          />
        </PopupHeader>
      }
    >
      <StyledContainer>
        <Input
          value={inputTemplateName}
          placeholder={"Type Here"}
          onChange={handleTemplateNameChange}
        />
        {error && (
          <AlertNotification
            alertType="error"
            style={{ height: 60, alignItems: "center", marginBottom: 16 }}
          >
            {error}
          </AlertNotification>
        )}
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "end",
            gap: 16,
          }}
        >
          <Button
            variant="secondary"
            onClick={onClose}
            size="medium"
            disabled={isLoading}
          >
            Cancel
          </Button>
          <Button
            variant={"primary"}
            disabled={!inputTemplateName.trim() || isLoading || error}
            onClick={handleSaveTemplate}
            size="medium"
            style={{ width: 135 }}
          >
            {isLoading ? (
              <SkubotSpinner
                size="button"
                skubot={false}
                style={{ margin: "auto" }}
              />
            ) : (
              "Save"
            )}
          </Button>
        </div>
      </StyledContainer>
    </Popup>
  );
};

export default SaveTemplatePopup;
