import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AlertNotification, colors, IconButton, Input, LabeledRadioGroup, Popup, Typography } from '@commonsku/styles';
import { createDeleteFile } from '../../actions/file';
import { useDispatch } from 'react-redux';
import { useArtworkLibraryContext } from './context';

export default function useFileActionModal() {
    const { reDispatch, updateFileName, moveFile, folders, activeParent } = useArtworkLibraryContext();
    const dispatch = useDispatch();
    const [open, setOpen] = useState(false);
    const [item, setItem] = useState(null);
    const [type, setType] = useState(null);
    const [fileName, setFileName] = useState('');
    const [newFolder, setNewFolder] = useState(null);
    const [error, setError] = useState('');
    const folderOptions = folders.filter(f => f.parent_type === activeParent.parent_type).map(f => ({ value: f.folder_id, label: f.folder_name }));

    useEffect(() => {
        setFileName(item?.file_display_name);
        const currentFolder = folders.find(f => f.folder_id === item?.folder_id);
        if (currentFolder) {
            setNewFolder(currentFolder.folder_id);
        }
    }, [folders, item]);

    let form;
    let title;
    if ('rename' === type) {
        form = (
            <>
                <Input name='fileName' value={fileName} onChange={(e) => { setFileName(e.target.value); }}></Input>
                {'' !== error && <AlertNotification alertType='error'>{error}</AlertNotification>}
            </>
        );
        title = 'Rename File';
    }
    if ('move' === type) {
        form = (
            <div style={{ display: 'flex', flexDirection: 'column', maxHeight: '350px', overflowY: 'auto' }}>
                <LabeledRadioGroup
                    name='move-folder'
                    label={null}
                    value={newFolder}
                    radios={folderOptions}
                    onChange={(e) => {
                        setNewFolder(e.target.value);
                    }}
                />
                {'' !== error && <AlertNotification alertType='error'>{error}</AlertNotification>}
            </div>
        );
        title = 'Move To Folder';
    }
    if ('delete' === type) {
        form = (
            <>
                <p style={{ textAlign: 'center', fontWeight: 'bold' }}>Are you sure you want to delete this file?</p>
            </>
        );
        title = '';
    }

    const actionCallback = useCallback((item) => {
        switch (type) {
            case 'rename':
                if ('' === fileName.trim()) {
                    setError('File name is empty');
                    return;
                }
                setOpen(false);
                updateFileName(item, fileName);
                break;
            case 'move':
                setOpen(false);
                const folder = folders.find(f => f.folder_id === newFolder);
                moveFile(item, folder);
                break;
            case 'delete':
                dispatch(createDeleteFile(item.file_id));
                reDispatch({
                    type: 'removed',
                    file_uuid: item.file_id,
                });
                break;
            default:
                break;
        }
    }, [dispatch, fileName, folders, moveFile, newFolder, reDispatch, type, updateFileName]);

    const popup = useMemo(() => {
        let popup = null;
        if (open) {
            popup = (
                <Popup zIndex={10004} overlayZIndex={10003} header={<span></span>} style={{ width: 'auto', height: 'auto' }}>
                    {'delete' !== type && <div style={{ float: 'right' }}><button className="close-button" aria-label="Close modal" type="button" onClick={e => { e.preventDefault(); setOpen(false); }}>
                        <span aria-hidden="true" style={{ color: colors.teal[60] }}>&times;</span>
                    </button></div>}
                    <Typography.H5>{title}</Typography.H5>
                    {form}
                    <div style={{ display: 'flex', minWidth: '400px', justifyContent: 'delete' === type ? 'center' : 'right', flexDirection: 'row', marginBottom: '1em', marginTop: '1em' }}>
                        <IconButton variant='secondary' style={{ marginRight: '1em' }} onClick={() => {
                            setOpen(false);
                        }}>Cancel</IconButton>
                        <IconButton variant={'delete' === type ? 'error' : 'primary'} disabled={'rename' === type && '' === fileName} onClick={() => {
                            actionCallback(item);
                        }}>{'rename' === type ? 'Rename' : 'delete' === type ? 'Delete' : 'Move'}</IconButton>
                    </div>
                </Popup>
            );
        }
        return popup;
    }, [actionCallback, fileName, form, item, open, title, type]);

    return [popup, setOpen, setItem, setType];
};