import { Button, LabeledInput, LabeledSelect, Text } from "@commonsku/styles";
import React, { useEffect, useState } from "react";
import { GoBackButton } from "./Questionnaire";
import { useDispatch, useSelector } from "react-redux";
import { getClientOptions } from "../../../selectors/dropdowns";
import { shopDetails } from "./constants";
import {
  createLoadClientList,
  LOAD_CLIENT_LIST_SUCCESS,
} from "../../../actions/client";

interface CreateShopPageProps {
  shopType: string;
  handleCustomRouting: (
    newRoute: string,
    selectedShopType?: string,
    shopName?: string,
    clientId?: string
  ) => void;
}

const CreateShopPage = ({
  shopType,
  handleCustomRouting,
}: CreateShopPageProps) => {
  const dispatch = useDispatch();
  const clients = useSelector(getClientOptions);
  const [isLoaded, setIsLoaded] = useState<boolean>(!!clients.length);
  const [clientId, setClientId] = useState<string | null>(null);
  const [shopName, setShopName] = useState<string>("");

  useEffect(() => {
    if (!isLoaded) {
      dispatch(createLoadClientList() as unknown as Promise<any>).then(
        (action) => {
          if (action.type === LOAD_CLIENT_LIST_SUCCESS) {
            setIsLoaded(true);
          }
        }
      );
    }
  }, []);

  const options = clients.map(
    (client: { client_name: string; client_id: string }) => ({
      label: client.client_name,
      value: client.client_id,
    })
  );
  const shopclientType =
    shopType === "COMPANY" || shopType === "POPUP"
      ? "single_client"
      : "multiple_client";

  return (
    <div
      style={{
        margin: "auto",
        marginTop: 182,
        marginBottom: 182,
        width: "50%",
        overflow: "hidden",
      }}
    >
      <Text as="h1" style={{ fontWeight: 600, fontSize: 32, marginBottom: 64 }}>
        Create a {shopDetails[shopType].type} Shop
      </Text>
      <div style={{ maxWidth: 384, textAlign: "left", margin: "auto" }}>
        <LabeledInput
          label="Shop Name"
          style={{ marginBottom: 24 }}
          onChange={(e) => setShopName(e.target.value.trim())}
          required
        />
        {"single_client" === shopclientType && (
          <>
            <LabeledSelect
              label="Client"
              options={options}
              value={options.find((v) => v.value === clientId)}
              isSearchable
              onChange={(e) => setClientId(e.value)}
              required
              error={isLoaded && clients.length === 0}
              menuListStyles={{
                maxWidth: "382px",
                height: "150px",
                overflowY: "auto",
                whiteSpace: "normal",
                wordBreak: "break-word",
              }}
              isDisabled={!isLoaded}
            />
            {clients.length === 0 && (
              <Text as="span" color="error">
                You need to add some clients before you can create a{" "}
                {shopDetails[shopType].type} Shop
              </Text>
            )}
          </>
        )}
      </div>
      <div style={{ marginTop: 64 }}>
        <Button
          size="huge"
          style={{ display: "block", margin: "auto", marginBottom: 16 }}
          disabled={
            !shopName || (shopclientType === "single_client" && !clientId)
          }
          onClick={() =>
            handleCustomRouting(
              "templateSelection",
              shopType,
              shopName,
              clientId
            )
          }
          id="shopCreation-createShopPage-continue.btn"
        >
          Continue
        </Button>
        <GoBackButton
          variant="text"
          size="small"
          style={{ display: "block", margin: "auto" }}
          onClick={() => handleCustomRouting("initialize")}
          id="shopCreation-selectedShopPage-goBack.btn"
        >
          Take Me Back to Shop Selection
        </GoBackButton>
      </div>
    </div>
  );
};

export default CreateShopPage;
