export const imageGuidelines = {
  SELECT_BACKGROUND_IMAGE: {
    Billboard: [
      "Preferred aspect ratio for this image is 16:9. Recommended size is 1920 x 1080px.",
      "Keep your images at a high resolution without going over 2mb to avoid blurriness and slow load times.",
      "Keep the focal point in the center of the image and avoid placing important information in the corners or edges to prevent cropping on different screen sizes.",
      "Avoid having text as part of your image, it may get warped on different screen sizes.",
    ],
    Poster: [
      "Preferred aspect ratio for this image is 16:9. Recommended size is 1920 x 1080px.",
      "Keep your images at a high resolution without going over 2mb to avoid blurriness and slow load times.",
      "Keep the focal point in the center of the image and avoid placing important information in the corners or edges to prevent cropping on different screen sizes.",
      "Avoid having text as part of your image, it may get warped on different screen sizes.",
    ],
    Split: [
      "Preferred aspect ratio for this image is 1:1. Recommended size is 1080 x 1080px.",
      "Keep your images at a high resolution without going over 2mb to avoid blurriness and slow load times.",
      "Keep the focal point in the center of the image and avoid placing important information in the corners or edges to prevent cropping on different screen sizes.",
      "Avoid having text as part of your image, it may get warped on different screen sizes.",
    ],
    Featured: [
      "Preferred aspect ratio for this image is 9:1. Recommended size is 1890 x 210px.",
      "Keep your images at a high resolution without going over 2mb to avoid blurriness and slow load times.",
      "Keep the focal point in the center of the image and avoid placing important information in the corners or edges to prevent cropping on different screen sizes.",
      "Avoid having text as part of your image, it may get warped on different screen sizes.",
    ],
  },
  SELECT_LOGO_IMAGE: [
    "Preferred aspect ratio for this image is 1:1. Recommended size is 200 x 200px.",
    "Preferred image format is .png with a transparent background.",
  ],
  SELECT_HELP_SCREEN_IMAGE: [
    "Preferred aspect ratio for this image is 4:5. Recommended size is 1080 x 1350px.",
    "Keep your images at a high resolution without going over 2mb to avoid blurriness and slow load times.",
    "Keep the focal point in the center of the image and avoid placing important information in the corners or edges to prevent cropping on different screen sizes.",
    "Avoid having text as part of your image, it may get warped on different screen sizes.",
  ],
  CATERGORY_IMAGES: [
    "Preferred aspect ratio for this image is 4:3. Recommended size is 1440 x 1080px.",
    "Keep your images at a high resolution without going over 2mb to avoid blurriness and slow load times.",
    "Keep the focal point in the center of the image and avoid placing important information in the corners or edges. This image can be subject to heavy cropping on the sides at smaller, narrower screen sizes.",
    "Avoid having text as part of your image, it may get warped on different screen sizes.",
  ],
  CATEGORY_BANNER: [
    "Preferred aspect ratio for this image is 4:1. Recommended size is 1920 x 480px.",
    "Keep your images at a high resolution without going over 2mb to avoid blurriness and slow load times.",
    "Keep the focal point in the center of the image and avoid placing important information in the corners or edges to prevent cropping on different screen sizes.",
    "Avoid having text as part of your image, it may get warped on different screen sizes.",
  ],
  SELECT_CAROUSEL_IMAGE: [
    "Preferred aspect ratio for this image is 4:1. Recommended size is 1920 x 480px.",
    "Keep your images at a high resolution without going over 2mb to avoid blurriness and slow load times.",
    "Keep the focal point in the center of the image and avoid placing important information in the corners or edges to prevent cropping on different screen sizes.",
    "Avoid having text as part of your image, it may get warped on different screen sizes.",
  ],
  SELECT_CATEGORIES_IMAGE: [
    "Preferred aspect ratio for this image is 1:1. Recommended size is 1080 x 1080px.",
    "Keep your images at a high resolution without going over 2mb to avoid blurriness and slow load times.",
    "Keep the focal point in the center of the image and avoid placing important information in the corners or edges to prevent cropping on different screen sizes.",
    "Avoid having text as part of your image, it may get warped on different screen sizes.",
  ],
};
