import { oauth } from '../utils';

export const LOAD_FOLDERS_LIST_REQUEST = 'LOAD_FOLDERS_LIST_REQUEST';
export const LOAD_FOLDERS_LIST_SUCCESS = 'LOAD_FOLDERS_LIST_SUCCESS';
export const LOAD_FOLDERS_LIST_FAILURE = 'LOAD_FOLDERS_LIST_FAILURE';

const createLoadFoldersListRequest = parent_id => ({
  type: LOAD_FOLDERS_LIST_REQUEST,
  payload: {
    parent_id
  }
});

const createFinalizeLoadFoldersList = folders => ({
  type: LOAD_FOLDERS_LIST_SUCCESS,
  payload: {
    folders
  }
});

const createFailedLoadFoldersList = folders => ({
  type: LOAD_FOLDERS_LIST_FAILURE,
  payload: {
    folders
  }
});

export const createLoadFoldersList = (parent_id, parent_type, client_id) => dispatch => {
  dispatch(createLoadFoldersListRequest(parent_id));

  oauth('GET', `folder`, {parent_id, parent_type, client_id}).then(
    ({ json }) => dispatch(createFinalizeLoadFoldersList(json.folders)),
    ({ json }) => dispatch(createFailedLoadFoldersList(json.folders))
  );
};