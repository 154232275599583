import {
  CheckmarkIcon,
  colors,
  Table,
  TBody,
  TD,
  TH,
  THead,
  TR,
} from "@commonsku/styles";
import React, { useRef } from "react";
import styled from "styled-components";
import { shopComparison } from "./constants";
import useContainerScroll, {
  ScrollContainer,
} from "../../../hooks/useContainerScroll";

const OuterDiv = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: center;
  text-align: left;
  width: 100%;
  padding-top: 48px !important;
  padding-bottom: 48px !important;
  max-height: 100vh;
  margin-bottom: 48px !important;
  margin-top: 48px !important;
`;

const HeaderDiv = styled.div`
  margin-bottom: 48px !important;
`;

const HeaderTitle = styled.h2`
  font-weight: 600 !important;
  font-size: 24px !important;
  color: ${colors.neutrals["90"]} !important;
`;

const HeaderDesc = styled.p`
  font-weight: 400 !important;
  font-size: 18px !important;
  color: ${colors.neutrals["90"]} !important;
`;

const BodyDiv = styled.div`
  max-height: 350px !important;
  overflow: hidden;
  position: relative;

  &::-webkit-scrollbar {
    display: none;
  }
  scrollbar-width: none;
  -ms-overflow-style: none;
`;

const ShopFeaturesDesc = () => {
  const tableRef = useRef(null);
  const { canScrollDown, scrollDown } = useContainerScroll(tableRef);

  return (
    <OuterDiv>
      <HeaderDiv id="featuresHeader">
        <HeaderTitle as="h2">Find the Right Shop For You!</HeaderTitle>
        <HeaderDesc>
          Compare the features of different Shop Types to find the best fit for
          your needs. If you're unsure which one to choose,{" "}
          <a
            target="_blank"
            href="https://help.commonsku.com/knowledge/shops-overview"
          >
            click here to learn more
          </a>{" "}
          about all Shop Types.
        </HeaderDesc>
      </HeaderDiv>

      <BodyDiv id="featuresBody" style={{ position: "relative" }}>
        <div style={{ height: "100%", overflowY: "auto" }} ref={tableRef}>
          <Table>
            <THead>
              <TR style={{ paddingLeft: 8, paddingRight: 8 }}>
                <TH sticky>Feature</TH>
                <TH sticky style={{ textAlign: "center" }}>
                  Pop-up Shop
                </TH>
                <TH sticky style={{ textAlign: "center" }}>
                  Company Shop
                </TH>
                <TH sticky style={{ textAlign: "center" }}>
                  Marketing Shop
                </TH>
              </TR>
            </THead>
            <TBody id={`shopCreation-featuresTab-tableBody`}>
              {shopComparison.features.map((feature) => (
                <TR key={feature} style={{ paddingLeft: 8, paddingRight: 8 }}>
                  <TD>{feature}</TD>
                  <TD style={{ textAlign: "center" }}>
                    {shopComparison.POPUP.features.includes(feature) && (
                      <CheckmarkIcon color="#E6BA00" />
                    )}
                  </TD>
                  <TD style={{ textAlign: "center" }}>
                    {shopComparison.COMPANY.features.includes(feature) && (
                      <CheckmarkIcon color={colors.secondary3["60"]} />
                    )}
                  </TD>
                  <TD style={{ textAlign: "center" }}>
                    {shopComparison.MARKETING.features.includes(feature) && (
                      <CheckmarkIcon />
                    )}
                  </TD>
                </TR>
              ))}
            </TBody>
          </Table>
        </div>
        <ScrollContainer
          bottom={-21}
          canScroll={canScrollDown()}
          color={colors.primary1["70"]}
          isUp={false}
          left
          onClick={scrollDown}
          right
          top
          width="100%"
          style={{
            position: "absolute",
            cursor: "pointer",
          }}
          margin={0}
          arrowSize="large"
          padding={10}
        />
      </BodyDiv>
    </OuterDiv>
  );
};

export default ShopFeaturesDesc;
