import React, { useEffect, useState } from 'react';
import { DoubleArrowIcon, colors } from '@commonsku/styles';
import useWindowSize from './useWindowSize';

export const ArrowsIcon = ({color, direction, margin, arrowSize, padding, style={}, ...props}) => (
  <DoubleArrowIcon
    size={arrowSize}
    direction={direction}
    color={color}
    style={{
      margin: margin,
      padding: padding,
      width: '100%',
      background: direction === 'up' ? 'linear-gradient(180deg, #DFEEF4 0%, rgba(166, 194, 198, 0.08) 100%)'
        : 'linear-gradient(0deg, #DFEEF4 0%, rgba(166, 194, 198, 0.08) 100%)',
      ...style
    }}
    {...props}
  />
);

export const ScrollContainer = ({ onClick, canScroll, isUp, width, color, top, right, bottom, left, zIndex=4, style={}, margin=4, arrowSize='medium', padding=0 }) => (
  <div className={`scroll_${isUp ? 'up' : 'down'}_container`} onClick={onClick} style={{
    color: colors.teal.main,
    fontSize: '3em',
    position: 'fixed',
    transition: 'visibility 0s linear 300ms, opacity 300ms',
    visibility: canScroll ? 'visible' : 'hidden',
    opacity: canScroll ? 1 : 0,
    top,
    right,
    bottom,
    left,
    width,
    zIndex,
    ...style,
  }}>
    <ArrowsIcon direction={isUp ? 'up' : 'down'} color={color} margin={margin} arrowSize={arrowSize} padding={padding} />
  </div>
);

/**
 * useContainerScroll
 *
 * @param {React.MutableRefObject<HTMLDivElement>} ref
 * @param {number | undefined} scrollHeightOffset
 * @param {Array<any>} deps
 * @returns {{
 *  scrollHeight: number,
 *  canScrollDown: () => boolean,
 *  canScrollUp: () => boolean,
 *  scrollDown: (offsetHeight?: number) => void,
 *  scrollUp: (offsetHeight?: number) => void,
 *  innerWidth: number,
 *  innerHeight: number,
 *  elemRect: DOMRect,
 *  isAtTop: () => boolean,
 * }}
 */
const useContainerScroll = (ref, deps = [], scrollHeightOffset = 10) => {
  const [scrollPos, setScrollPos] = useState(-1);
  const [elemRect, setElemRect] = useState({
    top: 0,
    bottom: 0,
    height: 0,
    width: 0,
    left: 0,
    right: 0,
    x: 0,
    y: 0,
  });
  const [innerWidth, innerHeight] = useWindowSize();

  const scrollHeight = ref.current ? ref.current.scrollHeight - scrollHeightOffset : 0;
  const canScrollDown = () => {
    if (!ref.current) { return false; }
    return (scrollPos + ref.current.clientHeight) < scrollHeight;
  };
  const canScrollUp = () => {
    if (!ref.current) { return false; }
    return (scrollPos - scrollHeightOffset) > 0;
  };

  const scrollDown = (offsetHeight = 100) => {
    if (!ref.current || !canScrollDown()) { return; }
    if (typeof offsetHeight !== 'number') {
      offsetHeight = 100;
    }
    ref.current.scroll({
      left: 0,
      top: scrollPos + ref.current.clientHeight - offsetHeight,
      behavior: 'smooth'
    });
  };
  const scrollUp = (offsetHeight = 100) => {
    if (!ref.current || !canScrollUp()) { return; }
    if (typeof offsetHeight !== 'number') {
      offsetHeight = 100;
    }
    ref.current.scroll({
      left: 0,
      top: scrollPos - ref.current.clientHeight + offsetHeight,
      behavior: 'smooth'
    });
  };

  const isAtTop = () => {
    return scrollPos <= scrollHeightOffset;
  };

  useEffect(() => {
    function updateElemInfo(elem, enteries) {
      let element = elem || ref.current;
      if (elem?.target && elem?.type) {
        element = elem?.target;
      }

      if (!element) { return; }
      setScrollPos(element.scrollTop);
      setElemRect(element.getBoundingClientRect());
    };

    const elem = ref.current;
    if (!elem) { return; }

    const observer = new ResizeObserver((entries) => {
      updateElemInfo(elem, entries[0]);
    });
    observer.observe(elem);
    elem.addEventListener('scroll', updateElemInfo);
    elem.addEventListener('resize', updateElemInfo);

    updateElemInfo(elem);

    return () => {
      elem.removeEventListener('scroll', updateElemInfo);
      elem.removeEventListener('resize', updateElemInfo);
      observer.disconnect();
    };
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);

  return {
    scrollHeight,
    canScrollDown,
    canScrollUp,
    scrollDown,
    scrollUp,
    innerWidth,
    innerHeight,
    elemRect,
    isAtTop,
  };
};

export default useContainerScroll;
