import { createSlice, PayloadAction } from "@reduxjs/toolkit";

type PublicViewTemplateOrderType = {
  public_view_template_order_type_id: string;
  public_view_template_id: string;
  order_type: string;
  is_default: number;
};

const initialState: PublicViewTemplateOrderType[] = [];

const templateOrderTypeSlice = createSlice({
  name: "templateOrderType",
  initialState,
  reducers: {
    setTemplateOrderTypes(
      state,
      action: PayloadAction<PublicViewTemplateOrderType[]>
    ) {
      return action.payload;
    },
    addDefaultTemplateOrderTypes(
      state,
      action: PayloadAction<{
        order_types: string[];
        orderTypesResponse: PublicViewTemplateOrderType[];
      }>
    ) {
      const { order_types, orderTypesResponse } = action.payload;

      const newState = state.map((s) => ({
        ...s,
        is_default:
          order_types.includes(s.order_type) && s.is_default === 1
            ? 0
            : s.is_default,
      }));

      orderTypesResponse.forEach((v) => {
        if (order_types.includes(v.order_type)) {
          const idx = newState.findIndex(
            (entry) =>
              entry.order_type === v.order_type &&
              entry.public_view_template_id === v.public_view_template_id
          );

          if (idx > -1) {
            newState[idx] = {
              ...newState[idx],
              is_default: 1,
            };
          } else {
            newState.push(v);
          }
        }
      });

      return newState;
    },
    removeDefaultTemplateOrderTypes(
      state,
      action: PayloadAction<{
        public_view_template_id: string;
        order_types: string[];
      }>
    ) {
      const { public_view_template_id, order_types } = action.payload;

      return state.filter(
        (v) =>
          !(
            order_types.includes(v.order_type) &&
            v.is_default === 1 &&
            v.public_view_template_id === public_view_template_id
          )
      );
    },
  },
});

export const {
  setTemplateOrderTypes,
  addDefaultTemplateOrderTypes,
  removeDefaultTemplateOrderTypes,
} = templateOrderTypeSlice.actions;

export default templateOrderTypeSlice.reducer;
