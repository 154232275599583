import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Row, LabeledInput, H5, colors } from '@commonsku/styles';

import DateInput, { CSKUInput } from '../DateInput';

import { updatePurchaseOrder } from '../../actions/purchase_order.js';
import { NotifyShippingChangesButton } from './NotifyShippingChangesPopup';
import { parseMysqlDate, formatDate } from "../../utils";

export default function Shipping({ purchase_order_id }) {
  const po = useSelector(state => state.entities.purchase_orders[purchase_order_id] || {});
  const shipments = useSelector(state => state.entities.shipments[purchase_order_id] ? Object.values(state.entities.shipments[purchase_order_id]) : []);

  const date_shipped = po.date_shipped ? (Number.isFinite(po.date_shipped) ? formatDate(po.date_shipped, true) : parseMysqlDate(po.date_shipped)) : null;

  const [shippingCost, setShippingCost] = useState(po.shipping_cost || '');
  const [shippingOverage, setShippingOverage] = useState(po.shipping_overage || '');

  const dispatch = useDispatch();
  const updatePOField = (field, preVal, newVal) => {
    if (preVal !== newVal) {
      dispatch(updatePurchaseOrder(purchase_order_id, field, preVal, newVal));
    }
  };

  useEffect(() => {
    setShippingCost(po.shipping_cost || '');
    setShippingOverage(po.shipping_overage || '');
  }, [po]);

  function renderShipment(s) {
    return (
      <Row key={s.shipment_id}>
        <Col padded xl={6} sm={6} xs>
          <label style={styles.label}>Tracking Number</label>
          <p style={styles.textField}>{s.tracking_number}</p>
        </Col>
        <Col padded xl={6} sm={6} xs>
          <label style={styles.label}>Shipment Time</label>
          <p style={styles.textField}>{s.converted_time}</p>
        </Col>
        <Col padded xl={6} sm={6} xs>
          <label style={styles.label}>Carrier</label>
          <p style={styles.textField}>{s.carrier}</p>
        </Col>
        <Col padded xl={6} sm={6} xs>
          <label style={styles.label}>Method</label>
          <p style={styles.textField}>{s.method}</p>
        </Col>
        <Col padded xl={6} sm={6} xs>
          <label style={styles.label}>Terms</label>
          <p style={styles.textField}>{s.terms}</p>
        </Col>
        <Col padded xs><hr /></Col>
      </Row>
    );
  }

  return (
    <>
      <Row>
        <Col padded xl={6} sm={6} xs>
          <label style={styles.label}>Date Shipped</label>
          <DateInput
            customInput={<CSKUInput />}
            showMonthDropdown
            showYearDropdown
            placeholder="Date Shipped"
            value={date_shipped}
            onChange={date => updatePOField('date_shipped', date_shipped, date)} />
        </Col>
        <Col padded xl={6} sm={6} xs>
          <LabeledInput
            label="Shipping Cost"
            value={shippingCost}
            placeholder="Shipping Cost"
            onChange={e => { e.preventDefault(); setShippingCost(e.target.value);}}
            onBlur={e => updatePOField('shipping_cost', po.shipping_cost, shippingCost)}
          />
        </Col>
        <Col padded xl={6} sm={6} xs>
          <LabeledInput
            label="Overrun / Underrun"
            value={shippingOverage}
            placeholder="Overrun / Underrun"
            onChange={e => { e.preventDefault(); setShippingOverage(e.target.value);}}
            onBlur={e => updatePOField( 'shipping_overage', po.shipping_overage, shippingOverage)}
          />
        </Col>
        <Col padded xl={6} sm={6} xs>
          <label style={styles.label}>Projected Ship Date</label>
          <DateInput
            customInput={<CSKUInput />}
            showMonthDropdown
            showYearDropdown
            placeholder="Projected Ship Date"
            value={po.date_shipdatecurrent}
            onChange={date => updatePOField( 'date_shipdatecurrent', po.date_shipdatecurrent, date)} />
        </Col>
        <Col padded xs>
          <ShippingTrackingIdsInput
            shippingTrackingIds={po.shipping_tracking_id}
            onBlur={(trackingIds) => updatePOField('shipping_tracking_id', po.shipping_tracking_id, trackingIds)}
          />
        </Col>
        <Col padded xs grid end={1}>
          <NotifyShippingChangesButton purchase_order_id={purchase_order_id} />
        </Col>
      </Row>
      {shipments.length ?
        <Row>
          <Col padded xs><H5>PS Shipping Info</H5></Col>
          {shipments.map(s =>
            renderShipment(s)
          )}
        </Row>
      : null}
    </>
  );
}

const styles = {
  label: {
    fontFamily: "'skufont-medium', sans-serif",
    color: colors.neutrals['100'],
    fontSize: '1rem',
    fontWeight: 400,
    width: '100%',
    boxSizing: 'border-box',
  },
  textField: {
    marginTop: 0
  }
};

export const ShippingTrackingIdsInput = ({
  shippingTrackingIds,
  onBlur,
  onChange
}) => {
  const [trackingIds, setTrackingIds] = useState(shippingTrackingIds || "");

  useEffect(() => {
    setTrackingIds(shippingTrackingIds || "");
  }, [shippingTrackingIds]);

  const formatTrackingIds = (trackingIds) =>
    trackingIds
      .split(",")
      .map((id) => id.trim())
      .filter((id) => !!id)
      .join(",");

  const handleTrackingIdsChange = (e) => {
    e.preventDefault();
    const trackingIds = e.target.value;
    setTrackingIds(trackingIds);
    onChange && onChange(trackingIds);
  };

  const handleTrackingIdsBlur = () => {
    onBlur && onBlur(formatTrackingIds(trackingIds));
  };

  return (
    <LabeledInput
      label={
        <label>
          Tracking numbers &nbsp;
          <span style={{ fontWeight: 700 }}>(comma separated)</span>
        </label>
      }
      value={trackingIds}
      placeholder="Shipping Tracking"
      onChange={handleTrackingIdsChange}
      onBlur={handleTrackingIdsBlur}
      data-testid="shipping_tracking_ids_input"
    />
  );
};

