import { get, isString, memoize, orderBy, toNumber, values } from 'lodash';
import { getCategoryItems } from '../../../helpers/shop';
import { formatMoney, getImageSrc, hasQueryParam, sizeSort } from '../../../utils';
import { PublicViewTemplate } from '../../../models/PublicViewTemplate';

export const DEFAULT_COLOR = '#5ca3b6';
export const TEMPLATE_SIDEBAR_WIDTH = 240;

export const CHECKOUT_PANELS = {
  COMPANY_NAME: 'COMPANY_NAME',
  SHIPPING_INFO: 'SHIPPING_INFO',
  BILLING_INFO: 'BILLING_INFO',
  CUSTOMER_INFO: 'CUSTOMER_INFO',
  ADDITIONAl_INFO: 'ADDITIONAl_INFO',
  COMMENTS: 'COMMENTS',
};

export const CHECKOUT_PANELS_ORDER = {
  COMPANY_NAME: 1,
  BILLING_INFO: 2,
  SHIPPING_INFO: 3,
  CUSTOMER_INFO: 4,
  ADDITIONAl_INFO: 5,
  COMMENTS: 6,
};

export const SHOP_TYPES = {
  POPUP_SHOP: 'POPUP_SHOP',
  MARKETING_SHOP: 'MARKETING_SHOP',
  COMPANY_SHOP: 'COMPANY_SHOP',
};

export const PRODUCT_LIST_OPTION = [
  { key: 'TILE', value: 'Tile' },
  { key: 'GRID', value: 'Grid' },
];

export const PRODUCT_PAGE_OPTION = [
  { key: 'CAROUSEL', value: 'Carousel' },
  { key: 'POPUP', value: 'Popup' },
  { key: 'DROPDOWN', value: 'Dropdown' },
];

const TEMPLATE_DEFAULT_HELP_SCREEN_OPTIONS = {
  help_screen_style: 'SIDE_BY_SIDE',
  help_screen_visible: '1',
  help_screen_image: '',
};

export const TEMPLATE_OPTIONS = {
  [PublicViewTemplate.FEATURED]: {
    title: '',
    button_text: '',
    background: '/images/template1-bg.jpg',
    ...TEMPLATE_DEFAULT_HELP_SCREEN_OPTIONS,
    help_screen_style: 'TEXT_ONLY',
  },
  [PublicViewTemplate.BILLBOARD]: {
    title: '',
    button_text: 'Browse all of our Products',
    darken: '1',
    background: '/images/template1-bg.jpg',
    ...TEMPLATE_DEFAULT_HELP_SCREEN_OPTIONS,
    help_screen_style: 'TEXT_ONLY',
  },
  [PublicViewTemplate.SPLIT]: {
    title: '',
    button_text: '',
    background: '/images/template1-bg.jpg',
    ...TEMPLATE_DEFAULT_HELP_SCREEN_OPTIONS,
    help_screen_style: 'TEXT_ONLY',
  },
  [PublicViewTemplate.CATEGORIES_CAROUSEL]: {
    title: '',
    hero: '1',
    fill: '1',
    autoscroll: '1',
    categorytitle: '1',
    useProductImages: '1',
    ...TEMPLATE_DEFAULT_HELP_SCREEN_OPTIONS,
    help_screen_style: 'TEXT_ONLY',
  },
  [PublicViewTemplate.LEFT_NAV_HERO_IMAGE]: {
    title: '',
    darken: '1',
    categorytitle: '1',
    has_sidebar: '1',
    sidebar_nav_underlined: '1',
    item_preview_version: '2',
    ...TEMPLATE_DEFAULT_HELP_SCREEN_OPTIONS,
  },
};

export const freeItemRowStyle = {
  overflowY: 'auto',
  width: '100%',
  display: 'flex',
  flexWrap: 'wrap',
};

export const freeItemColStyle = {
  display: 'flex',
  flexGrow: 1,
  textAlign: 'center',
  margin: 0,
  padding: 2,
  color: 'white',
  minWidth: 144,
};

export function isShopAggregate(shop) {
  return ('' + shop.aggregate) === '1';
}

export function isShopFree(shop) {
  return ('' + shop.is_shop_free) === '1';
}

/**
 * get shop type
 * @param {import('../../../interfaces/Shop').Shop} shop shop object
 * @returns {'POPUP_SHOP' | 'MARKETING_SHOP' | 'COMPANY_SHOP'}
 */
export function getShopType(shop) {
  if (isShopAggregate(shop)) {
    return SHOP_TYPES.POPUP_SHOP;
  }
  if (!isShopAggregate(shop) && !shop.client_id) {
    return SHOP_TYPES.MARKETING_SHOP;
  }
  return SHOP_TYPES.COMPANY_SHOP;
}

const getSkus = memoize((item, buyInventory) => {
  const inventoryItems = item.inventory_items;
  const inventorySkus = item.skus;

  const inventory_skus = inventoryItems.map(ii => ii.product_sku_id);
  return inventorySkus.filter(
    s => buyInventory || inventoryItems.length === 0 || inventory_skus.includes(s.product_sku_id)
  );
});

export const getOptionsFromSkus = memoize((skus) => {
  const baseOptions = skus.reduce(
    (o, sku) => o.concat(sku.options),
    []
  ).reduce(
    (o, option) => ({
      ...o,
      [option.option_axis]: Array.from(new Set((o[option.option_axis] || []).concat(option.option_name))).sort(
        'size' === option.option_axis ? sizeSort : undefined
      )
    }),
    {}
  );
  const optionKeys = Object.keys(baseOptions);
  const hasSizeAxis = optionKeys.includes('size');
  const axes = optionKeys.filter(a => 'dimension' !== a).sort();
  const options = optionKeys.filter(
    k => axes.includes(k)
  ).reduce(
    (o, k) => ({ ...o, [k]: baseOptions[k] }),
    {}
  );
  return options;
});

const isSkuOptionSet = (options, value, option) => {
  return options[option.option_axis].length === 1 || value[option.option_axis] === option.option_name;
};

export const getSkuFromOptions = (skus, value, options={}) => {
  if (Object.keys(options || {}).length === 0) {
    options = getOptionsFromSkus(skus || []);
  }
  const axes = Object.keys(options);
  const sku = skus.filter(
    sku => sku.options.filter(o => axes.includes(o.option_axis)).every(o => isSkuOptionSet(options, value, o))
  ).filter(
    sku => !value.sku || value.sku === 'Quantity' || value.sku === (sku.description || sku.sku)
  ).map(
    sku => sku.product_sku_id
  );
  return sku;
};

export function formatNumber(value, isMoney = false) {
  if (isMoney) {
    return formatMoney(value, 2);
  }
  if (toNumber(value) === 0) { return value; }
  return toNumber(value).toLocaleString();
}

// item sku selector utils
export const hasSkuInventory = (item, buyInventory) => buyInventory || item.inventory_items.length > 0;
export const getItemSkus = (item, buyInventory) => {
  const skus = getSkus(item, buyInventory);
  const options = getOptionsFromSkus(skus);
  const axes = Object.keys(options).filter(a => 'dimension' !== a).sort();

  return { skus, options, axes };
};
export const getQuantityAxis = (axes) => axes.includes('size') ? 'size' : axes.includes('dimension') ? 'dimension' : 'sku';
export const getSkuInventory = (item, buyInventory, product_sku_id) => {
  const available = item.inventory_items.filter(ii => ii.product_sku_id === product_sku_id).map(ii => ii.available);
  if (available.length > 0) {
    return Math.max(0, available[0]);
  }
  return buyInventory ? 0 : null;
};

export const onChangeItemSkuQuantity = (value, item, buyInventory, option, onChangeQuantity, quantityAxis = null, optionValue = {}) => e => {
  const { skus, axes } = getItemSkus(item, buyInventory);
  if (quantityAxis === null) {
    quantityAxis = getQuantityAxis(axes);
  }

  const hasInventory = hasSkuInventory(item, buyInventory);
  const newValue = e.target.value.replace(/[^0-9]/g, '');
  if (hasInventory && !buyInventory) {
    const maxValue = getSkuInventory(item, hasInventory, getSkuFromOptions(skus, { ...value, [quantityAxis]: option })[0]);
    if (newValue > maxValue) {
      return onChangeQuantity(quantityAxis, option, maxValue, optionValue);
    }
  }
  return onChangeQuantity(quantityAxis, option, newValue, optionValue);
};

// item option selector utils
export const showMinQty = (item, buyInventory, aggregate, force_minimum_qty) => (!!buyInventory || item.inventory_items.length === 0) && (!aggregate || force_minimum_qty);
export const showMaxQty = (buyInventory, hasInventory, quantity, maxQuantity) => hasInventory && !buyInventory && quantity === maxQuantity;
export const hasItemInventory = (item, buyInventory) => item.inventory_items.length > 0 || buyInventory;
export const getItemInventory = (item, size_id, color_id, buyInventory) => {
  const available = item.inventory_items.filter(ii => ii.color_id === color_id && ii.size_id === size_id).map(ii => ii.available);
  if (available.length > 0) {
    return Math.max(0, available[0] || 0);
  }
  return buyInventory ? 0 : null;
};
export const onChangeItemOption = (item, size_id, color_id, buyInventory, onChangeSize) => (e) => {
  const value = e.target.value.replace(/[^0-9]/g, '');
  const hasInventory = hasItemInventory(item, buyInventory);
  if (hasInventory && !buyInventory) {
    const maxValue = getItemInventory(item, size_id, color_id, buyInventory);
    if (value > maxValue) {
      onChangeSize(size_id)(maxValue);
      return;
    }
  }
  onChangeSize(size_id)(value);
};

export function getShopItemMinQuantity(
  { force_minimum_qty, buy_inventory, aggregate },
  item
) {
  const inventoryItemsLen = (get(item, 'inventory_items') || []).length;
  const minQty = get(item, 'minimum_quantity');
  const isAggregate = isShopAggregate({ aggregate });
  return (!!buy_inventory || inventoryItemsLen == 0) &&
    ((isAggregate && force_minimum_qty == 1) || !isAggregate) ? minQty : 1;
}

export function getItemSelectedSizeOptionsByColorId({ buy_inventory, is_shop_free }, item, selectedColorId) {
  let selectedSizeOptions = [];
  if('ps-products' === item.copied_from) {
    selectedSizeOptions = item.skus.filter(v => v.options.filter(o => ['color', 'dimension'].includes(o.option_axis) && o.option_name).length > 0);
  } else {
    const buyInventory = !!buy_inventory;
    const hasInventory = hasItemInventory(item, buyInventory);
    const getInventory = (size_id) => getItemInventory(item, size_id, selectedColorId, buyInventory);
    const inventory_sizes = item.inventory_items.map(ii => ii.size_id);
    const sizes = (!(item.sizes || []).length ? [{ size_id: 'TBD', size_name: is_shop_free == 1 ? 'One Size' : 'Quantity' }] : (item.sizes || [])).filter(
      s => buyInventory || item.inventory_items.length === 0 || inventory_sizes.includes(s.size_id)
    );
    selectedSizeOptions = sizes.filter(s => hasInventory || getInventory(s.size_id) == null);
  }

  return selectedSizeOptions;
}

export const getShopHeaderTitle = (templateData, shop_name='') => {
  let headerTitle = get(templateData, ['header-title', 'value']);
  let headerTitleType;
  if (headerTitle !== undefined) {
    headerTitleType = get(templateData, ['header-title-type', 'value']);
  } else {
    headerTitle = get(templateData, ['header-title']);
    headerTitleType = get(templateData, ['header-title-type']);
  }
  return {
    headerTitle: headerTitle || shop_name,
    headerTitleType: headerTitleType || 'text',
  };
};

export const isShopPreviewing = () => (hasQueryParam('previewShop') === 'true');
export const hideShopIntro = () => (hasQueryParam('hideHelp') === 'true');

const defaultCategoryTitle = index => `Category ${index + 1}`;

export const getShopCategoryWithImages = (items, publicViewTemplateData) => {
  const categories = items.filter(i => 'SEPARATOR' === i.parent_type);
  const categoryWithImages = [];

  for (let index = 0; index < categories.length; index++) {
    const category = categories[index];
    const itemImages = getCategoryItems(items, category.item_id).filter(i => +i.hidden !== 1).map(i => i.images[0]);
    const categoryImages = [];
    const mainImg = get(publicViewTemplateData, [`CATEGORY-${category.item_id}-IMAGE-MAIN`]);
    const hoverImg = get(publicViewTemplateData, [`CATEGORY-${category.item_id}-IMAGE-HOVER`]);

    if (mainImg) {
      categoryImages.push(mainImg.value || mainImg);
    }
    if (hoverImg) {
      categoryImages.push(hoverImg.value || hoverImg);
    }
    categoryWithImages.push({ categoryImages, itemImages, ...category, item_name: category.item_name.trim() || defaultCategoryTitle(index) });
  }

  return orderBy(categoryWithImages, [v => +v.display_order], ['asc']);
};

export const getShopCategoryUrl = (category, hoverImage, useProductImages, mainImageIndex=0, hoverImageIndex=1) => {
  let url = '';
  let hoverUrl = false;
  if (useProductImages) {
    url = category.itemImages ? getImageSrc(category.itemImages[0], 'large') : '';
    if (category.itemImages && category.itemImages[1]) {
      hoverUrl = getImageSrc(category.itemImages[1], 'large');
    }
  } else {
      url = category.categoryImages &&
            category.categoryImages.length > 0
          ? category.categoryImages[mainImageIndex]
          : '';
      if (category.categoryImages &&
          category.categoryImages.length > 1 &&
          category.categoryImages[hoverImageIndex]
      ) {
          hoverUrl = category.categoryImages[hoverImageIndex];
      }
  }
  if (hoverImage === category.item_id && hoverUrl && hoverUrl.length > 0) {
      url = hoverUrl;
  }

  return { url, hoverUrl };
};

export const publicViewTemplateHasSidebar = (templateData) => {
  const hasSidebar = values(templateData.public_view_template_properties)
    .find(v => v.public_view_template_property_name === 'HasSidebar');
  return hasSidebar && +hasSidebar.public_view_template_property_value === 1;
};

const publicViewTemplatePropertyFilter = (propertyName) => (stp) =>
  stp.public_view_template_property_context === "DEFAULT" &&
  stp.public_view_template_property_name === propertyName;

export const getPublicViewTemplateDefaultProperty = (
  propertyName,
  publicViewTemplateProperties,
  defaultValue = null
) =>
  get(
    values(publicViewTemplateProperties).find(
      publicViewTemplatePropertyFilter(propertyName)
    ),
    ["public_view_template_property_value"],
    defaultValue
  );

const getCategoryImage = (
  category,
  publicViewTemplateData,
  index,
  banner = false
) => {
  let imageKey = `CATEGORY-${category.item_id}-IMAGE-${
    banner ? "BANNER" : "MAIN"
  }`;
  // if already customized, use the customized image
  const image = publicViewTemplateData[imageKey];
  if (image) {
    return isString(image) ? image : get(image, ["value"]);
  }
  // fallback to default image
  const imageIndex = ((index + banner) % 10) + 1;
  imageKey = `CATEGORY-${imageIndex}-IMAGE-MAIN`;
  const defaultImage = `/images/shop-resources/left-nav-hero-template-default-${imageIndex}.jpeg`;

  return getPublicViewTemplateDefaultProperty(imageKey, publicViewTemplateData.public_view_template_properties, defaultImage);
};

const getPosterCategoryProductImage = (items, category) => {
  const image = getCategoryItems(items, category.item_id)
    .filter((i) => +i.hidden !== 1)
    .map((i) => i.images[0])[0];

  return image ? getImageSrc(image, "medium") : "";
};

export const getPosterCategories = (items, publicViewTemplateData) => {
  const categories = items.filter((i) => "SEPARATOR" === i.parent_type);
  const allCategoriesBannerImage =
    get(publicViewTemplateData, ["CATEGORY-ALL-IMAGE-BANNER", "value"]) || "";
  const applyBannerToAllCategpties =
    !!allCategoriesBannerImage &&
    get(publicViewTemplateData, ["APPLY-BANNER-TO-ALL-CATEGORIES", "value"], "0") ===
      "1";
  const defaultBannerDimPercentage = getPublicViewTemplateDefaultProperty(
    "BANNER-DIM_PERCENTAGE",
    publicViewTemplateData.public_view_template_properties,
    "0.5"
  );
  const useProductImages =
    get(publicViewTemplateData, ["useProductImages", "value"], "1") === "1";

  const categoriesWithImage = categories
    .map((c, i) => {
      const bannerDimPercentage = get(
        publicViewTemplateData,
        [`CATEGORY-${c.item_id}-BANNER-DIM`, "value"],
        defaultBannerDimPercentage
      );
      // 0 is banner, 1 is category image on home page
      const categoryImages = [
        applyBannerToAllCategpties
          ? allCategoriesBannerImage
          : getCategoryImage(c, publicViewTemplateData, i, true),
        useProductImages
          ? getPosterCategoryProductImage(items, c)
          : getCategoryImage(c, publicViewTemplateData, i),
      ];

      return {
        ...c,
        item_name: c.item_name.trim() || defaultCategoryTitle(i),
        bannerDimPercentage,
        categoryImages,
      };
    })
    .concat({
      item_name: "ALL PRODUCTS",
      item_id: "ALL_PRODUCTS",
      display_order: 0,
      bannerDimPercentage: get(
        publicViewTemplateData,
        ["ALL-PRODUCTS-BANNER-DIM", "value"],
        defaultBannerDimPercentage
      ),
      categoryImages: [
        applyBannerToAllCategpties
          ? allCategoriesBannerImage
          : get(
              publicViewTemplateData,
              ["ALL-PRODUCTS-IMAGE-MAIN", "value"],
              getPublicViewTemplateDefaultProperty(
                "CATEGORY-1-IMAGE-MAIN",
                publicViewTemplateData.public_view_template_properties,
                "/images/shop-resources/left-nav-hero-template-default-1.jpeg"
              )
            ),
      ],
    });
  return orderBy(categoriesWithImage, [(v) => +v.display_order], ["asc"]);
};

const getPublicViewTemplateBooleanProperty = (templateData, templateProperties, propertyName) =>
  templateData[propertyName]
    ? templateData[propertyName] === "1"
    : values(templateProperties).find(
    publicViewTemplatePropertyFilter(propertyName)
  )?.public_view_template_property_value === "1";

export const getTemplateBannerOnHover = (templateData, templateProperties) =>
  getPublicViewTemplateBooleanProperty(templateData, templateProperties, "BANNER-ON-HOVER");

export const getApplyBannerToAllCategories = (templateData, templateProperties) =>
  getPublicViewTemplateBooleanProperty(templateData, templateProperties, "APPLY-BANNER-TO-ALL-CATEGORIES");

export const getShopHelpScreenImage = (templateData, templateProperties) => {
  const helpScreenImage = get(templateData, ["help_screen_image", "value"]);
  if (!!helpScreenImage) {
    return helpScreenImage;
  }
  return getPublicViewTemplateDefaultProperty(
    "help-screen-image",
    templateProperties,
    "/images/shop-resources/left-nav-hero-template-default-7.jpeg"
  );
};
