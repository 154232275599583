import React, { useMemo } from "react";
import { useDispatch } from "react-redux";
import { createUpdateTemplate } from "../../../actions/shop";
import { colors, Dropdown, EllipsisIcon, Text } from "@commonsku/styles";
import {
  createDeleteTemplatePopup,
  createPublishTemplatePopup,
  createRenameTemplatePopup,
} from "../../../actions/popup";
import "./ThreeDotsMenu.css";
import { useIdentity } from "../../../hooks";
import { getIdentityUtils } from "../../../utils";
import styled from "styled-components";

interface MenuItemProps {
  delete?: boolean;
}

const MenuItem = styled.p<MenuItemProps>`
  color: ${(props) => (props.delete ? colors.error : colors.neutrals["90"])};
  font-weight: 400;
  margin-bottom: 0 !important;
`;

interface ThreeDotsMenuProps {
  public_view_template_id: string;
  public_view_template_title: string;
  draft?: string;
  isDefaultTemplate: boolean;
  onRemoveDefaultTemplate: (
    public_view_template_id: string,
    order_types: string[]
  ) => void;
  onAddDefaultTemplate: (
    public_view_template_id: string,
    order_types: string[]
  ) => void;
  templateNames?: string[];
}

const ThreeDotsMenu = ({
  draft,
  public_view_template_id,
  public_view_template_title,
  isDefaultTemplate,
  onRemoveDefaultTemplate,
  onAddDefaultTemplate,
  templateNames,
}: ThreeDotsMenuProps) => {
  const dispatch = useDispatch();
  const identity = useIdentity();
  const isTeam = getIdentityUtils(identity).isTeam();

  const options = useMemo(
    () =>
      !isTeam
        ? [
            {
              onClick: () =>
                onAddDefaultTemplate(public_view_template_id, ["SHOP"]),
              content: <MenuItem>Set as default Template</MenuItem>,
              active: true,
              show: !isDefaultTemplate,
            },
            {
              onClick: () =>
                onRemoveDefaultTemplate(public_view_template_id, ["SHOP"]),
              content: <MenuItem>Remove as default Template</MenuItem>,
              active: true,
              show: isDefaultTemplate,
            },
            {
              onClick: () =>
                dispatch(
                  createRenameTemplatePopup(
                    public_view_template_id,
                    public_view_template_title,
                    templateNames
                  )
                ),
              content: <MenuItem>Rename</MenuItem>,
              active: true,
              show: true,
            },
            {
              onClick: () =>
                dispatch(
                  createDeleteTemplatePopup(
                    public_view_template_id,
                    public_view_template_title
                  )
                ),
              content: <MenuItem delete>Delete</MenuItem>,
              active: true,
              show: true,
            },
          ]
        : [
            {
              onClick: () =>
                console.log("Not part of the MVP, will be implemented later"),
              content: <MenuItem>View in Editor</MenuItem>,
              active: true,
              show: false,
            },
            {
              onClick: () =>
                dispatch(
                  createUpdateTemplate(public_view_template_id, {
                    status: "DRAFT",
                  })
                ),
              content: <MenuItem>Unpublish Template</MenuItem>,
              active: true,
              show: draft === "PUBLISHED",
            },
            {
              onClick: () =>
                dispatch(createPublishTemplatePopup(public_view_template_id)),
              content: <MenuItem>Publish Template</MenuItem>,
              active: true,
              show: draft === "DRAFT",
            },
            {
              onClick: () =>
                dispatch(
                  createRenameTemplatePopup(
                    public_view_template_id,
                    public_view_template_title,
                    templateNames
                  )
                ),
              content: <MenuItem>Rename</MenuItem>,
              active: true,
              show: true,
            },
            {
              onClick: () =>
                dispatch(
                  createDeleteTemplatePopup(
                    public_view_template_id,
                    public_view_template_title
                  )
                ),
              content: <MenuItem delete>Delete</MenuItem>,
              active: true,
              show: true,
            },
          ],
    [
      dispatch,
      isTeam,
      draft,
      public_view_template_id,
      public_view_template_title,
      isDefaultTemplate,
      onRemoveDefaultTemplate,
      onAddDefaultTemplate,
    ]
  );
  return (
    <div className="three-dots-dropdown">
      <Dropdown
        items={options.filter((option) => option.show)}
        icon={<EllipsisIcon size="huge" />}
        style={{ float: "right" }}
        hideOnMouseLeave={false}
      />
    </div>
  );
};

export default ThreeDotsMenu;
