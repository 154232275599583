import { Avatar, Typography } from '@commonsku/styles';
import React from 'react';
import { AvatarInitials } from '../helpers/Avatar';
import { dateStr } from '../../utils';

export type FileControlsProps = {
    file: any,
};

export const RepAndDate = (props: FileControlsProps) => {
    const rep = props.file?.uploader;
    let repImage = null;
    if (rep?.user_image_paths?.small) {
        repImage = <Avatar shape="circle" mr={5} pic={rep.user_image_paths.small} style={{ width: '28px', height: '28px', marginRight: '5px' }} />;
    } else if (rep?.user_first_name || rep?.user_last_name) {
        const name = (rep?.user_first_name || '') + ' ' + (rep?.user_last_name || '');
        repImage = <AvatarInitials name={name.trim()} style={{ width: '28px', height: '28px', marginRight: '5px' }} />;
    }

    return <div style={{ margin: '15px 0', display: 'flex', alignItems: 'baseline' }}>
        {repImage}
        <Typography style={{ fontSize: '0.9rem', color: 'gray' }}>{dateStr(new Date(props.file.date_uploaded))}</Typography>
    </div>;
};
