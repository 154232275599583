import { LabeledIconInput, DollarIcon } from '@commonsku/styles';
import { useRange } from 'react-instantsearch';
function CustomRangeInput({ defaultLabel, isMin = false, value, setValue, ...props }) {
  const { range, refine } = useRange(props);
  function stripLeadingZeroFromInput(value) {
    return value.replace(/^(0+)\d/, (part) => Number(part).toString());
  }
  return (
    <div>
      <LabeledIconInput
        labelOnTop
        label={defaultLabel}
        value={stripLeadingZeroFromInput(value)}
        placeholder={isMin ? range.min?.toString() : range.max?.toString()}
        Icon={<DollarIcon />}
        style={{ width: 'auto' }}
        onChange={({ currentTarget }) => {
          if (!/^\d*$/.test(currentTarget.value)) {
            return;
          }
          const value = currentTarget.value;
          setValue(prev => ({ ...prev, [isMin ? 'min' : 'max']: value }));
          const min = isMin ? Number(value) : undefined;
          const max = !isMin ? (Number(value) || undefined) : undefined;
          refine([min, max])
        }}
      />
    </div>
  );
}

function CustomPriceRange({ range, setRange }) {
  return (
    <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
      <CustomRangeInput
        defaultLabel="From"
        isMin={true}
        attribute='min_price'
        value={range.min}
        setValue={setRange}
      />
      <CustomRangeInput
        defaultLabel="To"
        attribute='max_price'
        value={range.max}
        setValue={setRange}
      />
    </div>
  );
}

export default CustomPriceRange;