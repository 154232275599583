import React, { useEffect, useMemo, useState } from "react";
import { createRoot } from "react-dom/client";
import { flushSync } from "react-dom";
import { isBool, isNumber, isString } from "../types/type_guards";
import { rebuildTooltip } from "../components/helpers";

type TooltipPlacement = "top" | "left" | "right" | "bottom";
type TooltipEffect = "float" | "solid";

interface TooltipAttributes {
  "data-for": string;
  "data-tip": string;
  "data-html": boolean;
  "data-delay-hide"?: number;
  "data-delay-update"?: number;
  "data-place"?: TooltipPlacement;
  "data-effect"?: TooltipEffect;
}

export interface TooltipOptions {
  delayHide?: number;
  delayUpdate?: number;
  place?: TooltipPlacement;
  effect?: TooltipEffect;
}

export default function useTooltipAttributes(
  tooltipText: React.ReactNode,
  targetId: string,
  options?: TooltipOptions,
): TooltipAttributes | null {
  const [formattedTooltipText, setFormattedTooltipText] = useState('');
  const [isHtml, setIsHtml] = useState(false);
  useEffect(() => {
    if (tooltipText === undefined || tooltipText === null || isBool(tooltipText)) {
      setFormattedTooltipText("");
      setIsHtml(false);
      return;
    }

    if (isString(tooltipText) || isNumber(tooltipText)) {
      const lines = String(tooltipText).split("\n").map((line) => line.trim()).filter((line) => line !== "");
      setFormattedTooltipText(lines.join("<br/>"));
      setIsHtml(lines.length > 1);
      return;
    }

    let mounted = true;

    function update() {
      const div = document.createElement("div");
      const root = createRoot(div);
      flushSync(() => {
        root.render(tooltipText);
      });
      if (mounted) {
        setFormattedTooltipText(div.innerHTML);
        setIsHtml(true);
	rebuildTooltip();
      }
    }
    setTimeout(update);
    return () => { mounted = false; }
  }, [tooltipText]);

  const delayHide = options?.delayHide;
  const delayUpdate = options?.delayUpdate;
  const place = options?.place;
  const effect = options?.effect;

  const tooltipAttributes = useMemo<TooltipAttributes | null>(() => {
    if (formattedTooltipText) {
      const attributes: TooltipAttributes = {
        "data-for": targetId,
        "data-tip": formattedTooltipText,
        "data-html": isHtml,
      };
      if (delayHide) {
	attributes["data-delay-hide"] = delayHide;
      };
      if (delayUpdate) {
	attributes["data-delay-update"] = delayUpdate;
      };
      if (place) {
	attributes["data-place"] = place;
      };
      if (effect) {
	attributes["data-effect"] = effect;
      };
      return attributes;
    }
    return null;
  }, [targetId, formattedTooltipText, isHtml, delayHide, delayUpdate, place, effect]);

  return tooltipAttributes;
}
