import { filter, get, isEmpty, values } from 'lodash';
import React, { useState, useEffect } from 'react';
import { useDispatch, connect, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Background, Box, Button, Col, H3, Row } from '@commonsku/styles';

import ProductSearch from './ProductSearch';

import { createDeleteTemp } from '../actions/temp';
import { window } from '../global';
import ThemeProductContainer from './theme/ThemeProductContainer';
import SuggestionContainer from './product-suggestion/SuggestionContainer';
import ga from '../utils/ga';
import { useIdentity } from '../hooks';
import { getIdentityUtils, oauth } from '../utils';
import { ThemeTileContainer } from './theme/ThemeTile';
import { hasAlgoliaConfig } from './product-suggestion/Algoliasearch';
const FETCH_INTERVAL = 2000;

const ProductSearchModal = ({ project, order, params, isCollectionPage, popups = [], location }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const themes = useSelector((state) => {
    const results = values(get(state, 'entities.themes', []));
    return order ? filter(results, (theme) =>
      +theme.is_featured === 1
    ) : results;
  });
  const [showThemes, setShowThemes] = useState(true);
  const [selectedTheme, setSelectedTheme] = useState(null);
  const identity = useIdentity();
  const [isSuggestSearch, setIsSuggestSearch] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [recommendationsLoading, setRecommendationsLoading] = useState(false);
  const { hasUserFlags } = getIdentityUtils(identity);
  const productSmartEnabled = hasUserFlags('PRODUCT-SMART-ENABLED');

  useEffect(() => {
    if (order.order_type === 'SHOP') {
      return;
    }
    setRecommendationsLoading(true);

    const fetchSuggestions = () => {
      oauth('GET', 'order/get-suggestions', { order_id: order.order_id }).then((response) => {
        if (!isEmpty(response.json.suggestions)) {
          setSuggestions(response.json.suggestions);
          setRecommendationsLoading(false);
          clearInterval(intervalId);
        }
      });
    };

    fetchSuggestions();
    const intervalId = setInterval(fetchSuggestions, FETCH_INTERVAL);

    return () => clearInterval(intervalId);
  }, []);

  const onClose = () => {
    dispatch(createDeleteTemp('new_product'));
    if (window.history.length > 2) {
      navigate(-1);
    } else if (location && location.pathname && (
      location.pathname.endsWith('/add-product')
      || location.pathname.endsWith('/add-product/')
    )) {
      window.location = location.pathname.replace('/add-product', '');
    }
  };

  const headerText = !!order && 'COLLECTION' === order.order_type ? 'Add a product to your collection' : 'Add Product';

  const onSelectTheme = (theme) => {
    setSelectedTheme(theme);
    ga.gtag("event", "click", {
      'order_id': get(order, 'order_id', ''),
      'theme_id': get(theme, 'theme_id', ''),
      'userId': identity.user_id,
    });
  };

  return (
    <Background fillWindow padded style={{
      position: 'absolute',
      width: '100%',
      height: '100%',
      overflow: 'hidden',
      display: 'flex',
      flexDirection: 'column',
    }}>
      <Row style={{ flex: '0 0 auto' }}>
        <Col style={{ flex: 2 }}>
          <H3>{headerText}</H3>
        </Col>
        <Col style={{ flex: 1 }}>
          {!popups.length && <Button onClick={onClose} style={{ float: 'right' }}>Done</Button>}
        </Col>
      </Row>
      {
        selectedTheme
          ? <ThemeProductContainer order={order} userId={identity.user_id} selectedTheme={selectedTheme} onClose={() => setSelectedTheme(false)} />
          : <>
            <Box borderless style={{
              ...showThemes ? { flex: '0 0 auto' } : { flex: 1, overflow: 'auto' },
              padding: '20px', display: 'flex', flexDirection: 'column', maxHeight: 'calc(100% - 20px)'
            }}>
              <ProductSearch
                project={project} order={order} params={params} location={location} isCollectionPage={isCollectionPage}
                onSearch={() => setShowThemes(false)} onClear={() => setShowThemes(true)}
                setIsSuggestSearch={setIsSuggestSearch}
                isSuggestSearch={isSuggestSearch}
              />
            </Box>
            {showThemes && (productSmartEnabled && hasAlgoliaConfig ?
              <SuggestionContainer
                themes={themes}
                order={order}
                onSelectTheme={onSelectTheme}
                isSuggestSearch={isSuggestSearch}
                setIsSuggestSearch={setIsSuggestSearch}
                suggestions={suggestions}
                recommendationsLoading={recommendationsLoading}
              />
              : !isEmpty(themes) && <Box borderless style={{
                padding: '20px', flex: 1, overflow: 'hidden', display: 'flex', flexDirection: 'column', marginTop: 20
              }}><ThemeTileContainer themes={themes} order={order} title={'Suggestions'} onClick={onSelectTheme} /></Box>
            )}
          </>
      }
    </Background>
  );
};

const mapStateToProps = (state, ownProps) => ({
  identity: state.identity
});

export default connect(mapStateToProps)(ProductSearchModal);
