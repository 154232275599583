import React, { useCallback, useState } from "react";
import { questions } from "./constants";
import {
  ArrowIcon,
  Button,
  colors,
  IconButton,
  LabeledRadio,
  Text,
} from "@commonsku/styles";
import styled from "styled-components";
import { useStateMachine } from "../helpers/useStateMachine";

export const GoBackButton = styled(Button)`
  &&& {
    background: transparent;
    border-color: transparent;
    padding: 0;

    &:hover {
      background: transparent;
      border-color: transparent;
      color: ${colors.primary1[60]};
    }
  }
`;

interface QuestionnaireProps {
  handleCustomRouting: (newRoute: string, selectedShopType?: string) => void;
}

const Questionnaire = ({ handleCustomRouting }: QuestionnaireProps) => {
  const [selectedAnswer, setSelectedAnswer] = useState(-1);
  const handleTransition = useCallback(
    (transition) => {
      setSelectedAnswer(-1);
      if (["MARKETING", "COMPANY", "POPUP"].includes(transition.nextState)) {
        handleCustomRouting("selectedShopPage", transition.nextState);
      }
    },
    [handleCustomRouting]
  );

  const {
    state: question,
    go,
    back,
  } = useStateMachine(questions, handleTransition);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "start",
      }}
    >
      <Text
        as="h1"
        style={{
          fontSize: 32,
          fontWeight: 600,
          marginBottom: 64,
          marginTop: 64,
        }}
      >
        {question.value}
      </Text>

      {question.transitions.map((answer, index: number) => (
        <LabeledRadio
          key={`answer-${index}`}
          label={answer.value}
          checked={selectedAnswer === index}
          onChange={() => setSelectedAnswer(index)}
          id={`shopCreation-questionnaire-option.${index}`}
        />
      ))}

      <div>
        {question.id !== "PURPOSE" && (
          <IconButton
            Icon={<ArrowIcon direction="left" />}
            iconPosition="left"
            variant="secondary"
            size="huge"
            style={{
              marginTop: 64,
              marginBottom: 16,
              marginRight: 24,
            }}
            onClick={back}
            id={`shopCreation-questionnaire-previousQuestion.btn`}
          >
            Previous Question
          </IconButton>
        )}

        <IconButton
          Icon={<ArrowIcon />}
          iconPosition="right"
          variant="primary"
          size="huge"
          style={{ marginTop: 64, marginBottom: 16 }}
          disabled={selectedAnswer === -1}
          onClick={() => go(question.transitions[selectedAnswer])}
          id={`shopCreation-questionnaire-continue.btn`}
        >
          Continue
        </IconButton>
      </div>
      <GoBackButton
        variant="text"
        size="small"
        style={{ display: "block" }}
        onClick={() => handleCustomRouting("initialize")}
        id={`shopCreation-questionnaire-goBack.btn`}
      >
        Take Me Back to Shop Selection
      </GoBackButton>
    </div>
  );
};

export default Questionnaire;
