import React, { useRef, useState } from 'react';
import { AlertIcon, AlertNotification, Button, colors, DoubleArrowIcon, Img, LabeledCheckbox, LabeledIconInput, PercentIcon, Popup, Row, XIcon } from '@commonsku/styles';
import { getImageSrc } from '../utils';

interface Item {
    item_id: string;
    copied_from: string;
    currency_id: string;
    item_images: {
        image: any
    }[];
};

type SetBulkMarginPopupProps = {
    selectedItems: Item[];
    onSetMarginClick: (margin: string, includeRunCharges: boolean) => Promise<void>;
    onClose: (done: boolean) => void;
    orderCurrencyId: string;
    orderType: string;
    checkBulkUpdateMarginStatus: (onSuccess: () => void) => void;
};

const LoadingMessage = 'Setting the margin. This may take a few minutes.';
const InitialWarning = 'Setting the margin will overwrite any previously entered margins for these items, for all quantity breaks. This will take a few minutes, and the order will be locked while updating.';
const EndQuantityPrincipleWarning = 'End-Quantity Pricing principle will be applied to all the tiers in a relevant proportion for non-zero margins. For zero margins it will be updated to the exact given margin.';

export default function SetBulkMarginPopup({
    selectedItems,
    onSetMarginClick,
    onClose,
    orderCurrencyId,
    orderType,
    checkBulkUpdateMarginStatus,
}: SetBulkMarginPopupProps) {
    const [margin, setMargin] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [showArrowsDown, setShowArrowsDown] = useState<boolean>(true);
    const [showArrowsUp, setShowArrowsUp] = useState<boolean>(false);
    const [inputError, setInputError] = useState<boolean>(false);
    const [marginInput, setMarginInput] = useState<string>('');
    const [includeRunCharges, setIncludeRunCharges] = useState<boolean>(false);
    const imagesRef = useRef(null);
    const warning = orderType === 'PRESENTATION' ? `${InitialWarning}\n\n${EndQuantityPrincipleWarning}` : InitialWarning;
    const differentCurrencies = selectedItems.filter((item) => item.currency_id !== orderCurrencyId);
    const error = differentCurrencies.length > 0 ? `${differentCurrencies.length} of the ${selectedItems.length} selected products have a different currency from the order's currency (${orderCurrencyId}). Deselect those products, and try again.` : null;
    const header = error ?
        `Can't set margin on ${differentCurrencies.length}/${selectedItems.length} products` :
        success ?
            `Margin set successfully on ${selectedItems.length} product${selectedItems.length === 1 ? '' : 's'}` :
            `Set margin on ${selectedItems.length} product${selectedItems.length === 1 ? '' : 's'}`;

    return <Popup
        style={{
            padding: 20,
            width: 'calc(100vw - 100px)',
            maxWidth: '50rem',
            height: 'auto',
            overflow: 'visible',
        }}
        header={
            <Row className='popup-header' justify="space-between" wrap="nowrap" style={{ alignItems: 'flex-start' }}>
                <h2 style={{
                    margin: '0.2em',
                    fontFamily: 'skufont-demibold',
                    fontSize: 26,
                    fontWeight: 500,
                    lineHeight: '26px',
                    color: colors.neutrals[90],
                }}>{header}</h2>
                <div>
                    <XIcon
                        cursor={'pointer'}
                        onClick={() => { onClose(loading || success) }}
                        mt={2}
                        ml={10}
                        size='large'
                    />
                </div>
            </Row>
        }
    >
        {success &&
            <div
                ref={imagesRef}
                style={{
                    display: 'flex',
                    gap: 10,
                    justifyContent: 'center',
                    flexWrap: 'wrap',
                    marginTop: 20,
                    marginBottom: 20,
                    maxHeight: 425,
                    overflow: 'hidden',
                    position: 'relative',
                    scrollBehavior: 'smooth',
                }
            }>
                {showArrowsUp &&
                    <DoubleArrowIcon
                        direction='up'
                        color={colors.primary[70]}
                        style={{
                            height: 45,
                            background: 'linear-gradient(0deg, transparent 0%, #DFEEF4 100%)',
                            width: '100%',
                            position: 'sticky',
                            top: 0,
                            cursor: 'pointer',
                            marginTop: -45,
                        }}
                        onClick={() => {
                            if (imagesRef && imagesRef.current) {
                                imagesRef.current.scrollTop -= imagesRef.current.clientHeight;
                                setShowArrowsDown(true);
                                if (imagesRef.current.scrollTop <= imagesRef.current.clientHeight) {
                                    setShowArrowsUp(false);
                                }
                            }
                        }}
                    />
                }

                {selectedItems.map((item) => {
                    const image = item.item_images.map(itemImage => itemImage.image)[0];
                    return <div
                        style={{
                            height: 140,
                            width: 140,
                            textAlign: 'center',
                        }}
                    >
                        <Img
                            style={{
                                height: 140,
                            }}
                            src={getImageSrc(image)}
                        />
                    </div>
                })}

                {selectedItems.length > 15 && showArrowsDown &&
                    <DoubleArrowIcon
                        direction='down'
                        color={colors.primary[70]}
                        style={{
                            height: 45,
                            background: 'linear-gradient(180deg, transparent 0%, #DFEEF4 100%)',
                            width: '100%',
                            position: 'sticky',
                            bottom: 0,
                            cursor: 'pointer',
                            marginBottom: -45,
                        }}
                        onClick={() => {
                            if (imagesRef && imagesRef.current) {
                                imagesRef.current.scrollTop += imagesRef.current.clientHeight;
                                setShowArrowsUp(true);
                                if (imagesRef.current.scrollTop + imagesRef.current.clientHeight >= imagesRef.current.scrollHeight - imagesRef.current.clientHeight) {
                                    setShowArrowsDown(false);
                                }
                            }
                        }}
                    />
                }
            </div>
        }

        {!success &&
            <AlertNotification
                alertType={loading ? 'loading' : error ? 'error' : 'warn'}
                style={{
                    marginTop: 10,
                    whiteSpace: 'pre-wrap',
                }}
            >{loading ? LoadingMessage : error ?? warning}</AlertNotification>
        }

        {!error && !success &&
            <>
                {inputError &&
                    <div
                        style={{
                            float: 'right',
                            width: 490,
                            marginTop: 25,
                            color: colors.errors[60],
                            fontFamily: 'skufont-regular',
                        }}
                    >
                        <AlertIcon
                            float='left'
                            mr={5}
                            color={colors.errors[60]}
                            size='medium'
                        />
                        Must be between 0 and 100
                    </div>
                }

                <LabeledIconInput
                    disabled={loading}
                    labelOnTop
                    label={null}
                    name='margin'
                    value={marginInput}
                    placeholder='Enter new margin here'
                    Icon={<PercentIcon />}
                    style={{
                        width: 250,
                        marginTop: 20,
                        marginBottom: 20,
                        ...(inputError ? { borderColor: colors.errors[60] } : {}),
                    }}
                    onChange={(e) => {
                        if (e.target.value !== '' && !/^[0-9\.]+$/.test(e.target.value)) {
                            return;
                        }

                        setMarginInput(e.target.value);
                    }}
                    onBlur={(e) => {
                        const updated = parseFloat(e.target.value);
                        if (!isNaN(updated) && updated >= 0 && updated <= 100) {
                            setMargin(updated.toFixed(2));
                            setMarginInput(updated.toFixed(2));
                            setInputError(false);
                        } else {
                            setInputError(true);
                        }
                    }}
                />
                <div>
                    <LabeledCheckbox
                        disabled={loading}
                        label='Apply to all additional charges'
                        checked={includeRunCharges}
                        onClick={() => setIncludeRunCharges(!includeRunCharges)}
                    />
                </div>
            </>
        }
        {!error &&
            <Row style={{ float: 'right' }}>
                {(success || loading) &&
                    <Button
                        disabled={loading}
                        onClick={() => { onClose(true) }}
                    >Done</Button>
                }

                {!success && !loading &&
                    <>
                        <Button
                            onClick={() => { onClose(false) }}
                            variant='secondary'
                        >Cancel</Button>

                        <Button
                            ml={15}
                            onClick={() => {
                                if (marginInput === '' || inputError) {
                                    return;
                                }

                                setLoading(true);
                                onSetMarginClick(margin, includeRunCharges);
                                checkBulkUpdateMarginStatus(() => {
                                    setSuccess(true);
                                    setLoading(false);
                                });
                            }}
                            disabled={marginInput === '' || inputError}
                        >Set margin for {selectedItems.length} item{selectedItems.length === 1 ? '' : 's'}</Button>
                    </>
                }
            </Row>
        }
    </Popup>;
}