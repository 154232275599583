import React from "react";
import { SwitchStyled, Text } from "@commonsku/styles";

export default function DimBackground(props) {
  const {
    value,
    onChange,
  } = props;

  return (
    <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
      }}
    >
      <div>
        <Text as="p" style={{
          color: 'var(--color-neutrals-90)',
          fontFamily: 'var(--font-family-regular)',
          fontSize: 16,
          marginBottom: 4,
        }}>Dim Background</Text>
      </div>
      <div >
        <SwitchStyled
          size="medium"
          selected={+value === 1}
          onClick={() => onChange(+value !== 1 ? 1 : 0)}
        />
      </div>
    </div>
  );
}
