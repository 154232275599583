import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEmpty, findIndex, filter, pick, find } from 'lodash';
import { useHits } from 'react-instantsearch';
import { getOrderItems } from '../../utils/order';
import { createLoadOrder } from '../../actions/order';
import { getOrderByNumber } from '../../selectors';
import { Row } from '@commonsku/styles';
import ProductChevronPopup from '../product/ProductChevronPopup';
import NewProduct from '../NewProduct';
import { formatMoney } from '../../utils';

const useOrderItems = (order) => {
  const dispatch = useDispatch();
  const full_order = useSelector(state => getOrderByNumber(state, pick(order, ['form_number', 'order_type'])));
  const [items, setItems] = useState(order ? getOrderItems(order) : []);
  useEffect(() => {
    if (full_order) {
      if (!full_order.loaded) {
        dispatch(createLoadOrder(full_order.order_id));
      } else {
        setItems(getOrderItems(full_order));
      }
    }
  }, [dispatch, full_order]);
  return [items, setItems];
};

function CustomHits({ loading, order, onAddItem, onDeleteItem, divisions, ...props }) {
  const { items, sendEvent } = useHits(props);
  const [selectedItem, setSelectedItem] = useState(null);
  const [addedItems, setAddedItems] = useOrderItems(order);

  const convertedItems = items.map(item => ({
    ...item,
    division_name: divisions[item.division_id]
  }));

  const findSelected = (id, ext_id) => {
    return find(addedItems, ({ ext_product_id, parent_id }) => {
      return (ext_product_id && ext_product_id === id) || (parent_id && parent_id === id) || (ext_id && ext_id === ext_product_id);
    });
  };

  const handleClickProductAction = async (item, onAddProduct) => {
    const added = findSelected(item.objectID, item.ext_product_id);
    if (!added) {
      const addedItem = await onAddProduct(item);
      sendEvent('conversion', item, `Product Added to ${order.order_type}`, {
        eventSubtype: 'addToCart',
      });
      setAddedItems([...addedItems, addedItem]);
    } else {
      onDeleteItem(added.item_id);
      setAddedItems(filter(addedItems, { item_id: added.item_id }));
    }
  };

  const getSubTitle = (minPrice, maxPrice) => {
    const minPriceFloat = parseFloat(minPrice);
    const maxPriceFloat = parseFloat(maxPrice);

    if (minPriceFloat === maxPriceFloat) {
      return `$${formatMoney(minPriceFloat)}`;
    }
    return `$${formatMoney(minPriceFloat)} - $${formatMoney(maxPriceFloat)}`;
  }

  return (
    <>
      <Row style={{ gap: 28, overflow: 'hidden', height: '380px' }}>
        {convertedItems.map((item) => {
          const parent_id = item.ext_product_id || item.objectID;
          const onLoading = loading.includes(parent_id);
          return <NewProduct
            key={parent_id}
            title={item.product_name}
            sku={item.product_supplier_code}
            subtitle={item.min_price && item.max_price ? getSubTitle(item.min_price, item.max_price) : null}
            description={item.division_name}
            product={item}
            loading={onLoading}
            loaded={!!findSelected(parent_id)}
            type="others"
            onClickProduct={(product) => {
              sendEvent('click', item, 'Product Clicked');
              setSelectedItem(product);
            }}
            onClickProductAction={() => {
              if (onLoading) {
                return;
              }
              handleClickProductAction(item, onAddItem);
            }}
          />
        })}
      </Row>

      {isEmpty(selectedItem) ? null : <ProductChevronPopup
        products={convertedItems}
        productIndex={findIndex(convertedItems, selectedItem)}
        isSelected={(item) => {
          const parent_id = item.ext_product_id || item.objectID;
          return !!findSelected(parent_id);
        }}
        onClose={() => setSelectedItem(null)}
        handleLoadNextPage={() => { }}
        isEnd={findIndex(convertedItems, selectedItem) === convertedItems.length - 1}
        onClickProductAction={() => handleClickProductAction(selectedItem, onAddItem)}
      />}
    </>
  );
}

export default CustomHits;