import { algoliasearch } from 'algoliasearch';
import config from '../../config';

let searchClient = null;
let indexName = null;
const hasAlgoliaConfig = config.algolia.applicationID && config.algolia.searchKey && config.algolia.index;

if (hasAlgoliaConfig) {
  searchClient = algoliasearch(config.algolia.applicationID, config.algolia.searchKey);
  indexName = config.algolia.index;
}

export { searchClient, indexName, hasAlgoliaConfig };
