import React, { useMemo } from "react";
import { AlertNotification, Col, Row, Text } from "@commonsku/styles";
import { getImageSrc } from "../../../../utils";
import { isShopThemeFavourite } from "./helpers";
import ThemeTile from "./ThemeTile";

export default function ThemeListView({
  templateData,
  themes,
  selectedTheme = null,
  onClose,
  onClickSelect,
  onClickPreview,
  onClickFavourite,
  tab,
  isShop,
}) {
  const desc = useMemo(() => {
    if (tab === "company") {
      return (
        <AlertNotification alertType="warn">
          <span>
            Templates DO NOT maintain changes you make to them unless you save
            them as a new template! Switching between templates is a destructive
            action!
          </span>
        </AlertNotification>
      );
    } else if (tab === "commonsku") {
      return "Get started with a commonsku template! ";
    } else {
      return "Layouts maintain changes you make to them in this Shop so you can switch through different layouts without losing work!";
    }
  }, [tab]);

  const sortedTemplates = useMemo(() => {
    if (!themes || !selectedTheme || tab === "layouts") {
      return themes || [];
    }

    const sortedThemes = themes.sort((a, b) =>
      b.date_created.localeCompare(a.date_created)
    );
    if (selectedTheme.company_id) {
      if (tab === "company") {
        return [
          selectedTheme,
          ...sortedThemes.filter(
            (t) =>
              t.public_view_template_id !==
              selectedTheme.public_view_template_id
          ),
        ];
      } else {
        return sortedThemes;
      }
    } else {
      if (tab === "company") {
        return sortedThemes;
      } else {
        return [
          selectedTheme,
          ...sortedThemes.filter(
            (t) =>
              t.public_view_template_id !==
              selectedTheme.public_view_template_id
          ),
        ];
      }
    }
  }, [selectedTheme, tab, themes]);

  return (
    <Row style={{ marginTop: 16 }}>
      <Col padded xs={12} md={12}>
        <Row>
          <Col xs style={{ paddingLeft: 8, paddingRight: 8 }}>
            {!isShop && (
              <Text
                as="p"
                style={{
                  color: "var(--color-neutrals-90)",
                  fontFamily: "var(--font-family-bold)",
                  fontSize: 32,
                }}
              >
                {selectedTheme
                  ? `Selected theme: ${selectedTheme.public_view_template_name} Theme`
                  : "Please select a theme"}
              </Text>
            )}
            <Text
              as="p"
              style={{
                fontFamily: "var(--font-family-regular)",
                fontSize: 18,
              }}
            >
              {!isShop
                ? "Customizations on themes will be saved to that specific theme until reset."
                : desc}
            </Text>
          </Col>
          <Col xs>
            <Row style={{ overflow: "auto" }}>
              {sortedTemplates.map((t, i) => (
                <Col padded xs sm={6} md={4} key={t.title + "-" + i}>
                  <ThemeTile
                    title={t.title}
                    template_name={t.title}
                    isFavourite={isShopThemeFavourite(templateData, t)}
                    isSelected={
                      selectedTheme &&
                      t.public_view_template_id ===
                        selectedTheme.public_view_template_id
                    }
                    imgSrc={
                      typeof t.image === "string"
                        ? t.image
                        : getImageSrc(t.image || {}, "original")
                    }
                    onClose={onClose}
                    onClickFavourite={() => onClickFavourite(t)}
                    onClickSelect={() => onClickSelect(t)}
                    onClickPreview={() => onClickPreview(t)}
                    isShop={isShop}
                    tab={tab}
                  />
                </Col>
              ))}
            </Row>
          </Col>
        </Row>
      </Col>
    </Row>
  );
}
