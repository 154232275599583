import React from "react";
import {
  AlertNotification,
  Button,
  H2,
  Popup,
  PopupHeader,
  XIcon,
} from "@commonsku/styles";
import { BASE_ZINDEX } from "../../../popup-factory";
import styled from "styled-components";

interface TemplateWarnPopupProps {
  onClose: () => void;
  onSaveAsTemplate: () => void;
  onDiscardChanges: () => void;
}

const StyledContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const TemplateWarnPopup = ({
  onClose,
  onSaveAsTemplate,
  onDiscardChanges,
}: TemplateWarnPopupProps) => {
  return (
    <>
      <Popup
        overlayZIndex={BASE_ZINDEX + 100}
        zIndex={BASE_ZINDEX + 200}
        style={{ width: 560, height: 224, borderRadius: 10 }}
        header={
          <PopupHeader
            style={{ padding: 0, alignItems: "center", marginBottom: 16 }}
          >
            <H2 style={{ margin: "0px" }} className="title">
              Unsaved Changes!
            </H2>
            <XIcon
              style={{ cursor: "pointer" }}
              size="large"
              onClick={() => onClose()}
            />
          </PopupHeader>
        }
      >
        <StyledContainer>
          <AlertNotification
            alertType="warn"
            style={{ height: 80, alignItems: "center" }}
          >
            You have unsaved changes. Changing to a different template will
            cause your changes to be lost.
          </AlertNotification>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "end",
              gap: 4,
            }}
          >
            <Button
              variant="secondary"
              onClick={onSaveAsTemplate}
              size="medium"
              style={{ flex: 1 }}
            >
              Save as a New Template
            </Button>
            <Button
              variant={"error"}
              disabled={false}
              onClick={onDiscardChanges}
              size="medium"
              style={{ flex: 1 }}
            >
              Discard Changes
            </Button>
          </div>
        </StyledContainer>
      </Popup>
    </>
  );
};

export default TemplateWarnPopup;
