import { useCallback, useState } from "react";

interface Transition<T, StateKeys extends string> {
  value: T;
  nextState: StateKeys;
  bidirectional?: boolean;
}

interface State<S, T, StateKeys extends string> {
  id: StateKeys;
  value: S | null;
  transitions: Transition<T, StateKeys>[];
}

interface StateMachine<
  S,
  T,
  States extends Record<string, State<S, T, keyof States & string>>
> {
  start: keyof States & string;
  states: States;
}

export const createStateMachine = <
  S,
  T,
  States extends Record<string, State<S, T, keyof States & string>>
>(
  sm: StateMachine<S, T, States>
) => sm;

interface TransitionHandler<T, K extends string> {
  (transition: Transition<T, K>): void;
}

export function useStateMachine<
  S,
  T,
  States extends Record<string, State<S, T, keyof States & string>>
>(
  rules: StateMachine<S, T, States>,
  onTransition?: TransitionHandler<T, keyof States & string>
) {
  const [path, setPath] = useState<Array<Transition<T, keyof States & string>>>(
    []
  );
  const state = rules.states[path[0]?.nextState ?? rules.start];

  const go = useCallback(
    (transition: Transition<T, keyof States & string>) => {
      if (!state.transitions.includes(transition)) {
        throw new Error("Invalid transition");
      }
      setPath([transition, ...path]);
      if (onTransition) {
        onTransition(transition);
      }
    },
    [path, onTransition]
  );

  const back = useCallback(() => {
    if (!path[0]?.bidirectional) {
      throw new Error("Invalid transition");
    }
    const [transition, ...newPath] = path;
    const newTransition = {
      value: transition.value,
      nextState: newPath[0]?.nextState ?? rules.start,
    };
    setPath(newPath);
    if (onTransition) {
      onTransition(newTransition);
    }
  }, [path, onTransition]);

  const reset = useCallback(() => {
    setPath([]);
  }, []);

  return {
    state,
    go,
    back,
    reset,
  };
}
