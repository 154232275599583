import {
  UPDATE_SHOP_SUCCESS,
  UPDATE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS,
  DELETE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS,
  LOAD_SHOP_ORDERS_SUCCESS,
  ADD_SHIPPING_RANGE_SUCCESS,
  UPDATE_SHIPPING_RANGE_SUCCESS,
  DELETE_SHIPPING_RANGE_SUCCESS,
  DELETE_PARTIAL_SUCCESS,
  UPDATE_SHOP_SUCCESS_FLAT_ARRAY,
  UPDATE_PUBLIC_VIEW_TEMPLATE_DATAS_SUCCESS,
  RESET_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS,
} from "../actions/shop";

import {
  ADD_METADATA_SUCCESS,
  UPDATE_METADATA_SUCCESS,
  DELETE_METADATA_SUCCESS,
} from "../actions";

import { ENABLE_INVENTORY_SUCCESS } from "../actions/inventory";

const reduce = (state = {}, action) => {
  let shop,
    new_template_data,
    shop_id,
    parent_type,
    shipping_range,
    shipping_range_id,
    new_shipping_ranges,
    metadata,
    metadata_id,
    new_metadata_list,
    order_id,
    new_orders,
    templateId;

  switch (action.type) {
    case UPDATE_SHOP_SUCCESS:
      return {
        ...state,
        [action.payload.id]: {
          ...state[action.payload.id],
          ...action.payload.data,
        },
      };
    case UPDATE_SHOP_SUCCESS_FLAT_ARRAY:
      const shops = state.map((s) => {
        if (s.shop_id === action.payload.id) {
          return { ...s, ...action.payload.data };
        }
        return s;
      });
      return [...shops];
    case UPDATE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS:
      if (action.payload.template_data.parent_type !== "SHOP") {
        return state;
      }
      shop_id = action.payload.template_data.parent_id;
      templateId = action.payload.template_data.public_view_template_id;
      shop = { template_data: {}, ...state[shop_id] };
      new_template_data = {
        ...(shop.template_data[templateId] || {}),
        [action.payload.template_data.name]: action.payload.template_data,
      };
      return {
        ...state,
        [shop_id]: {
          ...shop,
          template_data: {
            ...shop.template_data,
            [templateId]: new_template_data,
          },
        },
      };
    case RESET_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS:
      if (action.payload.parent_type !== 'SHOP') {
        return state;
      }
      shop_id = action.payload.parent_id;
      return {
        ...state,
        [shop_id]: {
          ...state[shop_id],
          template_data: action.payload.template_data,
        }
      };
    case UPDATE_PUBLIC_VIEW_TEMPLATE_DATAS_SUCCESS:
      if (action.payload.parent_type !== "SHOP") {
        return state;
      }
      shop_id = action.payload.parent_id;
      shop = { template_data: {}, ...state[shop_id] };

      if (action.payload.deleteNames && action.payload.deleteNames.length > 0) {
        Object.keys(shop.template_data[shop.public_view_layout_id]).forEach(
          (k) => {
            const value = shop.template_data[shop.public_view_layout_id][k];
            if (action.payload.deleteNames.includes(value.name)) {
              delete shop.template_data[shop.public_view_layout_id][k];
            }
          }
        );
      }

      if (action.payload.changeType === "reset-poster-category") {
        shop.template_data[shop.public_view_layout_id] = {};
      }

      for (const template_data of action.payload.template_data) {
        templateId = template_data.public_view_template_id;
        new_template_data = {
          ...(shop.template_data[templateId] || {}),
          [template_data.name]: template_data,
        };
        shop.template_data = {
          ...shop.template_data,
          [templateId]: new_template_data,
        };
      }

      if (action.payload.changeType === "reset-category-images") {
        Object.keys(shop.template_data[shop.public_view_layout_id]).forEach(
          (k) => {
            const matched = k.match(/CATEGORY-.*-IMAGE-HOVER/g);
            if (matched && matched.length > 0) {
              delete shop.template_data[shop.public_view_layout_id][k];
            }
          }
        );
      }

      return {
        ...state,
        [shop_id]: {
          ...shop,
        },
      };
    case DELETE_PUBLIC_VIEW_TEMPLATE_DATA_SUCCESS:
      if (action.payload.parent_type !== "SHOP") {
        return state;
      }
      shop_id = action.payload.parent_id;
      templateId = action.payload.template_id;
      shop = { template_data: {}, ...state[shop_id] };
      const itemsToRemove = action.payload.name.split(",");
      const items = Object.assign({}, shop.template_data[templateId]);
      for (const item of itemsToRemove) {
        delete items[item];
      }

      return {
        ...state,
        [shop_id]: {
          ...shop,
          template_data: {
            ...shop.template_data,
            [templateId]: items,
          },
        },
      };
    case LOAD_SHOP_ORDERS_SUCCESS:
      return {
        ...state,
        [action.payload.shop_id]: {
          ...state[action.payload.shop_id],
          sales_orders: action.payload.sales_orders,
          partials: action.payload.partials,
          invoices: action.payload.invoices,
        },
      };
    case ADD_SHIPPING_RANGE_SUCCESS:
      shop_id = action.payload.shop_id;
      shipping_range = action.payload.shipping_range;

      return Object.assign({}, state, {
        [shop_id]: Object.assign({}, state[shop_id], {
          shipping_ranges: Object.assign({}, state[shop_id].shipping_ranges, {
            [shipping_range.shipping_range_id]: shipping_range,
          }),
        }),
      });
    case UPDATE_SHIPPING_RANGE_SUCCESS:
      shop_id = action.payload.shop_id;
      shipping_range_id = action.payload.shipping_range_id;

      return Object.assign({}, state, {
        [shop_id]: Object.assign({}, state[shop_id], {
          shipping_ranges: Object.assign({}, state[shop_id].shipping_ranges, {
            [shipping_range_id]: Object.assign(
              {},
              state[shop_id].shipping_ranges[shipping_range_id],
              action.payload.data
            ),
          }),
        }),
      });
    case DELETE_SHIPPING_RANGE_SUCCESS:
      shop_id = action.payload.shop_id;
      shipping_range_id = action.payload.shipping_range_id;

      new_shipping_ranges = Object.assign({}, state[shop_id].shipping_ranges);
      delete new_shipping_ranges[shipping_range_id];

      return Object.assign({}, state, {
        [shop_id]: Object.assign({}, state[shop_id], {
          shipping_ranges: new_shipping_ranges,
        }),
      });
    case DELETE_PARTIAL_SUCCESS:
      shop_id = action.payload.shop_id;
      order_id = action.payload.order_id;

      new_orders = Object.assign({}, state[shop_id].partials);

      return Object.assign({}, state, {
        [shop_id]: Object.assign({}, state[shop_id], {
          partials: (Object.values(new_orders) || []).filter(
            (p) => p.order_id !== order_id
          ),
        }),
      });
    case ADD_METADATA_SUCCESS:
      parent_type = action.payload.parent_type;
      if (parent_type === "SHOP") {
        shop_id = action.payload.parent_id;
        metadata = action.payload.metadata;

        return Object.assign({}, state, {
          [shop_id]: Object.assign({}, state[shop_id], {
            metadata_list: Object.assign({}, state[shop_id].metadata_list, {
              [metadata.metadata_id]: metadata,
            }),
          }),
        });
      }
      return state;
    case UPDATE_METADATA_SUCCESS:
      parent_type = action.payload.parent_type;
      if (parent_type === "SHOP") {
        shop_id = action.payload.parent_id;
        metadata_id = action.payload.metadata_id;

        return Object.assign({}, state, {
          [shop_id]: Object.assign({}, state[shop_id], {
            metadata_list: Object.assign({}, state[shop_id].metadata_list, {
              [metadata_id]: Object.assign(
                {},
                state[shop_id].metadata_list[metadata_id],
                action.payload.data
              ),
            }),
          }),
        });
      }
      return state;
    case DELETE_METADATA_SUCCESS:
      parent_type = action.payload.parent_type;
      if (parent_type === "SHOP") {
        shop_id = action.payload.parent_id;
        metadata_id = action.payload.metadata_id;

        new_metadata_list = Object.assign({}, state[shop_id].metadata_list);
        delete new_metadata_list[metadata_id];

        return Object.assign({}, state, {
          [shop_id]: Object.assign({}, state[shop_id], {
            metadata_list: new_metadata_list,
          }),
        });
      }
      return state;
    case ENABLE_INVENTORY_SUCCESS:
      return {
        ...state,
        [action.payload.inventory.shop_id]: {
          ...state[action.payload.inventory.shop_id],
          inventory_id: action.payload.inventory.inventory_id,
        },
      };
  }
  return state;
};

export default reduce;
