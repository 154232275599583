import { find, get, isEmpty, filter, assign, bindAll, map, last, sumBy, forEach, toInteger } from 'lodash';
import React, { Component, useState } from 'react';
import { findDOMNode } from 'react-dom';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { getProductConfigurations } from '../promostandards';
import { Link, LockIcon } from '@commonsku/styles';

import * as actions from '../actions/item';
import {
  createUpdate, createFinalizeUpdate, updateItemCost,
  createAddBreakdown, createDeleteBreakdown, createAddItemCost,
  createDeleteItemCost, createAddItemLocation, createDeleteItemLocation,
  updateBreakdownDimensions, createFetchProductWarnings, createCheckProductWarnings,
  createAddItemWarning, createDeleteItemWarning, createCheckItemInCollection,
} from '../actions';
import { createLoadOrder } from '../actions/order';
import { createFetchProduct } from '../actions/product';
import { createArtworkPopup, createSelectFilePopup,
  createSelectDecorationProductPopup, createCheckInventoryLevelPopup,
  createUpdateNetCostPopup, createSelectBreakdownSkuByColorPopup,
  createClientDisplayOptionsPopup, createApplyMatrixToItemPopup, createCheckPSInventoryLevelPopup, } from '../actions/popup';
import { createUploadFile } from '../actions/file';
import { getFullItem, getProductImagesByItemId, getProduct } from '../selectors';
import { getProductSkuDropdown } from '../selectors/dropdowns';
import ItemBreakdown from './ItemBreakdown';
import ItemCost from './ItemCost';
import ItemLocation from './ItemLocation';
import SelectWarehouse from './SelectWarehouse';
import DropdownMenu, { MenuTrigger } from './DropdownMenu';
import TextArea from 'react-textarea-autosize';
import Img from './Img';
import { getImageSrc } from '../utils.js';
import ProductWarning from './ProductWarning';
import ItemWarnings from './ItemWarnings';
import ItemMarginSummary from './ItemMarginSummary';
import ItemSummary from './ItemSummary';
import PS from './PromostandardsLabel';
import { isChargeMandatory, getItemSku } from '../promostandard_utils';
import AvalaraCategorySelect from './avalara/AvalaraCategorySelect';
import { isAvalaraOrder } from '../helpers/order';
import Tooltip from './helpers/ReactTooltip';
import { window } from '../global';
import DecoratorSelector from "./decorate/DecoratorSelector";
import LocationDropdown from './decorate/LocationDropdown';
import { cleanupPcnaDuplicateLocations } from '../helpers/ps_locations';
import BreakdownDetailsDropdown from './BreakdownDetailsDropdown';
import DisabledColorSizeTooltip from './DisabledColorSizeTooltip';

const CloseDialogBtn = () => {
  const navigate = useNavigate();
  return (
    <a className="button"
      style={{ position: 'fixed', right: '1rem', top: '1rem', zIndex: 1 }}
      onClick={() => navigate(-1)}
    >Close</a>
  );
};

const isCostVisible = ic =>
  ic.item_location_id === null && (
  ic.item_cost_tab !== 'INTERNATIONAL' ||
  ic.hidden == 0 ||
  (ic.quantity != 1 && ic.quantity != null) ||
  (ic.quantity == null && ic.percent != 0) ||
  (ic.unit_cost != null && ic.unit_cost != 0) ||
  (ic.unit_price != null && ic.unit_price != 0) ||
  !ic.from_db);

const getState = props => {
  if (!props.item) {
    return {
      breakdowns: [],
      item_costs: [],
      item_locations: [],
      product_costs: null,
      supplier_preferred: null
    };
  }
  return {
    breakdowns: props.item.breakdowns.map(b => b.breakdown_id),
    item_costs: props.item.item_costs.filter(isCostVisible).map(ic => ic.item_cost_id),
    item_locations: props.item.item_locations.map(il => il.item_location_id),
    product_costs: props.item.product_costs ? props.item.product_costs : null,
    supplier_preferred: props.item.supplier_preferred ? props.item.supplier_preferred : null,
  };
};

const headingStyle = {
  fontWeight: '600',
  fontSize: '1.5rem',
  lineHeight: '2rem',
  margin: '2rem 0 1rem 2.5rem'
};

const AddSizesAndColorsButton = ({ item, field_lock, skus, onAddItemBreakdown }) => {
  const [show, setShow] = useState(false);
  const disabled = 'ps-products' === item.copied_from && !!item.ext_fob_id && !find(skus, ({ options }) => {
    return find(options, ({ option_axis }) => {
      return option_axis === 'color' || option_axis === 'size';
    });
  });

  return <div>
    <DisabledColorSizeTooltip showTooltip={disabled}>
      <a
        className={`button small ${disabled ? 'disabled' : ''}`}
        onClick={(e) => {
          e.stopPropagation();
          setShow(!show);
        }}
        style={{ margin: '0 0 0.3rem 0' }}
        data-heap-edit-item-add-sizes-and-colors-product-type={'ps-products' === item.copied_from}
      ><i className="fi-thumbnails"></i>&nbsp;&nbsp;&nbsp;Add sizes and colors</a>
    </DisabledColorSizeTooltip>
    {show && <BreakdownDetailsDropdown
      style={{ position: 'absolute', top: 48, marginTop: 0 }}
      item={item}
      onCloseBreakdownDetails={() => setShow(false)}
      onDone={(size_id, color_id, product_sku_id, quantities) => {
        map(quantities, ({ color_id, size_id, quantity, product_sku_id }) => {
          const breakdown = last(item.breakdowns);
          // EDGE-99
          // If locked, use the retail price from the bottom line breakdown for all products added with the multi add, adjust the margin.
          // If unlocked, set the retail to be the cost + margin for all added products.
          onAddItemBreakdown(item.item_id, {
            quantity,
            ...(field_lock === 'unit_price' ? { unit_price: breakdown?.unit_price } : {}),
            ...(isEmpty(product_sku_id) ? { size_id, color_id } : { product_sku_id }),
          });
        });
      }}
      skus={skus}
      multi_edit={true}
    />}
  </div>;
};

class OrderItem extends Component {

  constructor(props) {
    super(props);

    this.state = Object.assign({
      show_add_additional: false,
      show_add_decoration_location: false,
      all_images: props.all_images,
      fetching_product_warning: false,
      editPS: true,
      locations: [],
      in_collection: false,
      field_lock: 'unit_cost',
      hoverImage:false,
      psLoaded: false,
    }, getState(this.props));

    bindAll(this, [
      'handleClickElsewhere',
      'handleClickLeft',
      'handleClickRight',
      'handleClickCreateSelectDecorationProductPopup',
      'handleClickAddItemLocation',
      'handleClickAddAdditional',
      'handleClickAddBreakdown',
      'onAddInternationalCost',
      'handleClickFetchWarnings',
      'onMouseEnterImage', 'onMouseLeaveImage',
      'getSupplierCostByBreakdowns'
    ]);

  }

  componentDidMount() {
    window.addEventListener('click', this.handleClickElsewhere, false);
    if (!this.props.order.loaded) {
      this.props.loadOrder(this.props.order.order_id).then(({ payload: { order: { items }}}) => {
        const item = (items ?? []).find(i => i.item_id === this.props.params.item_id);
        if ('ps-products' === item?.copied_from) {
          if (!this.props.product) {
            this.loadPSProduct(item);
          } else {
            this.loadPSProductConfigurations();
          }
          this.loadPSCustomerPrice(item);
        }
      });
    } else if (['esp-products', 'sage-products', 'dc-products'].includes(this.props.item.copied_from) &&
      1 == this.props.project.show_product_warnings) {
      this.props.onCheckProductWarnings(this.props.item.item_id, this.props.item.parent_id);
    } else if (this.isPS()) {
      if (!this.props.product) {
        this.loadPSProduct(this.props.item);
      } else {
        this.loadPSProductConfigurations();
      }
      this.loadPSCustomerPrice(this.props.item);
    }

    if (this.props.params && this.props.params.item_id) {
      this.props.checkItemInCollection(this.props.params.item_id).then((resp) => {
        if (resp && resp.payload !== null && resp.payload !== undefined) {
          this.setState({in_collection: resp.payload.value});
        }
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('click', this.handleClickElsewhere, false);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    this.setState(getState(nextProps));
    if (nextProps.all_images.length !== this.state.all_images.length) {
      this.setState({ all_images: nextProps.all_images });
    }
  }

  async loadPSProductConfigurations(product) {
    try {
      const { locations } = await getProductConfigurations({
        productId: (product || this.props.product).ext_product_id,
      }) || {};
      this.setState({ locations: locations || [], psLoaded: true });
    } catch (e) {
      this.setState({ locations: [], psLoaded: true });
    }
  }

  loadPSProduct(item) {
    this.props.loadProduct(item.parent_id, {
      fob_id: item.ext_fob_id,
      currency_id: item.currency_id,
      division_id: item.division_id,
    }).then(({ payload: { product }}) => {
      return this.loadPSProductConfigurations(product);
    });
  }

  loadPSCustomerPrice(item) {
    if (item) {
	    this.props.loadProduct(item.parent_id, {
	      fob_id: item.ext_fob_id,
	      currency_id: item.currency_id,
	      division_id: item.division_id,
	      skus: map(item.breakdowns, 'sku').join(','),
	    });
    }
  }

  handleClickFetchWarnings(item_id, product_id) {
    this.setState({ fetching_product_warning: true });

    const _this = this;
    window.setTimeout(function() {
      _this.props.onCreateFetchProductWarnings(item_id, product_id);
      _this.setState({ fetching_product_warning: false });
    }, 1000);
  }

  handleClickAddAdditional(e) {
    e.preventDefault();
    this.setState({ show_add_additional: true });
  }

  handleClickElsewhere(e) {
    const add_decoration_location_button = findDOMNode(this.refs.add_decoration_location_button);

    if (this.state.show_add_decoration_location && e.target !== add_decoration_location_button) {
      e.preventDefault();
      this.setState({ show_add_decoration_location: false });
    }
  }

  isPS() {
    return 'ps-products' === this.props.item.copied_from && !!this.props.item.ext_fob_id;
  }

  onMove(type) {
    return (dragIndex, hoverIndex) => {
      const items = this.state[type];
      const dragItem = items[dragIndex];
      const item_copy = items.slice();
      item_copy.splice(dragIndex, 1);
      item_copy.splice(hoverIndex, 0, dragItem);
      this.setState({ [type]: item_copy });
    };
  }

  onDrop(type) {
    return (item) => {
      this.props.onReorder(type)(item.id, this.props.item[type + 's'].filter(i => i[type + '_id'] === item.id)[0].display_order)(item.index);
    };
  }
  onMouseEnterImage() {
    this.setState({ hoverImage: true });
  }
  onMouseLeaveImage() {
    this.setState({ hoverImage: false });
  }

  handleClickLeft(item_id) {
    const { all_images } = this.state;
    const { item, onUpdateItem } = this.props;
    const image_id = item.item_images[0].file_id;
    const index = all_images.indexOf(image_id);
    if (-1 === index) {
      onUpdateItem(item_id, 'image_id', image_id)(all_images[0]);
    } else if (0 === index) {
      onUpdateItem(item_id, 'image_id', image_id)(all_images[all_images.length - 1]);
    } else {
      onUpdateItem(item_id, 'image_id', image_id)(all_images[index - 1]);
    }
  }

  handleClickRight(item_id) {
    const { all_images } = this.state;
    const { item, onUpdateItem } = this.props;
    const image_id = item.item_images[0].file_id;
    const index = all_images.indexOf(image_id);
    if (-1 === index) {
      onUpdateItem(item_id, 'image_id', image_id)(all_images[0]);
    } else if (all_images.length - 1 === index) {
      onUpdateItem(item_id, 'image_id', image_id)(all_images[0]);
    } else {
      onUpdateItem(item_id, 'image_id', image_id)(all_images[index + 1]);
    }
  }

  handleClickCreateSelectDecorationProductPopup(order_number, order_type, item_id, onCreateSelectDecorationProductPopup) {
    this.setState({show_add_decoration: false});
    onCreateSelectDecorationProductPopup(order_number, order_type, item_id);
  }

  handleClickAddItemLocation(item_id, onAddItemLocation, ext_location = null) {
    this.setState({show_add_decoration: false});
    onAddItemLocation(item_id, null, null, null, ext_location);
  }

  handleClickAddBreakdown(e) {
    const { onAddItemBreakdown, skus, item } = this.props;
    e && e.preventDefault();
    e && e.stopPropagation();
    if (e) return;
    if (skus && this.isPS()) {
      return this.props.onAddBreakdownSkus(
        item.parent_id,
        product_sku_id => onAddItemBreakdown(item.item_id, { product_sku_id })
      );
    } else {
      return onAddItemBreakdown(item.item_id);
    }
  }

  onAddInternationalCost(item_cost_type) {
    const item_costs = this.props.item.item_costs.filter(ic => ic.item_cost_name === item_cost_type);
    if (this.state.item_costs.filter(item_cost_id =>
        item_costs.map(ic => ic.item_cost_id).includes(item_cost_id)
    ).length || !item_costs.length) {
      this.props.onAddItemCost(this.props.item.item_id, null, 1, item_cost_type);
    } else {
      // If we have one that we're not showing
      this.props.onChangeItemCost(item_costs[0].item_cost_id, 'from_db', item_costs[0].from_db)(false);
    }
  }

  canAddInternationalCost(item_cost_type) {
    if (this.state.item_costs.filter(item_cost_id =>
        this.props.item.item_costs.filter(ic => ic.item_cost_name === item_cost_type).map(ic => ic.item_cost_id).includes(item_cost_id)
    ).length) {
      return 'DUTY' !== item_cost_type;
    }
    return true;
  }

  getSupplierCostByBreakdowns(baseCost, quantity, currency) {
    if (!['SALES ORDER', 'ESTIMATE'].includes(this.props.order_type) || isEmpty(baseCost)) return 0;
    let costs = [];
    let result = null;
    costs[baseCost.currency_id] = {};
    let previousQuantity = 0;
    for (let i = 1; i < 7; ++i) {
      const costQuantity = toInteger(get(baseCost, `quantity${i}`, null));
      const unitCost = get(baseCost, `cost${i}`, null);
      if (costQuantity === null || unitCost === null || costQuantity == previousQuantity) {
        break;
      }
      if (currency === baseCost.currency_id) {
        if (result === null || quantity >= costQuantity) {
          result = unitCost;
        }
      }
      costs[baseCost.currency_id][costQuantity] = unitCost;
      previousQuantity = costQuantity;
    }
    if (result !== null) {
      return result;
    };

    if (result === null) {
      forEach(costs, cost => {
        forEach(cost, (unitCost, costQuantity) => {
          if (result === null || quantity >= costQuantity) {
            result = unitCost;
          }
        });
        if (result !== null) {
          return result;
        }
      });
    }

    return result || 0;
  };

  renderLoading() {
    return (
      <div className="main-content project-order" style={{ textAlign: 'center'}} >
        <img style={{ marginTop: '2rem' }} src="/images/gears.gif" />
      </div>
    );
  }

  render() {
    const { order } = this.props;

    if (!order.loaded) {
      return this.renderLoading();
    }
    const isLoadingPS = this.isPS() && (!this.props.product || !this.state.psLoaded);

    const {
      item,
      product,
      skus,
      order_number,
      order_type,
      onUpdateItem,
      onChangeItem,
      onAddItemCost,
      onUpdateItemCost,
      onDeleteItemCost,
      onAddItemLocation,
      onUpdateItemLocation,
      onChangeItemLocation,
      onDeleteItemLocation,
      onUpdateItemBreakdownDimensions,
      onUpdateItemBreakdown,
      onDeleteItemBreakdown,
      onAddItemWarning,
      onUpdateItemWarning,
      onDeleteItemWarning,
      onEditArtwork,
      onCreateSelectFilePopup,
      onCopyItemBreakdown,
      onCreateSelectDecorationProductPopup,
      onCreateCheckInventorylevelPopup,
      onCreateCheckPSInventoryLevelPopup,
      onCreateUpdateNetCostPopup,
      onSelectClientDisplayOptions,
      onUploadFile,
      locked,
      popups,
      zip2tax = false,
      show_product_warnings,
      quirks,
      canEditPS,
      useMarginCurrency,
      onAddItemBreakdown
    } = this.props;
    const { editPS, locations, field_lock } = this.state;

    const ext_location_ids = item.item_locations.map(il => il.ext_location_id);
    const cleanLocations = cleanupPcnaDuplicateLocations(locations, ext_location_ids);
    const charges = Object.values(product ? (product.charges || {}) : {});

    const isPSChargeMandatory = charge => isChargeMandatory(quirks, item.division_id, charge);

    let options_additional = !charges.length ? [
      { key: 'run-charge', value: 'Run Charge', onClick: () => onAddItemCost(item.item_id, null, null) },
      { key: 'fixed-charge', value: 'Fixed Charge', onClick: () => onAddItemCost(item.item_id, null, 1) }
    ] : [];
    options_additional = map(options_additional, (option) => {
      return assign({}, option, {
        onClick: (e) => {
          this.additionalDropdown.showDropdown(false);
          return option.onClick(e);
        },
      });
    });

    let options_international = [
      { key: 'inbound-freight', value: 'Inbound Freight', onClick: () => this.onAddInternationalCost('INBOUND-FREIGHT') },
      { key: 'brokerage', value: 'Brokerage', onClick: () => this.onAddInternationalCost('BROKERAGE') }
    ];
    if (this.canAddInternationalCost('DUTY')) {
      options_international.push({ key: 'duty', value: 'Duty', onClick: () => this.onAddInternationalCost('DUTY') });
    }
    options_international = map(options_international, (option) => {
      return assign({}, option, {
        onClick: (e) => {
          this.internationalDropdown.showDropdown(false);
          return option.onClick(e);
        },
      });
    });

    const product_url = `/product.php?id=${item.parent_id}`;
    const default_image = item.item_images.map(ii => ii.image)[0];

    const handleClickCostGrid = e => {
      e.preventDefault();
      if (!this.isPS()) {
        onCreateUpdateNetCostPopup(item, locked);
      }
    };

    const quantity = sumBy(item.breakdowns, (breakdown) => parseInt(breakdown.quantity));

    const indexed_item_costs = item.item_costs.reduce((o, ic) => ({ ...o, [ic.item_cost_id]: ic }), {});
    const centerStyle = {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center'
    };
    const editStyle = {
      ...centerStyle,
      color: 'white',
      fontSize: 'large',
      fontWeight: 'bold'
    };

    const opacity = this.state.hoverImage ? 0.5 : 1;
    const availableLocations = filter(cleanLocations, l => !ext_location_ids.includes(`${l.ps_location_id}`));

    return (
      <div data-heap-edit-item-view-product-type={item.copied_from ?? ''} style={{ zIndex: 100, position: 'absolute', top: 0, left: 0, background: 'white', width: '100%', height: '100%' }}>
        <div className="row full-width">
          <div className="small-12 columns modal-header">
            <h1>Edit Item</h1>
            {!popups.length ? <CloseDialogBtn /> : null}
          </div>
        </div>
        <div className="row full-width mega-modal-content">
          <div className="medium-10 column">
            <div className="row">
              <div className="medium-6 columns">
                <label>Product Name</label>
                <input type="text" disabled={locked} value={item.item_name} onChange={e => onChangeItem(item.item_id, 'item_name', item.item_name)(e.target.value)} onBlur={e => onUpdateItem(item.item_id, 'item_name', item.item_name)(e.target.value)} />
              </div>
              <div className="medium-4 columns">
                <label>Supplier</label>
                <input type="text" value={get(item, 'division_name') || get(item, 'origin.division_name', '')} disabled={true} />
              </div>
              <div className="medium-2 columns">
                <label>SKU</label>
                <input type="text" value={item.item_sku} disabled={true} />
              </div>
            </div>
            {isAvalaraOrder(order) && <div className="row">
              <div className="medium-4 columns">
                <label>Avalara Item Code</label>
                <AvalaraCategorySelect
                  value={item.avalara_category_id}
                  onChange={(value) => {
                    onUpdateItem(item.item_id, 'avalara_category_id', item.avalara_category_id)(value);
                  }}
                />
              </div>
            </div>}
            <div className="row">
              <div className="medium-12 columns">
                <label>Product Description</label>
                <TextArea disabled={locked} value={item.item_description || ''} onChange={e => onChangeItem(item.item_id, 'item_description', item.item_description)(e.target.value)} onBlur={e => onUpdateItem(item.item_id, 'item_description', item.item_description)(e.target.value)}></TextArea>
              </div>
            </div>

            <div className="row">
              <div className="medium-12 columns">
                <label>Private Note</label>
                <TextArea disabled={locked} value={item.private_notes || ''} onChange={e => onChangeItem(item.item_id, 'private_notes', item.private_notes)(e.target.value)} onBlur={e => onUpdateItem(item.item_id, 'private_notes', item.private_notes)(e.target.value)}></TextArea>
              </div>
            </div>

            {item.product_warnings ?
              <div className="row">
                <div className="medium-12 columns">
                  <ProductWarning warnings={item.product_warnings} show={show_product_warnings} loading={this.state.fetching_product_warning} />
                </div>
              </div>
            : null}

            <div className="row">
              <div className="medium-12 columns">
                <ItemWarnings locked={locked} onAddItemWarning={onAddItemWarning} onUpdateItemWarning={onUpdateItemWarning} onDeleteItemWarning={onDeleteItemWarning} item_warnings={item.item_warnings} />
              </div>
            </div>

            <div className="row">
              <div className="medium-12 columns">
                <a href={product_url} target="_blank" className="button">View Product Page</a> &nbsp; &nbsp; &nbsp;
                <SelectWarehouse
                  items={[{...item, skus }]} readonly={true} title="Check Warehouses"
                  render={({ onClick }) => {
                    return <a className="button" onClick={onClick} style={{ marginRight: 10, }}>Check Warehouses</a>;
                  }}
                />
                {(item.promo_api_name || this.isPS()) &&
                <div style={{display: 'inline-block'}}>
                  <a className="button"
                    onClick={e => {
                      e.preventDefault();
                      const item_sku = getItemSku(item);
                      if (!this.isPS()) {
                        onCreateCheckInventorylevelPopup(item_sku, item.promo_api_name, item.item_id);
                      } else {
                        onCreateCheckPSInventoryLevelPopup(item.parent_id, item.item_id, item_sku, item.promo_api_name);
                      }
                    }}
                  >Check Inventory</a>
                  &nbsp; &nbsp; &nbsp;
                </div>
                }
                {!this.isPS() && <a className="button" onClick={handleClickCostGrid}>Check Pricing
                  {item.preferred_pricing == 1 ?
                    <span style={{fontStyle: 'italic', fontSize: '0.75rem'}}> (ESP Preferring Pricing Available)</span>
                    : null}
                </a>}
                &nbsp; &nbsp; &nbsp;
                {show_product_warnings == 1 && <div style={{ display: 'inline-block', position: 'absolute' }}>
                  <a className="button" style={{ marginBottom: 0 }} onClick={e => { e.preventDefault(); this.handleClickFetchWarnings(item.item_id, item.parent_id); }}>
                    Check Prop65 Warnings
                  </a>
                  <p style={{ fontStyle: 'italic', fontSize: '0.75rem' }}>
                    {item.date_warnings_updated ? `Last updated ${item.date_warnings_updated}` : 'Never checked'}
                  </p>
                </div>}
                <label style={{display: 'inline-block', float: 'right'}}>Currency: {item.currency_id}</label>
              </div>
              {(this.isPS() && canEditPS) && <div>
                <input type="checkbox" checked={editPS} onChange={() => this.setState(prevState => ({ editPS: !prevState.editPS }))} />
                <label>Edit <PS style={{ marginRight: 'initial' }} /> Pricing</label>
              </div>}
            </div>
          </div>
          <div className="medium-2 column"  onMouseEnter={this.onMouseEnterImage} onMouseLeave={this.onMouseLeaveImage}  onClick={e => {e.preventDefault(); if (!locked) { onCreateSelectFilePopup(item.item_id); } }} >
                 <div style={{ position: 'relative', width: '100%', marginBottom: '3px',  backgroundColor: this.state.hoverImage ? 'grey' : 'white', cursor: 'pointer' }}>
            <Img src={getImageSrc(default_image, 'large')} style={{ opacity }}  />
            {this.state.hoverImage &&
            <div style={editStyle}>
              Click to {locked ? 'View' : 'Edit'}
            </div>}
            </div>
          </div>
        </div>
        <div className="row full-width mega-modal-content">
          <div className="column">
            <div style={headingStyle}>Pricing</div>
            <table className="pricing-table">
              <thead>
                <tr>
                  <th>&nbsp;</th>
                  <th style={{minWidth: '10rem'}}>Color/Size{('ESTIMATE' === order_type && this.isPS()) && <button className="button tiny" style={{ margin: 0, float: 'right' }} onClick={() => onSelectClientDisplayOptions(item.item_id)}>Display Options</button>}</th>
                  <th>SKU</th>
                  <th>QTY</th>
                  <th>Net Cost{order.currency_id !== item.currency_id && ` (${item.currency_id})`}</th>
                  <th>Margin</th>
                  <th>Retail{!locked && <Link onClick={() => this.setState(prevState => ({ field_lock: prevState.field_lock === 'unit_cost' ? 'unit_price' : 'unit_cost' }))}><LockIcon ml={10} locked={field_lock === 'unit_price'} style={{verticalAlign: "middle"}} /></Link>}{order.currency_id !== item.currency_id && ` (${order.currency_id})`}</th>
                  <th style={{textAlign: 'right'}}>Client Price{order.currency_id !== item.currency_id && ` (${order.currency_id})`}</th>
                  <th style={{textAlign: 'right'}}>Total</th>
                  <th>&darr;</th>
                </tr>
              </thead>
              <tbody>
                {this.state.breakdowns.map((breakdown_id, idx) => {
                  const breakdown = item.breakdowns.filter(b => b.breakdown_id === breakdown_id)[0];
                  return <ItemBreakdown
                    key={breakdown_id}
                    id={breakdown_id}
                    index={idx}
                    onMove={this.onMove('breakdowns')}
                    onDrop={this.onDrop('breakdown')}
                    breakdown={breakdown}
                    item_sku={getItemSku(item)}
                    item={item}
                    hidden_costs={item.hidden_costs}
                    skus={this.isPS() ? skus : null}
                    // isPS={this.isPS()}
                    onUpdate={onUpdateItemBreakdown}
                    onDelete={onDeleteItemBreakdown}
                    onAddItemBreakdown={onAddItemBreakdown}
                    breakdowns={item.breakdowns}
                    onUpdateBreakdownDimensions={onUpdateItemBreakdownDimensions(breakdown_id, item.parent_id, breakdown.size_id, breakdown)}
                    onCopyItemBreakdown={onCopyItemBreakdown}
                    locked={locked}
                    breakdowns_count={this.state.breakdowns.length}
                    editPS={editPS}
                    field_lock={field_lock}
                    exchange_rate={Number(item.exchange_rate)}
                    onEnter={(e, field_name, next = true) => {
                      if (!field_name) { return; }
                      if (
                        (next && idx === this.state.breakdowns.length - 1)
                        || (!next && idx === 0)
                      ) { return; }
                      const nextBreakdownId = this.state.breakdowns[next ? idx + 1 : idx - 1];
                      if (!nextBreakdownId) { return; }

                      const elemSelector = `[id='${nextBreakdownId}'] .item-breakdown-${field_name}-input`;
                      const elem = findDOMNode(document.querySelector(elemSelector));
                      elem?.focus && elem?.focus();
                    }}
                    supplierCost={!isEmpty(product) ? this.getSupplierCostByBreakdowns(get(product, ['indexed_costs', product.currency_id, breakdown.sku]), quantity, product.currency_id) : 0}
                  />;
                }
                )}
                {this.state.item_costs.filter(item_cost_id => 'INTERNATIONAL' !== indexed_item_costs[item_cost_id].item_cost_tab).map((item_cost_id, idx) =>
                  <ItemCost
                    key={item_cost_id}
                    id={item_cost_id}
                    index={idx}
                    onMove={this.onMove('item_costs')}
                    onDrop={this.onDrop('item_cost')}
                    item_cost={indexed_item_costs[item_cost_id]}
                    breakdown_quantity={item.breakdown_quantity}
                    breakdown_cost={item.breakdown_cost}
                    onUpdate={onUpdateItemCost(item.breakdown_cost)}
                    onDelete={onDeleteItemCost}
                    locked={locked}
                    editPS={editPS}
                    field_lock={field_lock}
                    exchange_rate={Number(item.exchange_rate)}
                  />
                )}
                {!locked ? <tr>
                  <td/>
                  <td colSpan="9" style={{ textAlign: 'left' }}>
                    <div style={{
                      display: 'flex', justifyContent: 'flex-start', alignItems: 'baseline', gap: '1rem',
                      fontSize: '0.875rem', fontWeight: 600
                    }}>
                      <AddSizesAndColorsButton item={item} skus={skus} field_lock={field_lock} onAddItemBreakdown={onAddItemBreakdown} />
                      <a
                        style={{  }}
                        data-heap-edit-item-add-breakdown-product-type={this.isPS()}
                        onClick={e => { e.preventDefault(); this.handleClickAddBreakdown(); }}
                      >Add Breakdown</a>
                      {(!!options_additional.length && !this.isPS()) &&
                        <DropdownMenu
                          ref={(dp) => {this.additionalDropdown = dp;}}
                          options={options_additional}
                        >
                          <MenuTrigger >
                            <a onClick={(e) => { e.preventDefault(); }}>Add Cost</a>
                          </MenuTrigger>
                        </DropdownMenu>}
                      <DropdownMenu
                        ref={(dp) => {this.internationalDropdown = dp;}}
                        options={options_international}
                      >
                        <MenuTrigger style={{paddingTop:'4px'}}>
                          <a onClick={(e) => { e.preventDefault(); }}>Add International Cost</a>
                        </MenuTrigger>
                      </DropdownMenu>
                    </div>
                  </td>
                </tr> : null}
              </tbody>
              {(!locked || this.state.item_costs.filter(item_cost_id => 'INTERNATIONAL' === indexed_item_costs[item_cost_id].item_cost_tab).length > 0) &&
                <tbody>
                  {this.state.item_costs.filter(item_cost_id => 'INTERNATIONAL' === indexed_item_costs[item_cost_id].item_cost_tab).map((item_cost_id, idx) =>
                    <ItemCost
                      key={item_cost_id}
                      id={item_cost_id}
                      index={idx}
                      onMove={this.onMove('item_costs')}
                      onDrop={this.onDrop('item_cost')}
                      item_cost={indexed_item_costs[item_cost_id]}
                      breakdown_quantity={item.breakdown_quantity}
                      breakdown_cost={item.breakdown_cost}
                      onUpdate={onUpdateItemCost(item.breakdown_cost)}
                      onDelete={onDeleteItemCost}
                      locked={locked}
                      editPS={editPS}
                      field_lock={field_lock}
                      exchange_rate={Number(item.exchange_rate)}
                    />
                  )}
                </tbody>
              }
              <tbody><tr><td colSpan={10} style={{ padding: 0 }}>
                <div style={headingStyle}>
                  Decoration
                  <DecoratorSelector
                    itemId={item.item_id}
                    defaultIndex={item.is_3rd_party_decorator}
                    isPS={item.copied_from === 'ps-products'}
                    style={{marginLeft: '20px'}}
                    itemLocationNum={item.item_locations.length}
                  />
                </div>
              </td></tr></tbody>
              {
                isLoadingPS
                ? <tbody><tr><td/><td colSpan={3}><img alt="Loading" src="/images/gears.gif" /></td></tr></tbody>
                : <>
                  {this.state.item_locations.map((item_location_id, idx) => {
                    const item_location = item.item_locations.filter(il => il.item_location_id === item_location_id)[0];
                    const ps_location = find(cleanLocations, ({ ps_location_id }) => {
                      return `${ps_location_id}` === item_location.ext_location_id;
                    });
                    return (
                      <ItemLocation
                        key={item_location_id}
                        id={item_location_id}
                        index={idx}
                        product={product}
                        onMove={this.onMove('item_locations')}
                        onDrop={this.onDrop('item_location')}
                        item_location={item_location}
                        item_costs={item.item_costs.filter(ic => ic.item_location_id === item_location_id)}
                        item_decorations={item.item_decorations.filter(id => id.item_location_id === item_location_id)}
                        artworks={item.artworks.filter(a => a.item_location_id === item_location_id)}
                        breakdown_quantity={item.breakdown_quantity}
                        breakdown_cost={item.breakdown_cost}
                        onChange={onChangeItemLocation}
                        onUpdate={onUpdateItemLocation}
                        onDelete={onDeleteItemLocation}
                        onCopy={onAddItemLocation}
                        onUpdateItemCost={onUpdateItemCost}
                        onAddItemCost={onAddItemCost}
                        onDeleteItemCost={onDeleteItemCost}
                        onEditArtwork={onEditArtwork(order.client_id, 'CLIENT')}
                        onUploadFile={onUploadFile}
                        ps_location={ps_location}
                        locked={locked}
                        isPSChargeMandatory={isPSChargeMandatory}
                        editPS={editPS}
                        onCreateApplyMatrixToItemPopup={!this.state.in_collection ? this.props.onCreateApplyMatrixToItemPopup : null}
                        field_lock={field_lock}
                        exchange_rate={Number(item.exchange_rate)}
                        quirks={quirks}
                        division_id={item.division_id}
                      />
                    );
                  })}
                  <tbody>
                    {locked ? null : <tr className="separator">
                      <td colSpan="10">
                        <LocationDropdown
                          locked={locked}
                          isPS={this.isPS()}
                          item_id={item.item_id}
                          division_id={item.division_id}
                          locations={availableLocations}
                          is3rdPartyDecorator={item.is_3rd_party_decorator}
                          onOptionClick={(l) =>
                            this.handleClickAddItemLocation(item.item_id, onAddItemLocation, l)
                          }
                          onClickNewDecoration={() =>
                            this.handleClickAddItemLocation(item.item_id, onAddItemLocation)
                          }
                          onClickCopySameOrder={() =>
                            this.handleClickCreateSelectDecorationProductPopup(
                              order_number,
                              order_type,
                              item.item_id,
                              onCreateSelectDecorationProductPopup,
                            )
                          }
                        >
                          <a style={{fontWeight: 'bold'}} href="#" ref="add_decoration_location_button" onClick={(e) => { e.preventDefault(); }}>
                            + Decoration Location
                          </a>
                        </LocationDropdown>
                      </td>
                    </tr>}
                  </tbody>
                </>
              }
            </table>
            <ItemMarginSummary order_currency_id={order.currency_id} item={item} locked={locked} onUpdateItem={onUpdateItem(item.item_id, 'exchange_rate', item.exchange_rate)} useMarginCurrency={useMarginCurrency} />
            <ItemSummary order={order} item={item} locked={locked} zip2tax={zip2tax} />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const item = ownProps.order.loaded ? getFullItem(state, { item_id: ownProps.params.item_id }) : null;
  const product = item ? getProduct(state, { product_id: item.parent_id }) : null;
  const product_images = item ? getProductImagesByItemId(state, item) : null;
  const all_images = Array.from(new Set([ ...((item || {}).item_images || []), ...(product_images || []) ].map(i => i.file_id)));
  const order_number = ownProps.params.order_number;
  const order = ownProps.order;
  const project = ownProps.project;

  return {
    item,
    product,
    all_images,
    order_number,
    order_type: order.order_type,
    locked: 1 == order.locked,
    order_currency_id: order.currency_id,
    zip2tax: 1 == project.zip2tax,
    show_product_warnings: project.show_product_warnings,
    skus: item ? getProductSkuDropdown(state, { product_id: item.parent_id }) : [],
    quirks: state.entities.promostandards_quirks,
    canEditPS: false, // 'SUPPLIER' === state.identity.company_type
    useMarginCurrency: +state.identity.use_margin_currency === 1
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  const updateItem = createUpdate('item', {
    request: actions.UPDATE_ITEM_REQUEST,
    success: actions.UPDATE_ITEM_SUCCESS,
    failure: actions.UPDATE_ITEM_FAILURE,
    failure_message: 'Unable to update item'
  });

  const updateItemWarning = createUpdate('item-warning', {
    request: actions.UPDATE_ITEM_WARNING_REQUEST,
    success: actions.UPDATE_ITEM_WARNING_SUCCESS,
    failure: actions.UPDATE_ITEM_WARNING_FAILURE,
    failure_message: 'Unable to update item warning'
  });
  const reorder = {
    breakdown: createUpdate('breakdown', {
      request: actions.REORDER_ITEM_BREAKDOWN_REQUEST,
      success: actions.REORDER_ITEM_BREAKDOWN_SUCCESS,
      failure: actions.REORDER_ITEM_BREAKDOWN_FAILURE,
      failure_message: 'Unable to update item breakdown'
    }),
    item_cost: createUpdate('item-cost', {
      request: actions.REORDER_ITEM_COST_REQUEST,
      success: actions.REORDER_ITEM_COST_SUCCESS,
      failure: actions.REORDER_ITEM_COST_FAILURE,
      failure_message: 'Unable to update item cost'
    }),
    item_location: createUpdate('item-location', {
      request: actions.REORDER_ITEM_LOCATION_REQUEST,
      success: actions.REORDER_ITEM_LOCATION_SUCCESS,
      failure: actions.REORDER_ITEM_LOCATION_FAILURE,
      failure_message: 'Unable to update item location'
    })
  };
  const changeItem = createFinalizeUpdate(actions.UPDATE_ITEM_SUCCESS);
  const changeItemCost = createFinalizeUpdate(actions.UPDATE_ITEM_COST_SUCCESS);
  const updateItemLocation = createUpdate('item-location', {
    request: actions.UPDATE_ITEM_LOCATION_REQUEST,
    success: actions.UPDATE_ITEM_LOCATION_SUCCESS,
    failure: actions.UPDATE_ITEM_LOCATION_FAILURE,
    failure_message: 'Unable to update item location'
  });
  const changeItemLocation = createFinalizeUpdate(actions.UPDATE_ITEM_LOCATION_SUCCESS);
  const updateItemBreakdown = createUpdate('breakdown', {
    request: actions.UPDATE_ITEM_BREAKDOWN_REQUEST,
    success: actions.UPDATE_ITEM_BREAKDOWN_SUCCESS,
    failure: actions.UPDATE_ITEM_BREAKDOWN_FAILURE,
    failure_message: 'Unable to update item breakdown'
  });
  return {
    loadOrder: order_id => dispatch(createLoadOrder(order_id)),
    loadProduct: (product_id, data) => dispatch(createFetchProduct(product_id, data)),
    onReorder: type => (id, previous_value) => value => {
      dispatch(reorder[type](id, 'display_order', previous_value, value));
    },
    onUpdateItem: (item_id, field, previous_value, field_lock = 'unit_cost') => value => {
      dispatch(updateItem(item_id, field, previous_value, value, { field_lock }));
    },
    onChangeItem: (item_id, field, previous_value) => value => {
      dispatch(changeItem(item_id, field, previous_value, value));
    },
    onAddItemCost: (item_id, item_location_id, quantity, item_cost_type = 'MISCELLANEOUS', item_cost_title = null, item_decoration_id = null, ext_cost_id = null) => {
      dispatch(createAddItemCost(item_id, item_location_id, quantity, false, item_cost_type, item_cost_title, item_decoration_id, ext_cost_id));
    },
    onUpdateItemCost: breakdown_cost => (item_cost_id, field, previous_value, field_lock = 'unit_cost') => value => {
      dispatch(updateItemCost(item_cost_id, breakdown_cost, field, previous_value, value, field_lock));
    },
    onChangeItemCost: (item_cost_id, field, previous_value) => value => {
      dispatch(changeItemCost(item_cost_id, field, previous_value, value));
    },
    onDeleteItemCost: item_cost_id => {
      dispatch(createDeleteItemCost(item_cost_id));
    },
    onAddItemLocation: (item_id, item_location_ids, target_type, copy_pricing, ext_location) => {
      dispatch(createAddItemLocation(item_id, item_location_ids, target_type, copy_pricing, ext_location));
    },
    onUpdateItemLocation: (item_location_id, field, previous_value) => value => {
      dispatch(updateItemLocation(item_location_id, field, previous_value, value));
    },
    onChangeItemLocation: (item_location_id, field, previous_value) => value => {
      dispatch(changeItemLocation(item_location_id, field, previous_value, value));
    },
    onDeleteItemLocation: item_location_id => {
      dispatch(createDeleteItemLocation(item_location_id));
    },
    onAddItemBreakdown: (item_id, data = {}) => {
      // data: { product_sku_id, quantity, size_id, color_id }
      return dispatch(createAddBreakdown(item_id, null, data));
    },
    onUpdateItemBreakdownDimensions: (item_breakdown_id, product_id, previous_size_id, previous_color_id) => (breakdown_id, size_id, size_name, color_id, color_name, quantity, field_lock, unit_price) => {
      return dispatch(updateBreakdownDimensions(breakdown_id, product_id, { previous_size_id, size_id, size_name, quantity, field_lock, unit_price }, { previous_color_id, color_id, color_name }));
    },
    onUpdateItemBreakdown: (item_breakdown_id, field, previous_value, field_lock = 'unit_cost', exchange_rate = 1.0) => value => {
      dispatch(updateItemBreakdown(item_breakdown_id, field, previous_value, value, { field_lock, exchange_rate }));
    },
    onDeleteItemBreakdown: breakdown_id => {
      dispatch(createDeleteBreakdown(breakdown_id));
    },
    onEditArtwork: (account_id, account_type) => (item_id, item_location_id, item_decoration_id, imprint_id, ext_artwork_id) => (artwork, locked) => {
      dispatch(createArtworkPopup({ item_id, item_location_id, item_decoration_id, imprint_id, ext_artwork_id, ...artwork }, locked, null, account_id, account_type));
    },
    onCreateSelectFilePopup: (item_id) => {
      return dispatch(createSelectFilePopup(
        null, item_id, 'ITEM', ownProps.order.client_id, true, {autoHideOnError: ['item']}
      ));
    },
    onCopyItemBreakdown: (breakdown_id) => {
      dispatch(createAddBreakdown(breakdown_id, 'COPY'));
    },
    onCreateSelectDecorationProductPopup: (order_number, order_type, item_id) => {
      dispatch(createSelectDecorationProductPopup(order_number, order_type, item_id));
    },
    onCreateCheckInventorylevelPopup: (sku, api_name, item_id) => {
      dispatch(createCheckInventoryLevelPopup(sku, api_name, item_id));
    },
    onCreateUpdateNetCostPopup: (item, locked) => {
      dispatch(createUpdateNetCostPopup(item, locked));
    },
    onCreateFetchProductWarnings: (item_id, product_id) => {
      dispatch(createFetchProductWarnings(item_id, product_id));
    },
    onCheckProductWarnings: (item_id, product_id) => {
      return dispatch(createCheckProductWarnings(item_id, product_id));
    },
    onSelectClientDisplayOptions: (item_id) => dispatch(createClientDisplayOptionsPopup(item_id)),
    onAddItemWarning: () => dispatch(createAddItemWarning(ownProps.params.item_id)),
    onDeleteItemWarning: item_warning_id => dispatch(createDeleteItemWarning(item_warning_id)),
    onUpdateItemWarning: item_warning => warning => dispatch(updateItemWarning(item_warning.item_warning_id, 'warning', item_warning.warning, warning)),
    onCreateCheckPSInventoryLevelPopup: (product_id, item_id, sku, api_name) => dispatch(createCheckPSInventoryLevelPopup(product_id, item_id, sku, api_name)),
    onAddBreakdownSkus: (product_id, onAdd) => dispatch(createSelectBreakdownSkuByColorPopup(
      product_id,
      null,
      null,
      onAdd,
      null,
      null,
      null
    )),
    onCreateApplyMatrixToItemPopup: (data) => {
      return dispatch(createApplyMatrixToItemPopup(data));
    },
    checkItemInCollection: (item_id) => dispatch(createCheckItemInCollection(item_id)),
    onUploadFile: (parent_id, parent_type, file) => dispatch(createUploadFile(parent_id, parent_type, file))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OrderItem);
