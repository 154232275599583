import React from 'react';
import { AddIcon, Dropzone } from "@commonsku/styles";
import PopUpInfoMenu from '../popups/PopUpInfoMenu';

const dropZoneStyle = {
    border: '2px dashed #5FE6FA',
    background: '#EDF4F7',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    fontFamily: 'sans-serif',
    width: '100%',
    height: '100%',
};

const UploadDropZone = (props) => {
    const {
        onUploadFile,
        height = '100%',
        imageGuideLineBody,
        popupType
    } = props;

    return (
        <div style={{width: '100%', height: height || '100%', minHeight: '200px' }}>
            <Dropzone onDrop={onUploadFile} style={dropZoneStyle} multiple={false} accept="image/*">
                <>
                    <AddIcon color="#00A0B6" size="huge"
                        width={96}
                        height={96}
                        viewBox={"0 0 24 24"}
                    />
                    <span style={{fontWeight: 600, color: '#899CA9', fontSize: '1.5rem'}}> Upload Image </span>
                    <br/>
                    <span style={{fontWeight: 400, color: '#899CA9', fontSize: '1rem'}}>or drag and drop</span>
                </>
            </Dropzone>
            <PopUpInfoMenu infoPanelHeight={height} children={imageGuideLineBody} infoMenuSize={popupType === 'SELECT_LOGO_IMAGE' ? 'LARGE' : 'DEFAULT'} menuButtonText='Image Guidelines'/>
        </div>
    );
};

export default UploadDropZone;